import React, { useState } from "react";
import { MinusIcon, PlusIcon } from "../../../assets";
import QuantityMeasureStyles from "../../../styles/components/quantityMeasure";
import LineItemCardStyles from "../../../styles/components/inspectionLineItemCard";

function QuantityMeasure({
  number,
  qty,
  setProductQuantity,
  disableQuantity,
  inspectionStatus,
  showPrice,
  price,
  sendQuantity = (val) => val,
  mandatory,
  ...props
}) {
  const [quantity, setQuantity] = useState(qty ? parseInt(qty) : 1);

  const increaseQuantity = () => {
    if (inspectionStatus === "InProgress") {
      setQuantity(quantity + 1);
      sendQuantity(quantity + 1);
    }
  };

  const decreaseQuantity = () => {
    if (quantity > 1 && inspectionStatus === "InProgress") {
      setQuantity(quantity - 1);
      sendQuantity(quantity - 1);
    }
  };

  const changeQuantity = (val) => {
    setQuantity(val);
    sendQuantity(val);
  };
  return (
    <>
    { mandatory&&<span className={QuantityMeasureStyles.mandatory}> *</span>}
    <div className="flex justify-between w-full">
      <div
        className={QuantityMeasureStyles.container}
        style={{
          backgroundColor:
            !disableQuantity && inspectionStatus === "InProgress"
              ? "white"
              : "#C8C8C8",
        }}
      >
        <span
          className={QuantityMeasureStyles.decreaseQuantity}
          onClick={decreaseQuantity}
          style={{
            backgroundColor:
              !disableQuantity && inspectionStatus === "InProgress"
                ? "#ECF5FF"
                : "#C8C8C8",
          }}
        >
          {!disableQuantity && inspectionStatus === "InProgress" && (
            <MinusIcon />
          )}
        </span>
        <input
          type="number"
          min={"0"}
          style={{
            backgroundColor:
              !disableQuantity && inspectionStatus === "InProgress"
                ? "#FFF"
                : "#C8C8C8",
          }}
          className={QuantityMeasureStyles.number}
          value={quantity}
          onChange={(e) =>
            !disableQuantity &&
            inspectionStatus === "InProgress" &&
            changeQuantity(e.target.value)
          }
        />

        <span
          className={QuantityMeasureStyles.increaseQuantity}
          onClick={increaseQuantity}
          style={{
            backgroundColor:
              !disableQuantity && inspectionStatus === "InProgress"
                ? "#ECF5FF"
                : "#C8C8C8",
            cursor: inspectionStatus === "InProgress" ? "pointer" : "default",
          }}
        >
          {!disableQuantity && inspectionStatus === "InProgress" && (
            <PlusIcon />
          )}
        </span>
      </div>
      {showPrice && (
        <label className={LineItemCardStyles.addProductPriceContainer}>
          Price: $ {price}
        </label>
      )}
    </div>
    </>
  );
}

export default QuantityMeasure;
