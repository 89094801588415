const QuantityMeasureStyles = {
  container: "flex border border-[#ECF5FF] rounded-md",
  decreaseQuantity:
    "w-[25px] h-[35.11px] p-1 rounded-l-[4px] flex items-center justify-center hover:bg-blue-100 cursor-pointer bg-[#ECF5FF]",
  number:
    "w-[40px] h-[35.11px]p-1 flex items-center justify-center font-lato font-semibold text-[15px] text-[#383838] text-center focus:outline-none",
  mandatory: "text-[#EC0D0D]",
  increaseQuantity:
    "w-[25px] h-[35.11px] p-1 rounded-r-[4px] flex items-center justify-center hover:bg-blue-100 cursor-pointer bg-[#ECF5FF]",
};

export default QuantityMeasureStyles;
