import React, { useEffect, useState } from "react";
import { Table } from "rsuite";
import { CheckCell } from "../../components/index";
import { SortIcon } from "../../assets";
const { Column, HeaderCell, Cell } = Table;

const StatusCell = ({ rowData, ...props }) => {
  return (
    <Cell
      className="font-lato font-normal text-[14px] text-[#25282B]"
      height={50}
      {...props}
    >
      <div
        className="font-lato font-normal text-[12px] p-[2px] w-[92px] rounded-[16px] text-center"
        style={{
          backgroundColor:
            rowData.status === "InProgress"
              ? "#ECF5FF"
              : rowData.status === "Open"
              ? "#E3FCEF"
              : rowData.status === "Cancelled"
              ? "#C8C8C8"
              : rowData.status === "Completed"
              ? "#E6FEED"
              : "#ECF5FF",
        }}
      >
        {rowData?.status === "NotAssigned" ? "InProgress" : rowData?.status}
      </div>
    </Cell>
  );
};

function InspectionGrid({
  tableData,
  checkedRows = (val) => val,
  isLoading,
  sort,
  sendSelectedRow = (val) => val,
  disableSorting,
  selectedStatuses,
  selectedProperties
}) {
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [prevSortByColumn, setPrevSortByColumn] = useState("");
  const [sortOrder, setSortOrder] = useState(1);
  const [baseClassName, setBaseClassName] = useState(
    "border-b-[1px] border-solid border-[#C8C8C8]"
  );
  let sortObject = {};

  const handleCheck = (value, checked) => {
    const keys = checked
      ? [...checkedKeys, value]
      : checkedKeys.filter((item) => item !== value);

    const statuses = keys.length === 1 && keys.every((key) => {
      const inspection = tableData.find((item) => item.id === key);
      return inspection && (inspection?.status === 'Completed' || inspection?.status === 'InProgress');
    });
    selectedStatuses(statuses);
    setCheckedKeys(keys);
    checkedRows(keys);

    let PropertyId;
    const propertySelected = keys.length > 0 && keys.every((key, index) => {
      const inspection = tableData.find((item) => item.id === key);
      if (index === 0) {
        PropertyId = inspection?.property?.id;
        return inspection?.status === 'Completed';
      } else {
        return inspection?.status === 'Completed' && inspection?.property?.id === PropertyId;
      }
    });

    selectedProperties(propertySelected);
    setCheckedKeys(keys);
    checkedRows(keys);
  };

  const handleSort = (column) => {
    if (disableSorting) {
      return;
    }
    sortObject = { sort_by: column, sort_order: sortOrder };
    if (prevSortByColumn === column) {
      sortObject.sort_order = -1 * sortObject.sort_order;
      setSortOrder(sortObject.sort_order);
    }
    setPrevSortByColumn(column);
    if (tableData?.length > 1) sort(sortObject);
  };

  useEffect(() => {
    setCheckedKeys([]);
    const rowClassName = () => {
      if (tableData && tableData?.length > 1) {
        setBaseClassName(
          "border-b-[1px] flex align-center border-solid border-[#C8C8C8]"
        );
      } else {
        setBaseClassName("border-0");
      }
    };
    rowClassName();
  }, [tableData])

  return (
    <Table
      className="w-full border mb-[100px]"
      data={tableData}
      rowClassName={baseClassName}
      loading={isLoading}
      height={tableData && tableData?.length > 1 ? 315 : 100}
      locale={{
        emptyMessage: (
          <div className="flex pl-6 italic font-lato text-[16px]">
            No Records Found
          </div>
        ),
        loading: "Loading",
      }}
      onRowClick={(rowData) => {
        sendSelectedRow(rowData);
      }}
    >
      <Column width={150}>
        <HeaderCell
          style={{
            backgroundColor: "#E9ECF0",
          }}
          className="font-lato font-medium text-[16px] text-[#25282B] text-left cursor-pointer"
        >
          <span
            className="gap-2 flex justify-center items-center"
            onClick={() => handleSort("id")}
          >
            Insp # <SortIcon />
          </span>
        </HeaderCell>

        <CheckCell
          checkedKeys={checkedKeys}
          onChange={handleCheck}
          dataKey="id"
        />
      </Column>

      <Column width={570}>
        <HeaderCell
          style={{
            backgroundColor: "#E9ECF0",
            display: "flex",
            alignItems: "center",
            handleSort,
          }}
          className="font-lato font-medium text-[16px] text-[#25282B] cursor-pointer"
        >
          <span
            className="gap-2 flex justify-center items-center"
            onClick={() => handleSort("inspection_name")}
          >
            Inspection Title <SortIcon />
          </span>
        </HeaderCell>
        <Cell
          dataKey="name"
          height={50}
          className="font-lato font-normal text-[14px] text-[#25282B]"
        />
      </Column>
      <Column width={150}>
        <HeaderCell
          style={{
            backgroundColor: "#E9ECF0",
            display: "flex",
            alignItems: "center",
          }}
          className="font-lato font-medium text-[16px] text-[#25282B] cursor-pointer"
        >
          <span
            className="gap-2 flex justify-center items-center"
            onClick={() => handleSort("inspection_status_id")}
          >
            Status <SortIcon />
          </span>
        </HeaderCell>
        <StatusCell dataKey="status" />
      </Column>
      <Column width={150} flexGrow={4}>
        <HeaderCell
          style={{
            backgroundColor: "#E9ECF0",
            display: "flex",
            alignItems: "center",
          }}
          className="font-lato font-medium text-[16px] text-[#25282B] cursor-pointer"
        >
          <span
            className="gap-2 flex justify-center items-center"
            onClick={() => handleSort("property_id")}
          >
            Property <SortIcon />
          </span>
        </HeaderCell>
        <Cell
          dataKey="property.name"
          height={50}
          className="font-lato font-normal text-[14px] text-[#25282B]"
        />
      </Column>
    </Table>
  );
}

export default InspectionGrid;