const QuoteStyles = {
    "outerContainer"            : "flex justify-between items-center mb-[20px]",
    "imageContainer"            : "flex justify-center items-center w-[280px]",
    "textContainer"             : "flex flex-col justify-center max-w-full max-h-[219px] px-[10px] py-[20px] space-y-4",
    "centeredText"              : "flex justify-center h-[20px] font-normal text-[26px] my-[5px] text-[#383838]",
    "blueDot"                   : "inline-block w-[10px] h-[10px] bg-blue-500 rounded-full ",
    "redDot"                    : "inline-block w-[10px] h-[10px] bg-[#BE2A15] rounded-full ",
    "greenDot"                  : "inline-block w-[10px] h-[10px] bg-[#048848] rounded-full ",
    "lineItemContainer"         : "flex flex-col my-[40px]",
    "lineItem"                  : "grid grid-cols-4 w-full mb-[10px] gap-x-[50px] mt-[50px] whitespace-normal ",
    "lineItemChild"             : "flex flex-col gap-3 break-words text-[#383838]",
}

export default QuoteStyles;