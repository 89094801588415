import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#555"
      d="M8.239 3.48c.14.135.331.21.53.21.198 0 .39-.075.53-.21l.72-.691v9.782c0 .191.079.374.22.51.14.135.33.21.53.21.199 0 .39-.075.53-.21a.705.705 0 0 0 .22-.51V2.79l.72.691a.75.75 0 0 0 .535.23.778.778 0 0 0 .544-.212.717.717 0 0 0 .22-.521.696.696 0 0 0-.24-.514l-2-1.92a.766.766 0 0 0-.53-.211.766.766 0 0 0-.53.21l-2 1.92a.706.706 0 0 0-.219.51c0 .19.08.373.22.508Zm-4.72 9.274.72-.691a.75.75 0 0 1 .535-.23.776.776 0 0 1 .544.211.718.718 0 0 1 .22.522.696.696 0 0 1-.24.514l-2 1.92a.766.766 0 0 1-.53.21.766.766 0 0 1-.53-.21l-2-1.92A.721.721 0 0 1 0 12.566a.694.694 0 0 1 .22-.522.756.756 0 0 1 .543-.21.775.775 0 0 1 .536.229l.72.69V2.972c0-.19.079-.374.22-.509a.766.766 0 0 1 .53-.21c.199 0 .39.075.53.21s.22.318.22.51v9.782Z"
    />
  </svg>
)
export default SvgComponent