export const statusMappings = {
  SNotSent: "Open",
  CNotRead: "Open",
  CReceived: "Open",
  CResponded: "Open",
  SResponded: "Open",
  CDeclined: "Rejected",
  SCancelled: "Cancelled",
  PendSSign: "Open",
  PendCSign: "Open",
  Contracted: "Accepted",
  Closed: "Expired",
};

export const statusMappingsTransactionHistory = {
  SNotSent: "Open",
  CNotRead: "Sent",
  CReceived: "Sent",
  CResponded: "CResponded",
  SResponded: "SResponded",
  CDeclined: "Rejected",
  SCancelled: "Cancelled",
  PendSSign: "PendSSign",
  PendCSign: "PendCSign",
  Contracted: "Accepted",
  Closed: "Expired",
};

export const statusOptions = [
  { id: "SNotSent,CNotRead,CReceived", name: "Open" },
  { id: "Closed", name: "Expired" },
  { id: "Contracted", name: "Accepted" },
  { id: "SCancelled", name: "Cancelled" },
  { id: "CDeclined", name: "Rejected" },
];

export const statusMapping = {
  Active: "Enabled",
  Inactive: "Disabled",
};

export const quoteCustomerViewStatusMappings = {
  CReceived: "Open",
  Contracted: "Accepted",
  CDeclined: "Rejected",
  SCancelled: "Cancelled",
  CNotRead: "Sent",
  SNotSent: "Sent",
  Closed: "Expired",
};

export const customerStatusOptions = [
  { id: "Active", name: "Enabled" },
  { id: "Inactive", name: "Disabled" },
];

export const inspectionStatusOptions = [
  { id: 1904, name: "In Progress" },
  { id: 1906, name: "Completed" },
  { id: 1907, name: "Cancelled" },
];

export const propertyStatusOptions = [
  { id: "Active", name: "Enabled" },
  { id: "Inactive", name: "Disabled" },
];

export const quoteStatusOptions = { contract_matrix: "Contract Matrix" };
