import React from "react";
import "../../../styles/components/imageComponent.css"; // Import your CSS file for styling
import LineItemCardStyles from "../../../styles/components/inspectionLineItemCard";

const ImageWrapper = ({ images, onClick, defaultImage, length }) => {
  return (
    <div
      className="relative inline-block text-center justify-center h-[80px] w-[100px]"
      onClick={onClick}
    >
      <div className="relative rounded-md">
        <img
          src={
            images
              ? images
              : "https://www.salonlfc.com/wp-content/uploads/2018/01/image-not-found-scaled-1150x647.png"
          }
          alt="No"
          className={LineItemCardStyles.img}
        />
        <div
          className="absolute top-[50%] left-[50%] opacity-50 h-[80px] w-[100px] text-white cursor-pointer text-[20px] flex flex-col justify-center items-center"
          style={{ transform: "translate(-50%, -50%)" }}
        >
          <span>+ {length}</span>
        </div>
      </div>
    </div>
  );
};

export default ImageWrapper;
