import React from "react";
import { ModalWrapper, Button } from "../../components";

export const ConfirmModal = ({ openModal, closeModal, handleReject }) => {
  return (
    <ModalWrapper openModal={openModal} closeModal={closeModal}>
      <main className="flex justify-center items-center flex-col space-y-[50px] py-[44px] w-[506px]">
        <section className="font-inter text-[24px] font-medium text-[#1B2B41B8] text-center">
          Are you sure you want to reject
          <br />a Quote Matrix?
        </section>
        <section className="flex justify-center w-full space-x-[70px]">
          <Button
            componentID={"confirmModal_btnClose"}
            buttonText={"Close"}
            theme={"tertiary-without-border"}
            onClick={closeModal}
          />
          <Button
            buttonText={"Yes"}
            componentID={"confirmModal_btnYes"}
            theme={"primary-fixed-width"}
            onClick={handleReject}
          />
        </section>
      </main>
    </ModalWrapper>
  );
};
