export function isEmpty(...args) {
    for (const arg of args) {
      // Check if the argument is empty (assuming empty means null, undefined, empty string, or empty array)
      if (arg === null || arg === undefined || (typeof arg === 'string' && arg.trim() === '') || (Array.isArray(arg) && arg.length === 0)) {
        return true;
      }
    }
    // If none of the arguments are empty, return false
    return false;
}

export function checkFieldValue (arg) {
    if (arg === null || arg === undefined || arg === "") return null;
    return arg ;
}

export default isEmpty;