import { createSlice } from "@reduxjs/toolkit";

export const completeInspectionSlice = createSlice({
  name: "completeInspection",
  initialState: {
    inspections: [],
  },
  reducers: {
    setPerformInspections: (state, action) => {
      state.inspections = action.payload.inspections;
    },
  },
});

export const { setPerformInspections } = completeInspectionSlice.actions;

export default completeInspectionSlice.reducer;
