import React from "react";
import InspectionFormFieldStyles from "../../../styles/components/inspectionFormField";
import { Dropdown } from "../../index";

function InspectionFormField({
  label,
  isMulti,
  error,
  errorMessage,
  isSearchable,
  placeHolder,
  linkButton,
  options,
  isClear,
  onChange,
  value,
  componentID,
  isRequired,
  searchText,
  mode,
  isAddLabel,
  isDisable,
}) {
  return (
    <div className={InspectionFormFieldStyles.outerContainer}>
      <div className="flex">
        <div className={InspectionFormFieldStyles.labelWrapper}>{label}</div>
        {isRequired && (
          <span className={InspectionFormFieldStyles.required}>*</span>
        )}
      </div>
      <main className={InspectionFormFieldStyles.mainContainer}>
        <Dropdown
          componentID={componentID}
          error={error}
          isMulti={isMulti}
          isClear={isClear}
          isSearchable={isSearchable}
          placeHolder={placeHolder}
          options={options}
          value={value}
          onChange={onChange}
          searchText={searchText}
          isDisable={isDisable}
        />
      </main>
      {error ? (
        <div className={InspectionFormFieldStyles.errorLabelWrapper}>
          {errorMessage}
        </div>
      ) : (
        <div className={InspectionFormFieldStyles.labelOuterContainer}>
          {isAddLabel && (
            <span className={InspectionFormFieldStyles.labelInnerContainer}>
              Didn't find what you were looking for?
            </span>
          )}

          <span>{linkButton}</span>
        </div>
      )}
    </div>
  );
}

export default InspectionFormField;
