export const errorMessage = {
    400 : "Bad Request",
    401 : "You are unauthorized to access this API",
    403 : "You are forbidden from accessing this API",
    404 : "API Not Found",
    422 : "Invalid credentials used",
    409 : "Record already exists",
    429 : "Too Many Requests",
    500 : "Internal Server Error",
    502 : "Bad Gateway",
    503 : "Currently this service is unavailable",
    504 : "Gateway Timeout"
}
