import React from "react";
import CheckBoxStyles from "../../../styles/components/checkBox";

function CheckBox(componentID) {
  return (
    <div className={CheckBoxStyles.container} id={componentID}>
      <input type="checkbox" className={CheckBoxStyles.input} />
      <label className={CheckBoxStyles.label}>
        Remember me?
      </label>
    </div>
  );
}

export default CheckBox;
