import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={12}
    fill="none"
    {...props}
  >
    <path
      fill="#5B5B5B"
      d="M.9 9.005 9.3.58c.1-.1.208-.171.325-.212.116-.042.241-.063.375-.063a1.1 1.1 0 0 1 .375.063.871.871 0 0 1 .325.212l8.425 8.425c.233.233.35.525.35.875s-.125.65-.375.9a1.2 1.2 0 0 1-.875.375 1.2 1.2 0 0 1-.875-.375L10 3.43l-7.35 7.35c-.234.233-.521.35-.862.35-.342 0-.638-.125-.888-.375a1.2 1.2 0 0 1-.375-.875A1.2 1.2 0 0 1 .9 9.005Z"
    />
  </svg>
)
export default SvgComponent