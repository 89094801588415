import React, { useState, useEffect } from "react";
import { Table } from "rsuite";
import { Heading } from "../../../components";
const { Column, HeaderCell, Cell } = Table;

function TransactionHistory({ tableData, isLoading, title }) {
  const [loading, setLoading] = useState(isLoading);
  const moment = require("moment");

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  return (
    <div className={"mb-[100px]"}>
      <Heading heading={title} theme={"primary"} />
      <Table
        loading={loading}
        className="w-full border z-0"
        data={tableData}
        height={tableData && tableData?.length > 1 ? 410 : 88}
        locale={{
          emptyMessage: (
            <div className="flex pl-6 italic font-lato text-[16px]">
              No Record(s) Found
            </div>
          ),
          loading: "Loading",
        }}
      >
        <Column flexGrow={15}>
          <HeaderCell
            style={{
              backgroundColor: "#E9ECF0",
              display: "flex",
              alignItems: "center",
              paddingLeft: "25px",
            }}
            className="font-lato font-medium text-[16px] text-[#25282B] text-center"
          >
            <span className="gap-2 flex justify-center items-center">
              Date & Time
            </span>
          </HeaderCell>
          <Cell style={{ paddingLeft: "25px" }}>
            {(rowData, rowIndex) => {
              return (
                <span className="font-lato font-medium text-[16px] text-[#25282B]">
                  {rowData.action_at
                    ? moment(rowData.action_at).local().format("MM/DD/YYYY hh:mmA")
                    : "-"}
                </span>
              );
            }}
          </Cell>
        </Column>
        <Column flexGrow={15}>
          <HeaderCell
            style={{
              backgroundColor: "#E9ECF0",
              display: "flex",
              alignItems: "center",
            }}
            className="font-lato font-medium text-[16px] text-[#25282B]"
          >
            <span className="gap-2 flex justify-center items-center">
              Action
            </span>
          </HeaderCell>
          <Cell>
            {(rowData, rowIndex) => {
              return (
                <span className="font-lato font-medium text-[16px] text-[#25282B]">
                  {rowData.action ? rowData.action : "-"}
                </span>
              );
            }}
          </Cell>
        </Column>
        <Column flexGrow={15}>
          <HeaderCell
            style={{
              backgroundColor: "#E9ECF0",
              display: "flex",
              alignItems: "center",
            }}
            className="font-lato font-medium text-[16px] text-[#25282B]"
          >
            <span className="gap-2 flex justify-center items-center">User</span>
          </HeaderCell>
          <Cell>
            {(rowData, rowIndex) => {
              return (
                <span className="font-lato font-medium text-[16px] text-[#25282B]">
                  {rowData.user?.first_name ? rowData.user?.first_name : "-"}
                </span>
              );
            }}
          </Cell>
        </Column>
      </Table>
    </div>
  );
}

export default TransactionHistory;
