import React from "react";
import ProgressBarStyles from "../../../styles/components/progressBar";

function ProgressBar({ percentage, steps }) {
  return (
    <div className={ProgressBarStyles.container}>
      <div className={ProgressBarStyles.progressBarOuterContainer}>
        <div
          className={ProgressBarStyles.progressBarInnerContainer}
          style={{ width: percentage }}
        ></div>
      </div>

      <div className={ProgressBarStyles.text}>{steps} of 4 steps</div>
    </div>
  
  );
}

export default ProgressBar;
