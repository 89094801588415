import React, { useState, useEffect } from "react";
import UnitNumberStyles from "../../../styles/components/unitNumber";

function UnitNumber({
  label,
  type,
  value,
  onChange,
  data,
  error,
  errorText,
  mandatory,
  mode,
  id,
}) {
  const [unitNumbers, setUnitNumbers] = useState(data);

  useEffect(() => {
    setUnitNumbers(data);
  }, [data]);
  
  const handleUnitNumberChange =  (unit, index, unitID) => {
    setUnitNumbers((prevUnitNumbers) => {
      const updatedUnitNumbers = [...prevUnitNumbers];
      if (mode === "edit") updatedUnitNumbers[index] = {id: unitID, name: unit};
      else updatedUnitNumbers[index] = unit;
      onChange(updatedUnitNumbers, id);
      return updatedUnitNumbers;
    });
  };

  return (
    <div className="flex justify-between">
      {unitNumbers.map((el, index) => (
        <input
          id={`unitNumber_txt_${index}`}
          key={index}
          type={type}
          value={mode === "edit" ? el.name : el}
          onChange={(event) =>
            handleUnitNumberChange(event.target.value, index, el.id)
          }
          className={mode === "page" ? UnitNumberStyles.primary : UnitNumberStyles.secondary}
        />
      ))}
    </div>
  );
}

export default UnitNumber;