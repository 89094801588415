/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import {
  InspectionFormField,
  LinkButton,
  SlideDrawer,
  Heading,
  InputField,
  Button,
  Footer,
  DropdownField,
} from "../../components";
import { CustomerForm, PropertyForm, FloorplanForm } from "../form/Index";
import httpAPICall from "../../utils/httpApiCall";
import { useNavigate } from "react-router-dom";
import generateDropdownOptions from "../../utils/dropdownOptionsGenerator";
import {
  PROPERTIES_API_URL,
  FLOORPLANS_API_URL,
  UNITS_API_URL,
  INSPECTIONS_API_URL,
  LISTS_API_URL,
  CUSTOMERS_API_URL,
  BUILDINGS_API_URL,
  USERS_API_URL
} from "../../constants";
import moment from "moment";

function InspectionForm({ mode, copiedInspectionDetails }) {
  const navigate = useNavigate();
  const [inspectionRecord, setInspectionRecord] = useState({});
  const [customers, setCustomers] = useState([]);
  const [properties, setProperties] = useState([]);
  const [floorplans, setFloorplans] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState([]);
  const [selectedFloorplan, setSelectedFloorplan] = useState([]);
  const [selectedBuilding, setSelectedBuilding] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState("");
  const [selectedList, setSelectedList] = useState([]);
  const [units, setUnits] = useState("");
  const [lists, setLists] = useState([]);
  const [inspectionTitle, setInspectionTitle] = useState("");
  const [openCustomerDrawer, setOpenCustomerDrawer] = useState(false);
  const [openPropertyrDrawer, setOpenPropertyDrawer] = useState(false);
  const [openFloorplanDrawer, setOpenFloorplanDrawer] = useState(false);
  const searchTextLength = 4;
  const [customerSearchText, setCustomerSearchText] = useState("");
  const [propertySearchText, setPropertySearchText] = useState("");
  const [floorplanSearchText, setFloorplanSearchText] = useState("");
  const [listSearchText, setListSearchText] = useState("");
  const [isCustomer, setIsCustomer] = useState(false);
  const [isProperty, setIsProperty] = useState(false);
  const [isFloorplan, setIsFloorplan] = useState(false);
  const [isUnit, setIsUnit] = useState(false);
  const [isList, setIsList] = useState(false);
  const [isDisableSubmit, setIsDisableSubmit] = useState(true);
  const [floorplanUnitCount, setFloorplanUnitCount] = useState("");
  const [type, setType] = useState({ name: "Floorplan", id: "Floorplan" });
  const [floorplanDrwnClear, setFloorplanDrwnClear] = useState(false);
  const [buildingDrwnClear, setBuildingDrwnClear] = useState(false);
  const [propertyType, setPropertyType] = useState(null);
  const floorplanBuildingOptionsRef = useRef([
    { name: "Floorplan", id: "Floorplan" },
    { name: "Building", id: "Building" },
  ]);

  const fetchCustomers = async (searchText) => {
    let CUSTOMERS_API_URL =
      "/companies" + (searchText ? `?q=${searchText}` : ``);
    let result = await httpAPICall(CUSTOMERS_API_URL, "GET", {});
    let options = generateDropdownOptions(result, "name", "id");
    setCustomers(options);
    setInspectionTitle(copiedInspectionDetails?.name);
  };

  const fetchProperties = async () => {
    let params = selectedCustomer?.id
      ? `?customer_ids=${selectedCustomer?.id}`
      : "";
    if (selectedCustomer?.id) {
      let result = await httpAPICall(PROPERTIES_API_URL + params, "GET", {});
      let options = generateDropdownOptions(result, "name", "id");
      setProperties(options);
    }
  };

  const fetchFloorplans = async () => {
    let params =
      selectedCustomer?.id && selectedProperty?.id
        ? `?customer_ids=${selectedCustomer?.id}&property_ids=${selectedProperty?.id}`
        : "";
    if (selectedCustomer?.id && selectedProperty?.id) {
      let result = await httpAPICall(FLOORPLANS_API_URL + params, "GET", {});
      let options = generateDropdownOptions(result, "name", "id", true);
      setFloorplans(options);
    }
    let copyParams =
      inspectionRecord?.customer?.id && selectedProperty?.id
        ? `?customer_ids=${inspectionRecord?.customer?.id}&property_ids=${selectedProperty?.id}`
        : "";
    if (mode !== "create") {
      if (inspectionRecord?.customer?.id && selectedProperty?.id) {
        let result = await httpAPICall(
          FLOORPLANS_API_URL + copyParams,
          "GET",
          {}
        );
        let options = generateDropdownOptions(result, "name", "id", true);
        setFloorplans(options);
      }
    }
  };

  const fetchBuildings = async () => {
    let params =
      selectedCustomer?.id && selectedProperty?.id
        ? `?customer_ids=${selectedCustomer?.id}&property_ids=${selectedProperty?.id}`
        : "";
    if (selectedCustomer?.id && selectedProperty?.id) {
      let result = await httpAPICall(BUILDINGS_API_URL + params, "GET", {});
      let options = generateDropdownOptions(result, "name", "id", true);
      setBuildings(options);
    }
    let copyParams =
      inspectionRecord?.customer?.id && inspectionRecord?.property?.id
        ? `?customer_ids=${inspectionRecord?.customer?.id}&property_ids=${inspectionRecord?.property?.id}`
        : "";
    if (mode !== "create") {
      if (inspectionRecord?.customer?.id && inspectionRecord?.property?.id) {
        let result = await httpAPICall(
          BUILDINGS_API_URL + copyParams,
          "GET",
          {}
        );
        let options = generateDropdownOptions(result, "name", "id", true);
        setBuildings(options);
      }
    }
  };

  const fetchLists = async (searchText) => {
    let generatedUrl = searchText ? `?q=${searchText}` : ``;
    let result = await httpAPICall(LISTS_API_URL + generatedUrl, "GET", {});
    let options = generateDropdownOptions(result, "name", "id");
    setLists(options);
  };

  const fetchInspectionDetails = async () => {
    const result = await httpAPICall(
      `${INSPECTIONS_API_URL}/${copiedInspectionDetails?.id}`,
      "GET",
      {}
    );
    setInspectionRecord(result);
    if (result?.property?.unit?.building) {
      setType({ id: "Building", name: "Building" });
      setSelectedBuilding(result?.property?.unit?.building);
    } else {
      setType({ id: "Floorplan", name: "Floorplan" });
      setSelectedFloorplan(result?.property?.unit?.floorplan);
    }
    setSelectedList(result?.list);
    setSelectedCustomer(result?.customer);
    setSelectedProperty(result?.property);
    setSelectedUnit(result?.property?.unit);
    setUnits(result?.property?.unit?.name);
  };

  const handleSubmitCustomerForm = async (value) => {
    try {
      const response = await httpAPICall(
        CUSTOMERS_API_URL,
        "POST",
        value,
        "",
        "PMC"
      );
      setOpenCustomerDrawer(false);
      fetchCustomers();
      setSelectedCustomer({ id: response.id, name: value?.name });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmitPropertyForm = async (
    data,
    action,
    propertyID,
    onsiteManagerPayload,
    supplierPurchaseDeptEmail,
    onsiteManagerID) => {
    try {
      const property = await httpAPICall(
        PROPERTIES_API_URL,
        "POST",
        data,
        "",
        "Property"
      );
      setOpenPropertyDrawer(false);
      fetchProperties();
      setSelectedProperty({ id: property?.id, name: data?.name });
      onsiteManagerPayload.access_level.id = property?.id;
      await httpAPICall(USERS_API_URL, "POST", onsiteManagerPayload);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmitFloorplanForm = async (value) => {
    try {
      const response = await httpAPICall(
        type.name === "Floorplan" ? FLOORPLANS_API_URL : BUILDINGS_API_URL,
        "POST",
        value,
        "",
        type.name === "Floorplan" ? "Floorplan" : "Building"
      );
      setOpenFloorplanDrawer(false);
      if (type?.name === "Floorplan") {
        fetchFloorplans();
        setFloorplanDrwnClear(false);
        setSelectedFloorplan({ id: response?.id, name: value?.name });
        setFloorplanUnitCount(value?.additional_info?.planned_renovation_unit_count);
      } else if (type?.name === "Building") {
        fetchBuildings();
        setBuildingDrwnClear(false);
        setSelectedBuilding({ id: response?.id, name: value?.name });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async () => {
    if (
      selectedCustomer.length === 0 &&
      selectedProperty.length === 0 &&
      selectedFloorplan.length === 0 &&
      selectedUnit === "" &&
      selectedList.length === 0
    ) {
      setIsCustomer(true);
      setIsProperty(true);
      setIsFloorplan(true);
      setIsList(true);
      setIsUnit(true);
    } else if (
      selectedCustomer.length !== 0 &&
      selectedProperty.length === 0 &&
      selectedFloorplan.length === 0 &&
      selectedUnit === "" &&
      selectedList.length === 0
    ) {
      setIsCustomer(false);
      setIsProperty(true);
      setIsFloorplan(true);
      setIsList(true);
      setIsUnit(true);
    } else if (
      selectedCustomer.length !== 0 &&
      selectedProperty.length !== 0 &&
      selectedFloorplan.length === 0 &&
      selectedUnit === "" &&
      selectedList.length === 0
    ) {
      setIsCustomer(false);
      setIsProperty(false);
      setIsFloorplan(true);
      setIsUnit(true);
      setIsList(true);
    } else if (
      selectedCustomer.length !== 0 &&
      selectedProperty.length !== 0 &&
      selectedFloorplan.length !== 0 &&
      selectedUnit === "" &&
      units === "" &&
      selectedList.length === 0
    ) {
      setIsCustomer(false);
      setIsProperty(false);
      setIsFloorplan(false);
      setIsUnit(true);
      setIsList(true);
    } else if (
      selectedCustomer.length !== 0 &&
      selectedProperty.length !== 0 &&
      selectedFloorplan.length !== 0 &&
      units !== "" &&
      selectedList.length === 0
    ) {
      setIsCustomer(false);
      setIsProperty(false);
      setIsFloorplan(false);
      setIsUnit(false);
      setIsList(true);
    } else if (
      selectedCustomer.length !== 0 &&
      selectedProperty.length !== 0 &&
      selectedFloorplan.length !== 0 &&
      units !== "" &&
      selectedUnit !== "" &&
      selectedList.length !== 0
    ) {
      setIsCustomer(false);
      setIsProperty(false);
      setIsFloorplan(false);
      setIsUnit(false);
      setIsList(false);
    }

    let queryString =
      type?.name === "Floorplan"
        ? `floorplan_ids=${selectedFloorplan?.id}`
        : `building_ids=${selectedBuilding?.id}`;
    let params =
      selectedCustomer?.id &&
        selectedProperty?.id &&
        (type.name === "Floorplan" ? selectedFloorplan?.id : selectedBuilding?.id)
        ? `?customer_ids=${selectedCustomer?.id}&property_ids=${selectedProperty?.id}&${queryString}`
        : "";

    if (
      selectedCustomer?.id &&
      selectedProperty?.id &&
      (type.name === "Floorplan"
        ? selectedFloorplan?.id
        : selectedBuilding?.id) &&
      units !== ""
    ) {
      let result = await httpAPICall(UNITS_API_URL + params, "GET", {});
      let checkUnitExistence = result?.find((result) => result.name === units);

      if (checkUnitExistence?.id) {
        let requestPayload = {
          title: inspectionTitle,
          customer: { id: selectedCustomer?.id },
          unit: {
            id: checkUnitExistence?.id,
            [type?.name === "Floorplan" ? "floorplan" : "building"]: {
              id:
                type?.name === "Floorplan"
                  ? selectedFloorplan?.id
                  : selectedBuilding?.id,
            },
            property: { id: selectedProperty?.id },
          },
          facilgo_list: {
            id: selectedList?.id,
          },
          additional_info: {
            unit_count: type.name === "Floorplan" ? floorplanUnitCount : null,
          },
        };
        let result = await httpAPICall(
          INSPECTIONS_API_URL,
          "POST",
          requestPayload,
          "",
          "Inspection"
        );
        if (result?.id) {
          navigate(`/inspection/${result?.id}`);
        }
      } else {
        let unitRequestPayload = [
          {
            customer: {
              id: selectedCustomer?.id,
            },
            [type?.name === "Floorplan" ? "floorplan" : "building"]: {
              id:
                type?.name === "Floorplan"
                  ? selectedFloorplan?.id
                  : selectedBuilding?.id,
            },
            name: units,
          },
        ];
        let unitCreation = await httpAPICall(
          `${PROPERTIES_API_URL}/${selectedProperty?.id}/units`,
          "POST",
          unitRequestPayload,
          "",
          ""
        );
        if (unitCreation[0]?.id) {
          let inspectionRequestPayload = {
            title: inspectionTitle,
            customer: { id: selectedCustomer?.id },
            unit: {
              id: unitCreation[0]?.id,
              [type?.name === "Floorplan" ? "floorplan" : "building"]: {
                id:
                  type?.name === "Floorplan"
                    ? selectedFloorplan?.id
                    : selectedBuilding?.id,
              },
              property: { id: selectedProperty?.id },
            },
            facilgo_list: {
              id: selectedList?.id,
            },
            additional_info: {
              unit_count: type.name === "Floorplan" ? floorplanUnitCount : null,
            },
          };
          let result = await httpAPICall(
            INSPECTIONS_API_URL,
            "POST",
            inspectionRequestPayload,
            "",
            "Inspection"
          );
          if (result?.id) {
            navigate(`/inspection/${result?.id}`);
          }
        }
      }
    }
  };

  const handleCopy = async () => {
    try {
      let queryString =
        type?.name === "Floorplan"
          ? `floorplan_ids=${selectedFloorplan?.id}`
          : `building_ids=${selectedBuilding?.id}`;
      let params =
        selectedCustomer?.id &&
          selectedProperty?.id &&
          (type.name === "Floorplan" ? selectedFloorplan?.id : selectedBuilding?.id)
          ? `?customer_ids=${selectedCustomer?.id}&property_ids=${selectedProperty?.id}&${queryString}`
          : "";

      if (
        selectedCustomer?.id &&
        selectedProperty?.id &&
        (type.name === "Floorplan"
          ? selectedFloorplan?.id
          : selectedBuilding?.id) &&
        units !== ""
      ) {
        let unitResult = await httpAPICall(UNITS_API_URL + params, "GET", {});
        let checkUnitExistence = unitResult?.find((result) => result.name === units);

        if (checkUnitExistence?.id) {
          await handleCopyInspection(checkUnitExistence.id);
        } else {
          let unitRequestPayload = [
            {
              customer: {
                id: selectedCustomer?.id,
              },
              [type?.name === "Floorplan" ? "floorplan" : "building"]: {
                id:
                  type?.name === "Floorplan"
                    ? selectedFloorplan?.id
                    : selectedBuilding?.id,
              },
              name: units,
            },
          ];
          let unitCreation = await httpAPICall(
            `${PROPERTIES_API_URL}/${selectedProperty?.id}/units`,
            "POST",
            unitRequestPayload,
            "",
            "Unit"
          );

          if (unitCreation[0]?.id) {
            await handleCopyInspection(unitCreation[0].id);
          }
        }
      }
    } catch (error) {
      console.error("Error in handleCopy:", error);
    }
  };

  const handleCopyInspection = async (unitId) => {
    try {
      let inspectionRequestPayload = {
        title: inspectionTitle,
        customer: { id: selectedCustomer?.id },
        unit: {
          id: unitId,
          [type?.name === "Floorplan" ? "floorplan" : "building"]: {
            id:
              type?.name === "Floorplan"
                ? selectedFloorplan?.id
                : selectedBuilding?.id,
          },
          property: { id: selectedProperty?.id },
        },
        additional_info: {
          unit_count: type.name === "Floorplan" ? floorplanUnitCount : null,
        },
      };
      let result = await httpAPICall(
        `${INSPECTIONS_API_URL}/${inspectionRecord.id}/copy`,
        "POST",
        inspectionRequestPayload,
        "",
        "Inspection"
      );

      if (result?.id) {
        navigate(`/inspection/${result?.id}`);
      }
    } catch (error) {
      console.error("Error in handleCopyInspection:", error);
    }
  };

  useEffect(() => {
    if (mode !== "create") {
      fetchInspectionDetails();
    }
  }, [mode]);

  useEffect(() => {
    if (customerSearchText && customerSearchText.length > searchTextLength) {
      fetchCustomers(customerSearchText);
    } else if (!customerSearchText) {
      fetchCustomers(customerSearchText);
    }
  }, [customerSearchText, openCustomerDrawer]);

  useEffect(() => {
    if (propertySearchText && propertySearchText.length > searchTextLength) {
      fetchProperties();
    }
  }, [propertySearchText, openPropertyrDrawer]);

  useEffect(() => {
    if (floorplanSearchText && floorplanSearchText.length > searchTextLength) {
      fetchFloorplans();
    }
  }, [floorplanSearchText, openFloorplanDrawer]);

  useEffect(() => {
    if (selectedProperty?.name && units !== "" && selectedList?.name) {
      setInspectionTitle(
        `${selectedProperty?.name}_${units}_${selectedList?.name
        }_${moment().format("YYYYMMDD")}`
      );
    }
  }, [selectedProperty, units, selectedList, mode]);

  useEffect(() => {
    if (selectedList?.name) {
      setInspectionTitle(
        `${selectedProperty?.name}_${units}_${
          selectedList?.name
        }_${moment().format("YYYYMMDD")}`
      );
    }
  }, [units, selectedList]);

  useEffect(() => {
    if (listSearchText && listSearchText.length > searchTextLength) {
      fetchLists(listSearchText);
    }
  }, [listSearchText]);

  useEffect(() => {
    fetchProperties();
  }, [selectedCustomer]);

  useEffect(() => {
    fetchFloorplans();
  }, [selectedCustomer, selectedProperty, inspectionRecord, mode]);

  useEffect(() => {
    fetchLists();
  }, [selectedList]);

  useEffect(() => {
    if (mode === "create") {
      setSelectedProperty([]);
      setSelectedFloorplan([]);
      setFloorplanUnitCount("");
      setUnits("");
      setSelectedList([]);
      setInspectionTitle("");
    }
  }, [selectedCustomer]);

  useEffect(() => {
    if (mode === "create") {
      setSelectedFloorplan([]);
      setFloorplanUnitCount("");
    }
  }, [selectedProperty]);

  useEffect(() => {
    if (
      Object.keys(selectedCustomer).length !== 0 &&
      Object.keys(selectedProperty).length !== 0 &&
      (type.name === "Floorplan"
        ? Object.keys(selectedFloorplan).length !== 0
        : Object.keys(selectedBuilding).length !== 0) &&
      units !== "" &&
      Object.keys(selectedList).length !== 0
    ) {
      setIsDisableSubmit(false);
    } else {
      setIsDisableSubmit(true);
    }
  }, [
    selectedCustomer,
    selectedProperty,
    selectedFloorplan,
    selectedBuilding,
    type,
    inspectionTitle,
    units,
    selectedList,
    mode,
  ]);

  useEffect(() => {
    if (mode === "edit" && inspectionTitle !== "") {
      setIsDisableSubmit(false);
    }
  }, [mode, inspectionTitle]);

  return (
    <div className="mb-[200px]">
      <aside>
        <Heading
          heading={mode === "create" ? "Create Inspection" : "Copy Inspection"}
        />
      </aside>
      <main className="mt-[3.5rem]">
        <section className="flex space-x-[75px] relative z-50">
          <InspectionFormField
            label={"PMC"}
            errorMessage={"Please select PMC"}
            error={isCustomer}
            isMulti={false}
            isAddLabel={true}
            placeHolder={"PMC"}
            isSearchable={true}
            isDisable={mode === "edit" ? true : false}
            componentID={"inspection_txtCustomer"}
            value={
              mode === "create" ? selectedCustomer : inspectionRecord?.customer
            }
            options={mode === "create" ? customers : selectedCustomer}
            isRequired={true}
            mode={mode}
            searchText={(searchText) => setCustomerSearchText(searchText)}
            linkButton={
              <LinkButton
                isDisable={mode === "edit" ? true : false}
                label={"Add New PMC"}
                theme={
                  mode === "edit" ? "secondary-with-disabled" : "secondary"
                }
                onClick={() => setOpenCustomerDrawer(true)}
              />
            }
            onChange={(value) => {
              setSelectedCustomer(value);
            }}
          />
          <SlideDrawer
            openDrawer={openCustomerDrawer}
            setOpenDrawer={() => setOpenCustomerDrawer(false)}
            componentID={"inspection_customerDrawer"}
            label={"Add New PMC"}
          >
            <CustomerForm
              mode={"create"}
              handleCancel={() => setOpenCustomerDrawer(false)}
              sendCustomerName={selectedCustomer}
              passDataToNextSection={(value) => handleSubmitCustomerForm(value)}
            />
          </SlideDrawer>
          <InspectionFormField
            label={"Property"}
            placeHolder={"Property"}
            errorMessage={"Please select property"}
            error={isProperty}
            isAddLabel={true}
            componentID={"inspection_txtProperty"}
            isMulti={false}
            isSearchable={true}
            mode={mode}
            isRequired={true}
            value={selectedProperty}
            options={properties}
            searchText={(searchText) => setPropertySearchText(searchText)}
            linkButton={
              <LinkButton
                label={"Add New Property"}
                onClick={() => setOpenPropertyDrawer(true)}
              />
            }
            onChange={(value) => {
              setIsProperty(false);
              setSelectedProperty(value);
              setSelectedFloorplan([]);
            }}
          />
          <SlideDrawer
            openDrawer={openPropertyrDrawer}
            setOpenDrawer={() => setOpenPropertyDrawer(false)}
            componentID={"inspection_propertyDrawer"}
            label={"Add New Property"}
          >
            <PropertyForm
              handleCancel={() => setOpenPropertyDrawer(false)}
              passDataToNextSection={handleSubmitPropertyForm}
              selectedCustomer={selectedCustomer}
              mode={"create"}
            />
          </SlideDrawer>
        </section>
        <section className="w-[432px] mt-[32px] ">
          <DropdownField
            label={"Floorplan/Building"}
            options={floorplanBuildingOptionsRef.current}
            theme={"secondary"}
            mandatory={true}
            componentID={"addFloorplanBuilding_dwnType"}
            value={type}
            onChange={(type) => {
              setSelectedFloorplan("");
              setUnits("");
              setSelectedBuilding("");
              setFloorplanDrwnClear(true);
              setBuildingDrwnClear(true);
              setType(type);
              type.name === "Floorplan" ? fetchFloorplans() : fetchBuildings();
            }}
          />
        </section>
        <section className="flex space-x-[75px] relative z-40 mt-[32px]">
          {type?.name === "Floorplan" ? (
            <>
              <InspectionFormField
                label={"Floorplan"}
                isMulti={false}
                errorMessage={"Please select floorplan."}
                error={isFloorplan}
                placeHolder={"Floorplan"}
                componentID={"inspection_txtFloorplan"}
                isSearchable={true}
                isAddLabel={true}
                mode={mode}
                isClear={floorplanDrwnClear}
                isRequired={true}
                value={selectedFloorplan}
                options={floorplans}
                searchText={(searchText) => setFloorplanSearchText(searchText)}
                linkButton={
                  <LinkButton
                    label={"Add New Floorplan"}
                    onClick={() => {
                      setOpenFloorplanDrawer(true);
                      setPropertyType("Floorplan");
                    }}
                  />
                }
                onChange={(value) => {
                  setFloorplanDrwnClear(false);
                  setIsFloorplan(false);
                  setSelectedFloorplan(value);
                  setFloorplanUnitCount(
                    value?.additional_info?.additional_info
                      ?.planned_renovation_unit_count
                  );
                }}
              />
              <span className="flex justify-between w-[433px]">
                <InputField
                  label={"Floorplan Unit Count"}
                  theme={"secondary"}
                  componentID={"inspection_txtFloorplanUnitCount"}
                  isDisabled={
                    floorplanUnitCount || mode === "edit" || mode === "create"
                      ? true
                      : false
                  }
                  value={
                    mode === "create"
                      ? floorplanUnitCount
                      : inspectionRecord?.property?.unit?.floorplan
                        ?.additional_info?.planned_renovation_unit_count
                  }
                  onChange={(e) => setFloorplanUnitCount(e.target.value)}
                />
                <span className="w-[223px] space-y-2">
                  <InputField
                    label={"Unit Number"}
                    theme={"secondary"}
                    mandatory={true}
                    errorText={"Please enter unit number."}
                    error={isUnit}
                    componentID={"inspection_txtUnitNumber"}
                    value={units}
                    onChange={(e) => {
                      if (e.target.value === "") setIsUnit(true);
                      else setIsUnit(false);
                      setUnits(e.target.value);
                    }}
                  />
                </span>
              </span>
            </>
          ) : (
            <>
              <InspectionFormField
                label={"Building"}
                isMulti={false}
                errorMessage={"Please select building."}
                error={isFloorplan}
                placeHolder={"Building Name"}
                componentID={"inspection_txtBuilding"}
                isSearchable={true}
                isAddLabel={true}
                mode={mode}
                isClear={buildingDrwnClear}
                isRequired={true}
                value={selectedBuilding}
                options={buildings}
                searchText={(searchText) => setFloorplanSearchText(searchText)}
                linkButton={
                  <LinkButton
                    label={"Add New Building"}
                    onClick={() => {
                      setOpenFloorplanDrawer(true);
                      setPropertyType("Building");
                    }}
                  />
                }
                onChange={(value) => {
                  setBuildingDrwnClear(false);
                  setIsFloorplan(false);
                  setSelectedBuilding(value);
                }}
              />
              <span className="w-[223px] space-y-2">
                <InputField
                  label={"Building Number"}
                  theme={"secondary"}
                  mandatory={true}
                  errorText={"Please enter building number."}
                  error={isUnit}
                  componentID={"inspection_txtBuildingNumber"}
                  value={units}
                  onChange={(e) => {
                    if (e.target.value === "") setIsUnit(true);
                    else setIsUnit(false);
                    setUnits(e.target.value);
                  }}
                />
              </span>
            </>
          )}
        </section>
        <section className="flex space-x-[75px] relative z-0 mt-[32px]">
          <InspectionFormField
            label={"List"}
            isMulti={false}
            errorMessage={"Please select list."}
            error={isList}
            componentID={"inspection_list"}
            placeHolder={"List"}
            isSearchable={true}
            isDisable={mode === "edit" ? true : false}
            isRequired={true}
            value={selectedList}
            theme={
              mode === "edit" ? "secondary-with-disabled" : "secondary"
            }
            options={lists}
            onChange={(e) => {
              setIsList(false);
              setSelectedList(e);
            }}
            searchText={(searchText) => setListSearchText(searchText)}
          />
          <span className="w-[433px]">
            <InputField
              label={"Inspection Title"}
              theme={"secondary"}
              componentID={"inspection_txtInspectionTitle"}
              value={inspectionTitle}
              mandatory={true}
              onChange={(event) => {
                setInspectionTitle(event.target.value);
              }}
              type={"text"}
            />
          </span>
        </section>
        <SlideDrawer
          openDrawer={openFloorplanDrawer}
          componentID={"inspection_floorplanDrawer"}
          setOpenDrawer={() => setOpenFloorplanDrawer(false)}
          label={"Add New Floorplan/Building"}
        >
          <FloorplanForm
            handleCancel={() => setOpenFloorplanDrawer(false)}
            passDataToNextSection={(val) => handleSubmitFloorplanForm(val)}
            selectedCustomer={selectedCustomer}
            mode={"create"}
            propertyType={propertyType}
            selectedProperty={selectedProperty}
            isPropertyType={true}
          />
        </SlideDrawer>
      </main>
      {!openCustomerDrawer && !openPropertyrDrawer && !openFloorplanDrawer && (
        <Footer theme={"primary"}>
          <Button
            theme={"tertiary"}
            buttonText={"Cancel"}
            componentID={"inspection_btnCancel"}
            onClick={() => navigate("/inspections")}
          />
          <Button
            theme={"primary-with-disabled"}
            componentID={"inspection_btnCreate"}
            disabled={isDisableSubmit}
            buttonText={mode === "create" ? "Create" : "Save"}
            onClick={() => {
              mode === "create" ? handleSubmit() : handleCopy();
            }}
          />
        </Footer>
      )}
    </div>
  );
}

export default InspectionForm;
