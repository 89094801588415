import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={25}
    height={25}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.33307 7.99984V3.99984C9.33307 3.26346 9.93003 2.6665 10.6664 2.6665H26.6664C27.4028 2.6665 27.9997 3.26346 27.9997 3.99984V22.6665C27.9997 23.4029 27.4028 23.9998 26.6664 23.9998H22.6664V27.9986C22.6664 28.7357 22.0665 29.3332 21.324 29.3332H5.34221C4.60079 29.3332 4 28.7404 4 27.9986L4.00347 9.33433C4.0036 8.59732 4.60352 7.99984 5.34589 7.99984H9.33307ZM6.66989 10.6665L6.66692 26.6665H19.9997V10.6665H6.66989ZM11.9997 7.99984H22.6664V21.3332H25.3331V5.33317H11.9997V7.99984Z"
      fill="#5B5B5B"
    />
  </svg>
);
export default SVGComponent;
