import React from "react";
import ProgressBarStyles from "../../../styles/components/progressBar";

function InspectionProgressBar({ percentage, componentID, progressMessage }) {
  return (
    <div className={ProgressBarStyles.secondaryContainer} id={componentID}>
      {progressMessage !== "Cancelled" && (
        <div className={ProgressBarStyles.secondaryPercentageText}>
          {percentage}% Completed
        </div>
      )}
      {progressMessage !== "Cancelled" && (
        <div className={ProgressBarStyles.secondaryProgressBarOuterContainer}>
          <div
            className={ProgressBarStyles.secondaryProgressBarInnerContainer}
            style={{ width: `${percentage}%` }}
          ></div>
        </div>
      )}
      {progressMessage !== "Cancelled" ? (
        <div className={ProgressBarStyles.secondaryStatusContainer}>
          <label className={ProgressBarStyles.secondaryStatusLabel}>
            {progressMessage}
          </label>
        </div>
      ) : (
        <div className={ProgressBarStyles.tertiaryStatusContainer}>
          <label className={ProgressBarStyles.tertiaryStatusLabel}>
            Cancelled
          </label>
        </div>
      )}
    </div>
  );
}

export default InspectionProgressBar;
