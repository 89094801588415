import axios from "axios";
import { errorMessage } from "../constants/messages";
import { webStore } from "../redux/index";
import { Simple as SimpleAlert } from "@reusejs/react-alerts";

axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;

axios.defaults.headers.common = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

function getSuccessMessage(type, method) {
  const action =
    method === "POST"
      ? "Added"
      : method === "PUT"
      ? "Information Updated"
      : method === "DELETE"
      ? "Deleted"
      : "";
  return `${type} ${action} Successfully`;
}

async function httpAPICall(url, method, payload, extraHeaders, type, message, responseHeaders) {
  try {
    let result = await axios.request({
      method: method,
      url: url,
      headers: {
        common: {
          Authorization: `Bearer ${webStore.getState().auth.accessToken}`,
        },
      },
      data: payload,
    });
    if (
      (method === "POST" || method === "PUT" || method === "DELETE") &&
      type !== "Login"
    ) {
      (type || message) &&
        SimpleAlert({
          message: message ? message : getSuccessMessage(type, method),
          borderClasses: "border-b-2 border-green-400",
          wrapperClasses: "inset-x-0 left-0 bottom-[75px] flex w-full z-[1000]",
          alignmentClasses: "w-full items-start",
        });
    }

    return responseHeaders ? result : result.data;
  } catch (error) {
    if (method === "POST" || method === "PUT" || method === "DELETE") {
      console.log(error, "error in unit", error.response?.status);
      SimpleAlert({
        message:
          error.response?.status === 422 || error.response?.status === 409
            ? error.response?.data["errors"]
                .map((error) => error?.message)
                .join("\n")
            : errorMessage[error.response?.status],
        borderClasses: "border-b-2 border-red-400",
        wrapperClasses: "inset-x-0 left-0 bottom-[75px] flex w-full z-[1000]",
        alignmentClasses: "w-full items-start",
      });
    }
  }
}

export default httpAPICall;
