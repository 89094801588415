import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={25}
    height={25}
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_744_3737)">
      <path
        d="M20.4167 27.7083H23.3333V20.4167H10.2083V7.29167H2.91667V10.2083H5.83333V13.125H2.91667V16.0417H7.29167V18.9583H2.91667V21.875H5.83333V24.7917H2.91667V27.7083H5.83333V24.7917H8.75V27.7083H11.6667V23.3333H14.5833V27.7083H17.5V24.7917H20.4167V27.7083ZM13.125 17.5H24.7917C25.1784 17.5 25.5494 17.6536 25.8229 17.9271C26.0964 18.2006 26.25 18.5716 26.25 18.9583V29.1667C26.25 29.5534 26.0964 29.9244 25.8229 30.1979C25.5494 30.4714 25.1784 30.625 24.7917 30.625H1.45833C1.07156 30.625 0.700626 30.4714 0.427136 30.1979C0.153645 29.9244 0 29.5534 0 29.1667V5.83333C0 5.44656 0.153645 5.07563 0.427136 4.80214C0.700626 4.52865 1.07156 4.375 1.45833 4.375H11.6667C12.0534 4.375 12.4244 4.52865 12.6979 4.80214C12.9714 5.07563 13.125 5.44656 13.125 5.83333V17.5Z"
        fill="#5B5B5B"
      />
    </g>
    <defs>
      <clipPath id="clip0_744_3737">
        <rect width={35} height={35} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SVGComponent;
