import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={11}
    fill="none"
    {...props}
  >
    <path
      fill="#5B5B5B"
      d="m18.575 2.15-8.4 8.425c-.1.1-.208.17-.325.212a1.099 1.099 0 0 1-.375.063c-.133 0-.258-.021-.375-.063a.871.871 0 0 1-.325-.212L.35 2.15A1.187 1.187 0 0 1 0 1.275c0-.35.125-.65.375-.9A1.2 1.2 0 0 1 1.25 0a1.2 1.2 0 0 1 .875.375l7.35 7.35 7.35-7.35c.233-.234.52-.35.862-.35.342 0 .638.125.888.375s.375.541.375.875a1.2 1.2 0 0 1-.375.875Z"
    />
  </svg>
)
export default SvgComponent