/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import {
  ProgressBar,
  Heading,
  AccordionSlider,
  VerticalDivider,
  Footer,
  Button,
  LinkButton,
} from "../../components";
import {
  CustomerForm,
  FloorplanForm,
  PropertyForm,
  UnitForm,
} from "../form/Index";
import {
  CUSTOMERS_API_URL,
  PROPERTIES_API_URL,
  FLOORPLANS_API_URL,
  UNITS_API_URL,
  USERS_API_URL,
  BUILDINGS_API_URL,
} from "../../constants";
import httpAPICall from "../../utils/httpApiCall";
import { useNavigate } from "react-router-dom";
import { CloseIcon } from "../../assets";

function CreateForm() {
  const navigate = useNavigate();
  const [customerData, setCustomerData] = useState([]);
  const [quoteApproverData, setQuoteApproverData] = useState([]);
  const customerErrorRef = useRef(null);
  const propertyErrorRef = useRef([]);
  const floorplanErrorRef = useRef([]);
  const unitErrorRef = useRef([]);
  const [propertyData, setPropertyData] = useState([]);
  const [floorplanData, setFloorplanData] = useState([]);
  const [propertyTypeData, setPropertyTypeData] = useState([]);
  const [unitTypeData, setUnitTypeData] = useState([]);
  const [buildingData, setBuildingData] = useState([]);
  const [unitData, setUnitData] = useState([]);
  const [onsiteManagerData, setOnsiteManagerData] = useState([]);
  const [customerName, setCustomerName] = useState("");
  const customerAccordionRef = useRef(null);
  const propertyAccordionRef = useRef(null);
  const floorplanAccordionRef = useRef(null);
  const unitAccordionRef = useRef(null);
  const customerRef = useRef(null);
  const propertyRefs = useRef([]);
  const floorplanRefs = useRef([]);
  const unitRefs = useRef([]);
  const [stepsCompleted, setStepsCompleted] = useState(1);
  const [percentage, setPercentage] = useState("25%");

  const customer = new Map();
  const property = new Map();
  const floorplan = new Map();
  const building = new Map();

  const [customerStatus, setCutomerStatus] = useState("inprogress");
  const [propertyStatus, setPropertyStatus] = useState("inactive");
  const [floorplanStatus, setFloorplanStatus] = useState("inactive");
  const [unitStatus, setUnitStatus] = useState("inactive");

  const [isCustomerAccordionOpen, setIsCustomerAccordionOpen] = useState(true);
  const [isPropertyAccordionOpen, setIsPropertyAccordionOpen] = useState(false);
  const [isFloorplanAccordionOpen, setIsFloorplanAccordionOpen] =
    useState(false);
  const [unitPropertyId, setUnitPropertyId] = useState([]);
  const [isUnitAccordionOpen, setIsUnitAccordionOpen] = useState(false);
  const [propertyComponent, setPropertyComponent] = useState([]);
  const [floorplanComponent, setFloorplanComponent] = useState([]);
  const [unitComponent, setUnitComponent] = useState([]);
  const [isDisableCustomerAccordion, setIsDisableCustomerAccordion] =
    useState(false);
  const [isDisablePropertyAccordion, setIsDisablePropertyAccordion] =
    useState(true);
  const [isDisableFloorplanAccordion, setIsDisableFloorplanAccordion] =
    useState(true);
  const [isDisableUnitAccordion, setIsDisableUnitAccordion] = useState(true);
  const [isAPICallInProgress, setIsAPICallInProgress] = useState(false);

  useEffect(() => {
    setPropertyComponent([PropertyComponent]);
    setFloorplanComponent([FloorplanComponent]);
    setUnitComponent([UnitComponent]);
  }, []);

  const handleComponent = (event, component, ref) => {
    component.map((component, index) => {
      return ref?.current[index]?.handleClick(event);
    });
  };

  const PropertyComponent = () => {
    return (
      <>
        {propertyComponent.map((component, index) => {
          return (
            <div
              key={index}
              className={
                index % 2 === 0
                  ? "bg-[white] px-[50px] pb-[10px]"
                  : "bg-[#fbfbfb] px-[50px] pb-[30px]"
              }
            >
              <section className="flex justify-end items-center h-[70px] cursor-pointer">
                {propertyComponent.length > 1 ? (
                  <CloseIcon
                    onClick={(event) => {
                      handleDeleteComponent(
                        index,
                        propertyComponent,
                        setPropertyComponent,
                        propertyData,
                        setPropertyData,
                        propertyErrorRef.current,
                        "property"
                      );
                    }}
                    id={`propertyClose_${index}`}
                  />
                ) : (
                  <></>
                )}
              </section>
              <PropertyForm
                id={index}
                mode={"page"}
                isEnableAccordion={(isEnable) =>
                  setIsDisablePropertyAccordion(isEnable)
                }
                passDataToNextSection={handlePropertySubmit}
                propertyData={propertyData[index]}
                onsiteManagerData={onsiteManagerData[index]}
                errors={
                  propertyErrorRef.current.length > 0 &&
                  propertyErrorRef.current[index]
                }
                ref={(el) => (propertyRefs.current[index] = el)}
                selectedCustomer={customerName}
              />
            </div>
          );
        })}
      </>
    );
  };

  const FloorplanComponent = () => {
    return (
      <>
        {floorplanComponent.map((component, index) => (
          <div
            key={index}
            className={
              index % 2 === 0
                ? "bg-[white] px-[50px] pb-[10px]"
                : "bg-[#fbfbfb] px-[50px] pb-[30px]"
            }
          >
            <section className="flex justify-end items-center h-[70px] cursor-pointer">
              {floorplanComponent.length > 1 ? (
                <CloseIcon
                  onClick={(event) => {
                    handleDeleteComponent(
                      index,
                      floorplanComponent,
                      setFloorplanComponent,
                      propertyTypeData[index] === "Floorplan"
                        ? floorplanData
                        : buildingData,
                      propertyTypeData[index] === "Floorplan"
                        ? setFloorplanData
                        : setBuildingData,
                      floorplanErrorRef.current,
                      "floorplan"
                    );
                  }}
                  id={`floorplanClose_${index}`}
                />
              ) : (
                <></>
              )}
            </section>
            <FloorplanForm
              id={index}
              mode={"page"}
              passDataToNextSection={handleFloorplanSubmit}
              floorplanData={floorplanData[index]}
              buildingData={buildingData[index]}
              propertyTypeData={propertyTypeData[index]}
              errors={floorplanErrorRef.current[index]}
              selectedCustomer={customerName}
              properties={propertyData}
              ref={(el) => (floorplanRefs.current[index] = el)}
            />
          </div>
        ))}
      </>
    );
  };

  const UnitComponent = () => {
    return (
      <>
        {unitComponent.map((component, index) => (
          <div
            key={index}
            className={
              index % 2 === 0
                ? "bg-[white] px-[50px] pb-[10px]"
                : "bg-[#fbfbfb] px-[50px] pb-[30px]"
            }
          >
            <section className="flex justify-end items-center h-[70px] cursor-pointer">
              {unitComponent.length > 1 ? (
                <CloseIcon
                  onClick={(event) => {
                    handleDeleteComponent(
                      index,
                      unitComponent,
                      setUnitComponent,
                      unitData,
                      setUnitData,
                      unitErrorRef.current,
                      "unit"
                    );
                  }}
                  id={`unitClose_${index}`}
                />
              ) : (
                <></>
              )}
            </section>
            <UnitForm
              id={index}
              mode="page"
              passDataToNextSection={handleUnitSubmit}
              selectedCustomer={customerName}
              unitData={unitData[index]}
              propertyTypeData={unitTypeData[index]}
              propertyId={unitPropertyId[index]}
              properties={propertyData}
              floorplans={floorplanData}
              buildings={buildingData}
              errors={unitErrorRef.current[index]}
              ref={(el) => (unitRefs.current[index] = el)}
            />
          </div>
        ))}
      </>
    );
  };

  const handleAppendComponent = (component, setComponent, form) => {
    const newComponent = form;
    setComponent([...component, newComponent]);
  };

  const handleDeleteComponent = (
    index,
    component,
    setComponent,
    data,
    setData,
    errors,
    type
  ) => {
    const updatedComponents = [...component];
    updatedComponents.splice(index, 1);
    setComponent(updatedComponents);

    if (type === "floorplan") {
      const updatedFloorplanData = [...floorplanData];
      updatedFloorplanData.splice(index, 1);
      setFloorplanData(updatedFloorplanData);
      const updatedBuildingData = [...buildingData];
      updatedBuildingData.splice(index, 1);
      setBuildingData(updatedBuildingData);
      const updatedPropertyTypeData = [...propertyTypeData];
      updatedPropertyTypeData.splice(index, 1);
      setPropertyTypeData(updatedPropertyTypeData);
      const updatedFloorplanErrors = [...floorplanErrorRef.current];
      updatedFloorplanErrors.splice(index, 1);
      floorplanErrorRef.current = updatedFloorplanErrors;
    } else {
      const updatedData = [...data];
      updatedData.splice(index, 1);
      setData(updatedData);
    }

    const updatedErrors = [...errors];
    updatedErrors.splice(index, 1);
    if (type === "property") propertyErrorRef.current = updatedErrors;
    else if (type === "floorplan") floorplanErrorRef.current = updatedErrors;
    else unitErrorRef.current = updatedErrors;
  };

  function hasObjectInArray(arr) {
    return arr.some((item) => typeof item === "object");
  }

  const handleCustomerSubmit = async (data, customerError, quoteApprover) => {
    setCustomerData((prevData) => [data]);
    setQuoteApproverData((prevData) => [quoteApprover]);
    customerErrorRef.current = customerError;
  };

  const handleCustomerAccordion = (event) => {
    customerRef?.current?.handleClick(event);
    if (!customerErrorRef.current) {
      setIsCustomerAccordionOpen(!isCustomerAccordionOpen);
      setCutomerStatus("");
      setPropertyStatus("inprogress");
      setStepsCompleted("2");
      setPercentage("50%");
      setIsPropertyAccordionOpen(true);
      setIsDisablePropertyAccordion(false);
    }
  };

  const handlePropertySubmit = async (
    property,
    key,
    propertyErrors,
    onsiteManager
  ) => {
    const index = propertyData.findIndex((item, index) => index === key);
    const errors = propertyErrorRef.current;
    if (index === -1) {
      setPropertyData((prevData) => [...prevData, property]);
      setOnsiteManagerData((prevData) => [...prevData, onsiteManager]);
      errors.push(propertyErrors);
    } else {
      setPropertyData((prevData) => {
        const newData = [...prevData];
        newData[index] = property;
        return newData;
      });
      setOnsiteManagerData((prevData) => {
        const newData = [...prevData];
        newData[index] = onsiteManager;
        return newData;
      });
      errors[index] = propertyErrors;
    }
    propertyErrorRef.current = errors;
  };

  const handlePropertyAccordion = (event) => {
    propertyComponent.map((component, index) => {
      return propertyRefs?.current[index]?.handleClick(event);
    });
    if (!hasObjectInArray(propertyErrorRef.current)) {
      setIsPropertyAccordionOpen(!isPropertyAccordionOpen);
      setPropertyStatus("");
      setFloorplanStatus("inprogress");
      setStepsCompleted("3");
      setPercentage("75%");
      setIsFloorplanAccordionOpen(true);
      setIsDisableFloorplanAccordion(false);
    }
  };

  const handleFloorplanSubmit = async (data, key, floorplanErrors, type) => {
    let errors = floorplanErrorRef.current;
    const errorsIndex = errors.findIndex((item, index) => index === key);
    const typeIndex = propertyTypeData.findIndex(
      (item, index) => index === key
    );
    if (errorsIndex === -1) {
      errors.push(floorplanErrors);
    } else {
      errors[key] = floorplanErrors;
    }
    if (typeIndex === -1) {
      const newData = propertyTypeData;
      newData[key] = type;
      setPropertyTypeData(newData);
    } else {
      setPropertyTypeData((prevData) => {
        const newData = [...prevData];
        newData[typeIndex] = type;
        return newData;
      });
    }
    if (type === "Floorplan") {
      const index = floorplanData.findIndex((item, index) => index === key);
      if (index === -1) {
        const newData = floorplanData;
        newData[key] = data;
        setFloorplanData(newData);
      } else {
        setFloorplanData((prevData) => {
          const newData = [...prevData];
          newData[index] = data;
          return newData;
        });
      }
    } else {
      const index = buildingData.findIndex((item, index) => index === key);
      if (index === -1) {
        const newData = buildingData;
        newData[key] = data;
        setBuildingData(newData);
      } else {
        setBuildingData((prevData) => {
          const newData = [...prevData];
          newData[index] = data;
          return newData;
        });
      }
    }
    floorplanErrorRef.current = errors;
  };

  const handleFloorplanAccordion = (event) => {
    event.preventDefault();
    floorplanComponent.map((component, index) => {
      return floorplanRefs?.current[index]?.handleClick(event);
    });
    if (!hasObjectInArray(floorplanErrorRef.current)) {
      setFloorplanStatus("");
      setUnitStatus("inprogress");
      setStepsCompleted("4");
      setPercentage("100%");
      setIsFloorplanAccordionOpen(!isFloorplanAccordionOpen);
      setIsUnitAccordionOpen(true);
      setIsDisableUnitAccordion(false);
    }
  };

  const handleUnitSubmit = async (data, property_id, key, unitErrors, type) => {
    const index = unitData.findIndex((item, index) => index === key);
    const typeIndex = unitTypeData?.findIndex((item, index) => index === key);
    const errors = unitErrorRef.current;
    if (typeIndex === -1) {
      const newData = unitTypeData;
      newData[key] = type;
      setUnitTypeData(newData);
    } else {
      setUnitTypeData((prevData) => {
        const newData = [...prevData];
        newData[typeIndex] = type;
        return newData;
      });
    }
    if (index === -1) {
      setUnitData((prevData) => [...prevData, data]);
      setUnitPropertyId((prevData) => [...prevData, property_id]);
      errors.push(unitErrors);
    } else {
      setUnitData((prevData) => {
        const newData = [...prevData];
        newData[index] = data;
        return newData;
      });
      setUnitPropertyId((prevData) => {
        const newData = [...prevData];
        newData[index] = property_id;
        return newData;
      });
      errors[index] = unitErrors;
    }
    unitErrorRef.current = errors;
  };

  const handleUnitAccordion = (event) => {
    unitComponent.map((component, index) => {
      return unitRefs.current[index]?.handleClick(event);
    });
    if (!hasObjectInArray(unitErrorRef.current)) {
      setIsUnitAccordionOpen(!isUnitAccordionOpen);
      setUnitStatus("");
    }
  };

  const handleFormSave = async (event) => {
    setIsAPICallInProgress(true);
    try {
      try {
        const response = await httpAPICall(
          CUSTOMERS_API_URL,
          "POST",
          customerData[0],
          ""
        );
        customer?.set(customerData[0].name, response?.id);
        if (quoteApproverData[0]) {
          let quoteApproverPayload = quoteApproverData[0];
          quoteApproverPayload.customer.id = response?.id;
          await httpAPICall(USERS_API_URL, "POST", quoteApproverPayload);
        }
      } catch (error) {
        console.error(error);
      }

      const createProperties = propertyData?.map(async (data, key) => {
        try {
          const propertySelected = propertyData[key];
          propertySelected.customer.id = customer?.get(
            propertySelected.customer.name
          );
          const response = await httpAPICall(
            PROPERTIES_API_URL,
            "POST",
            propertySelected,
            ""
          );
          property.set(propertySelected.name, response?.id);
          let onsiteManagerPayload = onsiteManagerData[key];
          onsiteManagerPayload.customer.id = customer?.get(
            onsiteManagerPayload?.customer?.id
          );
          onsiteManagerPayload.access_level.id = response?.id;
          await httpAPICall(USERS_API_URL, "POST", onsiteManagerPayload);
        } catch (error) {
          console.error(error);
        }
      });

      await Promise.all(createProperties);

      const createFloorplans = floorplanData?.map(async (data, key) => {
        try {
          if (data) {
            const floorplanSelected = floorplanData[key];
            floorplanSelected.customer.id = customer?.get(
              floorplanSelected.customer?.name
            );
            floorplanSelected.property.id = property?.get(
              floorplanSelected?.property?.id
            );
            const response = await httpAPICall(
              FLOORPLANS_API_URL,
              "POST",
              floorplanSelected,
              ""
            );
            floorplan.set(
              floorplanSelected?.name + floorplanSelected?.property.id,
              response?.id
            );
          }
        } catch (error) {
          console.error(error);
        }
      });

      await Promise.all(createFloorplans);

      const createBuildings = buildingData?.map(async (data, key) => {
        try {
          if (data) {
            const buildingSelected = buildingData[key];
            buildingSelected.customer.id = customer?.get(
              buildingSelected?.customer?.name
            );
            buildingSelected.property.id = property?.get(
              buildingSelected?.property?.id
            );
            const response = await httpAPICall(
              BUILDINGS_API_URL,
              "POST",
              buildingSelected,
              ""
            );
            building.set(
              buildingSelected?.name + buildingSelected?.property.id,
              response?.id
            );
          }
        } catch (error) {
          console.error(error);
        }
      });

      await Promise.all(createBuildings);

      const createUnits = unitData.map(async (data, key) => {
        try {
          const unitSelected = unitData[key];
          const property_id = property?.get(unitPropertyId[key]);
          unitSelected?.forEach((unit) => {
            unit.customer.id = customer?.get(unit?.customer?.id);
            if (unit?.floorplan) {
              unit.floorplan.id = floorplan.get(
                unit.floorplan.id + property_id
              );
            } else if (unit?.building) {
              unit.building.id = building?.get(
                unit?.building?.id + property_id
              );
            }
          });
          await httpAPICall(
            `properties/${property_id}` + UNITS_API_URL,
            "POST",
            unitSelected,
            ""
          );
        } catch (error) {
          console.error(error);
        }
      });

      await Promise.all(createUnits);

      setIsAPICallInProgress(false);
      navigate("/renovation-setup", {
        state: { successMsg: "Renovation Successfully Created" },
      });
    } catch (error) {
      setIsAPICallInProgress(false);
      navigate("/renovation-setup", {
        state: {},
      });
    }
  };

  const handleCustomerFromChild = (customer) => {
    setCustomerName(customer);
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <Heading
          heading={"PMC - Property - Floorplan/Building - Unit Setup"}
          theme={"primary"}
        />
        <ProgressBar
          percentage={percentage}
          steps={stepsCompleted}
          theme={"secondary"}
        />
      </div>
      <div className="flex flex-col mb-[75px]">
        <AccordionSlider
          number={"1"}
          name={"PMC Setup"}
          isDisabled={isDisableCustomerAccordion}
          mode={customerStatus}
          ref={customerAccordionRef}
          collapsible={0}
          componentID={"customerSetup_accordionForm"}
          isOpen={isCustomerAccordionOpen ? 0 : 1}
          onButtonClick={handleCustomerAccordion}
        >
          <CustomerForm
            mode={"page"}
            customerData={customerData}
            quoteApproverData={quoteApproverData[0]}
            passDataToNextSection={handleCustomerSubmit}
            errors={customerErrorRef.current}
            ref={customerRef}
            isEnableAccordion={(isEnable) =>
              setIsDisableCustomerAccordion(isEnable)
            }
            sendCustomerName={handleCustomerFromChild}
          />
        </AccordionSlider>
        <VerticalDivider />
        <AccordionSlider
          number={"2"}
          name={"Property Setup"}
          isDisabled={isDisablePropertyAccordion}
          mode={propertyStatus}
          componentID={"propetySetup_accordionForm"}
          collapsible={0}
          content={"table"}
          isOpen={isPropertyAccordionOpen ? 0 : 1}
          onButtonClick={handlePropertyAccordion}
          ref={propertyAccordionRef}
        >
          <div className="flex flex-col gap-4">
            <PropertyComponent />
            <div className="flex justify-end pr-10 pb-3">
              <LinkButton
                theme={"primary"}
                label={"Add Property"}
                onClick={(event) => {
                  handleAppendComponent(
                    propertyComponent,
                    setPropertyComponent,
                    PropertyComponent
                  );
                  handleComponent(event, propertyComponent, propertyRefs);
                }}
              />
            </div>
          </div>
        </AccordionSlider>
        <VerticalDivider />
        <AccordionSlider
          number={"3"}
          name={"Floorplan/Building Setup"}
          isDisabled={isDisableFloorplanAccordion}
          componentID={"floorplanSetup_accordionForm"}
          isOpen={isFloorplanAccordionOpen ? 0 : 1}
          collapsible={0}
          content={"table"}
          mode={floorplanStatus}
          onButtonClick={handleFloorplanAccordion}
          ref={floorplanAccordionRef}
          style={{ zIndex: 25 }}
        >
          <div className="flex flex-col gap-4">
            <FloorplanComponent style={{ zIndex: 1200 }} />
            <div className="flex justify-end pr-10 pb-3">
              <LinkButton
                theme={"primary"}
                label={"Add Floorplan/Building"}
                onClick={(event) => {
                  handleAppendComponent(
                    floorplanComponent,
                    setFloorplanComponent,
                    FloorplanComponent
                  );
                  handleComponent(event, floorplanComponent, floorplanRefs);
                }}
              />
            </div>
          </div>
        </AccordionSlider>
        <VerticalDivider />
        <AccordionSlider
          number={"4"}
          name={"Unit/Building Setup"}
          isDisabled={isDisableUnitAccordion}
          componentID={"unitSetup_accordionForm"}
          isOpen={isUnitAccordionOpen ? 0 : 1}
          mode={unitStatus}
          collapsible={0}
          content={"table"}
          ref={unitAccordionRef}
          onButtonClick={handleUnitAccordion}
        >
          <div className="flex flex-col gap-4">
            <UnitComponent />
            <div className="flex justify-end pr-10 pb-3">
              <LinkButton
                theme={"primary"}
                label={"Add Unit/Building Number"}
                onClick={(event) => {
                  handleAppendComponent(
                    unitComponent,
                    setUnitComponent,
                    UnitComponent
                  );
                  handleComponent(event, unitComponent, unitRefs);
                }}
              />
            </div>
          </div>
        </AccordionSlider>
      </div>
      <Footer theme={"secondary"}>
        <Button
          theme={"tertiary"}
          buttonText={"Cancel"}
          componentID={"renovationSetup_cancel"}
          onClick={() => navigate("/renovation-setup")}
        />
        <Button
          theme={"primary"}
          buttonText={"Save"}
          processing={isAPICallInProgress}
          disabled={
            isCustomerAccordionOpen ||
            isPropertyAccordionOpen ||
            isFloorplanAccordionOpen ||
            isUnitAccordionOpen
          }
          componentID={"renovationSetup_save"}
          onClick={(event) => handleFormSave(event)}
        />
      </Footer>
    </div>
  );
}
export default CreateForm;
