const InspectionFormFieldStyles = {
  outerContainer: "flex flex-col w-[433px]",
  labelWrapper: "font-inter font-medium text-[14px] text-[#1B2B41B8]",
  errorLabelWrapper:
    "flex justify-end text-[14px] font-medium leading-[17px] text-[#EC0D0D] text-opacity-70 mt-3",
  required: "text-[#EC0D0D] ml-1",
  mainContainer: "z-50 mt-2",
  labelOuterContainer: "flex justify-between z-0 mt-1",
  labelInnerContainer:
    "font-lato font-normal text-[12px] text-[#858585CC] italic",
};

export default InspectionFormFieldStyles;
