import React from "react";
import RadioButtonStyles from "../../../styles/components/radioButton";

function RadioButton() {
  return (
    <div>
      <div className={RadioButtonStyles.defaultContainer}>
        <input
          id="default-radio-1"
          type="radio"
          value=""
          name="default-radio"
          className={RadioButtonStyles.defaultInput}
        />
        <label className={RadioButtonStyles.defaultLabel}>
          Default radio
        </label>
      </div>
      <div className={RadioButtonStyles.checkedContainer}>
        <input
          id="default-radio-2"
          type="radio"
          value=""
          name="default-radio"
          className={RadioButtonStyles.checkedInput}
        />
        <label className={RadioButtonStyles.checkedLabel}>
          Checked state
        </label>
      </div>
    </div>
  );
}

export default RadioButton;
