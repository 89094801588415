import React from "react";
import YesOrNoStyles from "../../../styles/components/yesOrNo";

function YesOrNo({ label, handleChange, theme, checked, componentID }) {
  return (
    <div
      className={
        theme === "primary" ? YesOrNoStyles.primary : YesOrNoStyles.secondary
      }
    >
      <label className="font-lato text-[#383838] font-medium">{label}</label>
      <div className="flex space-x-[28px]">
        <section className="space-x-1 text-[#383838] text-base font-medium font-lato flex items-center">
          <input
            type="radio"
            value="yes"
            id={componentID}
            checked={checked === "yes"}
            onChange={handleChange}
            style={{ width: "20px", height: "20px" }}
          />
          <span>Yes</span>
        </section>
        <section className="space-x-1  text-[#383838] text-base font-medium font-lato flex items-center">
          <input
            type="radio"
            value="no"
            id={componentID}
            checked={checked === "no"}
            onChange={handleChange}
            style={{ width: "20px", height: "20px" }}
            pattern=""
          />
          <span>No</span>
        </section>
      </div>
    </div>
  );
}

export default YesOrNo;
