import React from "react";
import AmountInputStyles from "../../../styles/components/amountInput";

function AmountInput() {
  return (
    <div>
      <span className={AmountInputStyles.currencyContainer}>
        $
      </span>
      <input className={AmountInputStyles.input} />
    </div>
  );
}

export default AmountInput;
