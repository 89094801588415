import React from "react";
import HeadingWrapper from "./HeadingWrapper";
import HeaderStyles from "../../../styles/components/header";

function Header({ heading, theme }) {
  return theme === "primary" ? (
    <HeadingWrapper>{heading}</HeadingWrapper>
  ) : (
    <label className={HeaderStyles.label}>
      {heading}
    </label>
  );
}

export default Header;
