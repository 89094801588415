import React, { useEffect, useState } from "react";
import { Button } from "../../index";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { setPerformInspections } from "../../../redux/slice/completeInspection";
import LineItemProductCardStyles from "../../../styles/components/lineItemProductCard";

const LineItemProductCard = ({
  quantity,
  products,
  lineItemId,
  sendProductsToAutoSave = (val) => val,
  existingSearchedProducts,
  AddMannualProduct,
}) => {
  const dispatch = useDispatch();
  const { inspections } = useSelector(
    (completeInspection) => ({
      inspections: completeInspection?.inspections,
    }),
    shallowEqual
  );
  const [selectedCards, setSelectedCards] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [checkProducts, setCheckProducts] = useState([]);
  const [disabledProducts, setDisabledProducts] = useState([]);

  const isProductAddedInRedux = (productSku) => {
    return inspections?.inspections.some((item) => {
      return (
        lineItemId === item?.line_item_id &&
        item?.mapped_item_list.some((item) => {
          return item.sku === productSku;
        })
      );
    });
  };

  const handleCardClick = (cardId, product) => {
    if (selectedCards.includes(cardId)) {
      setSelectedCards(selectedCards.filter((id) => id !== cardId));
      let originalArray = inspections?.inspections;
      const finalArray = originalArray.map((item) => {
        return lineItemId === item?.line_item_id
          ? {
            ...item,
            mapped_item_list: item?.mapped_item_list.filter(
              (item) => item.id !== cardId
            ),
          }
          : item;
      });
      dispatch(setPerformInspections({ inspections: finalArray }));
    } else {
      setSelectedCards([...selectedCards, cardId]);
      setSelectedProducts([...selectedProducts, product]);
      sendProductsToAutoSave([...selectedProducts, product]);
      setDisabledProducts([...disabledProducts, product.id]);
      setCheckProducts([...checkProducts, product]);
      let originalArray = inspections?.inspections;
      const finalArray = originalArray.map((item) => {
        return lineItemId === item?.line_item_id
          ? {
            ...item,
            mapped_item_list: [
              ...new Set([...item?.mapped_item_list, product]),
            ],
          }
          : item;
      });
      dispatch(setPerformInspections({ inspections: finalArray }));
    }
  };

  useEffect(() => {
    setCheckProducts(existingSearchedProducts);
  }, [checkProducts, existingSearchedProducts]);

  return products?.map((item, index) => {
    const isDisabled = disabledProducts.includes(item.sku) || isProductAddedInRedux(item.sku);
    return item?.id ? (
      <div
        className={LineItemProductCardStyles.productContainer}
        style={{
          border: selectedCards?.includes(index)
            ? "1px solid #006CDB"
            : "1px solid #C8C8C8",
        }}
        key={index}
      >
        <section>
          <img
            src={
              item?.picture_list.length > 0
                ? item["picture_list"][0]?.url?.original
                : "https://cdn.vectorstock.com/i/preview-1x/32/45/no-image-symbol-missing-available-icon-gallery-vector-45703245.jpg"
            }
            alt="Line Item Product"
            className={LineItemProductCardStyles.img}
          />
        </section>
        <section className={LineItemProductCardStyles.detailsContainer}>
          <span className={LineItemProductCardStyles.name}>
            {item?.name ? item?.name : "N/A"}
          </span>
          <span className={LineItemProductCardStyles.sku}>
            SKU: {item?.sku ? item?.sku : "N/A"}
          </span>
          <div className={LineItemProductCardStyles.priceOuterContainer}>
            <span className={LineItemProductCardStyles.priceInnerContainer}>
              Price: $ {item?.price}
            </span>

            <span>
              <Button
                theme={"secondary"}
                buttonText={"Add"}
                disabled={isDisabled}
                onClick={() => {
                  const updatedItem = { ...item, quantity: quantity };
                  handleCardClick(index, updatedItem);
                }}
              />
            </span>
          </div>
        </section>
      </div>
    ) : (
      <div>{AddMannualProduct}</div>
    );
  });
};

export default LineItemProductCard;
