import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={20}
    height={21}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Frame">
      <path
        id="Vector"
        d="M10.0003 18.8332C5.39795 18.8332 1.66699 15.1022 1.66699 10.4998C1.66699 5.89746 5.39795 2.1665 10.0003 2.1665C14.6027 2.1665 18.3337 5.89746 18.3337 10.4998C18.3337 15.1022 14.6027 18.8332 10.0003 18.8332ZM10.0003 17.1665C13.6822 17.1665 16.667 14.1818 16.667 10.4998C16.667 6.81794 13.6822 3.83317 10.0003 3.83317C6.31843 3.83317 3.33366 6.81794 3.33366 10.4998C3.33366 14.1818 6.31843 17.1665 10.0003 17.1665ZM9.16699 6.33317H10.8337V7.99984H9.16699V6.33317ZM9.16699 9.6665H10.8337V14.6665H9.16699V9.6665Z"
        fill="#383838"
      />
    </g>
  </svg>
);
export default SVGComponent;
