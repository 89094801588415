export const timeZones = [
  { id: "Eastern Standard Time (US & Canada)", name: "(GMT-05:00) Eastern Standard Time (US & Canada)" },
  { id: "Eastern Daylight Time (US & Canada)", name: "(GMT-04:00) Eastern Daylight Time (US & Canada)" },
  { id: "Central Standard Time (US & Canada)", name: "(GMT-06:00) Central Standard Time (US & Canada)" },
  { id: "Central Daylight Time (US & Canada)", name: "(GMT-05:00) Central Daylight Time (US & Canada)" },
  { id: "Mountain Standard Time (US & Canada)", name: "(GMT-07:00) Mountain Standard Time (US & Canada)" },
  { id: "Mountain Daylight Time (US & Canada)", name: "(GMT-06:00) Mountain Daylight Time (US & Canada)" },
  { id: "Pacific Standard Time (US & Canada)", name: "(GMT-08:00) Pacific Standard Time (US & Canada)" },
  { id: "Pacific Daylight Time (US & Canada)", name: "(GMT-07:00) Pacific Daylight Time (US & Canada)" },
  { id: "Arizona", name: "(GMT-07:00) Arizona(US & Canada)" },
];
