import { RadioGroup, Radio } from "rsuite";

function RadioCell({
  rowData,
  onChange,
  checkedValue = "",
  dataKey,
  selectedOption,
  componentID,
  onRowClick,
  ...props
}) {
  const handleRadioChange = (value) => {
    onChange(value);
  };

  return (
      <div className="flex items-center justify-center h-7 cursor-pointer" onClick={() => handleRadioChange(rowData.id)}>
        <RadioGroup
          name={`radioGroup-${rowData.id}`}
          value={checkedValue || selectedOption?.id}
          onChange={handleRadioChange}
          className='cursor-pointer'
        >
          <label className="cursor-pointer">
            <Radio value={rowData.id} id={componentID} />
          </label>
        </RadioGroup>
      </div>
  );
}

export default RadioCell;
