import React from "react";
import { Loader } from "rsuite";
import ButtonStyles from "../../../styles/components/button";

const ButtonLoader = () => {
  return <div className="flex items-center"><Loader content="Processing..." /></div>;
};

function Button({
  buttonText,
  onClick,
  theme,
  type,
  disabled,
  processing,
  componentID,
}) {
  return (
    <button
      className={
        theme === "primary"
          ? ButtonStyles.primary
          : theme === "secondary"
          ? ButtonStyles.secondary
          : theme === "tertiary"
          ? ButtonStyles.tertiary
          : theme === "quaternary"
          ? ButtonStyles.quaternary
          : theme === "tertiary-without-border"
          ? ButtonStyles.tertiaryStylesWithoutBorder
          : theme === "primary-fixed-width"
          ? ButtonStyles.primaryStylesWithFixedWidth
          : theme === "secondary-small-fixed-width"
          ? ButtonStyles.secondaryStylesWithSmallFixedWidth
          : theme === "secondary-large-fixed-width-disable"
          ? ButtonStyles.secondaryStylesWithLargeFixedWidthDisable
          : theme === "secondary-large-fixed-width"
          ? ButtonStyles.secondaryStylesWithLargeFixedWidth
          : disabled && theme === "primary-with-disabled"
          ? ButtonStyles.primaryStylesWithDisabled
          : disabled && theme === "secondary-with-disabled"
          ? ButtonStyles?.secondaryStylesWithDisabled
          : disabled && theme === "tertiary-with-disabled"
          ? ButtonStyles?.tertiaryStylesWithDisabled
          : ButtonStyles?.primary
      }
      id={componentID}
      disabled={disabled}
      type={type}
      onClick={onClick}
      processing={processing}
    >
      {processing ? <ButtonLoader /> : buttonText}
    </button>
  );
}

export default Button;
