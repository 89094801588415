import React from "react";
import Layout from "../layout/layout";
import { useSelector, shallowEqual } from "react-redux";
import { Navigate } from "react-router-dom";

function ProtectedRoute({
  children,
  activeTab,
  sendSearchText = (val) => val,
  token,
  expandView,
  component: Component,
  ...restOfProps
}) {
  const { accessToken } = useSelector(
    ({ auth }) => ({
      accessToken: auth.accessToken,
    }),
    shallowEqual
  );

  return accessToken ? (
    <Layout {...restOfProps} activeTab={activeTab} expandView={expandView}>
      {children}
    </Layout>
  ) : (
    <Navigate to={"/"} />
  );
}

export default ProtectedRoute;
