/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useLocation } from "react-router-dom";
import FooterStyles from "../../styles/components/footer";
import { statusMappings } from "../../utils/statusMappings";
import formatPhoneNumber from "../../utils/formatPhoneNumber";
import {
  FacilgoIcon,
  TransactionHistoryIcon,
  XlsIcon,
  EyeIcon,
} from "../../assets";
import HeaderStyles from "../../styles/components/header";
import { Button } from "../../components";
import QuoteStyles from "../../styles/components/signQuote";
import Base64 from "../../utils/base64";
import * as FileSaver from "file-saver";
import { Loader } from "rsuite";
import { QUOTE_MATRIX_URL } from "../../constants";
import { SignModal } from "./SignModal";
import AddQuoteMatrix from "../quote_matrix/Add";
import axios from "axios";
import { ConfirmModal } from "./ConfirmModal";
import { quoteStatusOptions } from "../../utils/statusMappings";

function SummaryQuote() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [openModal, setOpenModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [status, setStatus] = useState("");
  const [mode, setMode] = useState("loading");
  const [errorMsg, setErrorMsg] = useState("");
  const [noOfSuppliers, setNoOfSuppliers] = useState(1);
  const [quote, setQuote] = useState(null);
  const a = searchParams.get("a");
  const [isExcelLoading, setIsExcelLoading] = useState(false);
  let decodedString = Base64.atob(a);

  useEffect(() => {
    getQuote();
  }, []);

  const getQuote = () => {
    axios
      .get(QUOTE_MATRIX_URL + `?${decodedString}`)
      .then((response) => {
        setStatus(response?.data?.status);
        setQuote(response?.data);
        setMode("viewQuote");
      })
      .catch((error) => {
        setMode("");
        setErrorMsg("We're sorry, but something went wrong.");
      });
  };

  const handleRejectQuote = () => {
    axios
      .put(QUOTE_MATRIX_URL + `/${quote?.id}/rejection?${decodedString}`)
      .then((response) => {
        setMode("loading");
        window.location.reload();
      })
      .catch((error) => {
        setMode("");
        setErrorMsg("We're sorry, but something went wrong.");
      });
  };

  const handleSign = (comments, signature) => {
    const payload = {
      name: "SFR FKH Preview PMC",
      url: "upload.png",
      longitude: null,
      latitude: null,
      comment: comments,
      data: signature,
    };
    axios
      .post(
        QUOTE_MATRIX_URL + `/${quote?.id}/signatures?${decodedString}`,
        payload
      )
      .then((response) => {
        setMode("loading");
        window.location.reload();
      })
      .catch((error) => {
        setMode("");
        setErrorMsg("We're sorry, but something went wrong");
      });
  };

  const downloadXml = () => {
    setIsExcelLoading(true);
    const generateAPIUrl = `${QUOTE_MATRIX_URL}/${quote?.id}?format=xls&${decodedString}`;

    const config = {
      method: "GET",
      url: generateAPIUrl,
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "blob",
      },
    };

    axios(config)
      .then((response) => {
        const outputFilename = `${
          quote?.property[0].name
        } - Reno Matrix - ${moment().format("YYYY-MM-DD")}.xlsx`;
        const url = URL.createObjectURL(
          new Blob([response?.data], {
            type: "application/vmd.openxmlformates",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.setAttribute("download", outputFilename);
        document.body.appendChild(link);
        link.click();
        setIsExcelLoading(false);
        return FileSaver.saveAs(response.data, outputFilename);
      })
      .catch(() => {
        setIsExcelLoading(false);
      });
  };

  const handleAcceptQuote = (event) => {
    setOpenModal(true);
  };

  return mode === "viewQuote" ? (
    <section className="flex flex-col justify-between">
      <section className={HeaderStyles.primary}>
        <div className="flex-shrink-0 font-inter font-bold text-[24px] leading-[35.17px] text-black cursor-pointer">
          <FacilgoIcon />
        </div>
      </section>
      <section className="h-full mx-[40px] my-[20px]">
        <section className=" font-lato text-[22px]">
          <div class={QuoteStyles.outerContainer}>
            <div class={QuoteStyles.imageContainer}>
              <img src={quote?.supplier?.url} alt="Not found" />
            </div>
            <div class={QuoteStyles.textContainer}>
              <div className="flex flex-col gap-[8px] text-[#383838] items-center justify-center">
                <div
                  class={QuoteStyles.centeredText}
                  style={{
                    textAlign: "center",
                    fontSize: "26px",
                    fontWeight: "bold",
                  }}
                >
                  {quote?.supplier?.name
                    ? quote?.supplier?.name?.toUpperCase()
                    : "-"}{" "}
                  PROJECT SUPPORT
                </div>
                <div class={QuoteStyles.centeredText}>
                  Unit Quote & Order Form
                </div>
              </div>
            </div>
            <div
              class={QuoteStyles.textContainer}
              className="flex flex-col gap-6 items-end mr-[30px] mt-2"
            >
              <div style={{ display: "flex", columnGap: "15px" }}>
                {noOfSuppliers > 1 && (
                  <a
                    href="#suppliers"
                    className="text-[#006CDB] font-lato text-[1rem] non-italic font-semibold cursor-pointer hover:no-underline flex items-center gap-x-1"
                  >
                    <EyeIcon />
                    View Suppliers
                  </a>
                )}

                <a
                  href="#transaction_history"
                  className="text-[#006CDB] font-lato text-[1rem] non-italic font-semibold cursor-pointer hover:no-underline flex items-center gap-x-1"
                >
                  <TransactionHistoryIcon />
                  Transaction History
                </a>
              </div>

              <section className="flex space-x-[20px] justify-center w-full">
                {isExcelLoading ? (
                  <Loader size="md" speed="normal" />
                ) : (
                  <XlsIcon onClick={() => downloadXml()} />
                )}
              </section>
              {statusMappings[status] !== "Open" && (
                <span
                  className={`flex self-center justify-center items-center text-[20px] font-semibold gap-2 h-[35px] rounded-md bg-${
                    statusMappings[status] === "Accepted"
                      ? "[#E5FFF3]"
                      : "[#FFE8E4]"
                  } p-2`}
                >
                  <span
                    className={
                      statusMappings[status] === "Accepted"
                        ? QuoteStyles.greenDot
                        : QuoteStyles.redDot
                    }
                  ></span>
                  <span
                    style={{
                      color:
                        statusMappings[status] === "Accepted"
                          ? "#048848"
                          : "#BE2A15",
                    }}
                  >
                    {statusMappings[quote?.status]}
                  </span>
                </span>
              )}
            </div>
          </div>
          <div class={QuoteStyles.lineItemContainer}>
            <div class={QuoteStyles.lineItem}>
              <div class={QuoteStyles.lineItemChild}>
                <div>
                  <b>Quote Matrix Title</b>
                </div>
                <div>{quote?.title}</div>
              </div>
              <div class={QuoteStyles.lineItemChild}>
                <div>
                  <b>Supplier Quote#</b>
                </div>
                <div>{quote?.id}</div>
              </div>
              <div class={QuoteStyles.lineItemChild}>
                <div>
                  <b>Date</b>
                </div>
                <div>
                  {moment(quote?.create_date).local().format("MM/DD/YYYY")}
                </div>
              </div>
              <div class={QuoteStyles.lineItemChild}>
                <div>
                  <b>PMC</b>
                </div>
                <div>{quote?.customer?.name}</div>
              </div>
            </div>
            <div class={QuoteStyles.lineItem}>
              <div class={QuoteStyles.lineItemChild}>
                <div>
                  <b>Reg/Property Coverage</b>
                </div>
                <div>
                  {quote?.property[0]?.name ? quote?.property[0]?.name : "-"}
                </div>
              </div>
              <div class={QuoteStyles.lineItemChild}>
                <div>
                  <b>Quote Type</b>
                </div>
                <div>{quoteStatusOptions[quote?.type] || "-"}</div>
              </div>
              <div class={QuoteStyles.lineItemChild}>
                <div>
                  <b>Exp Date</b>
                </div>
                <div>
                  {quote?.due_date
                    ? moment(quote?.due_date).local().format("MM/DD/YYYY")
                    : "-"}
                </div>
              </div>
              <div class={QuoteStyles.lineItemChild}>
                <div>
                  <b>Total Units</b>
                </div>
                <div>
                  {quote?.total_renovation_unit_count
                    ? quote?.total_renovation_unit_count
                    : "-"}
                </div>
              </div>
            </div>
            <div class={QuoteStyles.lineItem}>
              <div class={QuoteStyles.lineItemChild}>
                <div>
                  <b>Address</b>
                </div>
                <div>
                  {quote?.property[0]?.address?.address
                    ? quote?.property[0]?.address?.address
                    : "-"}
                </div>
              </div>
              <div class={QuoteStyles.lineItemChild}>
                <div>
                  <b>Quote Approver Full Name</b>
                </div>
                <div>
                  {quote?.to_be_approved_by?.full_name
                    ? quote?.to_be_approved_by?.full_name
                    : "-"}
                </div>
              </div>
              <div class={QuoteStyles.lineItemChild}>
                <div>
                  <b>Quote Approver Phone</b>
                </div>
                <div>
                  {quote?.to_be_approved_by?.phone_number
                    ? formatPhoneNumber(quote?.to_be_approved_by?.phone_number)
                    : "-"}
                </div>
              </div>
              <div class={QuoteStyles.lineItemChild}>
                <div>
                  <b>Quote Approver Email</b>
                </div>
                <div>
                  {quote?.to_be_approved_by?.email
                    ? quote?.to_be_approved_by?.email
                    : "-"}
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </section>
        <section>
          <AddQuoteMatrix
            mode={"customerView"}
            quoteMatrixData={quote}
            suppliers={(noOfSuppliers) => {
              setNoOfSuppliers(noOfSuppliers);
            }}
          />
        </section>
      </section>
      {status === "CReceived" && (
        <section
          className={FooterStyles.tertiary}
          style={{
            overflow: "auto",
            boxShadow: "-9px 9px 12px 6px #aeaeae",
            position: "fixed",
            bottom: 0,
            width: "100%",
            zIndex: 999,
          }}
        >
          <Button
            theme={"tertiary"}
            buttonText={"Reject"}
            componentID={"quoteSign_reject"}
            onClick={() => setConfirmModal(true)}
          />
          <Button
            theme={"primary"}
            buttonText={"Accept"}
            componentID={"quoteSign_accept"}
            onClick={(event) => handleAcceptQuote(event)}
          />
        </section>
      )}
      <SignModal
        openModal={openModal}
        closeModal={() => setOpenModal(false)}
        handleSign={handleSign}
        customerName={quote?.customer?.name}
        approverName={quote?.to_be_approved_by?.full_name}
      />
      <ConfirmModal
        openModal={confirmModal}
        closeModal={() => setConfirmModal(false)}
        handleReject={handleRejectQuote}
      />
    </section>
  ) : mode === "loading" ? (
    <Loader size="md" center speed="normal" content="Loading..." vertical />
  ) : (
    <div className="flex flex-col items-center font-lato text-[26px] h-screen mt-[150px]">
      <div className="items-center border-2 border-x-gray-400 border-t-red-900 border-t-4 rounded ">
        <span className="flex items-center justify-center font-semibold text-red-800 ">
          {errorMsg}
        </span>
        <div>
          <hr className="my-2 border-t border-gray-300" />
        </div>
        <span className="font-normal text-[22px]">
          If you are the application owner check the logs for more information.
        </span>
      </div>
      <div></div>
    </div>
  );
}

export default SummaryQuote;
