import { createSlice } from "@reduxjs/toolkit";

export const createSetup = createSlice({
  name: "createSetup",
  initialState: {
    setup: [],
  },
  reducers: {
    setPerformInspections: (state, action) => {
      state.setup = action.payload.setup;
    },
  },
});

export const { setCreateSetup } = createSetup.actions;

export default createSetup.reducer;
