/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { loadGoogleMapsAPI } from '../../../utils/googleAPILoader';
import InputFieldStyles from "../../../styles/components/inputField";

function MapComponent({
  value,
  error,
  errorText,
  componentID,
  isDisabled,
  setAddress,
  label,
  mandatory,
  onChange,
}) {
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState(value || "");
  let address = {};
  const [isFocused, setIsFocused] = useState(false);


  useEffect(() => {

    loadGoogleMapsAPI().then(() => {
      const autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current
      );
      const infowindow = new window.google.maps.InfoWindow();
  
      autocomplete.addListener("place_changed", () => {
        infowindow.close();
        // marker.setVisible(false);
  
        const place = autocomplete.getPlace();
  
        if (!place.geometry || !place.geometry.location) {
          window.alert("No details available for input: '" + place.name + "'");
          return;
        }
  
        const addressComponents = place.address_components;
  
        let city = "";
        let state = "";
        let zipCode = "";

        city     = addressComponents.filter(component => component?.types?.includes("locality")).map(component => component.long_name)[0];
        state    = addressComponents.filter(component => component?.types?.includes("administrative_area_level_1")).map(component => component.long_name)[0];
        zipCode  = addressComponents.filter(component => component?.types?.includes("postal_code")).map(component => component.long_name)[0];
  
        const completeAddress = place.formatted_address;
        address.city = city;
        address.state = state;
        address.zipCode = zipCode;
        const parts = completeAddress.split(",");
        const formattedAddressWithoutCountry = parts.slice(0, parts.length - 1).join(",");
        address.address = formattedAddressWithoutCountry;
        setAddress(address);
  
        setInputValue(completeAddress);
        infowindow.setContent(
          `<div><strong>${completeAddress}</strong><br>${city}, ${state} ${zipCode}</div>`
        );
      });
    });
  }, []);

  const handleInputChange = (event) => {
    setInputValue(event.target.value); // Update local input value
    onChange(event.target.value); // Notify parent component of the change
  };

  return (
    <div className={InputFieldStyles.inputContainer} style={{ zIndex: 1051 }}>
      <label
        className={
          isDisabled ? InputFieldStyles.disabled : InputFieldStyles.active
        }
      >
        {label}{" "}
        {mandatory && <span className={InputFieldStyles.mandatory}> *</span>}
      </label>
      <input
        ref={inputRef}
        type="text"
        id={componentID}
        placeholder=""
        value={value}
        onChange={handleInputChange}
        className="w-full bg-[#FFFFFF] px-[22px] h-[48px] rounded-[3px] border border-[#D0D0D0] text-black outline-none font-lato font-medium text-[14px] leading-[15.6px] placeholder:text-white"
      />
      {error && (
        <label
          className={
            isFocused
              ? InputFieldStyles.pageErrorLabelBottom
              : InputFieldStyles.pageErrorLabelBlur
          }
        >
          {errorText}
        </label>
      )}
    </div>
  );
}

export default MapComponent;
