function calculateFloorplanSubtotals(
  data,
  floorPlanKey,
  inspectionList,
  mode,
  subtotalsByFloorplan
) {
  inspectionList?.forEach((inspection) => {
    const floorplanName =
      mode === "view" || mode === "customerView"
        ? inspection?.inspection?.property?.unit?.floorplan?.name
        : inspection?.property?.unit?.floorplan?.name; // Replace 'yourValue' with the actual property you want to use.
    subtotalsByFloorplan[floorplanName] = {};
  });

  data.forEach((obj) => {
    if (obj?.floorPlanName === floorPlanKey) {
      const floorPlanName = obj.inspectionProperty?.unit?.floorplan?.name;
      const unitPrice = obj.lineItemPrice;
      const category = obj.lineItemType;
      const detailList = obj.detailItemQuantity;
      if (!subtotalsByFloorplan[floorPlanName][category]) {
        subtotalsByFloorplan[floorPlanName][category] = 0;
      }
      // Calculate subtotal for the current object
      let quantity = detailList
        .filter((item) => item?.floorplan_name === floorPlanName)
        .map((item) => item.quantity);

      const subTotal = Number(quantity) * unitPrice;

      subtotalsByFloorplan[floorPlanName][category] += subTotal;
    }
  });

  return subtotalsByFloorplan;
}

export default calculateFloorplanSubtotals;
