import { axiosObject } from "./setup";

function logout(payload) {
  return new Promise((resolve, reject) => {
    let uri =  process.env.REACT_APP_LOGOUT_API_URL;

    axiosObject
      .post(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        console.log(errors);
        if (errors) {
          reject(errors);
        }
        reject(errors);
      });
  });
}

export { logout };