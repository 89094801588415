function calculateSubtotals(
  data,
  categoryKey,
  inspectionList,
  mode,
  floorplanHeaders
) {
  let subtotalsByFloorplan = {};
  let subtotalsBySupplier = {};
  floorplanHeaders.forEach((item, index) => {
    subtotalsByFloorplan[item.name] = 0;
  });
  let floorplan_headers = { ...subtotalsByFloorplan };

  data.forEach((obj) => {
    if (obj?.lineItemType === categoryKey) {
      const unitPrice = obj.lineItemPrice;
      const supplier = obj?.supplier?.name || "";
      const detailList = obj.detailItemQuantity;
      if (!subtotalsBySupplier[supplier])
        subtotalsBySupplier[supplier] = { ...floorplan_headers };

      detailList.forEach((detailItem) => {
        const floorPlanName = detailItem.floorplan_name;
        if (floorplanHeaders.some((header) => header.name === floorPlanName)) {
          let subTotal = 0;
          if (detailItem.quantity !== "-") {
            subTotal = Number(detailItem.quantity) * unitPrice;
          }
          subtotalsByFloorplan[floorPlanName] += subTotal;
          subtotalsBySupplier[supplier][floorPlanName] += subTotal;
        }
      });
    }
  });

  return Object.keys(subtotalsBySupplier).length === 1
    ? { "Sub Total": subtotalsByFloorplan }
    : { ...subtotalsBySupplier, "Sub Total": subtotalsByFloorplan };
}

export default calculateSubtotals;
