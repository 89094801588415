function formatPrice(number) {
  const integerPart = Math.floor(number);
  const decimalPart = (number - integerPart).toFixed(2);

  const formattedInteger = integerPart.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const formattedPrice = decimalPart
    ? `${formattedInteger}${decimalPart.slice(1)}`
    : `${formattedInteger}.00`;

  return formattedPrice;
}

export default formatPrice;
