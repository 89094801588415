/* eslint-disable no-unused-vars */
import React from "react";
import Dropdown from "./Dropdown";
import DropdownFieldStyles from "../../../styles/components/dropdown";

function DropdownField({
  label,
  placeholder,
  options,
  isMulti,
  isSearchable,
  onChange,
  filterById,
  searchText,
  isClear,
  error,
  errorText,
  mandatory,
  mode,
  value,
  isDisabled,
  componentID
}) {
  return(
    <div className={DropdownFieldStyles.container}>
      <label className={isDisabled ? DropdownFieldStyles.disabled : DropdownFieldStyles.label}>
        {label} {mandatory && <span className={DropdownFieldStyles?.mandatory}> *</span>}
      </label>
      <div>
      <Dropdown
        placeHolder={placeholder}
        options={options}
        isMulti={isMulti}
        isSearchable={isSearchable}
        onChange={onChange}
        filterById={filterById}
        searchText={searchText}
        isClear={isClear}
        value={value}
        isDisable={isDisabled ? true : false}
        componentID={componentID}
      />
      </div>
      {error && (
        <label className={DropdownFieldStyles.pageErrorLabelBottom}>
          {errorText}
        </label>
      )}
    </div>
  );
}

export default DropdownField;
