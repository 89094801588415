function calculatePerUnitTypeTotals(data, taxPercentage, floorPlanHeaders) {
  const result = {};
  const perUnitTypeTotals = [];
  const supplierTotals = {};

  floorPlanHeaders.forEach((item, index) => {
    result[item.name] = {
      renovationCount: 0,
      subTotal: 0,
      perUnitTotalWithOutTax: 0,
      perUnitTotalWithTax: 0,
    };
  });

  data?.forEach((item, index, array) => {
    const {
      inspectionUnit,
      lineItemPrice,
      detailItemRenovationUnitCount,
      detailItemQuantity,
    } = item;
    const detailList = detailItemQuantity;
    const renovationCount = detailItemRenovationUnitCount;
    const unitPrice = lineItemPrice;
    const floorplanName = inspectionUnit?.floorplan?.name;
    const supplier = (item?.supplier?.name || "") + " Grand Total";

    if (!supplierTotals[supplier]) supplierTotals[supplier] = 0;

    let quantity = detailList
      .filter((item) => item?.floorplan_name === floorplanName)
      .map((item) => item.quantity);

    const subTotal = Number(quantity) * unitPrice;
    const perUnitTotal = subTotal * renovationCount;
    const taxAmount = (perUnitTotal * taxPercentage) / 100;
    const perUnitTotalWithTax = perUnitTotal + taxAmount;

    result[floorplanName].subTotal += subTotal;
    result[floorplanName].renovationCount = renovationCount;
    result[floorplanName].subTotal += subTotal;
    result[floorplanName].perUnitTotalWithOutTax += perUnitTotal;
    result[floorplanName].perUnitTotalWithTax += perUnitTotalWithTax;
    supplierTotals[supplier] += perUnitTotalWithTax;
    if (index === array.length - 1) {
      result[floorplanName].perUnitTotalWithTax = Math.floor(result[floorplanName].perUnitTotalWithTax * 100) / 100;
  }
  });
  perUnitTypeTotals.push(result);


  let perUnitTypeGrandTotalWithoutTax = 0;
  let perUnitTypeGrandTotalWithTax = 0;
  for (const floorplanName in result) {
    perUnitTypeGrandTotalWithoutTax +=
      result[floorplanName].perUnitTotalWithOutTax;
    perUnitTypeGrandTotalWithTax += parseFloat(result[floorplanName].perUnitTotalWithTax);
  }

  return {
    perUnitTypeTotals,
    perUnitTypeGrandTotalWithoutTax,
    perUnitTypeGrandTotalWithTax : {...supplierTotals, "Grand Total" : (Math.floor(perUnitTypeGrandTotalWithTax * 100) / 100)},
  };
}

export default calculatePerUnitTypeTotals;
