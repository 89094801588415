/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  YesOrNo,
  Button,
  ToolTip,
  InputField,
  PriceInputField,
  AutoEditInputField,
  QuantityMeasure,
  Footer,
  LinkButton,
  SlideDrawer,
  ImageWrapper,
  FilterProductForm,
  DropdownField,
} from "../../index";
import {
  CommentIcon,
  MeasureScaleIcon,
  FilterIcon,
  ClipBoardIcon,
  ToolTipIcon,
  ProductDeleteIcon,
  ManualProductIcon,
  DeleteIcon,
} from "../../../assets";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import { setPerformInspections } from "../../../redux/slice/completeInspection";
import LineItemCardStyles from "../../../styles/components/inspectionLineItemCard";
import { httpAPICall, uom, isEmpty } from "../../../utils";
import { deletePicture } from "../../../services/api/deletePicture";
import { INSPECTIONS_API_URL } from "../../../constants";

const PhotoContainer = ({
  images,
  handleCancel,
  handleDelete,
  reloadInspection = (val) => val,
}) => {
  const deletePhoto = async (id) => {
    const result = await deletePicture(id);
    if (result) {
      handleDelete();
      reloadInspection(true);
    }
  };
  return (
    <>
      <main className="block overflow-y-auto px-10 h-full">
        <form className="flex flex-col space-y-5">
          {images.map((picture, index) => {
            return (
              <div className="flex gap-[80px]" key={index}>
                <div>
                  <label className="h-[20px] mb-[10px]">
                    {picture?.url?.id}
                  </label>
                  <img
                    src={picture?.url?.original}
                    alt="Error occured while loading"
                    className="w-[300px] h-[200px] rounded-[10px]"
                  />
                </div>
                <div className="flex items-center cursor-pointer">
                  <DeleteIcon onClick={() => deletePhoto(picture?.id)} />
                </div>
              </div>
            );
          })}
        </form>
      </main>
      <footer className="bg-white mt-[15px] flex justify-end items-center shadow-[_0px_-13px_28px_rgba(0,0,0,0.025)] pr-4 gap-4 h-[86px]">
        <Button
          theme={"tertiary"}
          onClick={handleCancel}
          buttonText={"Cancel"}
          componentID={"inspectionLineItemPhotos_btnCancel"}
        />
      </footer>
    </>
  );
};

const AddProductForm = ({
  setOpenDrawer,
  lineItemId,
  sendManualProductAutoSave = (val) => val,
}) => {
  const dispatch = useDispatch();
  const { inspections } = useSelector(
    (completeInspection) => ({
      inspections: completeInspection?.inspections,
    }),
    shallowEqual
  );
  const [title, setTitle] = useState("");
  const [sku, setSku] = useState("");
  const [ea, setEa] = useState({ id: "EA", name: "EA (Each)" });
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState("");
  const [isUOMError, setIsUOMError] = useState(false);

  const isDoneButtonDisabled = !(
    title !== "" &&
    sku !== "" &&
    ea !== "" &&
    quantity >= 1
  );

  const handleChange = (event, setterFunction, setErrorFunction) => {
    const value = event.target.value;
    setterFunction(value);
    setErrorFunction(isEmpty(value));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let requestPayload = {
      name: title,
      sku: sku,
      uom: ea?.id,
      quantity: quantity,
      price: price === "" ? 0 : price,
    };
    if (!isDoneButtonDisabled) {
      let originalArray = inspections?.inspections;
      const finalArray = originalArray?.map((item) => {
        return lineItemId === item?.line_item_id
          ? {
              ...item,
              mapped_item_list: [...item?.mapped_item_list, requestPayload],
            }
          : item;
      });
      dispatch(setPerformInspections({ inspections: finalArray }));
      sendManualProductAutoSave(requestPayload);
      setOpenDrawer(false);
    }
  };

  return (
    <div className="px-10">
      <section className={LineItemCardStyles.addProductFormOuterContainer}>
        <InputField
          label={"Product Title"}
          placeholder={"Product Title"}
          mandatory={true}
          value={title}
          theme={"secondary"}
          onChange={(event) => setTitle(event.target.value)}
        />
        <InputField
          label={"SKU"}
          placeholder={"SKU"}
          mandatory={true}
          value={sku}
          theme={"secondary"}
          onChange={(e) => setSku(e.target.value)}
        />
        <section className={LineItemCardStyles.addProductFormInnerContainer}>
          <span className="w-[200px]">
            <DropdownField
              label={"UOM"}
              options={uom}
              theme={"secondary"}
              mode={"page"}
              isSearchable={true}
              componentID={"filter_uom"}
              error={isUOMError}
              searchText={() => {}}
              errorText={"Please select a UOM"}
              mandatory={true}
              value={ea}
              onChange={(timeZone) =>
                handleChange(
                  { target: { value: timeZone } },
                  setEa,
                  setIsUOMError
                )
              }
            />
          </span>
          <span className="space-y-4">
            <label className="font-inter font-medium text-[14px] leading-[17px] text-[_rgba(27,43,65,0.72)]">
              Quantity
            </label>
            <QuantityMeasure
              number={quantity}
              sendQuantity={(val) => {
                setQuantity(val);
              }}
              inspectionStatus={"InProgress"}
              disableQuantity={false}
              mandatory={true}
            />
          </span>
        </section>
        <PriceInputField
          label={"Price"}
          placeholder={"Price"}
          value={price}
          theme={"secondary"}
          onChange={(e) => setPrice(e.target.value)}
        />
      </section>
      <section className="pb-16">
        <Footer theme={"primary"}>
          <Button
            theme={"tertiary"}
            buttonText={"Cancel"}
            onClick={() => setOpenDrawer(false)}
          />
          <Button
            buttonText={"Done"}
            theme={!isDoneButtonDisabled ? "primary" : "primary-with-disabled"}
            disabled={!isDoneButtonDisabled ? false : true}
            onClick={(e) => {
              handleSubmit(e);
            }}
          />
        </Footer>
      </section>
    </div>
  );
};

const AddProduct = ({
  products,
  sendRemovedProductData = (val) => val,
  inspectionId,
  lineItemId,
  apiStatus,
  inspectionStatus,
  sendProductQty = (val) => val,
  sendManualProductAutoSave = (val) => val,
  sendSKU = (val) => val,
}) => {
  const { inspections } = useSelector(
    (completeInspection) => ({
      inspections: completeInspection?.inspections,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [productData, setProductData] = useState([]);

  useEffect(() => {
    if (lineItemId === products?.line_item_id) {
      const mappedItems = products?.mapped_item_list?.map((item) => {
        return { ...item, line_item_id: products?.line_item_id };
      });
      setProductData(mappedItems);
    }
  }, [products, inspections?.inspections]);

  const removeCard = async (id) => {
    let originalArray = inspections?.inspections;
    const finalArray = originalArray?.map((item) => {
      return lineItemId === item?.line_item_id
        ? {
            ...item,
            mapped_item_list: item?.mapped_item_list.filter(
              (product) => product?.id !== id
            ),
          }
        : item;
    });
    dispatch(setPerformInspections({ inspections: finalArray }));
    let generateAPIUrl = `${INSPECTIONS_API_URL}/${inspectionId}/items/${lineItemId}/mapped-items/${id}`;
    await httpAPICall(generateAPIUrl, "DELETE", {}, "", "");
    const updatedCards = products?.mapped_item_list.filter(
      (product) => product?.id !== id
    );
    sendRemovedProductData(updatedCards);
  };

  return (
    <div>
      {productData?.length > 0 &&
        productData?.map((item, index) => {
          return (
            <div key={index}>
              <div className={LineItemCardStyles.addProductIconContainer}>
                {item?.picture_list?.length > 0 ? (
                  <img
                    src={
                      item?.picture_list[0]?.url?.original
                        ? item?.picture_list[0]?.url?.original
                        : "https://cdn.vectorstock.com/i/preview-1x/32/45/no-image-symbol-missing-available-icon-gallery-vector-45703245.jpg"
                    }
                    alt="Product"
                    className={LineItemCardStyles.addProductIcon}
                  />
                ) : item?.additional_info?.product_image_url ? (
                  <img
                    src={item?.additional_info?.product_image_url}
                    alt="Product"
                    className={LineItemCardStyles.addProductIcon}
                  />
                ) : item?.additional_info?.product_image_url ? (
                  <img
                    src={item?.additional_info?.product_image_url}
                    alt="Product"
                    className={LineItemCardStyles.addProductIcon}
                  />
                ) : (
                  <div className={LineItemCardStyles.addProductIconManual}>
                    <ManualProductIcon />
                  </div>
                )}

                <div className="flex flex-col w-full space-y-[24px]">
                  <div className="flex items-center justify-between font-lato text-[#383838]">
                    <span
                      className={LineItemCardStyles.addProductNameContainer}
                    >
                      {item?.name || item?.title}
                    </span>
                    {!apiStatus && inspectionStatus === "InProgress" && (
                      <span
                        onClick={() => {
                          removeCard(item.id);
                        }}
                      >
                        <ProductDeleteIcon />
                      </span>
                    )}
                  </div>
                  <span className={LineItemCardStyles.addProductSKUContainer}>
                    SKU: #{item?.sku}&nbsp;&nbsp;{item?.uom}
                  </span>
                  <span
                    className={
                      LineItemCardStyles.addProductQuantityMeasureContainer
                    }
                  >
                    <QuantityMeasure
                      sendQuantity={(val) => {
                        productData.map((product) => {
                          return (
                            product?.id === item?.id &&
                            sendProductQty({
                              id: item?.id,
                              quantity: val,
                              price: item?.price,
                            })
                          );
                        });
                      }}
                      showPrice={true}
                      qty={item?.quantity}
                      price={item?.price}
                      disableQuantity={apiStatus}
                      inspectionStatus={inspectionStatus}
                    />
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      {openDrawer && lineItemId === products?.line_item_id && (
        <SlideDrawer
        openDrawer={openDrawer}
        label={"Add Item Manually"}
        setOpenDrawer={() => setOpenDrawer(false)}
        >
          <AddProductForm
           setOpenDrawer={setOpenDrawer}
            sendManualProduct={(val) => {
              setProductData((productData) => {
                val["unique_id"] = productData[0]?.unique_id;
                const manualProduct = [...productData, val];
                return manualProduct;
              });
            }}
            sendManualProductAutoSave={(val) => {
              sendManualProductAutoSave(val);
              productData.map((product) => {
                return (
                  product?.id &&
                  sendProductQty({
                    id: null,
                    quantity: val?.quantity,
                    price: val?.price,
                  })
                );
              });
              
            }}
            lineItemId={products?.line_item_id}
          />
        </SlideDrawer>
      )}
      {lineItemId === products?.line_item_id &&
        productData?.length > 0 &&
        !products?.api_call &&
        inspectionStatus === "InProgress" && (
          <section className="mt-2">
            <LinkButton
              label={"Add Manual Item"}
              theme={"primary-italic-label"}
              onClick={() => setOpenDrawer(true)}
            />
          </section>
        )}
    </div>
  );
};

function LineItemCard({
  uniqueKey,
  lineItemDetails,
  inspectionStatus,
  onDelete,
  searchProduct = (val) => val,
  sendProduct = (val) => val,
  sendLineItemLabel = (val) => val,
  selectedProducts,
  sendProductsToDrawer = (val) => val,
  sendCheckedLineItem = (val) => val,
  sendProductQty = (val) => val,
  sendProductsToAutoSave = (val) => val,
  sendRemovedProductData = (val) => val,
  sendLineItemId = (val) => val,
  sendFilterProductPayload = (val) => val,
  sendLineItemMeasurement = (val) => val,
  sendLineItemHeading = (val) => val,
  sendManualProductAutoSave = (val) => val,
  sendLineItemNotes = (val) => val,
  reloadInspection = (val) => val,
  inspectionId,
  checkAll,
  activeTab,
  lineItemId,
  apiCall,
  deleteLineItem,
  handleCopyInspectionLineItem,
}) {
  const dispatch = useDispatch();
  const [isOpenPhotos, setIsOpenPhotos] = useState(false);
  const { inspections } = useSelector(
    (completeInspection) => ({
      inspections: completeInspection?.inspections,
    }),
    shallowEqual
  );
  const [showTooltipText, setShowTooltipText] = useState(false);
  const [products, setProducts] = useState([]);
  const [checkLineItem, setCheckLineItem] = useState(null);
  const [rating, setRating] = useState(null);
  const [measurement, setMeasurement] = useState(
    lineItemDetails?.measurements?.dimensions
  );
  const [lineItemLabel, setLineItemLabel] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [searchLabel, setSearchLabel] = useState(
    lineItemDetails?.line_item_name
  );
  const [notes, setNotes] = useState(
    lineItemDetails?.notes ? lineItemDetails?.notes : ""
  );

  const copyLineItem = async (copiedLineItem) => {
    try {
      const apiUrl = `${INSPECTIONS_API_URL}/${inspectionId}/items`;
      const payload = {
        customer: {
          id: copiedLineItem?.customer?.id,
        },
        inspection: {
          id: copiedLineItem?.id,
          name: copiedLineItem?.name,
        },
        quantity: copiedLineItem?.quantity,
        name: copiedLineItem?.line_item_name,
        type: copiedLineItem?.type,
        notes: copiedLineItem?.notes,
        status: copiedLineItem?.status,
        measurements: {
          dimensions: copiedLineItem?.measurements?.dimensions,
        },
      };
      const response = await httpAPICall(apiUrl, "POST", payload, "", "");
      return response;
    } catch (error) {
      throw error;
    }
  };

  const handleCopyLineItem = (lineItemDetails) => {
    copyLineItem(lineItemDetails)
      .then((response) => {
        handleCopyInspectionLineItem();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const deleteCopiedLineItemAPI = async (inspectionId, lineItemId) => {
    try {
      const apiUrl = `${INSPECTIONS_API_URL}/${inspectionId}/items/${lineItemId}`;
      const response = await httpAPICall(apiUrl, "DELETE", {}, "", "", "", true);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const handleDeleteCopiedLineItem = async (inspectionId, lineItemId) => {
    try {
      const response = await deleteCopiedLineItemAPI(inspectionId, lineItemId);
      if (response.status === 204) {
        let originalArray = inspections?.inspections;
        const finalArray = originalArray?.map((item) => {
          if (item.id === inspectionId) {
            if (item.copied_line_items) {
              item.copied_line_items = item.copied_line_items.filter(
                (copiedLineItem) => copiedLineItem.id !== lineItemId
              );
            }
          }
          return item;
        });

        dispatch(setPerformInspections({ inspections: finalArray }));
        handleCopyInspectionLineItem();
      }
    } catch (error) {
      console.error("Error deleting copied line item:", error);
    }
  };

  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);

  useEffect(() => {
    setProducts(lineItemDetails);
  }, [selectedProducts, lineItemDetails]);

  useEffect(() => {
    if (checkAll) {
      setCheckLineItem(checkAll);
    } else {
      setCheckLineItem((prevState) => !prevState);
    }
  }, [checkAll]);

  useEffect(() => {
    sendProductsToAutoSave(lineItemDetails?.mapped_item_list);
  }, [lineItemDetails?.mapped_item_list]);

  return (
    <div className={LineItemCardStyles.lineItemOuterContainer}>
      <section className={LineItemCardStyles.lineItemInnerContainer}>
        <span>
          <AutoEditInputField
            text={lineItemDetails?.line_item_name}
            theme={"primary"}
            inspectionStatus={inspectionStatus}
            disabled={apiCall}
            sendAutoSave={(val) => {
              setLineItemLabel(val);
              sendLineItemId(lineItemId);
              setSearchLabel(val);
              let originalArray = inspections?.inspections;
              const finalArray = originalArray?.map((item) => {
                return lineItemId === item?.line_item_id
                  ? {
                      ...item,
                      line_item_name: val,
                    }
                  : item;
              });
              dispatch(setPerformInspections({ inspections: finalArray }));
              sendLineItemLabel(val);
            }}
          />
          <span
            className={LineItemCardStyles.addProductQuantityMeasureContainer}
          >
            <span className="flex flex-row mt-[25px] gap-x-[9px] items-center">
              <label className="font-Lato font-semibold text-[18px] text-[#383838]">
                Quantity:
              </label>
              <QuantityMeasure
                number={quantity}
                sendQuantity={(val) => {
                  setQuantity(val);
                }}
                inspectionStatus={"InProgress"}
                disableQuantity={false}
              />
            </span>
          </span>
        </span>
        {activeTab === lineItemDetails?.tab_type &&
          lineItemId === lineItemDetails?.line_item_id &&
          (inspectionStatus !== "InProgress" ? (
            <label className={LineItemCardStyles.statusContainer}>
              {lineItemDetails?.status === "Major Issues"
                ? "Yes"
                : lineItemDetails?.status === "Fair"
                ? "No"
                : null}
            </label>
          ) : (
            <span>
              {lineItemId === lineItemDetails?.line_item_id && (
                <YesOrNo
                  theme={"secondary"}
                  checked={
                    lineItemDetails?.status === "Major Issues"
                      ? "yes"
                      : lineItemDetails?.status === "Fair"
                      ? "no"
                      : null
                  }
                  handleChange={(e) => {
                    lineItemId === lineItemDetails?.line_item_id &&
                      sendCheckedLineItem(e.target.value);
                    sendLineItemId(lineItemId);
                    if (inspectionStatus === "InProgress") {
                      lineItemId === lineItemDetails?.line_item_id &&
                        setRating(e.target.value);
                      let originalArray = inspections?.inspections;
                      const finalArray = originalArray?.map((item) => {
                        return lineItemId === item?.line_item_id
                          ? {
                              ...item,
                              status:
                                e.target.value === "yes"
                                  ? "Major Issues"
                                  : e.target.value === "no"
                                  ? "Fair"
                                  : "Pending",
                          }
                          : item;
                      });
                      dispatch(
                        setPerformInspections({ inspections: finalArray })
                      );
                    }
                  }}
                />
              )}
            </span>
          ))}
      </section>
      <section className={LineItemCardStyles.commentContainer}>
        <span>
          <CommentIcon />
        </span>
        <span>
          <AutoEditInputField
            text={lineItemDetails?.notes ? lineItemDetails?.notes : "No notes"}
            sendAutoSave={(val) => {
              setLineItemLabel(val);
              sendLineItemId(lineItemId);
              setNotes(val);
              let originalArray = inspections?.inspections;
              const finalArray = originalArray?.map((item) => {
                return lineItemId === item?.line_item_id
                  ? {
                    ...item,
                    notes: val,
                  }
                  : item;
              });
              dispatch(setPerformInspections({ inspections: finalArray }));
              sendLineItemNotes(val);
            }}
            theme={"secondary"}
            inspectionStatus={inspectionStatus}
            disabled={apiCall}
          />
        </span>
      </section>

      <section className={LineItemCardStyles.measureContainer}>
        <section className="flex items-center justify-between space-x-[15px]">
          <span>
            <MeasureScaleIcon />
          </span>
          <span>
            <InputField
              theme={"secondary"}
              placeholder={"0"}
              value={measurement}
              onChange={(e) => {
                if (!apiCall && inspectionStatus === "InProgress") {
                  setMeasurement(e.target.value);
                  let originalArray = inspections?.inspections;
                  const finalArray = originalArray?.map((item) => {
                    return lineItemId === item?.line_item_id
                      ? {
                        ...item,
                        measurements: { dimensions: e.target.value },
                      }
                      : item;
                  });
                  dispatch(setPerformInspections({ inspections: finalArray }));
                }
              }}
              sendAutoSave={(val) => {
                sendLineItemMeasurement(val);
                sendLineItemId(lineItemId);
              }}
              isDisabled={inspectionStatus === "InProgress" ? false : true}
            />
          </span>
          {!apiCall && (
            <span className={LineItemCardStyles.helpTextContainer}>
              <label>Measure</label>
              <span className="ml-[4px]">
                <ToolTipIcon
                  onMouseEnter={() => setShowTooltipText(true)}
                  onMouseLeave={() => setShowTooltipText(false)}
                />
                {showTooltipText && (
                  <ToolTip
                    text={
                      "Click here to take measurments of cabinet doors, drawers, blinds and record the measurements."
                    }
                  />
                )}
              </span>
            </span>
          )}
        </section>
        <section>
          {apiCall ||
            (inspectionStatus === "Completed" &&
              lineItemDetails?.picture_list.length > 0 && (
                <span>
                  <ImageWrapper
                    images={lineItemDetails?.picture_list[0]?.url?.original}
                    onClick={() => setIsOpenPhotos(!isOpenPhotos)}
                    length={lineItemDetails?.picture_list.length - 1}
                  />
                </span>
              ))}
        </section>
      </section>
      {!apiCall && inspectionStatus === "InProgress" && (
        <section className={LineItemCardStyles.footerOuterContainer}>
          <div className={LineItemCardStyles.footerInnerContainer}>
            <div className={LineItemCardStyles.searchContainer}>
              <span>
                <Button
                  theme={"quaternary"}
                  buttonText={"Search"}
                  onClick={() => {
                    sendLineItemId(lineItemId);
                    searchProduct({
                      title: lineItemDetails?.line_item_name,
                      notes: lineItemDetails?.notes
                        ? lineItemDetails?.notes
                        : "",
                      measurement: lineItemDetails?.measurements?.dimensions
                        ? lineItemDetails?.measurements?.dimensions
                        : "",
                    }, quantity);
                    sendProductsToDrawer(lineItemDetails?.mapped_item_list);
                    sendProduct({
                      title: lineItemDetails?.line_item_name,
                      notes: lineItemDetails?.notes
                        ? lineItemDetails?.notes
                        : "",
                      measurement: lineItemDetails?.measurements?.dimensions
                        ? lineItemDetails?.measurements?.dimensions
                        : "",
                    });
                  }}
                />
              </span>
              <span
                onClick={() => {
                  sendLineItemId(lineItemId);
                  setOpenFilterDrawer(true);
                }}
              >
                <FilterIcon />
              </span>
            </div>
          </div>
          <div className={LineItemCardStyles.imgContainer}>
            {lineItemDetails?.picture_list.length > 0 && (
              <ImageWrapper
                images={lineItemDetails?.picture_list[0]?.url?.original}
                onClick={() => setIsOpenPhotos(!isOpenPhotos)}
                length={lineItemDetails?.picture_list.length - 1}
              />
            )}
            <span>
              <ClipBoardIcon
                className="cursor-pointer"
                onClick={() => handleCopyLineItem(lineItemDetails)}
              />
            </span>
            {lineItemDetails.list_item_id && (
              <span>
                <DeleteIcon
                  className="cursor-pointer"
                  onClick={() =>
                    handleDeleteCopiedLineItem(
                      lineItemDetails.id,
                      lineItemDetails.line_item_id
                    )
                  }
                />
              </span>
            )}
          </div>
        </section>
      )}
      <SlideDrawer
        label={"Photos"}
        customWidth={"xs"}
        openDrawer={isOpenPhotos}
        componentID={"inspectionLineItem_openPhotosDrawer"}
        setOpenDrawer={() => setIsOpenPhotos(false)}
      >
        <PhotoContainer
          images={lineItemDetails?.picture_list}
          handleCancel={() => setIsOpenPhotos(false)}
          handleDelete={() => {
            setIsOpenPhotos(false);
          }}
          inspectionId={inspectionId}
          reloadInspection={(val) => reloadInspection(val)}
        />
      </SlideDrawer>

      {openFilterDrawer && (
        <section>
          <SlideDrawer
            openDrawer={openFilterDrawer}
            label={"Filter"}
            setOpenDrawer={() => {
              setOpenFilterDrawer(false);
            }}
          >
            <section className={LineItemCardStyles.filterProductFormContainer}>
              <FilterProductForm
                setOpenFilterDrawer={setOpenFilterDrawer}
                sendFilterProductPayload={(val) =>
                  sendFilterProductPayload(val)
                }
                existingDetails={{
                  title: lineItemDetails?.line_item_name,
                  notes: lineItemDetails?.notes ? lineItemDetails?.notes : "",
                  measurement: lineItemDetails?.measurements?.dimensions
                    ? lineItemDetails?.measurements?.dimensions
                    : "",
                }}
              />
            </section>
          </SlideDrawer>
        </section>
      )}
      <section>
        <AddProduct
          uniqueKey={uniqueKey}
          products={products}
          activeTab={activeTab}
          sendRemovedProductData={(val) => sendRemovedProductData(val)}
          lineItemId={lineItemId}
          inspectionId={inspectionId}
          apiStatus={lineItemDetails?.api_call}
          inspectionStatus={inspectionStatus}
          sendProductQty={(val) => {
            sendProductQty(val);
            sendLineItemId(lineItemId);
          }}
          sendManualProductAutoSave={(val) => {
            sendLineItemId(lineItemId);
            sendManualProductAutoSave(val);
          }}
        />
      </section>
    </div>
  );
}

export default LineItemCard;
