import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={10}
    height={10}
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 1L6.544 6.544"
      stroke="#8E8E8E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.544 1L1 6.544"
      stroke="#8E8E8E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SVGComponent;