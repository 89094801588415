import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { formatPrice, calculateSubtotals } from "../../utils";
import _, { isArray } from "lodash";
import { setCreateQuoteMatrix } from "../../redux/slice/createQuoteMatrix";

const SummaryQuote = ({
  categoryKey,
  tableData = [],
  headers,
  status,
  inspectionList,
  mode,
  sendFloorPlanRenoCount = (val) => val,
  floorplanHeaders,
  sizeOfGroupByType,
  categoryIndex,
  perUnitTotals,
  perUnitTypeTotals,
  perUnitTypeTotalsGrandTotal,
  setTax,
  taxPercentage,
  quoteSummaryRecords,
  perUnitTotalsWithTax,
}) => {
  const dispatch = useDispatch();
  const [subTotals, setSubTotals] = useState([]);
  const rightAlignedHeaders = [
    "Unit Price",
    "Total Units and Items",
    "Supplier",
  ];
  const isRightAligned = (header) => rightAlignedHeaders.includes(header);

  useEffect(() => {
    let generateSubTotals = calculateSubtotals(
      tableData,
      categoryKey,
      inspectionList,
      mode,
      floorplanHeaders
    );
    setSubTotals(generateSubTotals);
  }, []);

  return (
    <div>
      <table className="w-full">
        <thead>
          <tr>
            {headers.map((header, index) =>
              index === 5 && isArray(header) ? (
                header?.map((item1, dataIndex) => {
                  return (
                    <th
                      key={index}
                      className="bg-[#F5F6F6] font-lato text-[#383838] text-sm font-bold text-left py-3"
                      style={{
                        paddingLeft: index === 0 && "25px",
                      }}
                    >
                      <div className="flex flex-col items-end space-y-[7px] mx-3">
                        <label className="font-lato text-sm not-italic font-bold text-[#383838]">
                          {item1?.name}
                        </label>
                        <input
                          id={item1?.name}
                          type="number"
                          min="1"
                          name={header}
                          disabled={
                            mode === "customerView" ||
                            status === "SCancelled" ||
                            status === "CDeclined" ||
                            status === "Contracted"
                          }
                          value={item1?.count}
                          onKeyDown={(evt) => {
                            if (evt.key === "e" || evt.key === "-") {
                              evt.preventDefault();
                            }
                          }}
                          className="w-[3.875rem] h-[2.1875rem] border rounded-[0.25rem] focus:outline-none text-center text-[#006CDB] mt-[0.44px]"
                          onChange={(e) => {
                            const originalArray = tableData;
                            let finalArray = originalArray.map((item) => {
                              return item1?.name === item?.floorPlanName &&
                                item?.lineItemType === categoryKey
                                ? {
                                    ...item,
                                    detailItemRenovationUnitCount:
                                      e.target.value,
                                  }
                                : item;
                            });
                            dispatch(
                              setCreateQuoteMatrix({
                                quoteMatrices: finalArray,
                              })
                            );
                            sendFloorPlanRenoCount({
                              ...item1,
                              value: Number(e.target.value),
                            });
                          }}
                        />
                      </div>
                    </th>
                  );
                })
              ) : (
                <th
                  key={index}
                  className="bg-[#F5F6F6] font-lato text-[#383838] text-sm font-bold text-left py-3 px-2"
                  style={{
                    paddingRight: index === 1 && "25px",
                    textAlign: isRightAligned(header) ? "right" : undefined,
                  }}
                >
                  {header}
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody className="w-full overflow-x-auto">
          {tableData?.map((row, index) => {
            return (
              row?.lineItemType === categoryKey && (
                <tr
                  className="p-3 font-lato text-[#383838] bg-white text-sm font-medium text-center border"
                  key={index}
                >
                  <td className="pl-2 py-3 text-left break-all">
                    <img
                      src={row?.lineItemAdditionalInfo?.product_image_url}
                      alt="Product"
                      className="w-[2.9375rem] h-[2.9375rem] pl-3"
                    />
                  </td>
                  <td className="py-3 text-left break-all">{index + 1}</td>
                  {row.lineItemSku ? (
                    <td
                      className=" py-3 text-left break-all cursor-pointer"
                      title={row.lineItemSku}
                    >
                      {row?.lineItemSku.length > 6
                        ? row?.lineItemSku.slice(0, 6) + "..."
                        : row?.lineItemSku}
                    </td>
                  ) : (
                    <td className="px-2 py-3 text-left break-all">-</td>
                  )}

                  {row?.lineItemName ? (
                    <td
                      className="py-3 text-left w-[200px] break-all cursor-pointer"
                      title={row.lineItemName}
                    >
                      {row.lineItemName.length > 40
                        ? row.lineItemName.slice(0, 40) + "..."
                        : row?.lineItemName}
                    </td>
                  ) : (
                    <td className="px-2 py-3 text-left break-all">-</td>
                  )}

                  {row?.lineItemName ? (
                    <td
                      className="py-3 text-right break-all w-[240px] cursor-pointer px-2"
                      title={row.lineItemName}
                    >
                      {row?.supplier?.name || "-"}
                    </td>
                  ) : (
                    <td className="px-2 py-3 text-left break-all">-</td>
                  )}

                  {headers[5]?.map((floorplanDetails, rowIndex) => {
                    const matchingDetailItem = row?.detailItemQuantity.find(
                      (detailItem) =>
                        detailItem.floorplan_name === floorplanDetails?.name
                    );
                    const quantity = matchingDetailItem?.quantity;

                    return (
                      <td
                        className="px-2 py-3 text-right break-all"
                        key={rowIndex}
                      >
                        {quantity === undefined || quantity === "-"
                          ? "-"
                          : Number(quantity)}
                      </td>
                    );
                  })}

                  {row?.rowTotalUnitsAndItems ? (
                    <td className="px-4 py-3 text-right break-all">
                      {row?.rowTotalUnitsAndItems}
                    </td>
                  ) : (
                    <td className="px-2 py-3 text-left break-all">-</td>
                  )}

                  <td className="px-4 py-3 text-right break-all">
                    {row.lineItemPrice ? `${formatPrice(Number(row.lineItemPrice))}` : `$0.00`}
                  </td>

                  {row?.lineItemUom ? (
                    <td className="px-2 py-3 text-left break-all">
                      {row.lineItemUom}
                    </td>
                  ) : (
                    <td className="px-2 py-3 text-left break-all">-</td>
                  )}
                  <td className="px-2 py-3 text-left break-all">
                    <input
                      type="number"
                      min="1"
                      disabled={
                        mode === "customerView" ||
                        status === "SCancelled" ||
                        status === "CDeclined" ||
                        status === "Contracted"
                      }
                      onKeyDown={(evt) => {
                        if (evt.key === "e" || evt.key === "-") {
                          evt.preventDefault();
                        }
                      }}
                      value={row?.lineItemPackQty ? row?.lineItemPackQty : ""}
                      onChange={(e) => {
                        const originalArray = tableData;
                        let finalArray = originalArray.map((item) => {
                          return row?.lineItemSku === item?.lineItemSku &&
                            item?.lineItemType === categoryKey
                            ? {
                                ...item,
                                lineItemPackQty: e.target.value,
                              }
                            : item;
                        });
                        dispatch(
                          setCreateQuoteMatrix({
                            quoteMatrices: finalArray,
                          })
                        );
                      }}
                      className="w-[3.875rem] h-[2.1875rem] border rounded-[0.25rem] focus:outline-none text-center text-black mt-[0.44px]"
                    />
                  </td>
                </tr>
              )
            );
          })}
        </tbody>
        <tbody className="w-full overflow-x-auto bg-[#C8C8C8]">
          {Object.entries(subTotals)?.map(([key, floorplanSubtotal], index) => {
            return (
              <tr
                key={index}
                className="w-full bg-[#C8C8C8] font-inter text-base font-medium text-[#383838]"
              >
                <td colSpan={4}></td>
                <td className="py-3 font-inter text-base not-italic font-bold text-[#383838] text-right px-2">
                  {key}
                </td>
                {Object.entries(floorplanSubtotal)?.map(
                  ([FKey, value], index) => {
                    return (
                      <td
                        key={index}
                        className="font-lato text-[18px] not-italic  text-[#383838] text-right font-bold"
                      >
                        ${formatPrice(Number(value))}
                      </td>
                    );
                  }
                )}
                <td colSpan={4}></td>
              </tr>
            );
          })}
        </tbody>
        {categoryIndex === sizeOfGroupByType - 1 && (
          <>
            <tbody className="w-full bg-[rgb(236,245,255)] border-b border-white">
              {Object.entries(perUnitTotals)?.map(
                ([Key, FloorplanwiseTotals], index) => {
                  return (
                    <tr key={index}>
                      <td colSpan={3}></td>
                      <td
                        colSpan={2}
                        className="py-3 text-[#006CDB] font-inter text-right px-2 text-base not-italic font-bold"
                      >
                        {Key}
                      </td>
                      {Object.entries(FloorplanwiseTotals)?.map(
                        ([FPKey, total], index) => {
                          return (
                            <td
                              key={index}
                              className="text-[#383838] font-lato text-sm not-italic font-bold text-right px-2"
                            >
                              ${formatPrice(Number(total))}
                            </td>
                          );
                        }
                      )}
                      <td></td>
                      <td colSpan={4}></td>
                    </tr>
                  );
                }
              )}
              <tr>
                <td colSpan={3}></td>
                <td
                  colSpan={2}
                  className="py-3 text-[#006CDB] text-right px-2 font-inter text-base not-italic font-bold"
                >
                  Unit Type Totals
                </td>
                {perUnitTypeTotals?.map((perUnitType, index) => {
                  return (
                    <td
                      key={index}
                      className="text-[#383838] font-lato text-sm not-italic font-bold text-right px-2"
                    >
                      $
                      {formatPrice(
                        Number(perUnitType?.perUnitTotalWithOutTax)
                      )}
                    </td>
                  );
                })}
                <td colSpan={4}></td>
              </tr>
              <tr>
                <td colSpan={3}></td>
                <td
                  colSpan={2}
                  className="py-3 text-[#006CDB] font-inter text-right px-2 text-base not-italic font-bold"
                >
                  Unit Type Grand Total
                </td>
                <td className="text-[#383838] font-lato text-sm not-italic font-bold text-right px-2">
                  $
                  {formatPrice(
                    Number(
                      perUnitTypeTotalsGrandTotal?.perUnitTypeGrandTotalWithoutTax
                    )
                  )}
                </td>
                {perUnitTotals &&
                  Array.from({ length: perUnitTotals.length - 1 }).map(
                    (_, index) => <td key={index}></td>
                  )}
                <td colSpan={4}></td>
              </tr>
            </tbody>
            <tbody className="w-full bg-[#ECF5FF] border-b border-white">
              <tr>
                <td colSpan={3}></td>
                <td
                  colSpan={2}
                  className="py-3 text-[#006CDB] text-right px-2 font-inter text-base not-italic font-bold"
                >
                  Tax
                </td>
                <td className="flex py-3 font-inter text-base not-italic font-bold text-[#383838] justify-end">
                  <input
                    type="number"
                    min={"0"}
                    value={taxPercentage}
                    onKeyDown={(evt) => {
                      if (evt.key === "e" || evt.key === "-") {
                        evt.preventDefault();
                      }
                    }}
                    disabled={
                      mode === "customerView" ||
                      quoteSummaryRecords?.status === "SCancelled" ||
                      quoteSummaryRecords?.status === "CDeclined" ||
                      quoteSummaryRecords?.status === "Contracted"
                    }
                    onChange={(e) => setTax(e.target.value)}
                    className="w-[90px] h-[48px] focus:outline-none pl-[23px] rounded-l-[4px] border"
                  />
                  <span className="w-[45px] h-[48px] flex justify-center items-center bg-[#ECF5FF] rounded-r-[4px] border">
                    %
                  </span>
                </td>
                {perUnitTotals &&
                  Array.from({ length: perUnitTotals.length - 1 }).map(
                    (_, index) => <td key={index}></td>
                  )}
                <td colSpan={4}></td>
              </tr>
              <tr>
                <td colSpan={3}></td>
                <td
                  colSpan={2}
                  className="py-3 text-[#006CDB] text-right px-2 font-inter text-base not-italic font-bold"
                >
                  Per Unit Total (Inclu. Tax)
                </td>
                {perUnitTotalsWithTax?.map((value, index) => {
                  return (
                    <td
                      key={index}
                      className="text-[#383838] font-lato text-sm not-italic font-bold text-right px-2"
                    >
                      ${formatPrice(Number(value))}
                    </td>
                  );
                })}
                <td colSpan={4}></td>
              </tr>
              <tr>
                <td colSpan={3}></td>
                <td
                  colSpan={2}
                  className="py-3 text-[#006CDB] text-right px-2 font-inter text-base not-italic font-bold"
                >
                  Unit Type Totals (Inclu. Tax)
                </td>
                {perUnitTypeTotals?.map((perUnitType, index) => {
                  return (
                    <td
                      key={index}
                      className="text-[#383838] font-lato text-sm not-italic font-bold text-right px-2"
                    >
                      $
                      {formatPrice(
                        Number(perUnitType?.perUnitTotalWithTax)
                      )}
                    </td>
                  );
                })}
                <td colSpan={4}></td>
              </tr>
            </tbody>
            <tbody className="w-full bg-[#ECF5FF]">
              {perUnitTypeTotalsGrandTotal?.perUnitTypeGrandTotalWithTax &&
                Object.entries(
                  perUnitTypeTotalsGrandTotal?.perUnitTypeGrandTotalWithTax
                )?.map(([UTKey, value], index) => {
                  return (
                    <tr key={index}>
                      <td colSpan={3}></td>
                      <td
                        colSpan={2}
                        className="py-3 text-[#006CDB] text-right px-2 font-lato not-italic font-bold"
                        style={{
                          fontSize: UTKey === "Grand Total" ? "24px" : "18px",
                        }}
                      >
                        {UTKey}
                      </td>
                      <td
                        className="text-[#383838] py-3 font-lato flex font-bold justify-end text-right px-2"
                        style={{
                          fontSize: UTKey === "Grand Total" ? "20px" : "16px",
                        }}
                      >
                        <span className="flex">
                          <span>${formatPrice(Number(value))}</span>
                        </span>
                      </td>
                      {perUnitTotals &&
                        Array.from({ length: perUnitTotals.length - 1 }).map(
                          (_, index) => <td key={index}></td>
                        )}
                      <td colSpan={4}></td>
                    </tr>
                  );
                })}
            </tbody>
          </>
        )}
      </table>
    </div>
  );
};

export default SummaryQuote;
