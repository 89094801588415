const LineItemCardStyles = {
  filterProductFormContainer: "px-10 overflow-y-auto",
  img: "w-[100px] h-[80px] rounded-[10px]",
  imgContainer: "flex items-end space-x-[20px] rounded-md",
  filterContainer: "space-y-4 overflow-y-auto",
  addProductFormOuterContainer: "space-y-4",
  addProductFormInnerContainer: "flex justify-between items-center",
  addProductIconContainer:
    "flex items-center space-x-[20px] border rounded-[8px] px-[25px] py-[8px] mt-[24px] shadow-sm bg-white",
  addProductIcon: "w-[180px] h-[139px]",
  addProductIconManual:
    "w-[180px] h-[139px] flex items-center justify-center bg-[#C8C8C8]",
  addProductNameContainer: "text-[16px] not-italic font-medium leading-[27px]",
  addProductSKUContainer: "text-[16px] italic font-medium leading-[20px]",
  addProductQuantityMeasureContainer: "flex items-center justify-between",
  addProductPriceContainer:
    "text-[16px] not-italic font-medium leading-[20px] text-[#006CDB]",
  lineItemOuterContainer: "border-b p-[24px] space-y-[10px]",
  lineItemInnerContainer: "flex justify-between",
  commentContainer: "flex space-x-[15px] items-center",
  measureContainer: "flex space-x-[15px] items-center justify-between",
  helpTextContainer: "font-lato font-normal text-[#006CDB] flex items-center",
  searchContainer: "flex items-center space-x-[12px] mt-3",
  footerOuterContainer: "flex justify-between",
  footerInnerContainer: "flex items-end",
  statusContainer: "font-lato font-medium text-base text-[#006CDB]",
};

export default LineItemCardStyles;
