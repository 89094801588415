/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from "react";
import {
  InputField,
  Button,
  DropdownField,
} from "../../components";
import httpAPICall from "../../utils/httpApiCall";
import {
  statusMapping,
  customerStatusOptions,
} from "../../utils/statusMappings";
import { CUSTOMERS_API_URL } from "../../constants";
import qs from "qs";
import { getUSStates } from "../../utils/stateCities";
import { isEmpty, checkFieldValue } from "../../utils/isEmpty";

const CustomerForm = forwardRef(
  (
    {
      mode,
      passDataToNextSection,
      handleCancel,
      sendCustomerName,
      customerID,
      customerData,
      quoteApproverData,
      errors,
      isEnableAccordion,
      isLoading,
    },
    ref
  ) => {
    const [customerName, setCustomerName] = useState("");
    const [debouncedSearch, setDebouncedSearch] = useState(null);
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [zip, setZip] = useState("");
    const [contactName, setContactName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [alias, setAlias] = useState("");
    const [isQuoteApprover, setIsQuoteApprover] = useState(false);
    const [isCustomerError, setIsCustomerError] = useState(false);
    const [isZipError, setIsZipError] = useState(false);
    const [quoteApproverFirstName, setQuoteApproverFirstName] = useState("");
    const [quoteApproverLastName, setQuoteApproverLastName] = useState("");
    const [quoteApproverPhone, setQuoteApproverPhone] = useState("");
    const [quoteApproverEmail, setQuoteApproverEmail] = useState("");
    const [isQuoteApproverLastNameError, setIsQuoteApproverLastNameError] =
      useState(false);
    const [isQuoteApproverPhoneError, setIsQuoteApproverPhoneError] =
      useState(false);
    const [isQuoteApproverEmailError, setIsQuoteApproverEmailError] =
      useState(false);
    const [customerErrorText, setCustomerErrorText] = useState(
      "Please enter the PMC name"
    );
    const [isEmailError, setIsEmailError] = useState(false);
    const [isPhoneNumberError, setIsPhoneNumberError] = useState(false);
    const [status, setStatus] = useState([]);
    const [isSave, setIsSave] = useState(false);
    const [isRegisteredCustomer, setIsRegisteredCustomer] = useState(0);
    let states = getUSStates();

    useEffect(() => {
      if (mode === "edit") {
        fetchCustomerData(customerID);
      }

      if (mode === "page") {
        if (quoteApproverData) {
          setIsQuoteApprover(true);
          setIsQuoteApproverLastNameError(false);
          setQuoteApproverFirstName(quoteApproverData.first_name);
          setQuoteApproverEmail(quoteApproverData.email);
          setQuoteApproverLastName(quoteApproverData.last_name);
          setQuoteApproverPhone(quoteApproverData.phone_number);
        }
        setCustomerName(customerData[0]?.name || "");
        setAlias(customerData[0]?.alias || "");
        setAddress1(customerData[0]?.address?.address1 || "");
        setAddress2(customerData[0]?.address?.address2 || "");
        setContactName(customerData[0]?.contact_name || "");
        setPhoneNumber(customerData[0]?.phone_number || "");
        setEmail(customerData[0]?.email || "");
        setCity(customerData[0]?.address?.city || "");
        setState(
          states.find(
            (state) => state?.id === customerData[0]?.address?.state
          ) || ""
        );
        setZip(customerData[0]?.address?.zip || "");
      }
    }, []);

    const fetchCustomerData = async (company_id) => {
      try {
        const response = await httpAPICall(
          CUSTOMERS_API_URL + `/${company_id}`,
          "GET",
          {}
        );
        if (response) {
          setCustomerName(response?.name);
          setAlias(response?.alias);
          setAddress1(response?.address?.address);
          setAddress2(response?.address?.address2);
          setCity(response?.address?.city);
          setContactName(response?.contact_name);
          setPhoneNumber(response?.phone_number);
          setState(
            states.find((state) => state?.id === response?.address?.state)
          );
          setEmail(response?.email);
          setZip(response?.address?.zip);
          setStatus(
            customerStatusOptions.find(
              (customerStatus) =>
                statusMapping[response?.status] === customerStatus?.name
            )
          );
        }
      } catch (error) {
        console.log(error);
      }
    };

    const fetchCustomer = async (filterObj) => {
      try {
        let generatedUrl = CUSTOMERS_API_URL + `?${qs.stringify(filterObj)}`;
        let result = await httpAPICall(generatedUrl, "GET", {});
        if (result?.length > 0) {
          setCustomerErrorText("PMC already exists");
          const {name, is_registered, alias, contact_name, phone_number, email, address } = result[0]
          !is_registered && setIsCustomerError(true);
          mode === "create" && setIsRegisteredCustomer(is_registered);
          if (mode === "create" && is_registered) {
            setCustomerName(name);
            setAlias(alias);
            setAddress1(address.address);
            setAddress2(address.address2);
            setCity(address.city);
            setState(
              states.find(
                (state) => state?.id === address?.state
              ) || ""
            );
            setZip(address.zip);
            setEmail(email);
            setPhoneNumber(phone_number);
            setContactName(contact_name);
          } else {
            setIsCustomerError(true);
          }
        } else {
          setIsCustomerError(false);
          setIsRegisteredCustomer(false);
          setAlias("");
          setAddress1("");
          setAddress2("");
          setCity("");
          setState("");
          setZip("");
          setEmail("");
          setPhoneNumber("");
          setContactName("");
          setCustomerErrorText("Please enter the PMC name");
        }
      } catch (error) {
        console.log(error);
      }
      mode === "create" && setIsSave(false);
      mode === "page" && isEnableAccordion(false);
    };

    const handleUpdate = async (event) => {
      event.preventDefault();
      const payload = {
        id: customerID,
        name: customerName,
        contact_name: checkFieldValue(contactName),
        email: checkFieldValue(email),
        phone_number: checkFieldValue(phoneNumber),
        alias: alias,
        status: status.id,
        address: {
          address1: checkFieldValue(address1),
          address2: checkFieldValue(address2),
          city: checkFieldValue(city),
          state: checkFieldValue(state?.id),
          zip: checkFieldValue(zip),
        },
      };
      try {
        if (
          !isEmpty(customerName, email) &&
          !isPhoneNumberError &&
          !isCustomerError &&
          !isEmailError &&
          !isZipError
        ) {
          passDataToNextSection(payload, "update", customerID);
        } else {
          isEmpty(customerName) && setIsCustomerError(true);
          isEmpty(email) && setIsEmailError(true);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const handleSubmit = (event) => {
      const payload = {
        name: customerName,
        alias: checkFieldValue(alias),
        email: checkFieldValue(email),
        phone_number: checkFieldValue(phoneNumber),
        contact_name: checkFieldValue(contactName),
        type: "pmc",
        address: {
          address1: checkFieldValue(address1),
          address2: checkFieldValue(address2),
          city: checkFieldValue(city),
          state: checkFieldValue(state?.id),
          zip: checkFieldValue(zip),
        },
      };

      try {
        const quoteApprover = {
          first_name: quoteApproverFirstName,
          last_name: quoteApproverLastName,
          email: quoteApproverEmail,
          phone_number: quoteApproverPhone,
          customer: {
            id: null,
          },
          access_level: {
            type: "Company",
          },
        };

        const quoteApproverPayload = isEmpty(quoteApproverFirstName)
          ? null
          : quoteApprover;
        const setErrors = () => {
          isEmpty(customerName) && setIsCustomerError(true);
          isEmpty(email) && setIsEmailError(true);
          if (!isEmpty(quoteApproverFirstName)) {
            isEmpty(quoteApproverLastName) &&
              setIsQuoteApproverLastNameError(true);
            isEmpty(quoteApproverEmail) && setIsQuoteApproverEmailError(true);
            isEmpty(quoteApproverPhone) && setIsQuoteApproverPhoneError(true);
          }
        };
        if (
          !isEmpty(customerName, email) &&
          !isPhoneNumberError &&
          !isCustomerError &&
          !isEmailError &&
          !isZipError &&
          !isSave &&
          (mode === "page" && !isEmpty(quoteApproverFirstName)
            ? !isEmpty(
                quoteApproverLastName,
                quoteApproverEmail,
                quoteApproverPhone
              ) &&
              !isQuoteApproverEmailError &&
              !isQuoteApproverLastNameError &&
              !isQuoteApproverPhoneError
            : true)
        )
          mode === "page"
            ? passDataToNextSection(payload, false, quoteApproverPayload)
            : passDataToNextSection(payload, "create");
        else {
          if (mode === "page") {
            setErrors();
            passDataToNextSection(payload, true, quoteApproverPayload);
          } else setErrors();
        }
      } catch (error) {
        console.error(error);
      }
    };

    const handleChange = (event, setterFunction, setErrorFunction) => {
      const value = event.target.value;
      setterFunction(value);
      setErrorFunction(isEmpty(value));
    };

    const handleCustomerChange = (event) => {
      mode === "page" && isEnableAccordion(true);
      mode === "create" && setIsSave(true);
      handleChange(event, setCustomerName, setIsCustomerError);
      if (mode === "page") sendCustomerName(event.target.value);
      if (debouncedSearch) {
        clearTimeout(debouncedSearch);
      }
      setDebouncedSearch(
        setTimeout(() => {
          let searchObj = {};
          if (event.target.value) {
            searchObj["name"] = event.target.value;
            searchObj["search_name_global"] = 1;
          }
          !isEmpty(event.target.value) && fetchCustomer(searchObj);
          if (isEmpty(event.target.value)) {
            setIsCustomerError(true);
            setCustomerErrorText("Please enter the PMC name");
          }
        }, 1200)
      );
    };

    useImperativeHandle(ref, () => ({
      handleClick(event) {
        handleSubmit(event);
      },
    }));

    const handleZipChange = (event) => {
      const value = event.target.value;
      const zipRegex = /^\d{5}$/;
      const isValidZip = zipRegex.test(value);
      if (value?.length <= 6 && value >= 0) {
        setIsZipError(!isValidZip);
        setZip(value);
      }
      if (isEmpty(value)) {
        setIsZipError(false);
      }
    };

    const validateEmail = (email) => {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      return emailRegex.test(email);
    };

    const validatePhoneNumber = (phoneNumber) => {
      const phoneNumberRegex = /^\d{10}$/;
      return isEmpty(phoneNumber) ? false : phoneNumberRegex.test(phoneNumber);
    };

    const handlePhoneNumberChange = (event, field) => {
      const value = event.target.value;
      if (value?.length <= 10 && value >= 0) {
        if (field) {
          setQuoteApproverPhone(value);
          setIsQuoteApproverPhoneError(!validatePhoneNumber(value));
        } else {
          setPhoneNumber(value);
          setIsPhoneNumberError(
            value === "" ? false : !validatePhoneNumber(value)
          );
        }
      }
    };

    const handleEmailChange = (event, field) => {
      const value = event.target.value;
      if (field) {
        setQuoteApproverEmail(value);
        setIsQuoteApproverEmailError(!validateEmail(value));
      } else {
        setEmail(value);
        setIsEmailError(!validateEmail(value));
      }
    };

    return mode === "page" ? (
      <form className="flex flex-col gap-4">
        <InputField
          label={"PMC Name"}
          theme={"secondary"}
          mode={"page"}
          error={isCustomerError}
          errorText={customerErrorText}
          mandatory={true}
          value={customerName}
          componentID={"addCustomer_txtCustomer"}
          onChange={(event) => handleCustomerChange(event)}
          onClick={() =>
            handleCustomerChange({ target: { value: customerName } })
          }
        />
        <div className="grid grid-cols-2 gap-[60px]">
          <InputField
            label={"Address 1"}
            theme={"secondary"}
            mode={"page"}
            componentID={"addCustomer_txtAddress1"}
            value={address1}
            onChange={(event) => setAddress1(event.target.value)}
          />
          <InputField
            label={"Address 2"}
            theme={"secondary"}
            mode={"page"}
            componentID={"addCustomer_txtAddress2"}
            value={address2}
            onChange={(event) => setAddress2(event.target.value)}
          />
        </div>
        <div className="grid grid-cols-3 gap-[50px]">
          <DropdownField
            label={"State"}
            options={states}
            theme={"secondary"}
            isSearchable={true}
            mode={"page"}
            componentID={"addCustomer_txtState"}
            value={state}
            onChange={(state) => setState(state)}
          />
          <InputField
            label={"City"}
            theme={"secondary"}
            mode={"page"}
            componentID={"addCustomer_txtCity"}
            value={city}
            onChange={(event) => setCity(event.target.value)}
          />
          <InputField
            label={"Zip"}
            theme={"secondary"}
            mode={"page"}
            type={"number"}
            value={zip}
            min={"0"}
            componentID={"addCustomer_numZip"}
            error={isZipError}
            errorText={"Zip code should be 5 digits"}
            onChange={(event) => handleZipChange(event)}
            onClick={() => handleZipChange({ target: { value: zip } })}
          />
        </div>
        <div className="grid grid-cols-2 gap-[60px]">
          <InputField
            label={"Contact Name"}
            theme={"secondary"}
            mode={"page"}
            componentID={"addCustomer_txtContactName"}
            value={contactName}
            onChange={(event) => setContactName(event.target.value)}
          />
          <InputField
            label={"Email"}
            theme={"secondary"}
            mode={"page"}
            componentID={"addCustomer_txtEmail"}
            mandatory={true}
            errorText={"Please enter the valid email address"}
            error={isEmailError}
            value={email}
            onChange={(event) => handleEmailChange(event)}
            onClick={() => handleEmailChange({ target: { value: email } })}
          />
        </div>
        <div className="grid grid-cols-2 gap-[60px]">
          <InputField
            label={"Phone Number"}
            theme={"secondary"}
            componentID={"addCustomer_numPhone"}
            mode={"page"}
            type={"tel"}
            value={phoneNumber}
            error={isPhoneNumberError}
            errorText={"Phone number should have 10 digits"}
            onChange={(event) => handlePhoneNumberChange(event)}
            onClick={() =>
              handlePhoneNumberChange({ target: { value: phoneNumber } })
            }
          />
          <InputField
            label={"Alias"}
            theme={"secondary"}
            mode={"page"}
            componentID={"addCustomer_txtAlias"}
            value={alias}
            onChange={(event) => setAlias(event.target.value)}
          />
        </div>
        <div className="grid grid-cols-2 gap-[60px]">
          <InputField
            label={"Quote Approver First Name"}
            errorText={"Please enter the quote approver first Name"}
            theme={"secondary"}
            componentID={"addQuoteApprover_txtFirstName"}
            value={quoteApproverFirstName}
            onChange={(event) => {
              setQuoteApproverFirstName(event.target.value);
              !isEmpty(event.target.value)
                ? setIsQuoteApprover(true)
                : setIsQuoteApprover(false);
            }}
          />
          {isQuoteApprover && (
            <InputField
              label={"Quote Approver Last Name"}
              errorText={"Please enter the quote approver last name"}
              error={isQuoteApproverLastNameError}
              mandatory={true}
              theme={"secondary"}
              componentID={"addQuoteApprover_txtLastName"}
              value={quoteApproverLastName}
              onChange={(event) => {
                setQuoteApproverLastName(event.target.value);
                isEmpty(event.target.value)
                  ? setIsQuoteApproverLastNameError(true)
                  : setIsQuoteApproverLastNameError(false);
              }}
            />
          )}
        </div>
        {isQuoteApprover && (
          <div className="grid grid-cols-2 gap-[60px]">
            <InputField
              label={"Quote Approver Contact"}
              errorText={"Please enter the quote approver contact"}
              theme={"secondary"}
              error={isQuoteApproverPhoneError}
              componentID={"addQuoteApprover_numContact"}
              mandatory={true}
              type={"number"}
              max={10}
              value={quoteApproverPhone}
              onChange={(event) =>
                handlePhoneNumberChange(event, "Quote Approver")
              }
            />
            <InputField
              label={"Quote Approver Email"}
              errorText={"Please enter the quote approver email"}
              theme={"secondary"}
              error={isQuoteApproverEmailError}
              componentID={"addQuoteApprover_txtEmail"}
              mandatory={true}
              value={quoteApproverEmail}
              onChange={(event) => handleEmailChange(event, "Quote Approver")}
            />
          </div>
        )}
      </form>
    ) : (
      <>
        <main className="overflow-y-auto px-10 h-full">
          <form className="flex flex-col gap-6" onSubmit={handleSubmit}>
            <InputField
              label={"PMC"}
              error={isCustomerError}
              theme={"secondary"}
              errorText={customerErrorText}
              mandatory={true}
              componentID={
                mode === "create"
                  ? "createCustomer_txtCustomer"
                  : "editCustomer_txtCustomer"
              }
              value={customerName}
              onChange={(event) => handleCustomerChange(event)}
              onClick={
                mode === "create"
                  ? () =>
                      handleCustomerChange({ target: { value: customerName } })
                  : undefined
              }
            />
            <InputField
              label={"Address 1"}
              componentID={
                mode === "create"
                  ? "createCustomer_txtAddress1"
                  : "editCustomer_txtAddress1"
              }
              theme={"secondary"}
              value={address1}
              isDisabled={isRegisteredCustomer}
              onChange={(event) => setAddress1(event.target.value)}
            />
            <InputField
              label={"Address 2"}
              componentID={
                mode === "create"
                  ? "createCustomer_txtAddress2"
                  : "editCustomer_txtAddress2"
              }
              theme={"secondary"}
              isDisabled={isRegisteredCustomer}
              value={address2}
              onChange={(event) => setAddress2(event.target.value)}
            />
            <div className="grid grid-cols-2 gap-[30px]">
              <DropdownField
                label={"State"}
                options={states}
                theme={"secondary"}
                isSearchable={true}
                componentID={
                  mode === "create"
                    ? "createCustomer_txtState"
                    : "editCustomer_txtState"
                }
                value={state}
                isDisabled={isRegisteredCustomer}
                onChange={(state) => setState(state)}
              />
              <InputField
                label={"City"}
                theme={"secondary"}
                value={city}
                componentID={
                  mode === "create"
                    ? "createCustomer_txtCity"
                    : "editCustomer_txtCity"
                }
                isDisabled={isRegisteredCustomer}
                onChange={(event) => setCity(event.target.value)}
              />
            </div>
            <div className="grid grid-cols-2 gap-[30px]">
              <InputField
                label={"Zip"}
                theme={"secondary"}
                type={"number"}
                value={zip}
                error={isZipError}
                componentID={
                  mode === "create"
                    ? "createCustomer_numZip"
                    : "editCustomer_numZip"
                }
                errorText={"Zip code should be 5 digits"}
                onChange={(event) => handleZipChange(event)}
                isDisabled={isRegisteredCustomer}
                onClick={
                  mode === "create"
                    ? () => handleZipChange({ target: { value: zip } })
                    : undefined
                }
              />
              <InputField
                label={"Contact Name"}
                theme={"secondary"}
                componentID={
                  mode === "create"
                    ? "createCustomer_txtContactName"
                    : "editCustomer_txtContactName"
                }
                isDisabled={isRegisteredCustomer}
                value={contactName}
                onChange={(event) => setContactName(event.target.value)}
              />
            </div>
            <div className="grid grid-cols-2 gap-[30px]">
              <InputField
                label={"Phone"}
                theme={"secondary"}
                componentID={
                  mode === "create"
                    ? "createCustomer_numPhone"
                    : "editCustomer_numPhone"
                }
                type={"number"}
                maxLength={"10"}
                value={phoneNumber}
                error={isPhoneNumberError}
                errorText={"Phone number should have 10 digits"}
                onChange={(event) => handlePhoneNumberChange(event)}
                isDisabled={isRegisteredCustomer}
                onClick={
                  mode === "create"
                    ? undefined
                    : () =>
                        handlePhoneNumberChange({
                          target: { value: phoneNumber },
                        })
                }
              />
              <InputField
                label={"Email"}
                theme={"secondary"}
                componentID={
                  mode === "create"
                    ? "createCustomer_txtEmail"
                    : "editCustomer_txtEmail"
                }
                mandatory={true}
                type={"email"}
                errorText={"Please enter the valid email address"}
                error={isEmailError}
                value={email}
                isDisabled={isRegisteredCustomer}
                onChange={(event) => handleEmailChange(event)}
                onClick={() => handleEmailChange({ target: { value: email } })}
              />
            </div>
            <div className="grid grid-cols-2 gap-[30px]">
              <InputField
                label={"Alias"}
                theme={"secondary"}
                componentID={
                  mode === "create"
                    ? "createCustomer_txtAlias"
                    : "editCustomer_txtAlias"
                }
                isDisabled={isRegisteredCustomer}
                value={alias}
                onChange={(event) => setAlias(event.target.value)}
              />
              {mode === "edit" && (
                <DropdownField
                  label={"Status"}
                  options={customerStatusOptions}
                  theme={"secondary"}
                  componentID={"editCustomer_txtStatus"}
                  value={status}
                  onChange={(status) => setStatus(status)}
                />
              )}
            </div>
          </form>
        </main>
        <footer className="bg-white mt-[10px] flex justify-end items-center shadow-[_0px_-13px_28px_rgba(0,0,0,0.025)] pr-4 gap-4 h-[86px]">
          <Button
            theme={"tertiary"}
            onClick={handleCancel}
            componentID={
              mode === "create"
                ? "createCustomer_btnCancel"
                : "editCustomer_btnCancel"
            }
            buttonText={"Cancel"}
          />
          <Button
            theme={"primary"}
            disabled={isSave}
            processing={isLoading}
            buttonText={mode === "create" ? "Add" : "Save"}
            componentID={
              mode === "create"
                ? "createCustomer_btnAdd"
                : "editCustomer_btnSave"
            }
            type={"submit"}
            onClick={mode === "create" ? handleSubmit : handleUpdate}
          />
        </footer>
      </>
    );
  }
);
export default CustomerForm;
