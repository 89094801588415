import React from "react";
import CustomerList from "../inspection/List";
import { HeadingWithButton } from "../../components";
import { useNavigate } from "react-router-dom";

function Index() {
  const navigate = useNavigate();
  return (
    <div>
      <aside>
        <HeadingWithButton onClick={() => navigate("/create-inspection")} componentID={"inpsection_btnCreate"}/>
      </aside>
      <main className="mt-[66px]">
        <CustomerList />
      </main>
    </div>
  );
}

export default Index;
