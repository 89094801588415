import React from "react";
import AddQuoteMatrix from "./Add";
import { useParams } from "react-router-dom";

function ViewQuoteMatrix() {
  const { id } = useParams();
  return (
    <div>
      <AddQuoteMatrix mode={"view"} quoteMatrixId={id}/>
    </div>
  );
}

export default ViewQuoteMatrix;
