/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import { PencilIcon } from "../../../assets";

function AutoEditInputField({
  text,
  theme,
  disabled,
  inspectionStatus,
  sendAutoSave = (val) => val,
}) {
  const labelRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [inputFieldWidth, setInputFieldWidth] = useState("");
  const [label, setLabel] = useState(text);
  const [focusField, setFocusField] = useState(true);

  const handleDoubleClick = () => {
    if (inspectionStatus === "InProgress") {
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  };

  const handleBlur = () => {
    if (inspectionStatus === "InProgress") {
      sendAutoSave(label);
    }
    if (label != null && label.length > 0) {
      setIsEditing(false)
    }
  };
  const handleChange = (e) => {
    setLabel(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setIsEditing(false);
    }
  };

  useEffect(() => {
    const elementWidth = labelRef.current.offsetWidth;
    setInputFieldWidth(elementWidth);
  }, []);

  const primaryStyles = {
    inputFieldClasses:
      "font-lato font-bold text-[#383838] border outline-[#006CDB]",
    inputFieldStyles: {
      fontSize: "28px",
      fontStyle: "normal",
      lineHeight: "20px",
      width: 300,
      padding: "5px",
    },
    labelClasses: "font-lato font-bold text-[#383838]",
    labelStyles: { fontSize: "28px", fontStyle: "normal", lineHeight: "20px" },
  };

  const secondaryStyles = {
    inputFieldClasses:
      "font-lato font-bold text-[#383838] border outline-black cursor-pointer",
    inputFieldStyles: {
      fontSize: "18px",
      fontStyle: "normal",
      lineHeight: "10px",
      width: 300,
      padding: "5px",
    },
    labelClasses: "text-base font-lato font-normal text-[#5B5B5B]",
    labelStyles: { fontSize: "18px", fontStyle: "normal", lineHeight: "10px" },
  };

  return (
    <div>
      {isEditing ? (
        <main className="flex flex-col">
          <input
            type="text"
            value={label}
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            autoFocus={focusField}
            className={
              theme === "primary"
                ? primaryStyles.inputFieldClasses
                : secondaryStyles.inputFieldClasses
            }
            style={
              theme === "primary"
                ? primaryStyles.inputFieldStyles
                : secondaryStyles.inputFieldStyles
            }
            maxLength={255}
          />
          {!label && theme === "primary" && (
            <label className="text-red-600">Name should not be empty</label>
          )}
        </main>
      ) : (
        <div
          className="flex space-x-[12px] items-center cursor-pointer"
          onClick={
            theme === "secondary" && !disabled ? handleDoubleClick : () => null
          }
        >
          <label
            ref={labelRef}
            className={
              theme === "primary"
                ? primaryStyles.labelClasses
                : secondaryStyles.labelClasses
            }
            style={
              theme === "primary"
                ? primaryStyles.labelStyles
                : secondaryStyles.labelStyles
            }
          >
            {label ? label : "N/A"}
          </label>
          {theme === "primary" && inspectionStatus === "InProgress" && (
            <span onClick={handleDoubleClick}>
              <PencilIcon />
            </span>
          )}
        </div>
      )}
    </div>
  );
}

export default AutoEditInputField;
