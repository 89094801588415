import React, { useState } from "react";
import "./toggleButton.css";

function ToggleButton({firstValue, secondValue, onToggle}) {
  const [isToggled, setIsToggled] = useState(true);

  const toggle = (event) => {
    const newToggleState = !isToggled;
    // event.stopPropagation();
    setIsToggled(newToggleState);
    onToggle(event, newToggleState);
  };
  return (
    <div className="flex mr-[40px]" onClick={toggle}>
      <div
        className={`toggle-button first ${isToggled ? "enabled" : "disabled"}`}
      >
        {firstValue}
      </div>
      <div
        className={`toggle-button second ${
          !isToggled ? "enabled" : "disabled"
        }`}
      >
        {secondValue}
      </div>
    </div>
  );
}

export default ToggleButton;