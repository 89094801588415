import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
    className="cursor-pointer"
  >
    <path
      fill="#006CDB"
      d="m12.417 6.778-1.296-1.296-8.538 8.537v1.296H3.88l8.537-8.537Zm1.297-1.296 1.296-1.297-1.296-1.296-1.297 1.296 1.297 1.297ZM4.639 17.149H.75v-3.89L13.065.945a.917.917 0 0 1 1.297 0l2.593 2.593a.917.917 0 0 1 0 1.296L4.639 17.15Z"
    />
  </svg>
)
export default SvgComponent