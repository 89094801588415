const SlideDrawerStyles = {
  container: "bg-[#F5F6F6] flex justify-between h-screen pt-10 shadow-xl",
  drawerContentsOuterContainer:
    "h-full flex justify-stretch flex-col bg-[#F5F6F6]",
  drawerContentsInnerContainer:
    "px-10 h-[109px] flex justify-between items-center",
  header:
    "font-open-sans font-semibold text-[31px] leading-[32.68px] text-[#2D2D2D]",
};

export default SlideDrawerStyles;
