import { axiosObject } from "./setup";
import { webStore } from "../../redux/index";

function deletePicture(id) {
    return new Promise((resolve, reject) => {
      let uri = process.env.REACT_APP_RAILS_API_URL+`/v2/pictures/${id}?token=${webStore.getState().auth.accessToken}`;
  
      axiosObject
        .delete(uri)
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => {
          console.log(errors);
          if (errors) {
            reject(errors);
          }
          reject(errors);
        });
    });
  }
  
  export { deletePicture };