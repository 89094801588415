import React from "react";
import { useLocation } from "react-router-dom";
import InspectionForm from "./Form";

function CreateInspection() {
  const location = useLocation();

  const copiedInspectionDetails = location?.state?.inspectionDetails;

  return (
    <InspectionForm
      mode={location?.state === null ? "create" : "edit"}
      copiedInspectionDetails={copiedInspectionDetails}
    />
  );
}

export default CreateInspection;
