/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import QuoteMatrixGrid from "./Grid";
import httpAPICall from "../../utils/httpApiCall";
import {
  SearchInput,
  Dropdown,
  Footer,
  Button,
  SlideDrawer,
} from "../../components";
import { generateDropdownOptions } from "../../utils";
import { statusOptions } from "../../utils/statusMappings";
import {
  QUOTE_MATRIX_URL,
  CUSTOMERS_API_URL,
  PROPERTIES_API_URL,
} from "../../constants";
import QuoteMatrixForm from "./Form";
import qs from "qs";

function QuoteMatrixList() {
  const [tableData, setTableData] = useState([]);
  const [loadTable, setLoadTable] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [properties, setProperties] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [quoteMatrixSelected, setQuoteMatrixSelected] = useState({});
  const [quoteMatrix, setQuoteMatrix] = useState("");
  const [isSendUsageDrawer, setIsSendUsageDrawer] = useState(false);
  const [titleDebouncedSearch, setTitleDebouncedSearch] = useState(false);
  const [customerDebouncedSearch, setCustomerDebouncedSearch] = useState(null);
  const [propertyDebouncedSearch, setPropertyDebouncedSearch] = useState(null);
  const [isUpdateExpiryDrawer, setIsUpdateExpiryDrawer] = useState(false);
  const [customerSearchValue, setCustomerSearchValue] = useState("");
  const [propertySearchValue, setPropertySearchValue] = useState("");
  const [statusSearchValue, setStatusSearchValue] = useState("");
  const [drawerMode, setDrawerMode] = useState("");
  const [showFooter, setShowFooter] = useState(false);
  const [quoteMatrixId, setQuoteMatrixId] = useState([]);
  const [contractorStatus, setContractorStatus] = useState([]);
  const [actionButtons, setActionButtons] = useState([]);
  const [enableFooter, setEnableFooter] = useState(false);

  const fetchQuoteMatrix = async (filterObj) => {
    setLoadTable(true);
    let generatedUrl = QUOTE_MATRIX_URL + `?${qs.stringify(filterObj)}`;
    let result = await httpAPICall(generatedUrl, "GET", {});
    setTableData(result);
    setContractorStatus(
      result?.filter((quote) => quote.status === "Contracted")
      );
  };

  const QuoteMatrixAction = async () => {
    let Buttons = [];
  
    if (Array.isArray(contractorStatus)) {
      const promises = contractorStatus.map(async (item) => {
        const id = item.id;
        setQuoteMatrixId((prevItems) => [...prevItems, id]);
        try {
          const apiUrl = `histories/${id}/quote?action=Usage Report Sent,Credit App Initiated`;
          const response = await httpAPICall(apiUrl, "GET", {});
          return generateActions(response, {}, id);
        } catch (error) {
          return null;
        }
      });
  
      const results = await Promise.all(promises);
      results.forEach(result => {
        if (result) {
          Buttons.push(result);
        }
      });
      setActionButtons(Buttons);
      setLoadTable(false);
    }
  };
  

  const generateActions = (response, buttonObj, id) => {
    if (response.length > 0) {
      const actionCreditAppInitiated = response.find(
        (item) => item.action === "Credit App Initiated"
      );
      const actionUsageReportSent = response.find(
        (item) => item.action === "Usage Report Sent"
      );

      if (actionCreditAppInitiated && actionUsageReportSent) {
        buttonObj = {
          id: id,
          sendUsageButton: "Re send",
          creditAppButton: "Completed",
          setDisable: true,
        };
      } else if (actionCreditAppInitiated) {
        buttonObj = {
          id: id,
          sendUsageButton: "Send Usage",
          creditAppButton: "Completed",
          setDisable: true,
        };
      } else if (actionUsageReportSent) {
        buttonObj = {
          id: id,
          sendUsageButton: "Re send",
          creditAppButton: "Send Credit App Link",
          setDisable: false,
        };
      }
    } else {
      buttonObj = {
        id: id,
        sendUsageButton: "Send Usage",
        creditAppButton: "Send Credit App Link",
        setDisable: false,
      };
    }
    return buttonObj;
  };

  const handleCreditAppLink = async (id) => {
    const APIsendcreditApp = `/quote-matrices/${id}/credit-app-link/click`;
    await httpAPICall(APIsendcreditApp, "POST");
    const externalLink = "https://www.lowesprosupply.com/AccountApp";
    window.open(externalLink, "_blank");
    setActionButtons((prevActionState) => {
      let actionButtons = prevActionState.slice();
      actionButtons = actionButtons.filter((actionItem) => {
        if (actionItem.id === id) {
          actionItem.creditAppButton = "Completed";
          actionItem.setDisable = true;
        }
        return true;
      });
      return actionButtons;
    });
  };

  useEffect(() => {
    QuoteMatrixAction();
  }, [contractorStatus]);

  useEffect(() => {
    if (
      Object.keys(quoteMatrixSelected).length === 0 ||
      quoteMatrixSelected?.status === "SCancelled" ||
      quoteMatrixSelected?.status === "Contracted" ||
      quoteMatrixSelected?.status === "CDeclined"
    ) {
      setEnableFooter(true);
    } else {
      setEnableFooter(false);
    }
  }, [quoteMatrixSelected?.status]);

  const fetchCustomers = async (filterObj) => {
    let generatedUrl = CUSTOMERS_API_URL + `?${qs.stringify(filterObj)}`;
    let result = await httpAPICall(generatedUrl, "GET", {});
    let options = generateDropdownOptions(result, "name", "id");
    setCustomers(options);
  };

  const fetchProperties = async (filterObj) => {
    let generatedUrl = PROPERTIES_API_URL + `?${qs.stringify(filterObj)}`;
    let result = await httpAPICall(generatedUrl, "GET", {});
    let options = generateDropdownOptions(result, "name", "id");
    setProperties(options);
  };

  const updateQuoteMatrix = async (payload) => {
    await httpAPICall(
      QUOTE_MATRIX_URL + `/${payload?.id}`,
      "PUT",
      payload,
      "",
      "Quote"
    );
    fetchQuoteMatrix();
  };

  const handleSearch = (
    value,
    searchType,
    setSearchText,
    debouncedSearch,
    setDebouncedSearch
  ) => {
    setSearchText(value);
    if (debouncedSearch) {
      clearTimeout(debouncedSearch);
    }
    setDebouncedSearch(
      setTimeout(() => {
        let searchObj = {};
        if (value) {
          if (searchType === "property") {
            searchObj["customer_ids"] = selectedCustomer?.id;
          }
          searchObj["q"] = value;
        }
        if (searchType === "property") fetchProperties(searchObj);
        else if (searchType === "customer") fetchCustomers(searchObj);
        else fetchQuoteMatrix(searchObj);
      }, 2000)
    );
  };

  const handleSendUsageAction = (id) => {
    setActionButtons((prevActionState) => {
      let actionButtons = prevActionState.slice();
      actionButtons = actionButtons.filter((actionItem) => {
        if (actionItem.id === id) {
          actionItem.sendUsageButton = "Re send";
        }
        return true;
      });
      return actionButtons;
    });
  };

  const handleChange = (value, setterFunction, field) => {
    let queryStringObj = {};
    setterFunction(value);

    if (field === "customer") {
      queryStringObj["customer_ids"] = value?.id;
      if (selectedStatus?.id) queryStringObj["statuses"] = selectedStatus?.id;
      fetchProperties({ customer_ids: value?.id });
    } else if (field === "property") {
      if (selectedCustomer?.id)
        queryStringObj["customer_ids"] = selectedCustomer?.id;
      queryStringObj["property_ids"] = value?.id;
      if (selectedStatus?.id) queryStringObj["statuses"] = selectedStatus?.id;
    } else {
      if (selectedCustomer?.id)
        queryStringObj["customer_ids"] = selectedCustomer?.id;
      if (selectedProperty?.id)
        queryStringObj["property_ids"] = selectedProperty?.id;
      queryStringObj["statuses"] = value?.id;
    }

    fetchQuoteMatrix(queryStringObj);
  };

  const voidQuoteMatrix = async (id) => {
    let params = QUOTE_MATRIX_URL + `/${id}/void`;
    await httpAPICall(params, "DELETE", {}, "", "", "Quote Matrix Voided Successfully");
    fetchQuoteMatrix();
  };

  useEffect(() => {
    fetchQuoteMatrix();
    fetchCustomers();

    return () => {
      if (titleDebouncedSearch) {
        clearTimeout(titleDebouncedSearch);
      }
      if (customerDebouncedSearch) {
        clearTimeout(customerDebouncedSearch);
      }
    };
  }, []);

  return (
    <div>
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center space-x-4 w-full">
          <SearchInput
            theme={"secondary"}
            searchValue={searchValue}
            placeholder={"Search"}
            onChange={(event) =>
              handleSearch(
                event.target.value,
                "titleSearch",
                setSearchValue,
                titleDebouncedSearch,
                setTitleDebouncedSearch
              )
            }
          />
          <span className="w-[200px]">
            <Dropdown
              options={customers}
              isSearchable={true}
              value={selectedCustomer}
              placeHolder={"PMC"}
              searchText={(searchText) =>
                handleSearch(
                  searchText,
                  "customer",
                  setCustomerSearchValue,
                  customerDebouncedSearch,
                  setCustomerDebouncedSearch
                )
              }
              onChange={(selectedValue) =>
                handleChange(selectedValue, setSelectedCustomer, "customer")
              }
            />
          </span>
          <span className="w-[200px]">
            <Dropdown
              options={properties}
              isSearchable={true}
              value={selectedProperty}
              placeHolder={"Property"}
              searchText={(searchText) =>
                handleSearch(
                  searchText,
                  "property",
                  setPropertySearchValue,
                  propertyDebouncedSearch,
                  setPropertyDebouncedSearch
                )
              }
              onChange={(selectedValue) =>
                handleChange(selectedValue, setSelectedProperty, "property")
              }
            />
          </span>
          <span className="w-[200px]">
            <Dropdown
              options={statusOptions}
              isSearchable={true}
              value={selectedStatus}
              placeHolder={"Status"}
              searchText={(searchText) => setStatusSearchValue(searchText)}
              onChange={(selectedValue) =>
                handleChange(selectedValue, setSelectedStatus, "status")
              }
            />
          </span>
          <div className="flex">
            <div
              id="clear-filter"
              className="font-lato font-semibold text-[16px] ml-[45px] leading-[16.8px] text-[#006CDB] hover:underline cursor-pointer"
              onClick={() => window.location.reload()}
            >
              Clear Filter
            </div>
          </div>
        </div>
      </div>
      <main className="mt-[32px] mb-[60px]">
        <QuoteMatrixGrid
          tableData={tableData}
          onSelect={(quoteMatrix) => {
            setQuoteMatrixSelected(quoteMatrix);
            setShowFooter(true);
          }}
          sendUsage={(rowData) => {
            setIsSendUsageDrawer(true);
            setDrawerMode("send_usage");
            setQuoteMatrix(rowData);
          }}
          handleCreditAppLink={handleCreditAppLink}
          handleExpiredQuote={(rowData) => {
            setIsUpdateExpiryDrawer(true);
            setDrawerMode("update_expiry");
            setQuoteMatrix(rowData);
          }}
          isLoading={loadTable}
          actionButtons={actionButtons}
        />
      </main>
      <SlideDrawer
        label={
          drawerMode === "send_usage" ? "Send Usage" : "Update Expiry Date"
        }
        openDrawer={
          drawerMode === "send_usage" ? isSendUsageDrawer : isUpdateExpiryDrawer
        }
        setOpenDrawer={() =>
          drawerMode === "send_usage"
            ? setIsSendUsageDrawer(false)
            : setIsUpdateExpiryDrawer(false)
        }
      >
        <QuoteMatrixForm
          mode={drawerMode === "send_usage" ? "send_usage" : "expired"}
          data={quoteMatrix}
          handleUpdate={updateQuoteMatrix}
          updateSendUsageAction={handleSendUsageAction}
          handleCancel={() =>
            drawerMode === "send_usage"
              ? setIsSendUsageDrawer(false)
              : setIsUpdateExpiryDrawer(false)
          }
        />
      </SlideDrawer>
      {!isSendUsageDrawer && !isUpdateExpiryDrawer && (
        <Footer theme={"secondary"}>
          <div className="flex w-full justify-between">
            <section>
              <Button
                buttonText={"Void Matrix"}
                theme={enableFooter ? "secondary-with-disabled" : "secondary"}
                disabled={enableFooter}
                onClick={() => voidQuoteMatrix(quoteMatrixSelected?.id)}
              />
            </section>
          </div>
        </Footer>
      )}
    </div>
  );
}

export default QuoteMatrixList;
