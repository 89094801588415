function groupLineItems(data, attribute) {
  let singleObj = {};
  data?.forEach((originalObj) => {
    let obj = { ...originalObj };

    let floorplan = obj.inspectionUnit.floorplan.name;
    let category = obj.lineItemType;
    let sku = obj.lineItemSku;

    if (!singleObj[floorplan]) {
      singleObj[floorplan] = {};
    }
    if (!singleObj[floorplan][category]) {
      singleObj[floorplan][category] = [];
    }
    const existingObject = singleObj[floorplan][category].find((item) => item.lineItemSku === sku);
    obj.lineItemQuantity = obj.detailItemQuantity.find((item) => item.floorplan_name === floorplan)?.quantity;
    obj.rowExistingPrice = Number(obj.lineItemQuantity) * Number(obj.lineItemPrice)

    if (!existingObject) {
      singleObj[floorplan][category].push(obj);
    }
  });
  return singleObj;
}

export default groupLineItems;