export const uom = [
  { id: "EA", name: "EA (Each)" },
  { id: "SQYD", name: "SQYD (Square Yards)" },
  { id: "SQFT", name: "SQFT (Square Feet)" },
  { id: "CUFT", name: "CUFT (Cubic Feet)" },
  { id: "CUYD", name: "CUYD (Cubic Yards)" },
  { id: "PL", name: "PL (Pail)" },
  { id: "5GL", name: "5GL (5 Gallon Container)" },
  { id: "GL", name: "GL (Gallon)" },
  { id: "PT", name: "PT (Pint)" },
  { id: "HP", name: "HP (Half Pint)" },
  { id: "LTR", name: "LTR (Liter)" },
  { id: "LF", name: "LF (Linear Feet)" },
  { id: "IN", name: "IN (Inches)" },
  { id: "CM", name: "CM (Centimeters)" },
  { id: "CT", name: "CT (Carton)" },
  { id: "BX", name: "BX (Box)" },
  { id: "ROOM", name: "ROOM (Room)" },
  { id: "PK", name: "PK (Pack)" },
  { id: "ST", name: "ST (Set)" },
  { id: "DZ", name: "DZ (Dozen)" },
  { id: "RL", name: "RL (Roll)" },
  { id: "MIN", name: "MIN (Minute)" },
  { id: "HR", name: "HR (Hour)" },
  { id: "Dy", name: "Dy (Day)" },
  { id: "WK", name: "WK (Week)" },
  { id: "MO", name: "MO (Month)" },
  { id: "CS", name: "CS (Case)" },
  { id: "UT", name: "UT (Unit)" },
  { id: "LB", name: "LB (Pound)" },
  { id: "OZ", name: "OZ (Ounce)" },
  { id: "KG", name: "KG (Kilogram)" },
  { id: "GM", name: "GM (Gram)" },
  { id: "KWh", name: "KWh (Kilowatt Hour)" },
  { id: "BG", name: "BG (Bag)" },
  { id: "FT", name: "FT (Feet)" },
  { id: "KT", name: "KT (Kit)" },
  { id: "PR", name: "PR (Pair)" },
  { id: "BTL", name: "BTL (Bottle)" },
  { id: "BA", name: "BA (Batch)" },
  { id: "VI", name: "VI (Vial)" },
  { id: "DI", name: "DI (Dispenser)" },
  { id: "QT", name: "QT (Quart)" },
  { id: "TB", name: "TB (Tube)" },
  { id: "TON", name: "TON (Ton)" },
  { id: "SQ", name: "SQ (Square)" },
  { id: "L", name: "L (Fifty)" },
  { id: "M", name: "M (Quill Drop)" },
  { id: "BD", name: "BD (BUNDLE)" },
  { id: "BK", name: "BK (Book)" },
  { id: "CD", name: "CD (Card)" },
  { id: "CW", name: "CW (Hundred Wght)" },
  { id: "GR", name: "GR (GROSS)" },
  { id: "HU", name: "HU (HUNDREDS)" },
  { id: "LT", name: "LT (Lot)" },
  { id: "PD", name: "PD (PAD)" },
  { id: "SH", name: "SH (SHEET)" },
  { id: "SP", name: "SP (Shipper)" },
  { id: "TH", name: "TH (Thousand)" },
  { id: "TL", name: "TL (Truck Load)" },
  { id: "TR", name: "TR (Tray)" },
  { id: "RM", name: "RM (Ream)" },
];

export default uom;
