import React from "react";
import LinkButtonStyles from "../../../styles/components/linkButton";

function LinkButton({ label, onClick, theme, componentID, isDisable }) {
  return (
    <div
      className={
        theme === "primary"
          ? LinkButtonStyles.primary
          : theme === "primary-italic-label"
          ? LinkButtonStyles.primaryItalic
          : theme === "secondary-with-disabled"
          ? LinkButtonStyles.secondaryWithDisabled
          : LinkButtonStyles.secondary
      }
      id={componentID}
      onClick={isDisable ? null : onClick}
    >
      + {label}
    </div>
  );
}

export default LinkButton;
