import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={30}
    height={30}
    id={props.id}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.1914 9.9375L15.2539 14L11.1914 18.0625"
      stroke="#263238"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.0625 9.9375L15 14L19.0625 18.0625"
      stroke="#263238"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SVGComponent;
