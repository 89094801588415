function calculatePerUnitTotals(data, taxPercentage, floorPlanHeaders) {
  const result = {};
  const supplierTotals = {};
  const unitTypeTotals = {};
  const unitTypeTotalsWithTax = {};

  floorPlanHeaders.forEach((item, index) => {
    unitTypeTotals[item.name] = 0;
    unitTypeTotalsWithTax[item.name] = 0;
  });
  let floorplan_headers = {...unitTypeTotals};

  data.forEach((item) => {
    const lineItemType = item.lineItemType;
    const floorplanName = item.floorPlanName;
    const unitPrice = item.lineItemPrice;
    const detailList = item.detailItemQuantity;
    const supplier = item?.supplier?.name || "";
    if (!supplierTotals[supplier+ " Per Unit"]) supplierTotals[supplier+" Per Unit"] = {...floorplan_headers}

    if (!result[lineItemType]) {
      result[lineItemType] = {};
    }

    if (!result[lineItemType][floorplanName]) {
      result[lineItemType][floorplanName] = {};
    }

    if (!result[lineItemType][floorplanName].subTotals) {
      result[lineItemType][floorplanName].subTotals = [];
      result[lineItemType][floorplanName].amountWithOutTax = 0;
      result[lineItemType][floorplanName].amountWithTax = 0;
    }

    let quantity = detailList
      .filter((item) => item?.floorplan_name === floorplanName)
      .map((item) => item.quantity);

    const subTotal = Number(quantity) * unitPrice;
    const amountWithOutTax = subTotal;
    const tax = (subTotal * taxPercentage) / 100;
    const amountWithTax = subTotal + tax;
    result[lineItemType][floorplanName].amountWithOutTax = amountWithOutTax;
    supplierTotals[supplier+ " Per Unit"][floorplanName] += subTotal;
    result[lineItemType][floorplanName].amountWithTax = amountWithTax;
    result[lineItemType][floorplanName].subTotals.push(subTotal);
  });

  for (const lineItemType in result) {
    for (const floorplanName in result[lineItemType]) {
      const subTotals = result[lineItemType][floorplanName].subTotals;
      const grandTotal = subTotals.reduce((acc, val) => acc + val, 0);
      result[lineItemType][floorplanName].grandTotal = grandTotal;
    }
  }

  for (const lineItemType in result) {
    for (const floorplanName in result[lineItemType]) {
      const grandTotal = result[lineItemType][floorplanName].grandTotal;
      const tax = (grandTotal * taxPercentage) / 100;
      const amountWithTax = grandTotal + tax;
      const grandTotalWithTax = amountWithTax;
      unitTypeTotals[floorplanName] += grandTotal;
      unitTypeTotalsWithTax[floorplanName] += grandTotalWithTax;
    }
  }

  return {
    subTotals: result,
    unitTypeTotals: Object.keys(supplierTotals).length === 1
      ? {"Per Unit Total": unitTypeTotals}
      : {...supplierTotals, "Per Unit Total": unitTypeTotals},
    unitTypeTotalsWithTax: unitTypeTotalsWithTax,
  };  
}

export default calculatePerUnitTotals;
