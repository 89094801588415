/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { InputField, Button, Toast } from "../../components";
import { LoginBackground, FacilgoLargeIcon } from "../../assets";
import { setUserLogin } from "../../redux/slice/authSlice";
import { login } from "../../services/api/login";
import { httpAPICall } from "../../utils";
import { USERS_API_URL } from "../../constants";
import { userInfo } from "../../services/api/userInfo";
import { setCompanyDetails } from "../../redux/slice/companyDetailsSlice";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [isUserName, setIsUserName] = useState(false);
  const [isPassword, setIsPassword] = useState(false);
  const [isAPICallInProgress, setIsAPICallInProgress] = useState(false);

  const submitHandler = async () => {
    setIsAPICallInProgress(true);
    if (username !== "" && password === "") {
      setIsPassword(true);
      setIsUserName(false);
    } else if (username === "" && password !== "") {
      setIsUserName(true);
      setIsPassword(false);
    } else if (username === "" && password === "") {
      setIsUserName(true);
      setIsPassword(true);
    } else {
      setIsUserName(false);
      setIsPassword(false);
      let requestPayload = {
        user: {
          email: username,
          password: password,
          remember_me: rememberMe,
        },
        web_login: true,
      };
      try {
        const response = await login(requestPayload);
        dispatch(setUserLogin({ accessToken: response.token }));
        if (response.token) {
          const user_info = await userInfo();
          const subscription_module_list = user_info?.subscription_module_list;
          const renoQuotingSubscription = "sup-renovation-quoting";
          if(subscription_module_list.some(item => item && item.includes(renoQuotingSubscription))){
            dispatch(setCompanyDetails({ details: user_info}));
            navigate("/inspections");
        } else {
          dispatch(setUserLogin({ accessToken: "" }));
          Toast("I'm sorry, but your account does not give you access to this functionality.  Please contact your FacilGo account manager for assistance or contact FacilGo support at support@facilgo.com", "error", 5000);
        }
        }
      } catch (error) {
        dispatch(setUserLogin({ accessToken: "" }));
        Toast(error?.response?.data?.error, "error", 5000);
      }
    }
    setIsAPICallInProgress(false);
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      submitHandler();
    }
  };

  return (
    <div className="flex" onKeyDown={handleEnterKeyPress}>
      <div className="relative w-full flex flex-col items-center">
        <LoginBackground className="w-full h-full top-0" />
        <main className="absolute w-[908px] min-h-[611px] flex bg-white top-[50%] border rounded-[10px] shadow-lg">
          <div className="w-[50%] bg-[#deebff70] flex justify-center items-center">
            <FacilgoLargeIcon />
          </div>
          <div className="w-[50%]">
            <header className="flex justify-center mt-[66px] font-open-sans font-bold text-[35px] leading-[43.86px]">
              Log in
            </header>
            <div className="font-lato mx-[47px]">
              <div className="space-y-3">
                <InputField
                  label={"Email"}
                  type={"email"}
                  theme={"primary"}
                  placeholder={"Email Address"}
                  errorText={"Please enter valid email."}
                  error={isUserName}
                  onKeyDown={handleEnterKeyPress}
                  onChange={(event) => setUserName(event.target.value)}
                />
                <InputField
                  label={"Password"}
                  type={"password"}
                  placeholder={"Password"}
                  theme={"primary"}
                  error={isPassword}
                  onKeyDown={handleEnterKeyPress}
                  errorText={"Please enter valid password."}
                  onChange={(event) => setPassword(event.target.value)}
                />

                {/* <div className="flex justify-between items-center">
                  <CheckBox checked={rememberme} onChange={() => setRememberMe((prevState) => !prevState)}/>
                  <Link label={"Forget Password?"} />
                </div> */}
                <div>
                  <Button
                    buttonText={"Sign In"}
                    theme={"primary"}
                    processing={isAPICallInProgress}
                    onClick={submitHandler}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Login;