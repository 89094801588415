import { useState } from "react";
import LineItemCardStyles from "../../../styles/components/inspectionLineItemCard";
import { InputField, Footer, Button } from "../../../components";

const FilterProductForm = ({
  setOpenFilterDrawer,
  sendFilterProductPayload = (val) => val,
  existingDetails,
}) => {
  const [title, setTitle] = useState(existingDetails?.title);
  const [measurement, setMeasurement] = useState(existingDetails?.measurement);
  const [comment, setComment] = useState(existingDetails?.notes);

  const handleSubmit = (e) => {
    e.preventDefault();
    let requestPayload = {
      title: title,
      measurement: measurement,
      comment: comment,
    };
    if (title || measurement || comment) {
      sendFilterProductPayload(
        `${requestPayload?.title} ${requestPayload?.measurement} ${requestPayload?.comment}`
      );
      setOpenFilterDrawer(false);
    }
  };

  const clearFormDetails = () => {
    setTitle("");
    setMeasurement("");
    setComment("");
  };

  return (
    <div>
      <section className={LineItemCardStyles.filterContainer}>
        <InputField
          label={"Product Title"}
          placeholder={"Product Title"}
          value={title}
          theme={"secondary"}
          onChange={(e) => setTitle(e.target.value)}
        />
        <InputField
          label={"Measurement"}
          placeholder={"Measurement"}
          value={measurement}
          theme={"secondary"}
          onChange={(e) => setMeasurement(e.target.value)}
        />
        <InputField
          label={"Comments"}
          placeholder={"Comments"}
          value={comment}
          theme={"secondary"}
          onChange={(e) => setComment(e.target.value)}
        />
      </section>
      <section>
        <Footer theme={"primary"}>
          <Button
            theme={"tertiary"}
            buttonText={"Clear All"}
            onClick={() => clearFormDetails()}
          />
          <Button
            theme={"primary"}
            buttonText={"Apply"}
            onClick={(e) => handleSubmit(e)}
          />
        </Footer>
      </section>
    </div>
  );
};

export default FilterProductForm;
