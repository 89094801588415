import React, { useEffect, useState } from "react";
import { ModalWrapper, SignatureCanvas } from "../../components";
import QuoteStyles from "../../styles/components/signQuote";
import InputFieldStyles from "../../styles/components/inputField";
import isEmpty from "../../utils/isEmpty";

export const SignModal = ({ openModal, closeModal, handleSign, customerName, approverName }) => {
  const [comments, setComments] = useState(null);
  const [signature, setSignature] = useState(null);
  const [isSignError, setIsSignError] = useState(false);
  const [isCommentsError, setIsCommentsError] = useState(false);
  const handleSignature = (value) => {
    if (value) setIsSignError(false);
    setSignature(value);
  };

  useEffect(() => {
    setComments(null);
    setSignature(null);
  }, [openModal]);

  const handleSingQuote = () => {
    if (!isEmpty(signature)) {
      handleSign(comments, signature);
    } else {
      setIsSignError(isEmpty(signature));
      setIsCommentsError(false);
    }
  };

  const handleComments = (value) => {
    if (value) setIsCommentsError(false);
    setComments(value);
  }

  return (
    <ModalWrapper
      openModal={openModal}
      closeModal={closeModal}
      title={"Quote Matrix"}
      openDrawer={handleSingQuote}
    >
      <main className="flex justify-center flex-col bg-[#F5F6F6] w-[801px]">
        <section className="font-inter text-[18px] font-medium text-[#1B2B41B8] m-0 p-0">
          <form className="mx-7 mt-7 mb-10">
            <div className="grid gap-4 w-full">
              <div class={QuoteStyles.lineItemChild}>
                <div>
                  <b>{approverName} for {customerName}</b>
                </div>
                <SignatureCanvas
                  className="w-full"
                  sendSignature={handleSignature}
                />
                {isSignError && (
                  <label
                    className={InputFieldStyles.pageErrorLabelBlur}
                  >
                    Please enter the sign
                  </label>
                )}
              </div>
              <div class={QuoteStyles.lineItemChild}>
                <div>
                  <b>Comments</b>
                </div>
                <textarea
                  onChange={(event) => handleComments(event.target.value)}
                  value={comments}
                  className="p-3"
                  rows="3"
                  placeholder="Type your comments here ..."
                />
              </div>
              <div>
                <div>
                  By signing this quote matrix, you are acknowledging you have received the quote. If you have any questions concerning the content, please contact your sales representative.
                </div>
              </div>
            </div>
          </form>
        </section>
      </main>
    </ModalWrapper>
  );
};
