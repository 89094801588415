const ProgressBarStyles = {
  container: "flex gap-4 items-center",
  progressBarOuterContainer: "bg-gray-200 rounded-[20px] w-[212px] h-[8px]",
  progressBarInnerContainer: "bg-[#006CDB] h-[8px] rounded-full",
  text: "text-[#5B5B5B] text-[14px] leading-[20px] font-semibold",
  secondaryContainer: "flex flex-col space-y-3",
  secondaryProgressBarOuterContainer: "bg-[#C8C8C8] rounded-[20px]",
  secondaryProgressBarInnerContainer:
    "bg-[#006CDB] h-[8px] rounded-full w-full",
  secondaryStatusContainer:
    "text-[#5B5B5B] text-[14px] leading-[20px] font-semibold w-[6.53406rem] bg-[#ECF5FF] rounded-full",
  secondaryStatusLabel:
    "font-lato text-[0.875rem] font-normal flex justify-center items-center text-[#006CDB]",
  secondaryPercentageText:
    "font-helvetica text-[#006CDB] text-[0.875rem] font-normal",
  tertiaryStatusContainer:
    "text-[#5B5B5B] text-[14px] leading-[20px] font-semibold w-[6.53406rem] bg-[#C8C8C8] rounded-full",
  tertiaryStatusLabel:
    "font-lato text-[0.875rem] font-normal flex justify-center items-center text-[#5B5B5B]",
};

export default ProgressBarStyles;
