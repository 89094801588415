import React from "react";
import FooterStyles from "../../../styles/components/footer";

function Footer({ children, theme }) {
  return (
    <footer
      className={
        theme === "primary" ? FooterStyles.primary : FooterStyles.secondary
      }
      style={{
        overflow: "auto",
        boxShadow: "-9px 9px 12px 6px #aeaeae",
      }}
    >
      {children}
    </footer>
  );
}

export default Footer;
