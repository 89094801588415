/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useState, useEffect } from "react";
import { InputField, Button } from "../../components";
import { USERS_API_URL } from "../../constants";
import httpAPICall from "../../utils/httpApiCall";
import isEmpty from "../../utils/isEmpty";

const QuoteApproverForm = forwardRef(
  (
    {
      mode,
      passDataToNextSection,
      handleCancel,
      quoteApprover,
      customerID,
      isLoading,
    },
    ref
  ) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [isFirstNameError, setIsFirstNameError] = useState(false);
    const [isLastNameError, setIsLastNameError] = useState(false);
    const [isPhoneError, setIsPhoneError] = useState(false);
    const [isEmailError, setIsEmailError] = useState(false);

    const handleChange = (event, setterFunction, setErrorFunction) => {
      const value = event.target.value;
      setterFunction(value);
      setErrorFunction(isEmpty(value));
    };

    const handleSubmit = async () => {
      const createPayload = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone_number: phone,
        customer: {
          id: customerID,
        },
        access_level: {
          type: "Company",
        },
      };
      const updatePayload = {
        id: quoteApprover?.id,
        first_name: firstName,
        last_name: lastName,
        status: quoteApprover?.status_name,
        enabled: quoteApprover?.is_enable,
        email: email,
        phone_number: phone,
        customer: {
          id: customerID,
        },
        access_level: {
          type: "Company",
        },
      };
      const payload = mode === "create" ? createPayload : updatePayload;
      if (
        !isEmpty(firstName, lastName, email, phone) &&
        !isEmailError &&
        !isPhoneError &&
        !isFirstNameError &&
        !isLastNameError
      ) {
        mode === "create"
          ? passDataToNextSection(payload, "create")
          : passDataToNextSection(payload, "edit", quoteApprover?.id);
      } else {
        isEmpty(firstName) && setIsFirstNameError(true);
        isEmpty(lastName) && setIsLastNameError(true);
        isEmpty(email) && setIsEmailError(true);
        isEmpty(phone) && setIsPhoneError(true);
      }
    };

    const validateEmail = (email) => {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      return emailRegex.test(email);
    };

    const validatePhoneNumber = (phoneNumber) => {
      const phoneNumberRegex = /^\d{10}$/;
      return isEmpty(phoneNumber) ? true : phoneNumberRegex.test(phoneNumber);
    };

    const handlePhoneNumberChange = (event) => {
      const value = event.target.value;
      if (value?.length <= 10 && value >= 0) {
        setPhone(value);
        setIsPhoneError(!validatePhoneNumber(value));
      }
    };

    const handleEmailChange = (event) => {
      const value = event.target.value;
      setEmail(value);
      setIsEmailError(!validateEmail(value));
    };

    const fetchQuoteApprover = async (userID) => {
      const response = await httpAPICall(
        USERS_API_URL + `/${userID}`,
        "GET",
        {}
      );
      if (response) {
        setFirstName(response?.first_name);
        setLastName(response?.last_name);
        setEmail(response?.email);
        setPhone(response?.phone_number);
      }
    };

    useEffect(() => {
      mode === "edit" && fetchQuoteApprover(quoteApprover?.id);
    }, []);

    return (
      <>
        <main className="overflow-y-auto px-10 h-full">
          <form className="flex flex-col gap-6" onSubmit={() => {}}>
            <InputField
              label={"Quote Approver First Name"}
              errorText={"Please enter the quote approver first name"}
              error={isFirstNameError}
              theme={"secondary"}
              componentID={
                mode === "create"
                  ? "createQuoteApprover_txtName"
                  : "editQuoteApprover_txtName"
              }
              mandatory={true}
              value={firstName}
              onChange={(event) =>
                handleChange(event, setFirstName, setIsFirstNameError)
              }
            />
            <InputField
              label={"Quote Approver Last Name"}
              errorText={"Please enter the quote approver last name"}
              error={isLastNameError}
              theme={"secondary"}
              componentID={
                mode === "create"
                  ? "createQuoteApprover_txtName"
                  : "editQuoteApprover_txtName"
              }
              mandatory={true}
              value={lastName}
              onChange={(event) =>
                handleChange(event, setLastName, setIsLastNameError)
              }
            />
            <InputField
              label={"Quote Approver Phone"}
              errorText={"Please enter the quote approver contact"}
              theme={"secondary"}
              error={isPhoneError}
              componentID={
                mode === "create"
                  ? "createQuoteApprover_numContact"
                  : "editQuoteApprover_numContact"
              }
              mandatory={true}
              value={phone}
              onChange={(event) => handlePhoneNumberChange(event)}
            />
            <InputField
              label={"Quote Approver Email"}
              errorText={"Please enter the quote approver email"}
              theme={"secondary"}
              error={isEmailError}
              componentID={
                mode === "create"
                  ? "createQuoteApprover_txtEmail"
                  : "editQuoteApprover_txtEmail"
              }
              mandatory={true}
              value={email}
              onChange={(event) => handleEmailChange(event)}
            />
          </form>
        </main>
        <footer className="bg-white mt-[10px] flex justify-end items-center shadow-[_0px_-13px_28px_rgba(0,0,0,0.025)] pr-4 gap-4 h-[86px]">
          <Button
            theme={"tertiary"}
            onClick={handleCancel}
            componentID={
              mode === "create"
                ? "createCustomer_btnCancel"
                : "editCustomer_btnCancel"
            }
            buttonText={"Cancel"}
          />
          <Button
            theme={"primary"}
            buttonText={mode === "create" ? "Add" : "Save"}
            processing={isLoading}
            componentID={
              mode === "create"
                ? "createCustomer_btnAdd"
                : "editCustomer_btnSave"
            }
            type={"submit"}
            onClick={handleSubmit}
          />
        </footer>
      </>
    );
  }
);
export default QuoteApproverForm;
