import React, { useRef } from "react";
import SignaturePad from "react-signature-canvas";

function SignatureCanvas({ className, sendSignature }) {
  const signatureRef = useRef(null);
  // const [signature, setSignature] = useState(null);

  const handleSignatureEnd = () => {
    sendSignature(signatureRef.current.toDataURL());
  }

  return (
    <div className="border w-full">
      <SignaturePad
        ref={signatureRef}
        backgroundColor="#E7F3FF"
        canvasProps={{ className: className }}
        onEnd={handleSignatureEnd}
      />
    </div>
  );
}

export default SignatureCanvas;
