function groupLineItemsBySupplier(data, attribute) {
    let singleObj = {};
    data?.forEach((originalObj) => {
      let obj = { ...originalObj };
  
      let supplier = obj?.supplier?.name || "";
      let category = obj.lineItemType;
      let sku = obj.lineItemSku;
  
      if (!singleObj[supplier]) {
        singleObj[supplier] = {};
      }
      if (!singleObj[supplier][category]) {
        singleObj[supplier][category] = [];
      }
      const existingObject = singleObj[supplier][category].find((item) => item.lineItemSku === sku);
      obj.lineItemQuantity = obj.detailItemQuantity.find((item) => item?.supplier?.name === supplier)?.quantity;
      obj.rowExistingPrice = Number(obj.lineItemQuantity) * Number(obj.lineItemPrice)
  
      if (!existingObject) {
        singleObj[supplier][category].push(obj);
      }
    });
    return singleObj;
  }
  
  export default groupLineItemsBySupplier;