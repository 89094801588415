import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M34.818 38C33.266 33.2024 30.2646 30.2294 26.2868 26.7958C30.0476 23.7942 34.8142 22 40 22V6H42.0164C43.112 6 44 6.8899 44 7.9868V40.0132C44 41.1104 43.0894 42 42.0164 42H5.9836C4.8881 42 4 41.1102 4 40.0132V7.9868C4 6.88952 4.91062 6 5.9836 6H12V2H16V10H8V24C18.4403 24 27.3236 28.9232 30.6254 38H34.818ZM36 2V10H20V6H32V2H36ZM33 20C31.3432 20 30 18.6569 30 17C30 15.3431 31.3432 14 33 14C34.6568 14 36 15.3431 36 17C36 18.6569 34.6568 20 33 20Z"
      fill="#5B5B5B"
    />
  </svg>
);
export default SVGComponent;
