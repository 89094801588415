/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { InputField, OutputField, Button } from "../../components";
import moment from "moment";
import { httpAPICall } from "../../utils";
import { PROPERTIES_API_URL, QUOTE_MATRIX_URL } from "../../constants";
import { isEmpty } from "../../utils/isEmpty";

function QuoteMatrixForm({
  mode,
  handleCancel,
  handleUpdate,
  data,
  updateSendUsageAction,
}) {
  const [expiryDate, setExpiryDate] = useState("");
  const [isExpiryError, setIsExpiryError] = useState(false);
  const [warehouseID, setWareHouseID] = useState("");
  const [warehouseEmail, setWarehouseEmail] = useState("");
  const [isWarehouseEmailError, setIsWarehouseEmailError] = useState(false);
  const [isWarehouseIDError, setIsWarehouseIDError] = useState(false);
  const [isUnitsRenovatedPerMonthError, setIsUnitsRenovaterPerMonthError] =
    useState(false);
  const [unitsRenovatedPerMonth, setUnitsRenovatedPerMonth] = useState("");
  const [property, setProperty] = useState({});

  const fetchProperty = async () => {
    const response = await httpAPICall(
      PROPERTIES_API_URL + `/${data?.property[0]?.id}`,
      "GET",
      {}
    );
    setWareHouseID(response?.warehouse?.id);
    setWarehouseEmail(response?.warehouse?.email);
    setUnitsRenovatedPerMonth(
      response?.additional_info?.planned_renovation_unit_count_per_month
    );
    setProperty(response);
  };

  const handleExpiryUpdate = () => {
    if (expiryDate === "") setIsExpiryError(true);
    else {
      data.due_date = expiryDate;
      handleUpdate(data);
    }
  };

  const handleSendUsage = () => {
    if (
      (warehouseID === property?.warehouse?.id && warehouseID != null) &&
      (warehouseEmail === property?.warehouse?.email && warehouseEmail != null) &&
      (unitsRenovatedPerMonth ===
        property?.additional_info?.planned_renovation_unit_count_per_month && unitsRenovatedPerMonth != null)
    ) {
      handleSendUsageReport();
      updateSendUsageAction(data.id);
      handleCancel();
    } else {
      try {
        if (
          !isEmpty(warehouseEmail, warehouseID, unitsRenovatedPerMonth) &&
          !isWarehouseEmailError
        ) {
          handleUpdateProperty();
          handleSendUsageReport();
          updateSendUsageAction(data.id);
          handleCancel();
        } else {
          isEmpty(warehouseEmail) && setIsWarehouseEmailError(true);
          isEmpty(warehouseID) && setIsWarehouseIDError(true);
          isEmpty(unitsRenovatedPerMonth) &&
            setIsUnitsRenovaterPerMonthError(true);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleSendUsageReport = async () => {
    await httpAPICall(
      QUOTE_MATRIX_URL + `/${data?.id}/usage-report/email`,
      "POST",
      {},
      ""
    );
  };

  const handleUpdateProperty = async () => {
    let payload = {
      id: property?.id,
      code: property?.name,
      name: property?.name,
      customer: {
        id: property?.company?.id,
        name: property?.company?.name,
      },
      status: property?.status,
      type: "Multifamily Property",
      cbsa: {
        id: 1,
      },
      time_zone: {
        name: property?.time_zone,
      },
      accounts: [
        {
          type: "Maintenance",
          account_number: property?.account_number,
        },
        {
          type: "Renovation",
          account_number: property?.renovation_account_number,
        },
      ],
      address: {
        address1: property?.address?.address,
        address2: property?.address?.address2,
        city: property?.address?.city,
        state: property?.address?.state,
        zip: property?.address?.zip,
      },
      warehouse: {
        external_reference_id: warehouseID,
        contact_email: warehouseEmail,
      },
      additional_info: {
        planned_renovation_unit_count_per_month: unitsRenovatedPerMonth,
      },
    };
    await httpAPICall(
      PROPERTIES_API_URL + `/${property?.id}`,
      "PUT",
      payload,
      ""
    );
  };
  const validateEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setWarehouseEmail(value);
    setIsWarehouseEmailError(!validateEmail(value));
  };

  const handleChange = (event, setterFunction, setErrorFunction) => {
    const value = event.target.value;
    setterFunction(value);
    setErrorFunction(isEmpty(value));
  };

  useEffect(() => {
    if (mode === "send_usage") {
      fetchProperty();
    }
  }, []);

  return (
    <div className="flex flex-col h-full justify-between">
      <main className="mt-10 px-10">
        {mode !== "expired" ? (
          <form className="flex flex-col gap-6">
            <InputField
              theme={"secondary"}
              label={"Warehouse"}
              errorText={"Please enter a warehouse name"}
              value={warehouseID}
              error={isWarehouseIDError}
              mandatory={true}
              type={"text"}
              onChange={(event) =>
                handleChange(event, setWareHouseID, setIsWarehouseIDError)
              }
            />
            <InputField
              theme={"secondary"}
              label={"Email ID"}
              errorText={"Please enter a email ID"}
              error={isWarehouseEmailError}
              mandatory={true}
              value={warehouseEmail}
              type={"email"}
              onChange={handleEmailChange}
            />
            <InputField
              label={"Units Renovated/Month"}
              theme={"secondary"}
              errorText={"Please enter units renovated/month"}
              error={isUnitsRenovatedPerMonthError}
              mandatory={true}
              value={unitsRenovatedPerMonth}
              type={"number"}
              min={1}
              onChange={(event) =>
                handleChange(
                  event,
                  setUnitsRenovatedPerMonth,
                  setIsUnitsRenovaterPerMonthError
                )
              }
            />
          </form>
        ) : (
          <form className="flex flex-col gap-10">
            <OutputField label={"Quote Matrix"} value={data?.title} />
            <OutputField
              label={"Expiry Date"}
              value={moment(data?.due_date).format("MM-DD-YYYY")}
            />
            <InputField
              theme={"secondary"}
              label={"Update Expiry Date"}
              type={"date"}
              error={isExpiryError}
              errorText={"Please select a expiry Date"}
              placeholder={""}
              min={new Date().toISOString().split("T")[0]}
              onChange={(event) =>
                setExpiryDate(moment(event.target.value).format("YYYY-MM-DD"))
              }
            />
          </form>
        )}
      </main>
      <footer className="bg-white mt-[80px] flex justify-end items-center shadow-[_0px_-13px_28px_rgba(0,0,0,0.025)] pr-4 gap-4 h-[66px]">
        <Button
          theme={"tertiary"}
          onClick={handleCancel}
          buttonText={"Cancel"}
        />
        <Button
          theme={"primary"}
          buttonText={mode === "expired" ? "Update" : "Send"}
          type={"submit"}
          onClick={mode === "expired" ? handleExpiryUpdate : handleSendUsage}
        />
      </footer>
    </div>
  );
}

export default QuoteMatrixForm;
