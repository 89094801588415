import React from "react";
import QuoteMatrixList from "../quote_matrix/List";

function Index() {
  return (
    <div>
      <h3 className="font-lato font-bold text-[31px] leading-[37.2px] text-[#25282B]">
        Quote Matrix - Summary View
      </h3>
      <main className="mt-[66px]">
        <QuoteMatrixList />
      </main>
    </div>
  );
}

export default Index;