import React from "react";
import { Checkbox, Table } from "rsuite";
import CheckCellStyles from "../../../styles/components/checkCell";
import { useNavigate } from "react-router-dom";

const { Cell } = Table;
function CheckCell({
  rowData,
  onChange,
  checkedKeys,
  dataKey,
  sendRecordId = (val) => val,
  ...props
}) {
  const navigate = useNavigate();
  return (
    <Cell {...props}>
      <div className={CheckCellStyles.innerContainer}>
        <Checkbox
          value={rowData[dataKey]}
          onChange={onChange}
          checked={checkedKeys.some((item) => item === rowData[dataKey])}
        />
        <span
          className={CheckCellStyles.dataContainer}
          onClick={() => {
            navigate(`/inspection/${rowData[dataKey]}`);
          }}
        >
          {rowData[dataKey]}
        </span>
      </div>
    </Cell>
  );
}

export default CheckCell;
