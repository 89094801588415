/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  SlideDrawer,
  Button,
  Heading,
  AccordionSlider,
  LinkButton,
  Dropdown,
  ModalWrapper,
} from "../../components";
import CustomerGrid from "../customer/Grid";
import PropertyGrid from "../property/Grid";
import FloorplanGrid from "../floorplan/Grid";
import BuildingGrid from "../building/Grid";
import UnitGrid from "../unit/Grid";
import generateDropdownOptions from "../../utils/dropdownOptionsGenerator";
import {
  CustomerForm,
  FloorplanForm,
  PropertyForm,
  UnitForm,
} from "../form/Index";
import {
  CUSTOMERS_API_URL,
  PROPERTIES_API_URL,
  FLOORPLANS_API_URL,
  BUILDINGS_API_URL,
  UNITS_API_URL,
  USERS_API_URL,
} from "../../constants";
import httpAPICall from "../../utils/httpApiCall";
import isEmpty from "../../utils/isEmpty";
import qs from "qs";

const UnitModal = ({
  openModal,
  closeModal,
  setupData,
  unitDrawer,
  unitDrawerMode,
}) => {
  const handleEdit = () => {
    closeModal();
    unitDrawer();
    unitDrawerMode();
  };
  return (
    <ModalWrapper
      openModal={openModal}
      closeModal={closeModal}
      title={"Unit"}
      openDrawer={handleEdit}
    >
      <main className="flex justify-center flex-col bg-[#F5F6F6] w-[801px]">
        <section className="font-inter text-[18px] font-medium text-[#1B2B41B8] m-0 p-0">
          <form>
            {setupData.map((attribute, index) => {
              return (
                <div
                  key={`setupData${index}`}
                  className="flex h-[50px] gap-[30px] items-center border-t border-[#c8c8c8]"
                >
                  <label className="pl-[50px] w-2/5 text-[#383838]">
                    {attribute?.id}
                  </label>
                  <div className="text-[#5B5B5B] text-[15px] overflow-auto w-full">
                    <p>{attribute?.value}</p>
                  </div>
                </div>
              );
            })}
          </form>
        </section>
      </main>
    </ModalWrapper>
  );
};

function RenovationSetup() {
  const [isCustomerDrawer, setIsCustomerDrawer] = useState(false);
  const [isPropertyDrawer, setIsPropertyDrawer] = useState(false);
  const [isFloorplanDrawer, setIsFloorplanDrawer] = useState(false);
  const [isUnitDrawer, setIsUnitDrawer] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [propertyData, setPropertyData] = useState([]);
  const [floorplanData, setFloorplanData] = useState([]);
  const [buildingData, setBuildingData] = useState([]);
  const [unitData, setUnitData] = useState([]);
  const [isAPICallInProgress, setIsAPICallInProgress] = useState(false);
  const [isCustomerLoading, setIsCustomerLoading] = useState(false);
  const [isPropertyLoading, setIsPropertyLoading] = useState(false);
  const [isFloorplanLoading, setIsFloorplanLoading] = useState(false);
  const [isBuildingLoading, setIsBuildingLoading] = useState(false);
  const [isUnitLoading, setIsUnitLoading] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);
  const navigate = useNavigate();
  const [customerSearchValue, setCustomerSearchValue] = useState("");
  const [propertySearchValue, setPropertySearchValue] = useState("");
  const [floorplanSearchValue, setFloorplanSearchValue] = useState("");
  const [buildingSearchValue, setBuildingSearchValue] = useState("");

  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedProperty, setSelectedProperty] = useState("");
  const [selectedFloorplan, setSelectedFloorplan] = useState("");
  const [selectedBuilding, setSelectedBuilding] = useState("");

  const [customerDrawerMode, setCustomerDrawerMode] = useState("create");
  const [propertyDrawerMode, setPropertyDrawerMode] = useState("create");
  const [floorplanDrawerMode, setFloorplanDrawerMode] = useState("create");
  const [unitDrawerMode, setUnitDrawerMode] = useState("create");
  const [customerAccordionStatus, setCustomerAccordionStatus] = useState(true);
  const [propertyAccordionStatus, setPropertyAccordionStatus] = useState(false);
  const [floorplanAccordionStatus, setFloorplanAccordionStatus] =
    useState(false);
  const [isCustomerAccordionOpen, setIsCustomerAccordionOpen] = useState(true);
  const [isPropertyAccordionOpen, setIsPropertyAccordionOpen] = useState(false);
  const [isFloorplanAccordionOpen, setIsFloorplanAccordionOpen] =
    useState(false);
  const [isUnitAccordionOpen, setIsUnitAccordionOpen] = useState(false);
  const [unitAccordionStatus, setUnitAccordionStatus] = useState(false);
  const [customerDebouncedSearch, setCustomerDebouncedSearch] = useState(null);
  const [propertyDebouncedSearch, setPropertyDebouncedSearch] = useState(null);
  const [floorplanDebouncedSearch, setFloorplanDebouncedSearch] =
    useState(null);
  const [buildingDebouncedSearch, setBuildingDebouncedSearch] = useState(null);
  const [companyID, setCompanyID] = useState();
  const [propertyID, setPropertyID] = useState();
  const [floorplanID, setFloorplanID] = useState();
  const [buildingID, setBuildingID] = useState();
  const [customerSort, setCustomerSort] = useState("");
  const [propertySort, setPropertySort] = useState("");
  const [floorplanSort, setFloorplanSort] = useState("");
  const [buildingSort, setBuildingSort] = useState("");
  const [propertyDrwnClear, setPropertyDrwnClear] = useState(false);
  const [floorplanDrwnClear, setFloorplanDrwnClear] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState("");
  const location = useLocation();
  const [renovationSuccessMsg, setRenovationSuccessMsg] = useState(
    location?.state?.successMsg
  );
  const [openModal, setOpenModal] = useState(false);
  const [isToggleState, setIsToggleState] = useState(true);
  const [selectedSetupData, setSelectedSetupData] = useState([]);
  const [selectedCustomerData, setSelectedCustomerData] = useState({});

  const toggle = (newToggleState) => {
    setIsToggleState(newToggleState);
    newToggleState ? setSelectedFloorplan("") : setSelectedBuilding("");
    setFloorplanDrwnClear(true);
    selectedProperty !== "" &&
      handlePropertyChange(selectedProperty, newToggleState);
  };

  const fetchCustomerData = async (filterObj) => {
    setIsCustomerLoading(true);
    let generatedUrl = CUSTOMERS_API_URL + `?${qs.stringify(filterObj)}`;
    let response = await httpAPICall(generatedUrl, "GET", {});
    setCustomerData(response);
    setIsCustomerLoading(false);
  };

  const fetchPropertyData = async (filterObj) => {
    setIsPropertyLoading(true);
    let generatedUrl = PROPERTIES_API_URL + `?${qs.stringify(filterObj)}`;
    let response = await httpAPICall(generatedUrl, "GET", {});
    setPropertyData(response);
    setIsPropertyLoading(false);
  };

  const fetchFloorplanData = async (filterObj) => {
    setIsFloorplanLoading(true);
    let generatedUrl = FLOORPLANS_API_URL + `?${qs.stringify(filterObj)}`;
    let response = await httpAPICall(generatedUrl, "GET", {});
    setFloorplanData(response);
    setIsFloorplanLoading(false);
  };

  const fetchBuildingData = async (filterObj) => {
    setIsBuildingLoading(true);
    let generatedUrl = BUILDINGS_API_URL + `?${qs.stringify(filterObj)}`;
    let response = await httpAPICall(generatedUrl, "GET", {});
    setBuildingData(response);
    setIsBuildingLoading(false);
  };

  const fetchUnitData = async (filterObj) => {
    setIsUnitLoading(true);
    let generatedUrl = UNITS_API_URL + `?${qs.stringify(filterObj)}`;
    let response = await httpAPICall(generatedUrl, "GET", {});
    setUnitData(response);
    setIsUnitLoading(false);
  };

  useEffect(() => {
    if (clearFilter) {
      window.location.reload();
    } else if (customerSort && Object.keys(customerSort).length > 0) {
      fetchCustomerData(customerSort);
      setCustomerSort({});
    } else if (propertySort && Object.keys(propertySort).length > 0) {
      let searchObject = { ...propertySort };
      searchObject["customer_ids"] = selectedCustomer?.id;
      fetchPropertyData(searchObject);
      setPropertySort({});
    } else if (floorplanSort && Object.keys(floorplanSort).length > 0) {
      let searchObject = { ...floorplanSort };
      searchObject["customer_ids"] = selectedCustomer?.id;
      searchObject["property_ids"] = selectedProperty?.id;
      fetchFloorplanData(searchObject);
      setFloorplanSort({});
    } else if (buildingSort && Object.keys(buildingSort).length > 0) {
      let searchObject = { ...buildingSort };
      searchObject["customer_ids"] = selectedCustomer?.id;
      searchObject["property_ids"] = selectedProperty?.id;
      fetchBuildingData(searchObject);
      setBuildingSort({});
    } else if (
      isEmpty(customerSort) &&
      isEmpty(propertySort) &&
      isEmpty(floorplanSort) &&
      isEmpty(buildingSort)
    ) {
      fetchCustomerData();
    }
    return () => {
      if (propertyDebouncedSearch) {
        clearTimeout(propertyDebouncedSearch);
      }
      if (customerDebouncedSearch) {
        clearTimeout(customerDebouncedSearch);
      }
      if (floorplanDebouncedSearch) {
        clearTimeout(floorplanDebouncedSearch);
      }
      if (buildingDebouncedSearch) {
        clearTimeout(buildingDebouncedSearch);
      }
    };
  }, [clearFilter, customerSort, propertySort, floorplanSort, buildingSort]);

  useEffect(() => {
    if (renovationSuccessMsg) {
      const timer = setTimeout(() => {
        navigate(".", { state: { successMsg: null } });
        setRenovationSuccessMsg(null);
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [renovationSuccessMsg]);

  const handleSearch = (
    value,
    searchType,
    setSearchText,
    debouncedSearch,
    setDebouncedSearch
  ) => {
    setSearchText(value);
    if (debouncedSearch) {
      clearTimeout(debouncedSearch);
    }
    setDebouncedSearch(
      setTimeout(() => {
        let searchObj = {};
        if (searchType !== "customer") {
          searchObj["customer_ids"] = selectedCustomer?.id;
          if (searchType !== "property")
            searchObj["property_ids"] = selectedProperty?.id;
        }
        if (value) {
          searchObj["q"] = value;
        }
        if (searchType === "property") fetchPropertyData(searchObj);
        else if (searchType === "customer") fetchCustomerData(searchObj);
        else if (searchType === "floorplan") fetchFloorplanData(searchObj);
        else if (searchType === "building") fetchBuildingData(searchObj);
      }, 1400)
    );
  };

  const handleCustomer = async (data, action, customerID) => {
    setIsAPICallInProgress(true);
    if (action === "create")
      await httpAPICall(CUSTOMERS_API_URL, "POST", data, "", "PMC");
    else
      await httpAPICall(
        CUSTOMERS_API_URL + `/${customerID}`,
        "PUT",
        data,
        "",
        "PMC"
      );
    fetchCustomerData();
    setIsCustomerDrawer(false);
    setIsAPICallInProgress(false);
  };

  const handleProperty = async (
    data,
    action,
    propertyID,
    onsiteManagerPayload,
    supplierPurchaseDeptEmail,
    onsiteManagerID
  ) => {
    setIsAPICallInProgress(true);
    if (action === "create") {
      const property = await httpAPICall(
        PROPERTIES_API_URL,
        "POST",
        data,
        "",
        "Property"
      );
      onsiteManagerPayload.access_level.id = property?.id;
      await httpAPICall(USERS_API_URL, "POST", onsiteManagerPayload);
    } else {
      await httpAPICall(
        PROPERTIES_API_URL + `/${propertyID}`,
        "PUT",
        data,
        "",
        "Property"
      );
      await httpAPICall(
        USERS_API_URL + `/${onsiteManagerID}`,
        "PUT",
        onsiteManagerPayload
      );
    }
    let searchObj = {};
    searchObj["customer_ids"] = selectedCustomer?.id;
    selectedCustomer?.id && fetchPropertyData(searchObj);
    setIsPropertyDrawer(false);
    setIsAPICallInProgress(false);
  };

  const handleFloorplan = async (data, action, floorplanID) => {
    setIsAPICallInProgress(true);
    if (action === "create") {
      await httpAPICall(FLOORPLANS_API_URL, "POST", data, "", "Floorplan");
      setSelectedFloorplan("");
      setFloorplanDrwnClear(true);
    } else
      await httpAPICall(
        FLOORPLANS_API_URL + `/${floorplanID}`,
        "PUT",
        data,
        "",
        "Floorplan"
      );
    let searchObj = {};
    searchObj["customer_ids"] = selectedCustomer?.id;
    searchObj["property_ids"] = selectedProperty?.id;
    selectedCustomer?.id &&
      selectedProperty?.id &&
      fetchFloorplanData(searchObj);
    setIsFloorplanDrawer(false);
    setIsAPICallInProgress(false);
  };

  const handleBuilding = async (data, action, buildingID) => {
    setIsAPICallInProgress(true);
    if (action === "create") {
      await httpAPICall(BUILDINGS_API_URL, "POST", data, "", "Building");
      setSelectedBuilding("");
      setFloorplanDrwnClear(true);
    } else
      await httpAPICall(
        BUILDINGS_API_URL + `/${buildingID}`,
        "PUT",
        data,
        "",
        "Building"
      );
    let searchObj = {};
    searchObj["customer_ids"] = selectedCustomer?.id;
    searchObj["property_ids"] = selectedProperty?.id;
    selectedCustomer?.id &&
      selectedProperty?.id &&
      fetchBuildingData(searchObj);
    setIsFloorplanDrawer(false);
    setIsAPICallInProgress(false);
  };

  const handleUnit = async (data, property_id, action) => {
    setIsAPICallInProgress(true);
    if (action === "create")
      await httpAPICall(
        `properties/${property_id}` + UNITS_API_URL,
        "POST",
        data,
        "",
        "Unit"
      );
    else
      await httpAPICall(
        `properties/${property_id}${UNITS_API_URL}`,
        "PUT",
        data,
        "",
        "Unit"
      );
    let searchObj = {};
    searchObj["customer_ids"] = selectedCustomer?.id;
    searchObj["property_ids"] = selectedProperty?.id;
    if (isToggleState) {
      searchObj["floorplan_ids"] = selectedFloorplan?.id;
      searchObj["status"] = "Active";
    } else {
      searchObj["building_ids"] = selectedBuilding?.id;
    }
    selectedCustomer?.id &&
      selectedProperty?.id &&
      (isToggleState ? selectedFloorplan?.id : selectedBuilding?.id) &&
      fetchUnitData(searchObj);
    setIsUnitDrawer(false);
    setIsAPICallInProgress(false);
  };

  const handleCustomerChange = (customerOpted) => {
    setPropertyDrwnClear(true);
    setSelectedProperty("");
    setFloorplanDrwnClear(true);
    setSelectedFloorplan("");
    setIsPropertyAccordionOpen(true);
    setFloorplanData([]);
    setUnitData([]);
    setIsFloorplanAccordionOpen(false);
    setFloorplanAccordionStatus(false);
    setIsUnitAccordionOpen(false);
    setUnitAccordionStatus(false);
    setPropertyAccordionStatus(true);
    setCustomerAccordionStatus(false);
    setClearFilter(false);
    setSelectedCustomer(customerOpted);
    setSelectedSetupData((prevData) => {
      const newData = [...prevData];
      newData[0] = { id: "PMC", value: customerOpted?.name };
      return newData;
    });
    setSelectedCustomerData(customerData?.filter(
      (customer) => customer.id === customerOpted?.id
    ));
    let searchObj = {};
    searchObj["customer_ids"] = customerOpted?.id;
    fetchPropertyData(searchObj);
  };

  const handlePropertyChange = (selectedProperty, toggleState) => {
    setPropertyDrwnClear(false);
    setFloorplanDrwnClear(true);
    setSelectedFloorplan("");
    setUnitData([]);
    setIsUnitAccordionOpen(false);
    setUnitAccordionStatus(false);
    let searchObj = {};
    searchObj["customer_ids"] = selectedCustomer?.id;
    searchObj["property_ids"] = selectedProperty?.id;
    const actualToggleState =
      typeof toggleState !== "undefined" ? toggleState : isToggleState;
    actualToggleState
      ? fetchFloorplanData(searchObj)
      : fetchBuildingData(searchObj);
    setIsFloorplanAccordionOpen(true);
    setFloorplanAccordionStatus(true);
    setPropertyAccordionStatus(false);
    setClearFilter(false);
    setSelectedProperty(selectedProperty);
    setSelectedSetupData((prevData) => {
      const newData = [...prevData];
      newData[1] = { id: "Property", value: selectedProperty?.name };
      return newData;
    });
  };

  const handleFloorplanChange = (selectedFloorplan, floorplan) => {
    setFloorplanDrwnClear(false);
    setIsUnitAccordionOpen(true);
    setUnitAccordionStatus(false);
    setFloorplanAccordionStatus(false);
    setClearFilter(false);
    if (isToggleState) {
      setSelectedFloorplan(selectedFloorplan);
      setSelectedSetupData((prevData) => {
        const newData = [...prevData];
        newData[2] = { id: "Floorplan", value: selectedFloorplan?.name };
        newData[3] = {
          id: "Floorplan Unit Count",
          value: floorplan[0].additional_info?.planned_renovation_unit_count,
        };
        return newData;
      });
    } else {
      setSelectedBuilding(selectedFloorplan);
      setSelectedSetupData((prevData) => {
        const newData = [...prevData];
        newData.splice(2);
        newData[2] = { id: "Building", value: selectedFloorplan?.name };
        return newData;
      });
    }
    let searchObj = {};
    searchObj["customer_ids"] = selectedCustomer?.id;
    searchObj["property_ids"] = selectedProperty?.id;
    isToggleState
      ? (searchObj["floorplan_ids"] = selectedFloorplan?.id)
      : (searchObj["building_ids"] = selectedFloorplan?.id);
    fetchUnitData(searchObj);
  };

  const handleEdit = (id, setID, setDrawerMode, setIsDrawer) => {
    setID(id);
    setDrawerMode("edit");
    setIsDrawer(true);
  };

  const handleClearFilter = () => {
    setClearFilter(!clearFilter);
  };

  const handleAccordion = (
    event,
    accordionStatus,
    setAccordionStatus,
    isAccordionOpen,
    setIsAccordionOpen
  ) => {
    setIsAccordionOpen(!isAccordionOpen);
    setAccordionStatus(!accordionStatus);
  };

  return (
    <div className="flex flex-col gap-12">
      <div className="flex justify-between items-center">
        <Heading heading={"Renovation Setup"} theme={"primary"} />
        {renovationSuccessMsg && (
          <span className="px-4 py-2 h-38 bg-[#E6FEED] font-inter font-medium text-[14px] text-[#048848]">
            {renovationSuccessMsg}
          </span>
        )}
        <Button
          buttonText={"PMC - Property - Floorplan/Building - Unit Setup"}
          componentID={"renovationSetup_btnGroupCreate"}
          onClick={() => navigate("/renovation-setup/create")}
          theme={"primary"}
        />
      </div>
      <div className="flex justify-between items-center">
        <div className="flex justify-between items-center space-x-4">
          <div className="w-[200px]">
            <Dropdown
              options={
                customerSearchValue &&
                generateDropdownOptions(customerData, "name", "id", "is_registered")
              }
              value={selectedCustomer}
              componentID={"renovationSetup_customerSearch"}
              isSearchable={true}
              placeHolder={"PMC"}
              searchText={(searchText) =>
                handleSearch(
                  searchText,
                  "customer",
                  setCustomerSearchValue,
                  customerDebouncedSearch,
                  setCustomerDebouncedSearch
                )
              }
              onChange={handleCustomerChange}
            />
          </div>
          <div className="w-[200px]">
            <Dropdown
              options={generateDropdownOptions(propertyData, "name", "id")}
              value={selectedProperty}
              componentID={"renovationSetup_propertySearch"}
              placeHolder={"Property"}
              isClear={propertyDrwnClear}
              isSearchable={true}
              searchText={(searchText) =>
                handleSearch(
                  searchText,
                  "property",
                  setPropertySearchValue,
                  propertyDebouncedSearch,
                  setPropertyDebouncedSearch
                )
              }
              onChange={handlePropertyChange}
              onClick={() =>
                handleSearch(
                  "",
                  "property",
                  setPropertySearchValue,
                  propertyDebouncedSearch,
                  setPropertyDebouncedSearch
                )
              }
            />
          </div>
          <div className="w-[200px]">
            <Dropdown
              options={
                isToggleState
                  ? generateDropdownOptions(floorplanData, "name", "id")
                  : generateDropdownOptions(buildingData, "name", "id")
              }
              componentID={
                isToggleState
                  ? "renovationSetup_floorplanSearch"
                  : "renovationSetup_buildingSearch"
              }
              value={isToggleState ? selectedFloorplan : selectedBuilding}
              isSearchable={true}
              isClear={floorplanDrwnClear}
              placeHolder={isToggleState ? "Floorplan" : "Building"}
              searchText={(searchText) => {
                isToggleState
                  ? handleSearch(
                      searchText,
                      "floorplan",
                      setFloorplanSearchValue,
                      floorplanDebouncedSearch,
                      setFloorplanDebouncedSearch
                    )
                  : handleSearch(
                      searchText,
                      "building",
                      setBuildingSearchValue,
                      buildingDebouncedSearch,
                      setBuildingDebouncedSearch
                    );
              }}
              onChange={(selectedFloorplan) => {
                handleFloorplanChange(
                  selectedFloorplan,
                  isToggleState &&
                    floorplanData.filter((floorplan) => {
                      return floorplan.id === selectedFloorplan.id;
                    })
                );
              }}
            />
          </div>
          <div className="flex">
            <div
              id="clear-filter"
              className="font-lato font-semibold text-[16px] ml-[45px] leading-[16.8px] text-[#006CDB] hover:underline cursor-pointer"
              onClick={handleClearFilter}
            >
              Clear Filter
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 z-0">
        <AccordionSlider
          name={"PMC"}
          componentID={"renovationSetup_customerAccordion"}
          mode={customerAccordionStatus ? "active" : "inactive"}
          collapsible={0}
          isOpen={isCustomerAccordionOpen ? 0 : 1}
          content={"table"}
          onButtonClick={(event) =>
            handleAccordion(
              event,
              customerAccordionStatus,
              setCustomerAccordionStatus,
              isCustomerAccordionOpen,
              setIsCustomerAccordionOpen
            )
          }
        >
          <CustomerGrid
            tableData={customerData}
            onSelect={handleCustomerChange}
            searchText={customerSearchValue}
            selectedOption={selectedCustomerData}
            editCustomer={(customer_ids) =>
              handleEdit(
                customer_ids,
                setCompanyID,
                setCustomerDrawerMode,
                setIsCustomerDrawer
              )
            }
            isLoading={isCustomerLoading}
            sort={(sortObject) => setCustomerSort(sortObject)}
          />
        </AccordionSlider>
        <span className="flex justify-end">
          <LinkButton
            theme="primary"
            className="float-right"
            label={"Add PMC"}
            componentID={"customerAccordion_addCustomer"}
            onClick={() => {
              setIsCustomerDrawer(true);
              setCustomerDrawerMode("create");
            }}
          />
        </span>
      </div>
      <div className="flex flex-col gap-4">
        <AccordionSlider
          name={"Property"}
          componentID={"renovationSetup_propertyAccordion"}
          mode={propertyAccordionStatus ? "active" : "inactive"}
          collapsible={1}
          isOpen={isPropertyAccordionOpen ? 0 : 1}
          content={"table"}
          onButtonClick={(event) =>
            handleAccordion(
              event,
              propertyAccordionStatus,
              setPropertyAccordionStatus,
              isPropertyAccordionOpen,
              setIsPropertyAccordionOpen
            )
          }
        >
          <PropertyGrid
            tableData={propertyData}
            selectedOption={propertyData?.filter(
              (property) => property?.id === selectedProperty.id
            )}
            onSelect={handlePropertyChange}
            searchText={propertySearchValue}
            isLoading={isPropertyLoading}
            editProperty={(property_id) =>
              handleEdit(
                property_id,
                setPropertyID,
                setPropertyDrawerMode,
                setIsPropertyDrawer
              )
            }
            registered={selectedCustomerData[0]?.is_registered}
            sort={(sortObject) => setPropertySort(sortObject)}
          />
        </AccordionSlider>
        <span className="flex justify-end">
          {selectedCustomerData[0]?.is_registered ? (
            <></>
          ) : (
            <LinkButton
              theme="primary"
              label={"Add Property"}
              componentID={"propertyAccordion_addProperty"}
              onClick={() => {
                setIsPropertyDrawer(true);
                setPropertyDrawerMode("create");
              }}
            />
          )}
        </span>
      </div>
      <div className="flex flex-col gap-4">
        <AccordionSlider
          name={"Floorplan/Building Setup"}
          companyID={"renovationSetup_floorplanAccordion"}
          mode={floorplanAccordionStatus ? "active" : "inactive"}
          isOpen={isFloorplanAccordionOpen ? 0 : 1}
          isToggle={true}
          toggleState={toggle}
          collapsible={1}
          content={"table"}
          onButtonClick={(event) =>
            handleAccordion(
              event,
              floorplanAccordionStatus,
              setFloorplanAccordionStatus,
              isFloorplanAccordionOpen,
              setIsFloorplanAccordionOpen
            )
          }
        >
          {isToggleState ? (
            <FloorplanGrid
              tableData={floorplanData}
              selectedOption={floorplanData?.filter(
                (floorplan) => floorplan.id === selectedFloorplan?.id
              )}
              onSelect={handleFloorplanChange}
              searchText={floorplanSearchValue}
              isLoading={isFloorplanLoading}
              sort={(sortObject) => setFloorplanSort(sortObject)}
              editFloorplan={(floorplan_id) =>
                handleEdit(
                  floorplan_id,
                  setFloorplanID,
                  setFloorplanDrawerMode,
                  setIsFloorplanDrawer
                )
              }
            />
          ) : (
            <BuildingGrid
              tableData={buildingData}
              selectedOption={buildingData?.filter(
                (building) => building.id === selectedBuilding?.id
              )}
              onSelect={handleFloorplanChange}
              searchText={buildingSearchValue}
              isLoading={isBuildingLoading}
              sort={(sortObject) => setBuildingSort(sortObject)}
              editBuilding={(building_id) =>
                handleEdit(
                  building_id,
                  setBuildingID,
                  setFloorplanDrawerMode,
                  setIsFloorplanDrawer
                )
              }
            />
          )}
        </AccordionSlider>
        <span className="flex justify-end">
          <LinkButton
            theme="primary"
            label={"Add Floorplan/Building"}
            componentID={"floorplanAccordion_addFloorplan"}
            onClick={() => {
              setIsFloorplanDrawer(true);
              setFloorplanDrawerMode("create");
            }}
          />
        </span>
      </div>
      <div className="flex flex-col gap-4">
        <AccordionSlider
          name={"Unit/Building No. Setup"}
          componentID={"renovationSetup_unitAccordion"}
          mode={unitAccordionStatus ? "active" : "inactive"}
          collapsible={1}
          isOpen={isUnitAccordionOpen ? 0 : 1}
          content={"table"}
          onButtonClick={(event) =>
            handleAccordion(
              event,
              unitAccordionStatus,
              setUnitAccordionStatus,
              isUnitAccordionOpen,
              setIsUnitAccordionOpen
            )
          }
        >
          <UnitGrid
            tableData={unitData}
            searchText={""}
            isLoading={isUnitLoading}
            selectedOption={selectedUnit}
            sendDataToNextComponent={(unitNumbers) => {
              setSelectedSetupData((prevData) => {
                const newData = [...prevData];
                isToggleState
                  ? (newData[4] = { id: "Unit Number", value: unitNumbers })
                  : (newData[3] = {
                      id: "Building Number",
                      value: unitNumbers,
                    });
                return newData;
              });
            }}
            showModal={() => setOpenModal(true)}
            editUnit={(unit_id) => {
              setIsUnitDrawer(true);
              setUnitDrawerMode("edit");
            }}
            onSelect={setSelectedUnit}
          />
        </AccordionSlider>
        <span className="flex justify-end">
          <LinkButton
            theme="primary"
            label={"Add Unit/Building"}
            componentID={"unitAccordion_addUnit"}
            onClick={() => {
              setIsUnitDrawer(true);
              setUnitDrawerMode("create");
            }}
          />
        </span>
      </div>
      <SlideDrawer
        label={customerDrawerMode === "create" ? "Add New PMC" : "Edit PMC"}
        openDrawer={isCustomerDrawer}
        componentID={"renovationSetup_customerDrawer"}
        setOpenDrawer={() => setIsCustomerDrawer(false)}
      >
        <CustomerForm
          isLoading={isAPICallInProgress}
          passDataToNextSection={handleCustomer}
          mode={customerDrawerMode}
          customerID={companyID}
          handleCancel={() => setIsCustomerDrawer(false)}
          customerUpdate={(isCustomerUpdate) =>
            isCustomerUpdate && fetchCustomerData()
          }
        />
      </SlideDrawer>
      <SlideDrawer
        label={
          propertyDrawerMode === "create" ? "Add New Property" : "Edit Property"
        }
        customerID={"renovationSetup_propertyDrawer"}
        openDrawer={isPropertyDrawer}
        setOpenDrawer={() => setIsPropertyDrawer(false)}
      >
        <PropertyForm
          isLoading={isAPICallInProgress}
          passDataToNextSection={handleProperty}
          mode={propertyDrawerMode}
          handleCancel={() => setIsPropertyDrawer(false)}
          propertyID={propertyID}
          selectedCustomer={selectedCustomer}
        />
      </SlideDrawer>
      <SlideDrawer
        label={
          floorplanDrawerMode === "create"
            ? "Add New Floorplan/Building"
            : "Edit Floorplan/Building"
        }
        openDrawer={isFloorplanDrawer}
        componentID={"renovationSetup_floorplanDrawer"}
        setOpenDrawer={() => setIsFloorplanDrawer(false)}
      >
        <FloorplanForm
          passDataToNextSection={(data, mode, id, type) => {
            type === "Floorplan"
              ? handleFloorplan(data, mode, id)
              : handleBuilding(data, mode, id);
          }}
          mode={floorplanDrawerMode}
          handleCancel={() => setIsFloorplanDrawer(false)}
          selectedCustomer={selectedCustomer}
          propertyType={isToggleState ? "Floorplan" : "Building"}
          floorplanID={floorplanID}
          isLoading={isAPICallInProgress}
          buildingID={buildingID}
          selectedProperty={selectedProperty}
        />
      </SlideDrawer>
      <SlideDrawer
        label={
          unitDrawerMode === "create"
            ? "Add New Unit/Building"
            : "Edit Unit/Building"
        }
        openDrawer={isUnitDrawer}
        componentID={"renovationSetup_unitDrawer"}
        setOpenDrawer={() => setIsUnitDrawer(false)}
      >
        <UnitForm
          passDataToNextSection={handleUnit}
          mode={unitDrawerMode}
          handleCancel={() => setIsUnitDrawer(false)}
          unitData={unitData}
          propertyType={isToggleState ? "Floorplan" : "Building"}
          selectedFloorplanUnitCount={selectedSetupData[3]?.value}
          selectedCustomer={selectedCustomer}
          selectedProperty={selectedProperty}
          selectedFloorplan={selectedFloorplan}
          selectedBuilding={selectedBuilding}
          isLoading={isAPICallInProgress}
        />
      </SlideDrawer>
      <section>
        <UnitModal
          openModal={openModal}
          closeModal={() => setOpenModal(false)}
          setupData={selectedSetupData}
          unitDrawer={() => setIsUnitDrawer(true)}
          unitDrawerMode={() => setUnitDrawerMode("edit")}
        />
      </section>
    </div>
  );
}
export default RenovationSetup;
