import React, { useState } from "react";
import { Table } from "rsuite";
import { RadioCell } from "../../components/index";
import { PencilIcon } from "../../assets";
const { Column, HeaderCell, Cell } = Table;

function UnitGrid({ tableData, editUnit, isLoading, showModal, sendDataToNextComponent }) {
  const [checkedKeys, setCheckedKeys] = useState([]);

  const handleCheck = (value) => {
    setCheckedKeys([value]);
  };

  const searializeUnitNumbers = (unitData) => {
    const searializedUnitData = [];
    searializedUnitData.push(unitData?.map((item) => item.name).join(", "));
    return (unitData && unitData?.length > 0) ? [{ id:0, data: searializedUnitData, status: "Enabled" }] : [];
  }
  
  let unitData = searializeUnitNumbers(tableData);
  if (checkedKeys.length === 0) {
    setCheckedKeys([0]);
  }

  return (
    <Table
      className="w-full border"
      loading={isLoading}
      data={unitData}
      height={88}
      locale={{emptyMessage: <div className="flex pl-6 italic font-lato text-[16px]">No Record(s) Found</div>, loading: "Loading"}}
    >
      <Column width={50} align="center">
        <HeaderCell
          style={{ padding: 0, backgroundColor: "#E9ECF0" }}
          className="font-lato font-medium text-[16px] text-[#25282B] text-center"
        >
          <div
            style={{ lineHeight: "40px", backgroundColor: "#E9ECF0" }}
            className="font-lato font-medium text-[16px] text-[#25282B] text-center"
          ></div>
        </HeaderCell>
        <Cell>
          {(rowData, rowIndex) => {
            return (
              <RadioCell
                dataKey="id"
                onChange={handleCheck}
                rowData={rowData}
                checkedValue={checkedKeys[0]}
                componentID={`unitGrid_select${rowIndex}`}
              />
            );
          }}
        </Cell>
      </Column>
      <Column flexGrow={5}>
        <HeaderCell
          style={{
            backgroundColor: "#E9ECF0",
            display: "flex",
            alignItems: "center",
          }}
          className="font-lato font-medium text-[16px] text-[#25282B]"
        >
          Status
        </HeaderCell>
        <Cell>
          {(rowData, rowIndex) =>{
            return <span className="font-lato font-medium text-[16px] text-[#25282B]">{rowData.status}</span>
          }}
        </Cell>
      </Column>
      <Column flexGrow={15}>
        <HeaderCell
          style={{
            backgroundColor: "#E9ECF0",
            display: "flex",
            alignItems: "center",
          }}
          className="font-lato font-medium text-[16px] text-[#25282B]"
        >
          <span className="gap-2 flex justify-center items-center">
            Unit Number
          </span>
        </HeaderCell>
        <Cell
          dataKey="email"
          className="font-lato font-medium text-[16px] text-[#25282B]"
        >
         {(rowData, rowIndex) =>{
           return <span className="font-lato font-medium text-[16px] text-[#006CDB] cursor-pointer" onClick={() => {sendDataToNextComponent(rowData?.data); showModal();}}>{rowData?.data}</span>
         }}
        </Cell>
      </Column>
      <Column width={75} align="center">
        <HeaderCell
          style={{
            backgroundColor: "#E9ECF0",
            display: "flex",
            alignItems: "center",
          }}
          className="font-lato font-medium text-[16px] text-[#25282B]"
        ></HeaderCell>
        <Cell>
          {(rowData, rowIndex) => {
            return (
              <PencilIcon
                id={`unitGrid_edit${rowIndex}`}
                onClick={() => editUnit(rowData.id)}
              />
            );
          }}
        </Cell>
      </Column>
    </Table>
  );
}

export default UnitGrid;