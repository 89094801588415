/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Table } from "rsuite";
import { PencilIcon, DeleteIcon } from "../../../assets/";
import {
  LinkButton,
  SlideDrawer,
  ModalWrapper,
  Button,
} from "../../../components";
import { QuoteApproverForm } from "../../form/Index";
import formatPhoneNumber from "../../../utils/formatPhoneNumber";
import { httpAPICall } from "../../../utils";
import { USERS_API_URL } from "../../../constants";
const { Column, HeaderCell, Cell } = Table;

const ConfirmModal = ({ openModal, closeModal, handleDelete }) => {
  return (
    <ModalWrapper openModal={openModal} closeModal={closeModal}>
      <main className="flex justify-center items-center flex-col space-y-[50px] py-[44px] w-[506px]">
        <section className="font-inter text-[24px] font-medium text-[#1B2B41B8] text-center">
          Are you sure you want to delete
          <br />a Quote Approver?
        </section>
        <section className="flex justify-center w-full space-x-[70px]">
          <Button
            componentID={"confirmModal_btnClose"}
            buttonText={"Close"}
            theme={"tertiary-without-border"}
            onClick={closeModal}
          />
          <Button
            buttonText={"Yes"}
            componentID={"confirmModal_btnYes"}
            theme={"primary-fixed-width"}
            onClick={handleDelete}
          />
        </section>
      </main>
    </ModalWrapper>
  );
};

function QuoteApproverGrid({ tableData, onSelect, editUnit }) {
  const [isQuoteApproverDrawer, setIsQuoteApproverDrawer] = useState(false);
  const [quoteApproverDrawerMode, setQuoteApproverDrawerMode] =
    useState("create");
  const [isDelete, setIsDelete] = useState(true);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [quoteApprover, setQuoteApprover] = useState("");
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [deleteQuoteApproverData, setDeleteQuoteApproverData] = useState("");
  const [isRegisteredQuoteApprover, setIsRegisteredQuoteApprover] = useState(tableData?.is_registered)

  const fetchUsers = async () => {
    setLoading(true);
    const users = await httpAPICall(
      USERS_API_URL +
        `?customer_ids=${tableData.id}&access_level_type=Company&status=Active`,
      "GET",
      {}
    );
    if (users && users.length === 1) {
      setIsDelete(false);
    } else if (users && users.length > 1) {
      setIsDelete(true);
    }
    setUsers(users);
    setLoading(false);
  };

  const editQuoteApprover = (quoteApprover) => {
    setQuoteApprover(quoteApprover);
    setQuoteApproverDrawerMode("edit");
    setIsQuoteApproverDrawer(true);
  };

  const deleteQuoteApprover = async (quoteApprover) => {
    setLoading(true);
    const payload = {
      id: quoteApprover.id,
      first_name: quoteApprover.first_name,
      last_name: quoteApprover.last_name,
      email: quoteApprover.email,
      status: "Deleted",
      enabled: quoteApprover.is_enable,
      phone_number: quoteApprover.phone_number,
      customer: {
        id: tableData.id,
      },
      access_level: {
        type: "Company",
      },
    };
    try {
      await httpAPICall(
        USERS_API_URL + `/${quoteApprover?.id}`,
        "PUT",
        payload,
        "",
        "Quote Approver"
      );
      fetchUsers();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleQuoteApprover = async (data, action, quoteApproverID) => {
    setLoading(true);
    try {
      if (action === "create") {
        await httpAPICall(USERS_API_URL, "POST", data, "", "Quote Approver");
      } else {
        await httpAPICall(
          USERS_API_URL + `/${quoteApproverID}`,
          "PUT",
          data,
          "",
          "Quote Approver"
        );
      }
      fetchUsers();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
    setIsQuoteApproverDrawer(false);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <>
      <div className="flex flex-col gap-2">
        <Table
          className="w-full border-2"
          loading={loading}
          data={users}
          rowClassName={
            "border-b-[1px] flex align-center border-solid border-[#C8C8C8]"
          }
          height={users && users?.length > 1 ? 132 : 88}
          locale={{
            emptyMessage: (
              <div className="flex pl-6 italic font-lato text-[16px]">
                No Record(s) Found
              </div>
            ),
            loading: "Loading",
          }}
        >
          <Column flexGrow={25}>
            <HeaderCell
              style={{
                backgroundColor: "#E9ECF0",
                display: "flex",
                alignItems: "center",
                paddingLeft: 30,
              }}
              className="font-lato font-medium text-[16px] text-[#25282B]"
            >
              Quote Approver Name
            </HeaderCell>
            <Cell style={{ paddingLeft: 30 }}>
              {(rowData, rowIndex) => {
                return (
                  <span className="font-lato font-medium text-[16px] text-[#25282B]">
                    {rowData?.full_name ? rowData?.full_name : "-"}
                  </span>
                );
              }}
            </Cell>
          </Column>
          <Column flexGrow={25}>
            <HeaderCell
              style={{
                backgroundColor: "#E9ECF0",
                display: "flex",
                alignItems: "center",
              }}
              className="font-lato font-medium text-[16px] text-[#25282B]"
            >
              <span className="gap-2 flex justify-center items-center">
                Quote Approver Phone
              </span>
            </HeaderCell>
            <Cell
              dataKey="email"
              className="font-lato font-medium text-[16px] text-[#25282B]"
            >
              {(rowData, rowIndex) => {
                return (
                  <span className="font-lato font-medium text-[16px] text-[#25282B]">
                    {rowData?.phone_number
                      ? formatPhoneNumber(rowData?.phone_number)
                      : "-"}
                  </span>
                );
              }}
            </Cell>
          </Column>
          <Column flexGrow={25}>
            <HeaderCell
              style={{
                backgroundColor: "#E9ECF0",
                display: "flex",
                alignItems: "center",
              }}
              className="font-lato font-medium text-[16px] text-[#25282B]"
            >
              <span className="gap-2 flex justify-center items-center">
                Quote Approver Email
              </span>
            </HeaderCell>
            <Cell
              dataKey="email"
              className="font-lato font-medium text-[16px] text-[#25282B]"
            >
              {(rowData, rowIndex) => {
                return (
                  <span className="font-lato font-medium text-[16px] text-[#25282B]">
                    {rowData?.email ? rowData?.email : "-"}
                  </span>
                );
              }}
            </Cell>
          </Column>
          <Column width={50} align="center">
            <HeaderCell
              style={{
                backgroundColor: "#E9ECF0",
                display: "flex",
                alignItems: "center",
              }}
              className="font-lato font-medium text-[16px] text-[#25282B]"
            ></HeaderCell>
            <Cell>
              {(rowData, rowIndex) => {
                return ( !isRegisteredQuoteApprover ? (
                  <PencilIcon
                    id={`unitGrid_edit${rowIndex}`}
                    onClick={() => editQuoteApprover(rowData)}
                  />
                ) : (
                <></>
                )
                );
              }}
            </Cell>
          </Column>
          <Column width={80} align="center">
            <HeaderCell
              style={{
                backgroundColor: "#E9ECF0",
                display: "flex",
                alignItems: "center",
              }}
              className="font-lato font-medium text-[16px] text-[#25282B]"
            ></HeaderCell>
            <Cell>
              {(rowData, rowIndex) => {
                return (isDelete && !isRegisteredQuoteApprover) ? (
                  <DeleteIcon
                    id={`unitGrid_delete${rowIndex}`}
                    onClick={() => {
                      setIsConfirmModal(true);
                      setDeleteQuoteApproverData(rowData);
                    }}
                  />
                ) : (
                  <></>
                );
              }}
            </Cell>
          </Column>
        </Table>
        {!isRegisteredQuoteApprover && (
          <span className="flex justify-end">
            <LinkButton
              label={"Add Quote Approver"}
              componentID={"quoteApproverForm_linkQuoteApprover"}
              onClick={() => {
                setIsQuoteApproverDrawer(true);
                setQuoteApproverDrawerMode("create");
              }}
            />
          </span>
        )}
      </div>
      <SlideDrawer
        label={
          quoteApproverDrawerMode === "create"
            ? "Add New Quote Approver"
            : "Edit Quote Approver"
        }
        openDrawer={isQuoteApproverDrawer}
        componentID={"renovationSetup_quoteApproverDrawer"}
        setOpenDrawer={() => setIsQuoteApproverDrawer(false)}
      >
        <QuoteApproverForm
          passDataToNextSection={handleQuoteApprover}
          mode={quoteApproverDrawerMode}
          handleCancel={() => setIsQuoteApproverDrawer(false)}
          quoteApprover={quoteApprover}
          customerID={tableData?.id}
          isLoading={loading}
        />
      </SlideDrawer>
      <ConfirmModal
        openModal={isConfirmModal}
        closeModal={() => setIsConfirmModal(false)}
        handleDelete={() => {
          deleteQuoteApprover(deleteQuoteApproverData);
          setIsConfirmModal(false);
        }}
      />
    </>
  );
}

export default QuoteApproverGrid;
