import React from "react";
import { formatPrice, calculateFloorplanSubtotals } from "../../utils";

const QuoteMatrixByFloorplan = ({
  headers,
  rows,
  floorPlanKey,
  inspectionList,
  categoryKey,
  mode,
}) => {
  let subtotalsByFloorplan = {};
  let rightAlignColumns = ["Qty", "Unit Price", "Ext Price"];
  subtotalsByFloorplan = calculateFloorplanSubtotals(
    rows,
    floorPlanKey,
    inspectionList,
    mode,
    subtotalsByFloorplan
  );

  return (
    <div className="w-full overflow-x-auto">
      <section className="py-3 pl-[2.43rem] w-full bg-[#ECF5FF] font-inter text-base font-medium text-[#383838]">
        {categoryKey || ""}
      </section>
      <table className="w-full">
        <thead className="bg-[#F5F6F6] ">
          <tr className="w-full">
            {headers.map((header, index) => (
              <th
                key={index}
                className="font-lato text-[#383838] text-sm font-bold text-left py-3 space-x-[60px] px-2"
                style={{
                  textAlign: rightAlignColumns.includes(header)
                    ? "right"
                    : undefined,
                }}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="w-full overflow-x-auto">
          {rows?.map((record, index) => {
            return (
              record?.inspectionUnit?.floorplan?.name ===
                floorPlanKey &&
              record?.lineItemType === categoryKey && (
                <tr
                  key={index}
                  className="py-3 font-lato text-[#383838] text-sm font-medium text-center border"
                >
                  <td className="py-3 px-2 text-left break-allflex flex justify-center items-center">
                    <img
                      src={record?.lineItemAdditionalInfo?.product_image_url}
                      alt="Product"
                      className="w-[2.9375rem] h-[2.9375rem]"
                    />
                  </td>
                  <td className="py-3 px-2 text-left break-all">{index + 1}</td>
                  {record.lineItemSku ? (
                    <td
                      className="py-3 px-2 text-left break-all"
                      title={record.lineItemSku}
                    >
                      {record.lineItemSku.length > 6
                        ? record.lineItemSku.slice(0, 6) + "..."
                        : record?.lineItemSku}
                    </td>
                  ) : (
                    <td className="py-3 px-2 text-left break-all">-</td>
                  )}

                  {record?.lineItemName ? (
                    <td
                      className="py-3 px-2 text-left break-all w-[240px] cursor-pointer"
                      title={record.lineItemName}
                    >
                      {record.lineItemName.length > 60
                        ? record.lineItemName.slice(0, 60) + "..."
                        : record?.lineItemName}
                    </td>
                  ) : (
                    <td className="py-3 px-2 text-left break-all">-</td>
                  )}

                  {record?.supplier ? (
                    <td
                      className="py-3 px-2 text-left break-all cursor-pointer"
                      title={record.supplier?.name}
                    >
                      {record.supplier?.name}
                    </td>
                  ) : (
                    <td className="py-3 px-2 text-left break-all">-</td>
                  )}

                  {record?.lineItemQuantity ? (
                    <td className="py-3 px-2 text-right break-all">
                      {Number(record?.lineItemQuantity)}
                    </td>
                  ) : (
                    <td className="py-3 px-2 text-left break-all">-</td>
                  )}

                  <td className="px-4 py-3 text-right break-all">
                    {record.lineItemPrice
                      ? `${formatPrice(Number(record.lineItemPrice))}`
                      : `$0.00`}
                  </td>

                  {record?.lineItemUom ? (
                    <td className="py-3 px-2 text-left break-all">
                      {record.lineItemUom}
                    </td>
                  ) : (
                    <td className="py-3 px-2 text-left break-all">-</td>
                  )}

                  <td className="px-4 py-3 text-right break-all">
                    {record?.rowExistingPrice
                      ? `${formatPrice(Number(record?.rowExistingPrice))}`
                      : `$0.00`}
                  </td>

                </tr>
              )
            );
          })}
        </tbody>
        <tbody>
          <tr className="py-3 pl-[2.43rem] w-[82.6875rem] bg-[#C8C8C8] font-inter text-base font-medium text-[#383838]">
            <td colSpan={7}></td>
            <td className="py-3 px-2 font-inter text-base not-italic font-bold text-[#383838]">
              Sub Total
            </td>
            <td className="font-lato text-[18px] not-italic  text-[#383838] text-right font-bold px-2">
              {subtotalsByFloorplan[floorPlanKey]
                ? "$" +
                  formatPrice(
                    Number(subtotalsByFloorplan[floorPlanKey][categoryKey])
                  )
                : "-"}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default QuoteMatrixByFloorplan;
