function generateDropdownOptions(
  payload,
  label,
  value,
  additional_info_required
) {
  if (!Array.isArray(payload)) {
    // Handle the case when payload is not an array
    return [];
  }

  return payload.map((data) => {
    if (typeof data === "object") {
      if (additional_info_required) {
        return {
          name: data[label],
          id: data[value],
          additional_info: data,
        };
      } else {
        return {
          name: data[label],
          id: data[value],
        };
      }
    } else {
      // Handle the case when data is not an object
      return {};
    }
  });
}

export default generateDropdownOptions;
