const AccordionStyles = {
    "primaryButton"            : "w-[29px] h-[29px] rounded-full bg-[#ECF5FF] font-lato font-semibold text-[14px] leading-[20px] text-[#1A46D9] flex justify-center items-center",
    "secondaryButton"          : "w-[29px] h-[29px] rounded-full border border-[#C8C8C8] bg-[#006CDB] font-lato font-semibold text-[14px] leading-[20px] flex justify-center items-center",
    "tertiaryButton"           : "w-[29px] h-[29px] rounded-full border border-[#C8C8C8] bg-[#ECF5FF] font-lato font-semibold text-[14px] leading-[20px] text-[#6A6A6A] flex justify-center items-center",
    "primaryAccordion"         : "bg-[#006CDB] px-[30px] text-white h-[67px]",
    "secondaryAccordion"       : "bg-[#ECF5FF] px-[30px] text-[#5C5C5C] h-[67px]",
    "content"                  : "border rounded-b-[8px]",
    "noContent"                : "border rounded-b-[8px] px-[50px] py-[30px]",
    "boxContainer"             : "font-lato font-semibold text-base leading-[20px]",
    "numberContainer"          : "flex space-x-5 items-center",
    "accordionIconContainer"   : "text-white text-4xl",
}

export default AccordionStyles;