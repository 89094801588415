import React, { forwardRef, useEffect, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";
import AccordionStyles from "../../../styles/components/accordion";
import { ToggleButton } from "../../index"

const AccordionSlider = forwardRef(({ number, name, children, collapsible, mode, content, onClick, isDisabled, isToggle, isOpen, onButtonClick, componentID, toggleState}, ref) => {
  const contentStyles =  content !== undefined ? AccordionStyles.content : AccordionStyles.noContent;
  const [isExpanded, setIsExpanded] = useState(collapsible === 0 ? true : false);

  const handleAccordionClick = (event) => {
    onButtonClick && onButtonClick(event);
  };

  const toggle = (event, newToggleState) => {
    event.stopPropagation();
    toggleState(newToggleState);
  };

  useEffect(() => {
    setIsExpanded(isOpen === 0 ? true : false);
  }, [isOpen]);

  return (
    onButtonClick ?
    <Accordion defaultIndex={[collapsible]} index={[isOpen]} allowMultiple onClick={onClick} ref={ref}>
      <AccordionItem isDisabled={isDisabled}>
        <h2>
          <AccordionButton className={mode === "inactive" ? AccordionStyles.secondaryAccordion : AccordionStyles.primaryAccordion} id={componentID} onClick={handleAccordionClick}>
            <Box
              as="span"
              flex="1"
              textAlign="left"
              className={AccordionStyles.boxContainer}
            >
              <div className={AccordionStyles.numberContainer}>
                { number && <span className={mode === "active" ? AccordionStyles.primaryButton : mode === "inprogress" ? AccordionStyles.secondaryButton : AccordionStyles.tertiaryButton}>
                  {number}
                </span> }
                <span>{name}</span>
              </div>
            </Box>
            { isToggle && isExpanded && 
            <ToggleButton firstValue="Floorplan" secondValue="Building" onToggle={toggle}/>}
            <AccordionIcon className={AccordionStyles.accordionIconContainer} />
          </AccordionButton>
        </h2>
        <AccordionPanel className={contentStyles}>
         {isExpanded && children}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
    : 
    <Accordion defaultIndex={[collapsible]} allowMultiple  >
      <AccordionItem isDisabled={isDisabled}>
        <h2>
          <AccordionButton className={mode === "inactive" ? AccordionStyles.secondaryAccordion : AccordionStyles.primaryAccordion} id={componentID}>
            <Box
              as="span"
              flex="1"
              textAlign="left"
              className={AccordionStyles.boxContainer}
            >
              <div className={AccordionStyles.numberContainer}>
                { number && <span className={mode === "active" ? AccordionStyles.primaryButton : mode === "inprogress" ? AccordionStyles.secondaryButton : AccordionStyles.tertiaryButton}>
                  {number}
                </span> }
                <span>{name}</span>
              </div>
            </Box>
            { isToggle && isExpanded && 
            <ToggleButton firstValue="Floorplan" secondValue="Building" onToggle={toggle}/>}
            <AccordionIcon className={AccordionStyles.accordionIconContainer} />
          </AccordionButton>
        </h2>
        <AccordionPanel className={contentStyles}>
         {isExpanded && children}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
});

export default AccordionSlider;