import React, { useState, useEffect } from "react";
import { Table } from "rsuite";
import { RadioCell, Button, ToolTip } from "../../components";
import { statusMappings } from "../../utils/statusMappings";
import { useNavigate } from "react-router-dom";
const { Column, HeaderCell, Cell } = Table;


function QuoteMatrixGrid({
  tableData,
  onSelect,
  selectedOption,
  isLoading,
  sendUsage,
  handleExpiredQuote,
  actionButtons,
  handleCreditAppLink
}) {
  const navigate = useNavigate();
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [loading, setLoading] = useState(isLoading);

  const handleCheck = (value) => {
    setCheckedKeys([value]);
    const selectedQuote = tableData?.find((quote) => quote.id === value);
    selectedQuote && onSelect(selectedQuote);
  };

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  return (
    <Table
      loading={loading}
      className="w-full border"
      data={tableData}
      height={tableData && tableData?.length > 1 ? 400 : 100}
      rowHeight={60}
      locale={{
        emptyMessage: (
          <div className="flex pl-6 italic font-lato text-[16px]">
            No Record(s) Found
          </div>
        ),
        loading: "Loading",
      }}
    >
      <Column width={50} align="center">
        <HeaderCell
          style={{ padding: 0, backgroundColor: "#E9ECF0" }}
          className="font-lato font-medium text-[16px] text-[#25282B] text-center"
        >
          <div
            style={{ lineHeight: "40px", backgroundColor: "#E9ECF0" }}
            className="font-lato font-medium text-[16px] text-[#25282B] text-center"
          ></div>
        </HeaderCell>
        <Cell>
          {(rowData, rowIndex) => {
            return (
              <RadioCell
                onChange={handleCheck}
                rowData={rowData}
                checkedValue={checkedKeys[0]}
                selectedOption={selectedOption}
                componentID={`quoteMatrixGrid_select${rowIndex}`}
              />
            );
          }}
        </Cell>
      </Column>

      <Column width={100}>
        <HeaderCell
          style={{
            backgroundColor: "#E9ECF0",
            display: "flex",
            alignItems: "center",
          }}
          className="font-lato font-medium text-[16px] text-[#25282B] text-center"
        >
          <span className="gap-2 flex justify-center items-center">
            QuoteM#
          </span>
        </HeaderCell>
        <Cell>
          {(rowData, rowIndex) => {
            return (
              <span
                className="font-lato font-medium text-[16px] text-[#006CDB] cursor-pointer underline underline-offset-4"
                onClick={() => {
                  navigate(`/quote-matrix/${rowData.id}`);
                }}
              >
                {rowData.id ? rowData.id : "-"}
              </span>
            );
          }}
        </Cell>
      </Column>
      <Column flexGrow={20}>
        <HeaderCell
          style={{
            backgroundColor: "#E9ECF0",
            display: "flex",
            alignItems: "center",
          }}
          className="font-lato font-medium text-[16px] text-[#25282B]"
        >
          <span className="gap-2 flex justify-center items-center">
            Quote Matrix Title
          </span>
        </HeaderCell>
        <Cell>
          {(rowData, rowIndex) => {
            return (
              <span className="font-lato font-medium text-[16px] text-[#25282B]">
                <ToolTip
                  text={rowData?.title ? rowData?.title : "-"}
                  mode="quote"
                  maxLength={45}
                ></ToolTip>
              </span>
            );
          }}
        </Cell>
      </Column>
      <Column flexGrow={15}>
        <HeaderCell
          style={{
            backgroundColor: "#E9ECF0",
            display: "flex",
            alignItems: "center",
          }}
          className="font-lato font-medium text-[16px] text-[#25282B]"
        >
          <span className="gap-2 flex justify-center items-center">
            Property
          </span>
        </HeaderCell>
        <Cell>
          {(rowData, rowIndex) => {
            if (rowData?.property && Array.isArray(rowData.property)) {
              const propertyNames = rowData.property.map((prop) => prop.name);
              return (
                <span className="font-lato font-medium text-[16px] text-[#25282B]" title={propertyNames}>
                  {propertyNames}
                </span>
              );
            }
            return "-";
          }}
        </Cell>
      </Column>
      <Column flexGrow={15}>
        <HeaderCell
          style={{
            backgroundColor: "#E9ECF0",
            display: "flex",
            alignItems: "center",
          }}
          className="font-lato font-medium text-[16px] text-[#25282B]"
        >
          <span className="gap-2 flex justify-center items-center">
            Quote Approver Name
          </span>
        </HeaderCell>
        <Cell>
          {(rowData, rowIndex) => {
            return (
              <span className="font-lato font-medium text-[16px] text-[#25282B]">
                {rowData.to_be_approved_by ? rowData.to_be_approved_by?.full_name : "-"}
              </span>
            );
          }}
        </Cell>
      </Column>
      <Column flexGrow={10}>
        <HeaderCell
          style={{
            backgroundColor: "#E9ECF0",
            display: "flex",
            alignItems: "center",
          }}
          className="font-lato font-medium text-[16px] text-[#25282B]"
        >
          <span className="gap-2 flex justify-center items-center">Status</span>
        </HeaderCell>
        <Cell>
          {(rowData, rowIndex) => {
            return statusMappings[rowData?.status] === "Expired" ? (
              <span
                onClick={() => handleExpiredQuote(rowData)}
                className="font-lato font-medium text-[16px] underline underline-offset-4 cursor-pointer text-[#25282B]"
              >
                {rowData?.status ? statusMappings[rowData?.status] : "-"}
              </span>
            ) : (
              <span className="font-lato font-medium text-[16px] text-[#25282B]">
                {rowData?.status ? statusMappings[rowData?.status] : "-"}
              </span>
            );
          }}
        </Cell>
      </Column>
      <Column width={460}>
        <HeaderCell
          style={{
            backgroundColor: "#E9ECF0",
            display: "flex",
            alignItems: "center",
          }}
          className="font-lato font-medium text-[16px] text-[#25282B]"
        >
          Action
        </HeaderCell>
        <Cell>
          {(rowData, rowIndex) => {
            const isContracted = actionButtons.find(obj => obj.id === rowData.id);
            return (
              <div className="flex items-center font-lato font-medium text-[16px] text-[#25282B] pr-[20px] space-x-5">
                <Button
                  buttonText={isContracted ? isContracted.sendUsageButton : "Send Usage"}
                  theme={isContracted
                    ? "secondary-small-fixed-width"
                    : "secondary-with-disabled"
                  }
                  disabled={
                    isContracted
                      ? false
                      : true
                  }
                  onClick={() => {
                    sendUsage(rowData)
                  }}
                />
                <Button
                  buttonText={isContracted ? isContracted.creditAppButton : "Send Credit App Link"}
                  theme={
                    isContracted?.creditAppButton === "Completed" ? "secondary-large-fixed-width-disable"
                      : isContracted ? "secondary-large-fixed-width" : "secondary-with-disabled"
                  }
                  disabled={isContracted ? isContracted.setDisable : true}
                  onClick={() => handleCreditAppLink(rowData.id)}
                />
              </div>
            );
          }}
        </Cell>
      </Column>
    </Table>
  );
}

export default QuoteMatrixGrid;
