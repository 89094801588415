import all from "axios";
import { webStore } from "../../redux";

var axiosObject = all.create();

axiosObject.defaults.baseURL = process.env.REACT_APP_FACILGO_API_URL;

axiosObject.defaults.headers.common = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

axiosObject.interceptors.request.use(
  async function (config) {
    const token = webStore.getState().auth.accessToken;
    if (token === "") {
      if (process.env.REACT_APP_ENV === "staging")
        config.headers["Authorization"] = `Basic bmFzaXBhZGFuZzpldmVyeTEhZGF5`;
    } else {
      config.headers["Authorization"] = `Bearer ${
        webStore.getState().auth.accessToken
      }`;
    }
    config["withCredentials"] = true;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosObject.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export { axiosObject };
