import { Simple as SimpleAlert } from "@reusejs/react-alerts";

function Toast(message, mode, duration) {
    return SimpleAlert({
        message: message,
        borderClasses: mode === "error" ? "border-b-2 border-red-400" : "border-b-2 border-green-400",
        wrapperClasses: "inset-x-0 left-0 bottom-[75px] flex w-full z-[1000]",
        alignmentClasses: "w-full items-start",
        duration: 10000
      });
  }

export default Toast;