import React from "react";
import HeadingWrapperStyles from "../../../styles/components/headingWrapper";

function HeadingWrapper({ children }) {
  return (
    <div className={HeadingWrapperStyles.container}>
      {children}
    </div>
  );
}

export default HeadingWrapper;
