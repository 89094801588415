const DropdownFieldStyles = {
    "primaryStyles"         :  "bg-[#1a38601a] pl-[22px] h-[38px] rounded-[3px] border border-[#D0D0D0] outline-none font-lato font-medium text-[13px] leading-[15.6px] placeholder:text-[#9CA0A7]",
    "secondaryStyles"       :  "bg-[#FFFFFF] pl-[22px] h-[48px] rounded-[3px] border border-[#D0D0D0] text-black outline-none font-lato font-medium text-[14px] leading-[15.6px] placeholder:text-[#9CA0A7]",
    "tertiaryStyles"        :  "bg-[#FFFFFF] h-[48px] rounded-[3px] border border-[#EC0D0D] border-opacity-70 outline-none font-lato font-medium text-[13px] leading-[15.6px] placeholder:text-[#9CA0A7]",
    "quaternaryStyles"      :  "bg-[#FFFFFF] pl-[22px] h-[48px] rounded-[3px] border border-[_rgba(200, 200, 200, 1)] text-[#c8c8c8] border-opacity-70 outline-none font-lato font-medium text-[14px] leading-[15.6px] placeholder:text-[_rgba(200, 200, 200, 1)]",
    "mandatory"             :  "text-[#EC0D0D]",
    "disabled"              : "font-inter font-medium text-[14px] leading-[17px] text-[#c8c8c8]",
    "pageErrorLabelBottom"  :  "flex justify-end text-[14px] font-medium leading-[17px] text-[#EC0D0D]",
    "label"                 :  "font-inter font-medium text-[14px] leading-[17px] text-[_rgba(27,43,65,0.72)]",
    "container"             :  "flex flex-col space-y-3",
}

export default DropdownFieldStyles;