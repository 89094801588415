import { createSlice } from "@reduxjs/toolkit";

export const createQuoteMatrix = createSlice({
  name: "quoteMatrixCalculation",
  initialState: {
    quoteMatrices: {},
  },
  reducers: {
    setCreateQuoteMatrix: (state, action) => {
      state.quoteMatrices = action.payload.quoteMatrices;
    },
  },
});

export const { setCreateQuoteMatrix } = createQuoteMatrix.actions;

export default createQuoteMatrix.reducer;
