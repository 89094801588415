/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Tabs,
  YesOrNo,
  LineItemCard,
  Heading,
  LineItemProductCard,
  SlideDrawer,
  Button,
  Footer,
  SearchInput,
  InspectionProgressBar,
  TransactionHistory,
  FilterProductForm,
  InputField,
  PriceInputField,
  QuantityMeasure,
  DropdownField,
} from "../../components";
import { TransactionHistoryIcon } from "../../assets";
import { useNavigate } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { GrLocation } from "react-icons/gr";
import { httpAPICall, checkDefaultStatus, uom, isEmpty } from "../../utils";
import { Simple as SimpleAlert } from "@reusejs/react-alerts";
import { INSPECTIONS_API_URL, PRODUCTS_API_URL } from "../../constants";
import { LuFilter } from "react-icons/lu";
import { Loader } from "rsuite";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { setPerformInspections } from "../../redux/slice/completeInspection";
import LineItemCardStyles from "../../styles/components/inspectionLineItemCard";
import InspectionViewStyles from "../../styles/pages/inspectionView";
const productsArray = [];

function ViewInspection() {
  const { inspections } = useSelector(
    (completeInspection) => ({
      inspections: completeInspection?.inspections,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(null);
  const [inspectionRecord, setInspectionRecord] = useState({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const [searchProducts, setSearchProducts] = useState("");
  const [existingFilterProduct, setExistingFilterProduct] = useState({});
  const [isFilterProducts, setIsFilterProducts] = useState(false);
  const [existingProducts, setExistingProducts] = useState([]);
  const [isLoadProducts, setIsLoadProducts] = useState(false);
  const [loadScreen, setLoadScreen] = useState(false);
  const [products, setProducts] = useState([]);
  const [lineItems, setLineItems] = useState([]);
  const [checkLineItem, setCheckLineItem] = useState(null);
  const [checkAll, setCheckAll] = useState({});
  const [lineItemId, setLineItemId] = useState("");
  const [enableCompletion, setEnableCompletion] = useState(
    "completion_disabled"
  );
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [isDisableDefaultStatus, setIsDisableDefaultStatus] = useState(false);
  const [productManualSearchValue, setProductManualSearchValue] = useState("");
  const [title, setTitle] = useState("");
  const [sku, setSku] = useState("");
  const [ea, setEa] = useState({ id: "EA", name: "EA (Each)" });
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState("");
  const [lineItemLabel, setLineItemLabel] = useState("");
  const [lineItemNotes, setLineItemNotes] = useState("");
  const [lineItemProducts, setLineItemProducts] = useState([]);
  const [lineItemProductQty, setLineItemProductQty] = useState("");
  const [lineItemMeasurement, setLineItemMeasurement] = useState("");
  const [reloadInspection, setReloadInspection] = useState(false);
  const [removeProducts, setRemoveProducts] = useState(false);
  const [enableCheckAll, setEnableCheckAll] = useState("");
  const [defaultQuantity, setDefaultQuantity] = useState(1);
  const [isUOMError, setIsUOMError] = useState(false);
  let lineItemArray = [];

  const fetchInspectionDetails = async () => {
    setLoadScreen(true);
    const result = await httpAPICall(`${INSPECTIONS_API_URL}/${id}`, "GET", {});
    result?.line_item_list?.forEach((item) => {
      if (item?.type === null || "") {
        return (item.type = "General");
      }
    });
    result?.line_item_list?.map((item) => {
      let payload = {
        tab_type: item?.type,
        id: result?.id,
        line_item_id: item?.id,
        list_item_id: item?.list_item ? false : true,
        api_call: result?.status === "Completed" ? true : false,
        default_status: null,
        type: item?.type,
        name: result?.name,
        line_item_name: item?.name,
        uom: item?.uom,
        measurements: item?.measurements?.dimensions
          ? { dimensions: item?.measurements?.dimensions }
          : { dimensions: null },
        status: item?.status || "Pending",
        customer: {
          id: result?.customer?.id,
        },
        notes: item?.notes,
        mapped_item_list: item.mapped_item_list,
        picture_list: item?.picture_list,
      };
      return lineItemArray?.push(payload);
    });
    dispatch(setPerformInspections({ inspections: lineItemArray }));
    const groupedByType = _.groupBy(result?.line_item_list, "type");
    setLineItems(groupedByType);
    const currentActiveTab = activeTab || Object.keys(groupedByType)[0];
    setActiveTab(currentActiveTab);
    setInspectionRecord(result);
    setLoadScreen(false);
  };
  const fetchLineItemProduct = async (manualSearch) => {
    if (searchProducts !== null || undefined) {
      setIsLoadProducts(true);
      let defaultSearchUrl = `${PRODUCTS_API_URL}?q=${searchProducts}`;
      let manualSearchUrl = `${PRODUCTS_API_URL}?q=${productManualSearchValue}`;
      const result = await httpAPICall(
        manualSearch === "default_search" ? defaultSearchUrl : manualSearchUrl,
        "GET",
        {},
        process.env.REACT_APP_LOWES_KEY
      );
      setIsLoadProducts(false);
      setProducts(result);
    } else {
      return null;
    }
  };

  useEffect(() => {
    fetchInspectionDetails();
  }, []);

  useEffect(() => {
    if (openDrawer) {
      fetchLineItemProduct("default_search");
    }
  }, [searchProducts]);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (productManualSearchValue !== "" && openDrawer) {
        fetchLineItemProduct("manual_search");
      } else if (openDrawer) {
        fetchLineItemProduct("default_search");
      }
    }, 2000);
    return () => clearTimeout(debounceTimer);
  }, [productManualSearchValue]);

  useEffect(() => {
    let originalArray = inspections?.inspections;
    const completionStatus = originalArray.filter(
      (item) => item.status === "Major Issues" || item.status === "Fair"
    );
    let finalPercentage =
      (completionStatus.length / inspections?.inspections.length) * 100;
    setCompletionPercentage(Math.ceil(finalPercentage));
    return setEnableCompletion(checkDefaultStatus(originalArray));
  }, [inspections?.inspections, enableCompletion, completionPercentage]);

  const handleCompleteInspection = async (inspection_id) => {
    const checkLineItems = inspections?.inspections.some(
      (obj) => obj.status === "Major Issues"
    );
    if (checkLineItems) {
      await httpAPICall(
        `${INSPECTIONS_API_URL}/${inspection_id}/complete`,
        "POST",
        {},
        "",
        "",
        "Inspection Completed Successfully."
      );
      navigate("/inspections");
      let originalArray = inspections?.inspections;
      const finalArray = originalArray.map((item) => {
        return {
          ...item,
          api_call: true,
        };
      });
      dispatch(setPerformInspections({ inspections: finalArray }));
      setIsDisableDefaultStatus(true);
    } else {
      SimpleAlert({
        message: "Atleast one line item should be rated Yes.",
        borderClasses: "border-b-2 border-red-400",
        wrapperClasses: "inset-x-0 left-0 bottom-[75px] flex w-full z-[1000]",
        alignmentClasses: "w-full items-start",
      });

    }
  };
  const AddProdcutButtonDisabled = !(
    title !== "" &&
    sku !== "" &&
    quantity >= 1
  );

  const clearManualAddProductForm = () => {
    setTitle("")
    setSku("")
    setPrice("")
  }
  const handleManualAddProduct = async (productDetails) => {
    if (!AddProdcutButtonDisabled) {
      const originalArray = [...inspections?.inspections];
      for (const lineItem of originalArray) {
        if (lineItemId === lineItem?.line_item_id) {
          let requestPayload = {
            id: lineItem?.line_item_id,
            name: lineItem?.line_item_name,
            uom: ea?.id,
            measurements: lineItem?.measurements,
            type: lineItem?.tab_type,
            status: lineItem?.status,
            customer: {
              id: lineItem?.customer?.id,
            },
            notes: lineItem?.notes,
            mapped_item_list: productDetails,
          };
          try {
            const response = await httpAPICall(
              `${INSPECTIONS_API_URL}/${id}/items/${lineItemId}`,
              "PUT",
              requestPayload
            );
            if (response) {
              setOpenDrawer(true);
              fetchInspectionDetails()
              setLoadScreen(false)
            } else {
              console.error("Failed to save the product");
            }
          } catch (error) {
            console.error("Network error:", error);
          }
          let originalArray = inspections?.inspections;
          const finalArray = originalArray.map((item) => {
            return lineItemId === item?.line_item_id
              ? {
                ...item,
                mapped_item_list: [...item?.mapped_item_list, productDetails[0]],
              }
              : item;
          });
          dispatch(setPerformInspections({ inspections: finalArray }));
          setOpenDrawer(true);
        }
      }
    }
  };

  const handleUpdateInspectionItem = async (id, inspectionAPICall) => {
    let originalArray = inspections?.inspections;
    const finalArray = originalArray;

    finalArray?.map(async (lineItem) => {
      if (id === lineItem?.line_item_id) {
        let requestPayload = {
          id: lineItem?.line_item_id,
          name: lineItem?.line_item_name,
          uom: lineItem?.uom,
          measurements: lineItem?.measurements,
          type: lineItem?.tab_type,
          status: lineItem?.status,
          customer: {
            id: lineItem?.customer?.id,
          },
          notes: lineItem?.notes,
          mapped_item_list: [],
        };
        lineItem?.mapped_item_list?.map((product) => {
          let obj = {};
          if (id === lineItem?.line_item_id) {
            if (!isNaN(product?.id)) {
              obj["id"] = product?.id;
              obj["additional_info"] = {
                product_image_url:
                  product["picture_list"]?.length > 0 &&
                  product["picture_list"][0]?.url?.original,
              };
            }
            obj["name"] = product?.name || product?.title;
            obj["sku"] = product?.sku;
            obj["quantity"] = lineItemProductQty?.id === product?.id
              ? lineItemProductQty.quantity
              : product?.quantity || 1;
            obj["price"] = product?.price;
            obj["uom"] = product?.uom;
            obj["additional_info"] = {
              product_image_url:
                product["picture_list"]?.length > 0
                  ? product["picture_list"][0]?.url?.original
                  : product?.additional_info?.product_image_url,
            };
            requestPayload.mapped_item_list?.push(obj);
          }
          return null;
        });

        if (id === lineItem?.line_item_id) {
          let generateAPIUrl = `${INSPECTIONS_API_URL}/${inspectionRecord?.id}/items/${lineItem?.line_item_id}`;
          setIsLoadProducts(true)
          let saveLineItem = await httpAPICall(
            generateAPIUrl,
            "PUT",
            requestPayload,
            "",
            "",
            ""
          );
          if (saveLineItem?.id && inspectionAPICall === "call_inspection_api") {
            const result = await httpAPICall(
              `${INSPECTIONS_API_URL}/${inspectionRecord?.id}`,
              "GET",
              {}
            );
            result?.line_item_list?.forEach((item) => {
              if (item?.type === null) {
                return (item.type = "General");
              }
            });
            setIsLoadProducts(false)
            result?.line_item_list?.map((item) => {
              let payload = {
                tab_type: item?.type,
                id: result?.id,
                line_item_id: item?.id,
                api_call: result?.status === "Completed" ? true : false,
                default_status: lineItem?.default_status || null,
                type: item?.type,
                name: result?.name,
                line_item_name: item?.name,
                uom: item?.uom,
                measurements: item?.measurements,
                status: item?.status || "Pending",
                customer: {
                  id: result?.customer?.id,
                },
                notes: item?.notes,
                mapped_item_list: item.mapped_item_list,
                picture_list: item?.picture_list,
              };
              return lineItemArray?.push(payload);
            });
            dispatch(setPerformInspections({ inspections: lineItemArray }));
          }
        }
      }
    });
  };

  const voidInspection = async () => {
    let params = INSPECTIONS_API_URL + `/${inspectionRecord?.id}/void`;
    await httpAPICall(
      params,
      "DELETE",
      {},
      "",
      "",
      "Inspection Voided Successfully"
    );
    navigate("/inspections");
  };

  const handleChange = (event, setterFunction, setErrorFunction) => {
    const value = event.target.value;
    setterFunction(value);
    setErrorFunction(isEmpty(value));
  };

  const reopenInspection = async () => {
    let params = INSPECTIONS_API_URL + `/${inspectionRecord?.id}/reopen`;
    await httpAPICall(
      params,
      "PUT",
      {},
      "",
      "",
      "Inspection Reopened Successfully"
    );
    navigate("/inspections");
  };

  const checkAllLineItems = async () => {
    let originalArray = inspections?.inspections;
    const finalArray = originalArray.map((item) => {
      return activeTab === item?.tab_type
        ? {
          ...item,
          status:
            checkAll[activeTab] === "yes"
              ? "Major Issues"
              : checkAll[activeTab] === "no"
                ? "Fair"
                : "Pending",
          default_status:
            checkAll[activeTab] === "yes"
              ? "Major Issues"
              : checkAll[activeTab] === "no"
                ? "Fair"
                : "Pending",
        }
        : item;
    });
    dispatch(setPerformInspections({ inspections: finalArray }));
    await finalArray.map(async (item) => {
      activeTab === item?.tab_type &&
        (await handleUpdateInspectionItem(item?.line_item_id));
    });
  };

  useEffect(() => {
    if (enableCheckAll === "check_all") {
      checkAllLineItems();
    }
  }, [enableCheckAll, checkAll]);

  useEffect(() => {
    if (lineItemId) {
      handleUpdateInspectionItem(lineItemId, "call_inspection_api");
    }
  }, [
    lineItemLabel,
    lineItemNotes,
    checkLineItem,
    lineItemProducts,
    lineItemProductQty,
    lineItemMeasurement,
    lineItemId,
  ]);

  return loadScreen ? (
    <div className="flex items-center justify-center">
      <Loader content="Loading..." vertical />
    </div>
  ) : (
    <div>
      <div className="flex justify-between items-center">
        <span>
          <Heading
            heading={"Inspection Details"}
            componentID={"inspection_detailsLabel"}
          />
        </span>
        <a
          href="#transaction_history"
          className="text-[#006CDB] font-lato text-[1rem] non-italic font-semibold cursor-pointer hover:no-underline flex items-center space-x-[0.25rem]"
        >
          <TransactionHistoryIcon />
          Transaction History
        </a>
      </div>

      <section className={InspectionViewStyles.addressProgressBarContainer}>
        <div className={InspectionViewStyles.addressContainer}>
          <section
            className={InspectionViewStyles.propertyUnitLabel}
            id="inspection_detailsPropertyLabel"
          >
            {inspectionRecord?.property?.name}_
            {inspectionRecord?.property?.unit?.name}_
          </section>
          <section
            className={InspectionViewStyles.listInspectionDateLabel}
            id="inspection_detailsListDateLabel"
          >
            {inspectionRecord?.list?.name}_
            {inspectionRecord?.name?.split("_")?.pop()}
          </section>
          <section
            className={InspectionViewStyles.locationIconAddressContainer}
          >
            <span>
              <GrLocation className={InspectionViewStyles.locationIconStyles} />
            </span>
            <span
              className={InspectionViewStyles.addressDetailsLabel}
              id="inspection_detailsAddressLabel"
            >
              {inspectionRecord?.property?.address?.address
                ? inspectionRecord?.property?.address?.address
                : "N/A"}
            </span>
          </section>
        </div>
        <div>
          <InspectionProgressBar
            componentID={"inspection_detailsProgressBar"}
            count={inspections?.inspections?.length}
            percentage={
              inspectionRecord?.status !== "Completed"
                ? completionPercentage
                : 100
            }
            progressMessage={
              inspectionRecord?.status === "Cancelled"
                ? "Cancelled"
                : inspectionRecord?.status === "InProgress"
                  ? "InProgress"
                  : inspectionRecord?.status === "Completed"
                    ? "Completed"
                    : "InProgress"
            }
          />
        </div>
      </section>
      <section className="mt-[28px]">
        <Tabs
          componentID={"inspection_detailsProgressBar"}
          sendTabName={(val) => setActiveTab(val)}
          active={activeTab}
          tabs={lineItems}
        />
      </section>
      <section
        className={InspectionViewStyles.inspectionTypeDefaultStatusContainer}
      >
        <label
          className={InspectionViewStyles.inspectionTypeLargerStyles}
          id="inspection_detailsActiveTabLabel"
        >
          {activeTab}
        </label>
        {!isDisableDefaultStatus &&
          inspectionRecord?.status === "InProgress" && (
            <div>
              <YesOrNo
                componentID={"inspection_detailsYesOrNo"}
                label={"Set Default Status"}
                theme="primary"
                checked={checkAll[activeTab] ?? ""}
                handleChange={(e) => {
                  setCheckAll((prev) => {
                    const updatedCheckAll = { ...prev };
                    updatedCheckAll[activeTab] = e.target.value;
                    return updatedCheckAll;
                  });
                  let originalArray = inspections?.inspections;
                  const finalArray = originalArray.map((item) => {
                    return activeTab === item?.tab_type
                      ? {
                        ...item,
                        status:
                          e.target.value === "yes"
                            ? "Major Issues"
                            : e.target.value === "no"
                              ? "Fair"
                              : "Pending",
                        default_status:
                          e.target.value === "yes"
                            ? "Major Issues"
                            : e.target.value === "no"
                              ? "Fair"
                              : "Pending",
                      }
                      : item;
                  });
                  dispatch(setPerformInspections({ inspections: finalArray }));
                  setEnableCheckAll("check_all");
                }}
              />
            </div>
          )}
      </section>
      <section className={InspectionViewStyles.inspectionLineItemCardStyles}>
        {inspections?.inspections &&
          inspections?.inspections.map((item, index) => {
            return (
              item?.tab_type === activeTab && (
                <div key={index}>
                  <LineItemCard
                    lineItemDetails={item}
                    inspectionStatus={inspectionRecord?.status}
                    apiCall={item.api_call}
                    deleteLineItem={item?.list_item_id === null ? true : false}
                    searchProduct={(val, defaultQuantity) => {
                      setOpenDrawer(true);
                      setDefaultQuantity(defaultQuantity);
                      setSearchProducts(
                        `${val?.title} ${val?.notes} ${val?.measurement}`
                      );
                      setExistingFilterProduct({
                        title: val?.title,
                        notes: val?.notes,
                        measurement: val?.measurement,
                      });
                    }}
                    sendProductsToDrawer={(val) => {
                      setExistingProducts(item?.mapped_item_list);
                    }}
                    activeTab={activeTab}
                    handleCopyInspectionLineItem={() => {
                      fetchInspectionDetails()
                      setLoadScreen(false)
                    }
                    }
                    sendCheckedLineItem={(val) => {
                      setCheckLineItem(val);
                      let originalArray = inspections?.inspections;
                      const finalArray = originalArray.map((item) => {
                        return activeTab === item?.tab_type
                          ? {
                            ...item,
                            status:
                              val === "yes"
                                ? "Major Issues"
                                : val === "no"
                                  ? "Fair"
                                  : "Pending",
                          }
                          : item;
                      });
                      dispatch(
                        setPerformInspections({ inspections: finalArray })
                      );
                    }}
                    checkLineItem={checkLineItem ? "yes" : "no"}
                    checkAll={checkAll[activeTab] ?? false}
                    lineItemId={item?.line_item_id}
                    lineItemName={item?.line_item_name}
                    sendLineItemId={(val) => {
                      setLineItemId(val);
                    }}
                    sendFilterProductPayload={(val) => {
                      setSearchProducts(val);
                      setOpenDrawer(true);
                    }}
                    isFilterProducts={isFilterProducts}
                    setIsFilterProducts={setIsFilterProducts}
                    inspectionId={id}
                    sendLineItemLabel={(val) => {
                      setLineItemLabel(val);
                    }}
                    sendLineItemNotes={(val) => {
                      setLineItemNotes(val);
                    }}
                    sendLineItemMeasurement={(val) => {
                      setLineItemMeasurement(val);
                    }}
                    sendManualProductAutoSave={(val) => {
                      setLineItemProducts([val]);
                    }}
                    reloadInspection={(val) => {
                      setReloadInspection(val);
                      fetchInspectionDetails();
                      setReloadInspection(false);
                    }}
                    sendProductQty={(val) => {
                      setLineItemProductQty(val);
                      let obj = {};
                      let productsArray = [];
                      item?.mapped_item_list?.map((product) => {
                        if (val?.id === product?.id) {
                          if (!isNaN(product?.id)) {
                            obj["id"] = product?.id;
                            obj["additional_info"] = {
                              product_image_url:
                                product["picture_list"]?.length > 0 &&
                                product["picture_list"][0]?.url?.original,
                            };
                          }
                          obj["name"] = product?.name || product?.title;
                          obj["sku"] = product?.sku;
                          obj["quantity"] = val?.quantity;
                          obj["price"] = product?.price;
                          obj["uom"] = product?.uom;
                          obj["additional_info"] = {
                            product_image_url:
                              product["picture_list"]?.length > 0
                                ? product["picture_list"][0]?.url?.original
                                : product?.additional_info?.product_image_url,
                          };
                          return productsArray.push(obj);
                        }
                        return productsArray;
                      });
                      setLineItemProducts(productsArray);
                    }}
                  />
                </div>
              )
            );
          })}
        <SlideDrawer
          openDrawer={openDrawer}
          label={
            <div>
              <span className={InspectionViewStyles.resultsForLabelStyles}>
                Results for
              </span>
              <span className={InspectionViewStyles.searchedProductLabelStyles}>
                {searchProducts}
              </span>
            </div>
          }
          setOpenDrawer={() => {
            setOpenDrawer(false);
            fetchInspectionDetails()
            setSearchProducts("");
            setProductManualSearchValue("");
            setLoadScreen(false)
          }}
        >
          <section
            className={InspectionViewStyles.searchInputFilterLabelContainer}
          >
            <div className="w-[80%]">
              <SearchInput
                searchValue={productManualSearchValue}
                onChange={(e) => {
                  setProductManualSearchValue(e.target.value);
                  setTitle(e.target.value);
                }}
                placeholder="Search"
              />
            </div>
            <div
              className={InspectionViewStyles.filterIconLabelStyles}
              onClick={() => {
                setOpenDrawer(false);
                setIsFilterProducts(true);
              }}
            >
              <span>
                <LuFilter className={InspectionViewStyles.filterIconStyles} />
              </span>
              <span className={InspectionViewStyles.filterLabelStyles}>
                Filter
              </span>
            </div>
          </section>

          {isLoadProducts ? (
            <section
              className={InspectionViewStyles.productSearchLoadingIconStyles}
            >
              <Loader content="Loading..." vertical />
            </section>
          ) : (
            <section
              className={InspectionViewStyles.productSearchResultContainer}
            >
              {products?.length > 0 ? (
                <LineItemProductCard
                  products={products}
                  quantity={defaultQuantity}
                  lineItemId={lineItemId}
                  existingSearchedProducts={existingProducts}
                  removeProducts={removeProducts}
                  sendProductsToAutoSave={(val) => {
                    let obj = {};
                    let productsArray = [];
                    val?.map((product) => {
                      if (!isNaN(product?.id)) {
                        obj["id"] = product?.id;
                        obj["additional_info"] = {
                          product_image_url:
                            product["picture_list"]?.length > 0 &&
                            product["picture_list"][0]?.url?.original,
                        };
                      }
                      obj["name"] = product?.name || product?.title;
                      obj["sku"] = product?.sku;
                      obj["quantity"] =
                        lineItemProductQty?.id === product?.id
                          ? lineItemProductQty?.quantity
                          : 1;
                      obj["price"] =
                        lineItemProductQty?.id === product?.id
                          ? lineItemProductQty?.price
                          : product?.price;
                      obj["uom"] = product?.uom;
                      obj["additional_info"] = {
                        product_image_url:
                          product["picture_list"]?.length > 0
                            ? product["picture_list"][0]?.url?.original
                            : product?.additional_info?.product_image_url,
                      };
                      return productsArray.push(obj);
                    });
                    setLineItemProducts(productsArray);
                  }
                  }
                />
              ) : (
                <div>
                  <span
                    className={
                      InspectionViewStyles.manualProductResultNotFoundLabel
                    }
                  >
                    No Results Found
                  </span>
                  <div className="mt-5">
                    <section
                      className={
                        LineItemCardStyles.addProductFormOuterContainer
                      }
                    >
                      <InputField
                        label={"Product Title"}
                        placeholder={"Product Title"}
                        mandatory={true}
                        value={title}
                        theme={"secondary"}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                      <InputField
                        label={"SKU"}
                        placeholder={"SKU"}
                        mandatory={true}
                        errorText={"Please enter SKU number."}
                        value={sku}
                        theme={"secondary"}
                        onChange={(e) => setSku(e.target.value)}
                      />
                      <DropdownField
                        label={"UOM"}
                        options={uom}
                        theme={"secondary"}
                        mode={"page"}
                        isSearchable={true}
                        componentID={"filter_uom"}
                        error={isUOMError}
                        searchText={() => { }}
                        errorText={"Please select a UOM"}
                        mandatory={true}
                        value={ea}
                        onChange={(timeZone) =>
                          handleChange(
                            { target: { value: timeZone } },
                            setEa,
                            setIsUOMError
                          )
                        }
                      />
                      <section
                        className={
                          LineItemCardStyles.addProductFormInnerContainer
                        }
                      >
                        <span className="space-y-4">
                          <label className="font-inter font-medium text-[14px] leading-[17px] text-[_rgba(27,43,65,0.72)]">
                            Quantity
                          </label>
                          <QuantityMeasure
                            number={quantity}
                            sendQuantity={(val) => {
                              setQuantity(val);
                            }}
                            inspectionStatus={"InProgress"}
                            disableQuantity={false}
                            mandatory={true}
                          />
                        </span>
                        <PriceInputField
                          label={"Price"}
                          placeholder={"Price"}
                          value={price}
                          theme={"primary"}
                          onChange={(e) => setPrice(e.target.value)}
                        />
                      </section>
                      <Button
                        theme={!AddProdcutButtonDisabled ? "secondary" : "secondary-with-disabled"}
                        disabled={!AddProdcutButtonDisabled ? false : true}
                        buttonText={"Add"}
                        onClick={() => {
                          let productDetails = {
                            name: title,
                            sku: sku,
                            uom: ea?.id,
                            price: price === "" ? 0 : price,
                            quantity: quantity,
                            additional_info: {},
                          };
                          handleManualAddProduct([productDetails]);
                          clearManualAddProductForm();
                        }}
                      />
                    </section>
                  </div>
                </div>
              )}
            </section>
          )}
          <section className="pb-16">
            <Footer theme={"primary"}>
              <Button
                theme={"tertiary"}
                buttonText={"Cancel"}
                onClick={() => {
                  setOpenDrawer(false);
                  fetchInspectionDetails()
                  setSearchProducts("");
                  setProductManualSearchValue("");
                  setLoadScreen(false)
                }}
              />
              <Button
                theme={"primary"}
                buttonText={"Done"}
                onClick={() => {
                  setOpenDrawer(false);
                  fetchInspectionDetails()
                  setLoadScreen(false)
                  setSearchProducts("");
                  setProductManualSearchValue("");
                }}
              />
            </Footer>
          </section>
        </SlideDrawer>

        {isFilterProducts && (
          <SlideDrawer
            openDrawer={isFilterProducts}
            label={"Filter"}
            setOpenDrawer={() => {
              setIsFilterProducts(false);
            }}
          >
            <section className={LineItemCardStyles.filterProductFormContainer}>
              <FilterProductForm
                setOpenFilterDrawer={setIsFilterProducts}
                sendFilterProductPayload={(val) => {
                  setSearchProducts(val);
                  setOpenDrawer(true);
                }}
                existingDetails={existingFilterProduct}
              />
            </section>
          </SlideDrawer>
        )}
      </section>
      <section id="transaction_history">
        <TransactionHistory
          tableData={inspectionRecord?.history_list}
          title={"Transaction History"}
        />
      </section>

      {isDisableDefaultStatus || inspectionRecord?.status !== "InProgress" ? (
        <section className={InspectionViewStyles.completeStatusFooterConatiner}>
          <Footer theme={"secondary"}>
            <div className="flex space-x-5">
              {inspectionRecord?.status !== "Cancelled" && (
                <Button
                  buttonText={"Void"}
                  theme={"tertiary"}
                  onClick={() => voidInspection()}
                />
              )}

              {inspectionRecord?.status !== "Cancelled" && (
                <Button
                  buttonText={"Re-Open"}
                  theme={"secondary"}
                  onClick={() => reopenInspection()}
                />
              )}
            </div>
            <div className="flex space-x-5">
              <Button
                buttonText={"Copy Inspection"}
                theme={"secondary"}
                onClick={() => {
                  navigate("/create-inspection", {
                    state: { inspectionDetails: inspectionRecord },
                  });
                }}
              />
              {inspectionRecord?.status !== "Cancelled" && (
                <Button
                  buttonText={"Create Quote Matrix"}
                  theme={"primary"}
                  onClick={() => navigate("/inspections")}
                />
              )}
            </div>
          </Footer>
        </section>
      ) : (
        <section className={InspectionViewStyles.defaultFooterContainer}>
          <Footer theme={"primary"}>
            <Button
              buttonText={"Complete"}
              theme={"primary-with-disabled"}
              disabled={
                enableCompletion === "completion_disabled" ? true : false
              }
              onClick={() => handleCompleteInspection(inspectionRecord?.id)}
            />
          </Footer>
        </section>
      )}
    </div>
  );
}

export default ViewInspection;
