function groupDataByType(originalArr) {
  // Create an empty object to store the grouped and merged results
  const groupedResults = {};

  // Iterate over the originalArr
  originalArr.forEach((item) => {
    const { lineItemType, lineItemDetailList } = item;

    // Initialize the result for the lineItemType if it doesn't exist
    if (!groupedResults[lineItemType]) {
      groupedResults[lineItemType] = { lineItemType, mergedDetailList: [] };
    }

    // Merge detailList for the same lineItemType
    groupedResults[lineItemType].mergedDetailList = [
      ...groupedResults[lineItemType].mergedDetailList,
      ...lineItemDetailList,
    ];
  });

  // Convert the groupedResults object to an array of values
  const resultArray = Object.values(groupedResults);

  return resultArray;
}

export default groupDataByType;
