const { State, City } = require('country-state-city');

function getUSStates() {
  const countryCode = 'US';
  const states = State.getStatesOfCountry(countryCode);
  return states.map(state => ({name: state?.name, id:state?.name}));
}

function getCitiesInState(stateName) {
  const countryCode = 'US';
  const states = State.getStatesOfCountry(countryCode);
  const state = states.find(state => state.name === stateName);
  if (state) {
    const cities = City.getCitiesOfState(countryCode, state.isoCode);
    return cities.map(city => ({ name: city?.name, id: city?.name }));
  } else {
    return [];
  }
}

export { getCitiesInState, getUSStates };