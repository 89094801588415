import * as React from "react"
const SvgComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={10}
        height={18}
        fill="none"
        {...props}
    >
        <path
            fill="#006CDB"
            d="M5.053 13.632c.75-.078 1.323-.321 1.719-.729.396-.414.594-.942.594-1.584 0-.282-.054-.525-.162-.729a1.64 1.64 0 0 0-.423-.531 2.505 2.505 0 0 0-.639-.396 7.714 7.714 0 0 0-.774-.297l-.315 4.266Zm-.36-10.161a3.423 3.423 0 0 0-.981.225c-.27.114-.495.255-.675.423a1.6 1.6 0 0 0-.387.576 1.846 1.846 0 0 0-.126.675c0 .27.048.507.144.711.096.204.228.384.396.54.174.15.375.282.603.396.234.114.483.219.747.315l.279-3.861Zm.792 4.212c.432.132.858.279 1.278.441.426.156.807.357 1.143.603.336.246.606.555.81.927.21.366.315.828.315 1.386 0 .54-.093 1.05-.279 1.53-.18.48-.444.903-.792 1.269a4.007 4.007 0 0 1-1.287.891c-.504.228-1.08.363-1.728.405l-.108 1.503a.5.5 0 0 1-.144.324.449.449 0 0 1-.342.144h-.648l.144-1.998a6.128 6.128 0 0 1-1.98-.549A5.493 5.493 0 0 1 .31 13.452l.531-.81a.71.71 0 0 1 .198-.18.488.488 0 0 1 .261-.072c.12 0 .252.054.396.162l.531.378c.204.138.444.276.72.414.282.132.618.225 1.008.279l.333-4.581a17.79 17.79 0 0 1-1.242-.423 4.293 4.293 0 0 1-1.098-.63 2.998 2.998 0 0 1-.783-.981c-.198-.39-.297-.879-.297-1.467A3.442 3.442 0 0 1 1.885 3.12c.336-.33.747-.6 1.233-.81.492-.21 1.053-.327 1.683-.351l.09-1.26a.5.5 0 0 1 .144-.324.449.449 0 0 1 .342-.144h.648l-.126 1.773c.63.084 1.173.252 1.629.504.462.246.867.543 1.215.891l-.423.648c-.126.198-.273.297-.441.297a.675.675 0 0 1-.315-.108 8.153 8.153 0 0 0-.441-.243 5.928 5.928 0 0 0-.585-.288 3.305 3.305 0 0 0-.747-.216l-.306 4.194Z"
        />
    </svg>
)
export default SvgComponent
