export const roofTypeOptions = [
  { id: "Asphalt Shingles", name: "Asphalt Shingles" },
  { id: "Tile", name: "Tile" },
  { id: "Metal", name: "Metal" },
  { id: "Membrane", name: "Membrane" },
  { id: "Other", name: "Other" },
];

export const sidingOptions = [
    { id: "Stucco", name: "Stucco" },
    { id: "Wood", name: "Wood" },
    { id: "Vinyl", name: "Vinyl" },
    { id: "Aluminum", name: "Aluminum" },
    { id: "Fiber Concrete", name: "Fiber Concrete" },
    { id: "Other", name: "Other" },
]