import React from "react";
import { Button } from "../../index";
import HeaderWithButtonStyles from "../../../styles/components/headerWithButton";

function HeadingWithButton({ heading, onClick, componentID }) {
  return (
    <div className={HeaderWithButtonStyles.container}>
      <h3 className={HeaderWithButtonStyles.heading}>
        Inspection
      </h3>
      <Button
        buttonText={"Create Inspection"}
        id={componentID}
        theme={"primary"}
        onClick={onClick}
      />
    </div>
  );
}

export default HeadingWithButton;
