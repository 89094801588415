/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Heading,
  AccordionSlider,
  VerticalDivider,
  Footer,
  Dropdown,
  Button,
  TransactionHistory,
  SlideDrawer,
} from "../../components";
import { QUOTE_MATRIX_URL, USERS_API_URL } from "../../constants";
import {
  TransactionHistoryIcon,
  MoreIcon,
  LessIcon,
  XlsIcon,
  EyeIcon,
} from "../../assets";
import {
  httpAPICall,
  groupLineItems,
  formatPrice,
  generateDropdownOptions,
  calculatePerUnitTotals,
  calculatePerUnitTypeTotals,
  groupDataByType,
  isEmpty,
  formatPhoneNumber,
  groupLineItemsBySupplier,
} from "../../utils";
import moment from "moment";
import { Loader } from "rsuite";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setCreateQuoteMatrix } from "../../redux/slice/createQuoteMatrix";
import _, { isArray } from "lodash";
import * as FileSaver from "file-saver";
import axios from "axios";
import QuoteApproverForm from "../form/QuoteApproverForm";
import { webStore } from "../../redux";
import { statusMappingsTransactionHistory } from "../../utils/statusMappings";
import qs from "qs";
import SummaryQuote from "./SummaryQuote";
import QuoteMatrixByFloorplan from "./QuoteMatrixByFloorplan";
import QuoteMatrixBySupplier from "./QuoteMatrixBySupplier";

const CustomerDetails = ({ label, value }) => {
  return (
    <div className="font-lato text-[#383838]">
      <header className="text-[22px] not-italic font-semibold leading-[27px]">
        {label}
      </header>
      <label className="text-[18px] not-italic font-normal leading-normal text-center">
        {value || "-"}
      </label>
    </div>
  );
};

const QuoteMatrixTitle = ({ label, value }) => {
  return (
    <div className="flex font-lato text-[#383838] items-center space-x-[28px]">
      <header className="text-[22px] not-italic font-normal leading-normal text-left">
        {label}:&nbsp;
      </header>
      <label className="text-[18px] not-italic font-semibold leading-normal pl-[29px] py-[10px] bg-[#ECF5FF] w-full">
        {value}
      </label>
    </div>
  );
};

const InspectionTitle = ({ inspections, mode }) => {
  const [view, setView] = useState("less");
  const [list, setList] = useState(inspections?.slice(0, 2));
  const navigate = useNavigate();

  useEffect(() => {
    if (view === "less") {
      setList(inspections?.slice(0, 2));
    } else {
      setList(inspections);
    }
  }, [view]);
  return (
    <div>
      <section className="flex font-lato text-[#383838] space-x-[28px]">
        <header className="text-[22px] not-italic font-normal leading-normal text-left w-fit">
          Inspections Titles :
        </header>
        <div className="flex flex-col text-[#006CDB]">
          {list?.map((record, index) => {
            return (
              <label
                className="text-[18px] not-italic font-semibold leading-normal underline pl-[29px] py-[10px] cursor-pointer"
                onClick={() =>
                  navigate(
                    `/inspection/${
                      mode === "view" ? record?.inspection?.id : record?.id
                    }`
                  )
                }
                key={index}
              >
                {mode === "view" ? record?.inspection?.name : record?.name}
              </label>
            );
          })}
        </div>
      </section>
      {inspections?.length > 2 && (
        <section className="flex justify-end">
          {view === "less" && (
            <MoreIcon
              onClick={() => setView("more")}
              className="cursor-pointer"
            />
          )}
          {view === "more" && (
            <LessIcon
              onClick={() => setView("less")}
              className="cursor-pointer"
            />
          )}
        </section>
      )}
    </div>
  );
};

function AddQuoteMatrix({ mode = "create", quoteMatrixId, quoteMatrixData, suppliers = () => {} }) {
  const { quoteMatrices } = useSelector(
    (createQuoteMatrix) => ({
      quoteMatrices: createQuoteMatrix?.quoteMatrices,
    }),
    shallowEqual
  );
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [quoteSummaryRecords, setQuoteSummaryRecords] = useState({});
  const [groupByType, setGroupByType] = useState({});
  const [groupBySupplier, setGroupBySupplier] = useState({});
  const [floorPlans, setFloorPlans] = useState([]);
  const [renovationFieldCount, setRenovationFieldCount] = useState(0);
  const [quoteApproverDetails, setQuoteApproverDetails] = useState([]);
  const [quoteApproverFullName, setQuoteApproverFullName] = useState("");
  const [quoteApproverPhone, setQuoteApproverPhone] = useState("");
  const [quoteApproverOptions, setQuoteApproverOptions] = useState([]);
  const [quoteApproverEmail, setQuoteApproverEmail] = useState("");
  const [selectedQuoteApproverName, setSelectedQuoteApproverName] =
    useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [perUnitTotals, setPerUnitTotals] = useState([]);
  const [perUnitTypeTotals, setPerUnitTypeTotals] = useState([]);
  const [perUnitTypeTotalsGrandTotal, setPerUnitTypeTotalsGrandTotal] =
    useState(0);
  const [taxPercentage, setTaxPercentage] = useState(0);
  const [floorPlanHeaders, setFloorPlanHeaders] = useState([]);
  const [renderHeaders, setRenderHeaders] = useState({});
  const [floorPlanPerUnitTotals, setFloorPlanPerUnitTotals] = useState([]);
  const [perUnitTotalsWithTax, setPerUnitTotalsWithTax] = useState([]);
  const [generateInspectionList, setGenerateInspectionList] = useState([]);
  const [enableSend, setEnableSend] = useState(false);
  const [isQuoteApproverDrawer, setIsQuoteApproverDrawer] = useState(false);
  const [isQMLoading, setIsQMLoading] = useState(false);
  const [isExcelLoading, setIsExcelLoading] = useState(false);
  const [qmproperty, setQMProperty] = useState("");
  const [isDisableFooter, setIsDisableFooter] = useState(false);
  const [customerID, setCustomerID] = useState("");
  let lineItemArray = [];
  let inspection_list = [];
  const [quote, setQuote] = useState(null);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [quoteApproverDebouncedSearch, setQuoteApproverDebouncedSearch] =
    useState(null);
  const [quoteApproverSearchValue, setQuoteApproverSearchValue] = useState();
  const [newQuoteApprover, setNewQuoteApprover] = useState("");

  const fetchQuoteMatrices = async () => {
    setIsQMLoading(true);
    let inspection_ids =
      location.state?.inspectionsIds.length > 0
        ? location.state?.inspectionsIds.join(",")
        : location.state?.inspectionsIds;
    const result = await httpAPICall(
      QUOTE_MATRIX_URL + `?draft=1&inspections=${inspection_ids}`
    );
    setQuoteSummaryRecords(result);
    let history = result?.history_list?.map((record) => {
      return Object.hasOwn(statusMappingsTransactionHistory, record?.action)
        ? {
            ...record,
            action: statusMappingsTransactionHistory[record?.action],
          }
        : record;
    });
    setTransactionHistory(history);
    let approverDetails = await fetchQuoteApproverDetails(result?.customer?.id);
    setQuoteApproverFullName(approverDetails?.full_name);
    setIsQMLoading(false);
    result?.inspection_list?.map((inspection) => {
      return result?.line_item_list?.map((lineItem) => {
        return lineItem?.detail_list?.map((detailItem) => {
          let payload = {
            inspectionId: inspection?.id,
            supplier: inspection?.supplier,
            inspectionName: inspection?.name,
            inspectionProperty: inspection?.unit?.property,
            floorPlanName: inspection?.unit?.floorplan?.name,
            inspectionUnit: inspection?.unit,
            lineItemType: lineItem?.type ? lineItem?.type : "General",
            lineItemName: lineItem?.name,
            lineItemSku: lineItem?.sku,
            lineItemUom: lineItem?.uom,
            lineItemPrice: lineItem?.price,
            lineItemPackQty: 1,
            lineItemQuantity: lineItem?.quantity,
            lineItemAdditionalInfo: lineItem?.additional_info,
            lineItemDetailList: lineItem?.detail_list,
            detailItemQuantity: result?.inspection_list?.reduce(
              (accumulator, inspection) => {
                const floorPlanName =
                  inspection?.unit?.floorplan?.name;
                let existingObj = accumulator.find(
                  (obj) => obj.floorplan_name === floorPlanName
                );
                if (!existingObj) {
                  let obj = {
                    floorplan_name: floorPlanName,
                    quantity: "-",
                  };
                  lineItem?.detail_list?.forEach((item) => {
                    if (
                      item?.inspection?.unit?.floorplan?.name ===
                      floorPlanName
                    ) {
                      obj.quantity = item?.quantity;
                    }
                  });
                  accumulator.push(obj);
                }
                return accumulator;
              },
              []
            ),

            rowTotalUnitsAndItems: lineItem?.detail_list?.reduce(
              (total, obj) => {
                return (
                  total +
                  Number(obj?.renovation_unit_count) * Number(obj?.quantity)
                );
              },
              0
            ),
            rowExistingPrice: lineItem?.quantity * lineItem?.price,
            detailItemRenovationUnitCount: detailItem?.renovation_unit_count,
          };
          return (
            inspection?.id === detailItem?.inspection?.id &&
            lineItemArray.push(payload)
          );
        });
      });
    });

    dispatch(setCreateQuoteMatrix({ quoteMatrices: lineItemArray }));
    groupProductsByType(lineItemArray);
    calculateTotals(lineItemArray);
  };

  const fetchQuoteMatrixById = async () => {
    let result = {};
    if (mode === "view") {
      setIsQMLoading(true);
      const generateUrl = `${QUOTE_MATRIX_URL}/${quoteMatrixId}`;
      result = await httpAPICall(generateUrl, "GET", {});
      setTaxPercentage(parseInt(result?.tax_percentage));
      setQuote(result);
      setIsQMLoading(false);
    } else if (mode === "customerView") {
      setIsQMLoading(true);
      result = quoteMatrixData;
      setQuote(quoteMatrixData);
      setTaxPercentage(parseInt(quoteMatrixData?.tax_percentage));
      setIsQMLoading(false);
    }
    setQuoteSummaryRecords(result);
    let history = result?.history_list?.map((record) => {
      return Object.hasOwn(statusMappingsTransactionHistory, record?.action)
        ? {
            ...record,
            action: statusMappingsTransactionHistory[record?.action],
          }
        : record;
    });
    setTransactionHistory(history);
    mode !== "customerView" && fetchQuoteApproverDetails(result?.customer?.id);
    setCustomerID(result?.customer?.id);
    setSelectedQuoteApproverName({
      id: result?.to_be_approved_by?.id,
      name: result?.to_be_approved_by?.full_name,
    });
    if (
      result?.status === "Contracted" ||
      result?.status === "CDeclined" ||
      result?.status === "SCancelled"
    )
      setIsDisableFooter(true);
    setQuoteApproverFullName(result?.to_be_approved_by?.full_name);
    setQuoteApproverPhone(result?.to_be_approved_by?.phone_number);
    setQuoteApproverEmail(result?.to_be_approved_by?.email);
    result?.line_item_list?.map((lineItem) => {
      return lineItem?.detail_list?.map((detailItem) => {
        let payload = {
          inspectionId: detailItem?.inspection?.id,
          inspectionName: detailItem?.inspection?.id,
          supplier: lineItem?.supplier,
          inspectionProperty: detailItem?.inspection?.unit?.property,
          floorPlanName:
            detailItem?.inspection?.unit?.floorplan?.name,
          inspectionUnit: detailItem?.inspection?.unit,
          lineItemType: lineItem?.type ? lineItem?.type : "General",
          lineItemName: lineItem?.name,
          lineItemSku: lineItem?.sku,
          lineItemUom: lineItem?.uom,
          lineItemId: lineItem?.id,
          lineItemPrice: lineItem?.price,
          lineItemPackQty:
            lineItem?.pack_quantity && parseInt(lineItem?.pack_quantity),
          lineItemQuantity: lineItem?.quantity,
          lineItemAdditionalInfo: lineItem?.additional_info,
          lineItemDetailList: lineItem?.detail_list,
          detailItemQuantity: lineItem?.detail_list?.reduce(
            (accumulator, item) => {
              const floorPlanName =
                item?.inspection?.unit?.floorplan?.name;
              let existingObj = accumulator.find(
                (obj) => obj.floorplan_name === floorPlanName
              );

              if (!existingObj) {
                let obj = {
                  floorplan_name: floorPlanName,
                  quantity: "-",
                };
                lineItem?.detail_list?.forEach((item) => {
                  if (
                    item?.inspection?.unit?.floorplan?.name ===
                    floorPlanName
                  ) {
                    obj.quantity = item?.quantity;
                  }
                });
                accumulator.push(obj);
              }
              return accumulator;
            },
            []
          ),

          rowTotalUnitsAndItems: lineItem?.detail_list?.reduce((total, obj) => {
            return (
              total + Number(obj?.renovation_unit_count) * Number(obj?.quantity)
            );
          }, 0),
          rowExistingPrice: lineItem?.quantity * lineItem?.price,
          detailItemRenovationUnitCount: detailItem?.renovation_unit_count,
          existingTaxPercentage: result?.tax_percentage
            ? result?.tax_percentage
            : "0",
        };

        inspection_list.push({
          ...detailItem,
        });

        return detailItem?.inspection?.id && lineItemArray.push(payload);
      });
    });

    groupProductsByType(lineItemArray);

    let uniqueRecords = [];
    const uniqueObjs = inspection_list.filter((element) => {
      const isDuplicate = uniqueRecords?.find(
        (inspection) => element?.inspection.id === inspection.id
      );
      if (!isDuplicate) {
        uniqueRecords.push(element?.inspection);
        return true;
      }
      return false;
    });
    dispatch(setCreateQuoteMatrix({ quoteMatrices: lineItemArray }));
    setQMProperty(result?.property[0]?.name);
    setGenerateInspectionList(uniqueObjs);
    calculateTotals(lineItemArray);
  };

  const groupProductsByType = (lineItemArray) => {
    if (Object.keys(lineItemArray).length !== 0) {
      let groupLineItemsByFloorplan = groupLineItems(lineItemArray);
      let lineItemsBySupplier = groupLineItemsBySupplier(lineItemArray);
      mode === "create" &&
        setQMProperty(lineItemArray[0]?.inspectionProperty?.name);
      setRenovationFieldCount(Object.keys(groupLineItemsByFloorplan).length);
      setFloorPlans(groupLineItemsByFloorplan);
      setGroupBySupplier(lineItemsBySupplier);
      groupDataByType(lineItemArray);
      const groupedData = _.chain(lineItemArray)
        .groupBy("lineItemType")
        .mapValues((group) => _.uniqBy(group, "lineItemSku"))
        .value();
      setGroupByType(groupedData);
    }
  };

  useEffect(() => {
    dispatch(setCreateQuoteMatrix({ quoteMatrices: {} }));
    if (mode === "view" || mode === "customerView") {
      fetchQuoteMatrixById();
    } else {
      fetchQuoteMatrices();
    }
  }, []);

  useEffect(() => {
    let uniqueRecords = [];
    let renoCount = Array.isArray(quoteMatrices?.quoteMatrices)
      ? quoteMatrices?.quoteMatrices.map((reduxRecord) => {
          return {
            name: reduxRecord?.floorPlanName,
            count: reduxRecord?.detailItemRenovationUnitCount,
          };
        })
      : [];
    const uniqueObjs = renoCount.filter((element) => {
      const isDuplicate = uniqueRecords?.includes(element.name);
      if (!isDuplicate) {
        uniqueRecords.push(element?.name);
        return true;
      }
      return false;
    });
    setFloorPlanHeaders(uniqueObjs);
  }, [renderHeaders, quoteMatrices?.quoteMatrices]);

  useEffect(() => {
    calculateTotals(quoteMatrices.quoteMatrices);
  }, [floorPlanHeaders]);

  const summary_quote_headers = [
    "",
    "NO",
    "SKU",
    "Item",
    "Supplier",
    floorPlanHeaders,
    "Total Units and Items",
    "Unit Price",
    "UOM",
    "Pck Qty",
  ];

  const supplier_headers = [
    "",
    "NO",
    "SKU",
    "Item",
    floorPlanHeaders,
    "Total Units and Items",
    "Unit Price",
    "UOM",
    "Pck Qty",
  ];

  const floorplan_headers = [
    "",
    "NO",
    "SKU",
    "Item",
    "Supplier",
    "Qty",
    "Unit Price",
    "UOM",
    "Ext Price",
  ];

  const calculateTotals = (lineItemArray) => {
    if (
      Object.keys(lineItemArray).length !== 0 &&
      floorPlanHeaders.length !== 0
    ) {
      let generatePerUnitTotals = calculatePerUnitTotals(
        lineItemArray,
        taxPercentage,
        floorPlanHeaders
      );
      setPerUnitTotals(generatePerUnitTotals?.unitTypeTotals);
      setPerUnitTotalsWithTax(
        Object.values(generatePerUnitTotals?.unitTypeTotalsWithTax)
      );
      setFloorPlanPerUnitTotals(generatePerUnitTotals?.unitTypeTotals);
      let generatePerUnitTypeTotals = calculatePerUnitTypeTotals(
        lineItemArray,
        taxPercentage,
        floorPlanHeaders
      );
      setPerUnitTypeTotalsGrandTotal(generatePerUnitTypeTotals);
      setPerUnitTypeTotals(
        Object.values(generatePerUnitTypeTotals?.perUnitTypeTotals[0])
      );
    }
  };

  const fetchQuoteApproverDetails = async (id, filterObj) => {
    const generatedUrl = `${USERS_API_URL}?customer_ids=${id}&access_level_type=Company&status=Active&${qs.stringify(
      filterObj
    )}`;
    const response = await httpAPICall(generatedUrl, "GET");
    let options = generateDropdownOptions(response, "full_name", "id");
    const addNewApprover = {
      id: "add_new_approver",
      name: "+ Add New Quote Approver",
    };
    setQuoteApproverOptions([addNewApprover, ...options]);
    setQuoteApproverDetails(response);
    if (mode === "create" && response?.length === 1) {
      setQuoteApproverFullName(response[0]?.full_name);
      setQuoteApproverPhone(response[0]?.phone_number);
      setQuoteApproverEmail(response[0]?.email);
    }
  };

  const handleQuoteApproverChange = (selectedValue, isNewQuoteApprover) => {
    let checkApprover = quoteApproverDetails?.find(
      (item) => item.id === selectedValue?.id
    );
    selectedValue !== "" &&
      setSelectedQuoteApproverName(
        isNewQuoteApprover
          ? { id: checkApprover?.id, name: checkApprover?.full_name }
          : selectedValue
      );
    if (selectedValue?.id !== "add_new_approver") {
      setQuoteApproverPhone(checkApprover?.phone_number);
      setQuoteApproverEmail(checkApprover?.email);
      setQuoteApproverFullName(checkApprover?.full_name);
    }
  };

  const createQuoteAprrover = async (data) => {
    const result = await httpAPICall(
      USERS_API_URL,
      "POST",
      data,
      "",
      "Quote Approver"
    );
    setIsQuoteApproverDrawer(false);
    await fetchQuoteApproverDetails(quoteSummaryRecords?.customer?.id);
    setNewQuoteApprover({ id: result?.id, name: result?.id });
  };

  const handleSaveQuoteMatrix = async () => {
    let requestPayload = {
      title: quoteSummaryRecords?.title,
      total_renovation_unit_count: 150,
      customer: quoteSummaryRecords?.customer,
      status: quoteSummaryRecords?.status,
      due_date: quoteSummaryRecords?.due_date,
      tax_percentage: isEmpty(taxPercentage) ? 0 : taxPercentage,
      type: "Contract Matrix",
      to_be_approved_by: selectedQuoteApproverName,
      inspection_list:
        mode === "view"
          ? generateInspectionList
          : quoteSummaryRecords?.inspection_list,
      line_item_list: [],
    };
    if (mode === "view") {
      requestPayload["id"] = quoteMatrixId;
    }
    const originalArray = quoteMatrices?.quoteMatrices?.reduce(
      (accumulator, current) => {
        if (
          !accumulator.some((item) => item.lineItemSku === current.lineItemSku)
        ) {
          accumulator.push(current);
        }
        return accumulator;
      },
      []
    );

    originalArray?.forEach((record) => {
      let generateObj = {};
      generateObj["id"] = record?.lineItemId;
      generateObj["type"] = record?.lineItemType;
      generateObj["name"] = record?.lineItemName;
      generateObj["sku"] = record?.lineItemSku;
      generateObj["uom"] = record?.lineItemUom;
      generateObj["price"] = record?.lineItemPrice;
      generateObj["quantity"] = record?.lineItemQuantity;
      generateObj["pack_quantity"] = record?.lineItemPackQty
        ? record?.lineItemPackQty
        : "0";
      generateObj["additional_info"] = record?.lineItemAdditionalInfo;
      generateObj["detail_list"] = record?.lineItemDetailList;
      return requestPayload?.line_item_list?.push(generateObj);
    });
    if (quoteSummaryRecords?.id) {
      await httpAPICall(
        `${QUOTE_MATRIX_URL}/${quoteSummaryRecords?.id}`,
        "PUT",
        requestPayload,
        "",
        "",
        "Quote Matrix Updated Successfully"
      );
      navigate("/quote-matrix");
    } else {
      await httpAPICall(
        QUOTE_MATRIX_URL,
        "POST",
        requestPayload,
        "",
        "",
        "Quote Matrix Saved Successfully"
      );
      navigate("/quote-matrix");
    }
  };

  const handleSendQuoteMatrix = async () => {
    let requestPayload = {
      title: quoteSummaryRecords?.title,
      status: quote?.status,
      due_date: quote?.due_date,
      tax_percentage: taxPercentage || 0,
      total_renovation_unit_count: 150,
      customer: quoteSummaryRecords?.customer,
      type: "Contract Matrix",
      to_be_approved_by: selectedQuoteApproverName,
      inspection_list:
        mode === "view"
          ? generateInspectionList
          : quoteSummaryRecords?.inspection_list,
      line_item_list: [],
    };
    if (mode === "view") {
      requestPayload["id"] = quoteMatrixId;
    }
    const originalArray = quoteMatrices?.quoteMatrices?.reduce(
      (accumulator, current) => {
        if (
          !accumulator.some((item) => item.lineItemSku === current.lineItemSku)
        ) {
          accumulator.push(current);
        }
        return accumulator;
      },
      []
    );
    originalArray?.forEach((record) => {
      let generateObj = {};
      generateObj["id"] = record?.lineItemId;
      generateObj["type"] = record?.lineItemType;
      generateObj["name"] = record?.lineItemName;
      generateObj["sku"] = record?.lineItemSku;
      generateObj["uom"] = record?.lineItemUom;
      generateObj["price"] = record?.lineItemPrice;
      generateObj["quantity"] = record?.lineItemQuantity;
      generateObj["pack_quantity"] = record?.lineItemPackQty;
      generateObj["additional_info"] = record?.lineItemAdditionalInfo;
      generateObj["detail_list"] = record?.lineItemDetailList;
      return requestPayload?.line_item_list?.push(generateObj);
    });
    let appendParam = mode === "view" ? "/" + quoteMatrixId : "";
    const saveQM = await httpAPICall(
      QUOTE_MATRIX_URL + appendParam,
      mode === "view" ? "PUT" : "POST",
      requestPayload,
      "",
      "",
      "Quote Matrix Created and Sent Successfully"
    );
    if (saveQM?.id) {
      await httpAPICall(
        `${QUOTE_MATRIX_URL}/${saveQM?.id}/email`,
        "PUT",
        {},
        "",
        ""
      );
      navigate("/quote-matrix");
    }
  };

  useEffect(() => {
    handleQuoteApproverChange(newQuoteApprover, true);
  }, [newQuoteApprover]);

  useEffect(() => {
    if (selectedQuoteApproverName !== "") {
      setEnableSend(false);
    } else {
      setEnableSend(true);
    }
  }, [selectedQuoteApproverName]);

  const handleSearch = (
    value,
    setSearchText,
    debouncedSearch,
    setDebouncedSearch
  ) => {
    setSearchText(value);
    if (debouncedSearch) {
      clearTimeout(debouncedSearch);
    }
    setDebouncedSearch(
      setTimeout(() => {
        let searchObj = {};
        if (value) {
          searchObj["q"] = value;
          fetchQuoteApproverDetails(quote?.customer?.id, searchObj);
        }
      }, 2000)
    );
  };

  const downloadXml = async () => {
    setIsExcelLoading(true);
    const generateAPIUrl = `${QUOTE_MATRIX_URL}/${quoteMatrixId}?format=xls`;

    const config = {
      method: "GET",
      url: generateAPIUrl,
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "blob",
        common: {
          Authorization: `Bearer ${webStore.getState().auth.accessToken}`,
        },
      },
    };
    axios(config)
      .then((response) => {
        const outputFilename = `${
          quoteSummaryRecords?.property[0].name
        } - Reno Matrix - ${moment().format("YYYY-MM-DD")}.xlsx`;
        const url = URL.createObjectURL(
          new Blob([response?.data], {
            type: "application/vmd.openxmlformates",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.setAttribute("download", outputFilename);
        document.body.appendChild(link);
        link.click();
        setIsExcelLoading(false);
        return FileSaver.saveAs(response.data, outputFilename);
      })
      .catch(() => {
        setIsExcelLoading(false);
        console.log("We're sorry, but something went wrong");
      });
  };

  useEffect(() => {
    if (selectedQuoteApproverName?.id === "add_new_approver") {
      setIsQuoteApproverDrawer(true);
    } else {
      setIsQuoteApproverDrawer(false);
    }
  }, [selectedQuoteApproverName?.id]);

  useEffect(() => {
    calculateTotals(quoteMatrices?.quoteMatrices);
  }, [taxPercentage, quoteMatrices?.quoteMatrices]);

  useEffect(() => {
    groupProductsByType(quoteMatrices?.quoteMatrices);
  }, [quoteMatrices?.quoteMatrices]);

  const sizeOfGroupByType = Object.keys(groupByType).length;
  const noOfSuppliers = Object.keys(groupBySupplier).length;
  suppliers(noOfSuppliers)

  return (
    <main>
      {isQMLoading ? (
        <div className="flex items-center justify-center">
          <Loader
            size="md"
            speed="normal"
            center
            content="Loading..."
            vertical
          />
        </div>
      ) : (
        <>
          {mode !== "customerView" && (
            <>
              <header className="flex justify-between items-center">
                <Heading theme={"primary"} heading={"Create Quote Matrix"} />
                <div className="flex justify-between items-center space-x-[20px]">
                  {mode === "view" && (
                    <div className="flex gap-2">
                      <a
                        href="#suppliers"
                        className="text-[#006CDB] font-lato text-[1rem] non-italic font-semibold cursor-pointer hover:no-underline flex items-center space-x-[0.25rem]"
                      >
                        <EyeIcon />
                        View Suppliers
                      </a>
                      <a
                        href="#transaction_history"
                        className="text-[#006CDB] font-lato text-[1rem] non-italic font-semibold cursor-pointer hover:no-underline flex items-center space-x-[0.25rem]"
                      >
                        <TransactionHistoryIcon />
                        Transaction History
                      </a>
                    </div>
                  )}
                  {mode === "view" &&
                    (isExcelLoading ? (
                      <Loader size="md" speed="normal" />
                    ) : (
                      <section className="flex space-x-[20px]">
                        <XlsIcon onClick={() => downloadXml()} />
                      </section>
                    ))}
                </div>
              </header>
              <section className="flex justify-between mt-[53px]">
                <CustomerDetails
                  label={"PMC"}
                  value={quoteSummaryRecords?.customer?.name}
                />
                <CustomerDetails
                  label={"Supplier Quote#"}
                  value={
                    quoteSummaryRecords?.id ? quoteSummaryRecords?.id : "-"
                  }
                />
                <CustomerDetails
                  label={"Quote Type"}
                  value={"Contract Matrix"}
                />
                <CustomerDetails
                  label={"Exp Date"}
                  value={
                    mode === "create"
                      ? moment().add(90, "days").format("MM/DD/YYYY")
                      : moment(quoteSummaryRecords?.due_date)
                          .local()
                          .format("MM/DD/YYYY")
                  }
                />
                <CustomerDetails
                  label={"Reg/Property Coverage"}
                  value={qmproperty}
                />
              </section>
              <section className="flex justify-between items-center mt-[40px] flex-grow">
                <span className="w-[270px] space-y-[10px]">
                  <label className="font-inter font-medium text-[14px] leading-[17px] text-[_rgba(27,43,65,0.72)]">
                    Quote Approver Name
                    <span className="text-[#EC0D0D]"> *</span>
                  </label>
                  <Dropdown
                    options={quoteApproverOptions}
                    componentID={"quoteMatrix_approverNameSearch"}
                    value={selectedQuoteApproverName}
                    placeHolder={"Quote Approver Name"}
                    isSearchable={true}
                    searchText={(searchText) =>
                      handleSearch(
                        searchText,
                        setQuoteApproverSearchValue,
                        quoteApproverDebouncedSearch,
                        setQuoteApproverDebouncedSearch
                      )
                    }
                    isDisable={
                      quoteSummaryRecords?.status === "SCancelled" ||
                      quoteSummaryRecords?.status === "CDeclined" ||
                      quoteSummaryRecords?.status === "Contracted"
                    }
                    onChange={(selectedValue) => {
                      if (selectedValue?.id === "add_new_approver") {
                        setIsDisable(false);
                      } else {
                        setIsDisable(true);
                      }
                      handleQuoteApproverChange(selectedValue);
                    }}
                  />
                </span>

                <SlideDrawer
                  label={"Add New Quote Approver"}
                  openDrawer={isQuoteApproverDrawer}
                  componentID={"renovationSetup_quoteApproverDrawer"}
                  setOpenDrawer={() => {
                    setIsQuoteApproverDrawer(false);
                    setSelectedQuoteApproverName("");
                  }}
                >
                  <QuoteApproverForm
                    passDataToNextSection={createQuoteAprrover}
                    mode={"create"}
                    handleCancel={() => {
                      setIsQuoteApproverDrawer(false);
                      setSelectedQuoteApproverName("");
                    }}
                    customerID={quoteSummaryRecords?.customer?.id}
                  />
                </SlideDrawer>
                <span>
                  <CustomerDetails
                    label={"Quote Approver Full Name"}
                    value={quoteApproverFullName}
                  />
                </span>
                <span>
                  <CustomerDetails
                    label={"Quote Approver Phone"}
                    value={formatPhoneNumber(quoteApproverPhone)}
                  />
                </span>
                <span>
                  <CustomerDetails
                    label={"Quote Approver Email"}
                    value={quoteApproverEmail}
                  />
                </span>
              </section>

              <section className="mt-[66px]">
                <QuoteMatrixTitle
                  label={"Quote Matrix Title"}
                  value={quoteSummaryRecords?.title}
                />
              </section>
              <section className="mt-[54px] mb-[20px]">
                <InspectionTitle
                  inspections={
                    mode === "view"
                      ? generateInspectionList
                      : quoteSummaryRecords?.inspection_list
                  }
                  mode={mode}
                />
              </section>
            </>
          )}
          <section className="mb-40 w-full">
            <AccordionSlider
              name={"Summary Quote"}
              collapsible={0}
              content={"table"}
              isOpen={0}
            >
              <div className="w-full">
                {Object.entries(groupByType)?.map(([key, value], index) => {
                  return (
                    <div key={index} className="overflow-x-auto bg-[#ECF5FF]">
                      <section className="py-3 pl-[2.43rem] bg-[#ECF5FF] font-inter text-base font-medium text-[#383838]">
                        {key}
                      </section>
                      <SummaryQuote
                        tableData={value}
                        groupByType={floorPlans}
                        inspectionList={
                          mode !== "create"
                            ? generateInspectionList
                            : quoteSummaryRecords?.inspection_list
                        }
                        categoryKey={key}
                        categoryIndex={index}
                        sizeOfGroupByType={sizeOfGroupByType}
                        mode={mode}
                        headers={summary_quote_headers}
                        sku={value?.lineItemSku}
                        status={quoteSummaryRecords?.status}
                        floorplanHeaders={floorPlanHeaders}
                        renovationFieldCount={renovationFieldCount}
                        perUnitTotals={perUnitTotals}
                        perUnitTypeTotals={perUnitTypeTotals}
                        setTax={setTaxPercentage}
                        taxPercentage={taxPercentage}
                        quoteSummaryRecords={quoteSummaryRecords}
                        perUnitTotalsWithTax={perUnitTotalsWithTax}
                        perUnitTypeTotalsGrandTotal={
                          perUnitTypeTotalsGrandTotal
                        }
                        sendFloorPlanRenoCount={(val) => {
                          const originalArray = quoteMatrices?.quoteMatrices;

                          const updatedArray = originalArray.map((item) => {
                            const isMatchedFloorPlan =
                              val?.name === item?.floorPlanName;

                            const updatedLineItemDetailList =
                              item?.lineItemDetailList?.map(
                                (detailListItem) => {
                                  const isMatchedDetailItem =
                                    val?.name ===
                                    detailListItem?.inspection?.unit
                                      ?.floorplan?.name;
                                  if (isMatchedDetailItem) {
                                    return {
                                      ...detailListItem,
                                      renovation_unit_count: val?.value,
                                    };
                                  }
                                  return detailListItem;
                                }
                              );

                            const rowTotalUnitsAndItems =
                              updatedLineItemDetailList.reduce((total, obj) => {
                                const isMatchedDetailListFloorplan =
                                  val?.name ===
                                  obj?.inspection?.unit?.floorplan
                                    ?.name;
                                const unitCount = isMatchedDetailListFloorplan
                                  ? val?.value
                                  : obj?.renovation_unit_count;
                                return (
                                  total +
                                  Number(unitCount) * Number(obj?.quantity)
                                );
                              }, 0);

                            if (isMatchedFloorPlan) {
                              return {
                                ...item,
                                floorName: val?.name,
                                detailItemRenovationUnitCount: val?.value,
                                lineItemDetailList: updatedLineItemDetailList,
                                rowTotalUnitsAndItems,
                              };
                            } else {
                              return {
                                ...item,
                                lineItemDetailList: updatedLineItemDetailList,
                                rowTotalUnitsAndItems,
                              };
                            }
                          });

                          dispatch(
                            setCreateQuoteMatrix({
                              quoteMatrices: updatedArray,
                            })
                          );
                          setRenderHeaders(val);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </AccordionSlider>
            <VerticalDivider />
            {Object.entries(floorPlans).map(
              ([FPkey, categoryWiseProduct], index) => (
                <div key={index}>
                  <AccordionSlider name={FPkey} content={"table"} isOpen={0}>
                    <div className="overflow-x-auto">
                      {Object.entries(categoryWiseProduct).map(
                        ([category, value], itemIndex) => (
                          <QuoteMatrixByFloorplan
                            key={itemIndex}
                            rows={value}
                            headers={floorplan_headers}
                            inspectionList={
                              mode !== "create"
                                ? generateInspectionList
                                : quoteSummaryRecords?.inspection_list
                            }
                            mode={mode}
                            categoryKey={category}
                            floorPlanKey={FPkey}
                          />
                        )
                      )}
                      <span className="flex space-x-[112px] justify-end bg-[#ECF5FF] w-full py-[14px]">
                        <label className="text-[#006CDB] font-inter text-base not-italic font-bold pr-4">
                          Unit Total
                        </label>
                        {Object.keys(floorPlanPerUnitTotals).length > 0 ? (
                          Object.entries(
                            floorPlanPerUnitTotals["Per Unit Total"]
                          ).map(
                            ([key, value], unitIndex) =>
                              FPkey === key && (
                                <span
                                  key={unitIndex}
                                  className="font-lato text-[18px] not-italic  text-[#383838] text-right font-bold pr-2"
                                >
                                  ${formatPrice(Number(value))}
                                </span>
                              )
                          )
                        ) : (
                          <span className="text-[#383838] font-lato text-sm not-italic font-bold">
                            -
                          </span>
                        )}
                      </span>
                    </div>
                  </AccordionSlider>
                  {index < Object.keys(floorPlans).length - 1 && (
                    <VerticalDivider />
                  )}
                </div>
              )
            )}
            {(noOfSuppliers > 1) && ( <>
            <VerticalDivider id="suppliers" />
            {Object.entries(groupBySupplier).map(
              ([SPkey, categoryWiseProduct], categoryIndex) => (
                <div key={categoryIndex}>
                  <AccordionSlider name={SPkey} content={"table"} isOpen={0}>
                    <div className="w-full">
                      {Object.entries(categoryWiseProduct)?.map(
                        ([key, value], index) => {
                          return (
                            <div
                              key={index}
                              className="overflow-x-auto bg-[#ECF5FF]"
                            >
                              <section className="py-3 pl-[2.43rem] bg-[#ECF5FF] font-inter text-base font-medium text-[#383838]">
                                {key}
                              </section>
                              <QuoteMatrixBySupplier
                                tableData={value}
                                groupByType={floorPlans}
                                inspectionList={
                                  mode !== "create"
                                    ? generateInspectionList
                                    : quoteSummaryRecords?.inspection_list
                                }
                                categoryKey={key}
                                categoryIndex={index}
                                isLastCategory={
                                  Object.keys(categoryWiseProduct).length -
                                    1 ===
                                  index
                                }
                                mode={mode}
                                headers={supplier_headers}
                                sku={value?.lineItemSku}
                                status={quoteSummaryRecords?.status}
                                unitTotals={
                                  floorPlanPerUnitTotals[SPkey + " Per Unit"]
                                }
                                floorplanHeaders={floorPlanHeaders}
                                renovationFieldCount={renovationFieldCount}
                                quoteSummaryRecords={quoteSummaryRecords}
                                sendFloorPlanRenoCount={(val) => {
                                  const originalArray =
                                    quoteMatrices?.quoteMatrices;

                                  const updatedArray = originalArray.map(
                                    (item) => {
                                      const isMatchedFloorPlan =
                                        val?.name === item?.floorPlanName;

                                      const updatedLineItemDetailList =
                                        item?.lineItemDetailList?.map(
                                          (detailListItem) => {
                                            const isMatchedDetailItem =
                                              val?.name ===
                                              detailListItem?.inspection
                                                ?.unit?.floorplan
                                                ?.name;
                                            if (isMatchedDetailItem) {
                                              return {
                                                ...detailListItem,
                                                renovation_unit_count:
                                                  val?.value,
                                              };
                                            }
                                            return detailListItem;
                                          }
                                        );

                                      const rowTotalUnitsAndItems =
                                        updatedLineItemDetailList.reduce(
                                          (total, obj) => {
                                            const isMatchedDetailListFloorplan =
                                              val?.name ===
                                              obj?.inspection?.unit
                                                ?.floorplan?.name;
                                            const unitCount =
                                              isMatchedDetailListFloorplan
                                                ? val?.value
                                                : obj?.renovation_unit_count;
                                            return (
                                              total +
                                              Number(unitCount) *
                                                Number(obj?.quantity)
                                            );
                                          },
                                          0
                                        );

                                      if (isMatchedFloorPlan) {
                                        return {
                                          ...item,
                                          floorName: val?.name,
                                          detailItemRenovationUnitCount:
                                            val?.value,
                                          lineItemDetailList:
                                            updatedLineItemDetailList,
                                          rowTotalUnitsAndItems,
                                        };
                                      } else {
                                        return {
                                          ...item,
                                          lineItemDetailList:
                                            updatedLineItemDetailList,
                                          rowTotalUnitsAndItems,
                                        };
                                      }
                                    }
                                  );

                                  dispatch(
                                    setCreateQuoteMatrix({
                                      quoteMatrices: updatedArray,
                                    })
                                  );
                                  setRenderHeaders(val);
                                }}
                              />
                            </div>
                          );
                        }
                      )}
                    </div>
                  </AccordionSlider>
                  {categoryIndex < noOfSuppliers - 1 && <VerticalDivider />}
                </div>
              )
            )}
            </>
            )}
            {(mode === "customerView" || mode === "view") && (
              <section id="transaction_history">
                <TransactionHistory
                  tableData={transactionHistory}
                  title={"Transaction History"}
                />
              </section>
            )}
          </section>
        </>
      )}

      {mode === "customerView" ? (
        <></>
      ) : (
        !isQuoteApproverDrawer &&
        !isDisableFooter && (
          <Footer theme={"secondary"}>
            <div className="flex w-full justify-between">
              <Button
                buttonText={"Cancel"}
                componentID={"quoteMatrix_btnCancelQuoteMatrix"}
                theme={"tertiary"}
                onClick={() => {
                  mode === "view"
                    ? navigate("/quote-matrix")
                    : navigate("/inspections");
                }}
              />

              <section className="space-x-[26.49px]">
                <Button
                  buttonText={"Save"}
                  componentID={"quoteMatrix_btnSaveQuoteMatrix"}
                  theme={enableSend ? "secondary-with-disabled" : "secondary"}
                  disabled={enableSend}
                  onClick={() => handleSaveQuoteMatrix()}
                />
                <Button
                  buttonText={"Send"}
                  componentID={"quoteMatrix_btnSendQuoteMatrix"}
                  disabled={enableSend}
                  theme={enableSend ? "primary-with-disabled" : "primary"}
                  onClick={() => handleSendQuoteMatrix()}
                />
              </section>
            </div>
          </Footer>
        )
      )}
    </main>
  );
}

export default AddQuoteMatrix;
