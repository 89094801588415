const ButtonStyles = {
  primary:
    "bg-[#006CDB] px-[40px] h-[40px] rounded-[8px] text-[#FFFFFF] hover:bg-blue-700 text-center font-lato",
  secondary:
    "bg-[#ffff] px-[40px] border border-[#006CDB] text-[#006CDB] rounded-[8px] hover:bg-blue-100 h-[40px] text-center font-lato",
  tertiary:
    "bg-[#F1F3F5] px-[40px] h-[40px] rounded-[8px] text-[#383838] hover:bg-gray-200 border border-[#5B5B5B] text-center font-lato",
  quaternary:
    "bg-[#ECF5FF] px-[40px] h-[40px] rounded-[8px] text-[#006CDB] hover:bg-gray-200 text-center bg-[#ECF5FF] font-lato",
  tertiaryStylesWithoutBorder:
    "bg-[#ECF5FF] px-[40px] h-[40px] rounded-[8px] w-[159px] text-[#383838] hover:bg-gray-200 text-center bg-[#F3F3F3] font-lato",
  primaryStylesWithFixedWidth:
    "bg-[#006CDB] px-[40px] h-[40px] rounded-[8px] w-[159px] text-[#ffff] hover:bg-blue-700 text-center font-lato",
  secondaryStylesWithSmallFixedWidth:
    "bg-[#ffff] px-[33px] border border-[#006CDB] text-[#006CDB] rounded-[8px] w-[160px] hover:bg-blue-100 h-[40px] text-center font-lato",
  secondaryStylesWithLargeFixedWidth:
    "bg-[#ffff] px-[33px] border border-[#006CDB] text-[#006CDB] rounded-[8px] w-[231px]  h-[40px] text-center font-lato",
  secondaryStylesWithLargeFixedWidthDisable:
    "bg-[#C8C8C8] px-[33px] text-[#383838] rounded-[8px] w-[231px]  h-[40px] text-center font-lato",
  primaryStylesWithDisabled:
    "bg-[#006CDB] px-[40px] h-[40px] rounded-[8px] text-[#ffff] text-center opacity-40 font-lato",
  secondaryStylesWithDisabled:
    "bg-[#ffff] px-[40px] border border-[#006CDB] text-[#006CDB] rounded-[8px] h-[40px] text-center font-lato opacity-40",
  tertiaryStylesWithDisabled:
    "bg-[#F1F3F5] px-[40px] h-[40px] rounded-[8px] text-[#383838] border border-[#5B5B5B] text-center font-lato opacity-40",
};

export default ButtonStyles;
