/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { conditionalStyle } from "../../../utils";
import TabsStyles from "../../../styles/components/tab";

function Tabs({ active, sendTabName = (val) => val, tabs, componentID }) {
  const [tabData, setTabData] = useState([]);
  const [intialTab, setIntialTab] = useState("");

  useEffect(() => {
    let result = Object.entries(tabs).map(([category, items]) => {
      return {
        category,
        items: items.map((item) => item.name),
      };
    });

    setTabData(result);
    setIntialTab(result[0]?.category);
  }, [tabs, active]);

  return (
    <div className={TabsStyles.outerContainer}>
      <div className={TabsStyles.innerContainer}>
        <nav className="flex" aria-label="Tabs" id={componentID}>
          {tabData?.map((tab) => (
            <button
              key={tab.category}
              className={conditionalStyle(
                TabsStyles.default,
                tab.category === active ? TabsStyles.active : ""
              )}
              onClick={() => {
                sendTabName(tab.category);
              }}
            >
              {tab.category}
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
}

export default Tabs;
