import React, { useState, useRef, useEffect } from "react";
import { Table, IconButton } from "rsuite";
import { RadioCell, ToolTip } from "../../components/index";
import { PencilIcon, SortIcon, ChevronUp, ChevronDown } from "../../assets";
import { statusMapping } from "../../utils/statusMappings";
import isEmpty from "../../utils/isEmpty";
import formatPhoneNumber from "../../utils/formatPhoneNumber";
import QuoteApproverGrid from "./quote_approver/Grid";
const { Column, HeaderCell, Cell } = Table;
const rowKey = "id";
let singleRecordTableLength = 88;

const ExpandCell = ({
  rowData,
  dataKey,
  expandedRowKeys,
  onChange,
  ...props
}) => (
  <Cell {...props} align="center" style={{ padding: 5 }}>
    <IconButton
      onClick={() => {
        onChange(rowData);
      }}
      icon={
        expandedRowKeys.some((key) => key === rowData[rowKey]) ? (
          <ChevronUp />
        ) : (
          <ChevronDown />
        )
      }
    />
  </Cell>
);

const renderRowExpanded = (rowData) => {
  return (
    <div className="mx-12">
      <QuoteApproverGrid tableData={rowData} />
    </div>
  );
};

function CustomerGrid({
  tableData,
  onSelect,
  editCustomer,
  searchText,
  selectedOption,
  isLoading,
  sort,
}) {
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [prevSortByColumn, setPrevSortByColumn] = useState("");
  const [sortOrder, setSortOrder] = useState(1);
  const [baseClassName, setBaseClassName] = useState(
    "border-b-[1px] border-solid border-[#C8C8C8]"
  );
  const dataRef = useRef(tableData);
  let sortObject = {};

  const handleExpanded = (rowData, dataKey) => {
    let open = false;
    const nextExpandedRowKeys = [];

    expandedRowKeys.forEach((key) => {
      if (key === rowData[rowKey]) {
        open = true;
      } else {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      nextExpandedRowKeys.push(rowData[rowKey]);
    }

    setExpandedRowKeys(nextExpandedRowKeys);
  };

  const handleCheck = (value) => {
    setCheckedKeys([value]);
    const selectedCustomer = dataRef.current?.find(
      (customer) => customer.id === value
    );
    if (selectedCustomer) {
      const { id, name, is_registered } = selectedCustomer;
      onSelect({ id: id, name: name, registered: is_registered });
    }
  };

  if (selectedOption?.length === 1) {
    dataRef.current = selectedOption;
  } else if (checkedKeys.length === 0 && isEmpty(searchText)) {
    dataRef.current = tableData;
  }

  let filteredData =
    checkedKeys?.length === 1 && selectedOption[0]?.id === checkedKeys[0]
      ? dataRef.current?.filter((customer) => customer.id === checkedKeys[0])
      : dataRef.current;
  if (tableData?.length === 1 && checkedKeys.length === 0) {
    filteredData = tableData;
    handleCheck(filteredData[0]?.id);
  }

  const handleSort = (column) => {
    sortObject = { sort_by: column, sort_order: sortOrder };
    if (prevSortByColumn === column) {
      sortObject.sort_order = -1 * sortObject.sort_order;
      setSortOrder(sortObject.sort_order);
    }
    setPrevSortByColumn(column);
    if (checkedKeys.length === 0 && filteredData?.length > 1) sort(sortObject);
  };

  const isAnyCheckedKeyInExpanded = expandedRowKeys.some(
    (key) => filteredData[0]?.id === key
  );
  
  useEffect(() => {
    const rowClassName = () => {
      if (filteredData && filteredData?.length > 1) {
        setBaseClassName(
          "border-b-[1px] flex align-center border-solid border-[#C8C8C8]"
        );
      } else {
        setBaseClassName("border-0");
      }
    };
    rowClassName();
  }, [filteredData]);

  isAnyCheckedKeyInExpanded
    ? (singleRecordTableLength = 240)
    : (singleRecordTableLength = 88);

  return (
    <Table
      loading={isLoading}
      className="w-full border"
      rowKey={rowKey}
      expandedRowKeys={expandedRowKeys}
      data={filteredData}
      rowClassName={baseClassName}
      rowExpandedHeight={210}
      height={
        filteredData && filteredData?.length > 1 ? 410 : singleRecordTableLength
      }
      locale={{
        emptyMessage: (
          <div className="flex pl-6 italic font-lato text-[16px]">
            No Record(s) Found
          </div>
        ),
        loading: "Loading",
      }}
      renderRowExpanded={renderRowExpanded}
    >
      <Column width={50} align="center">
        <HeaderCell
          style={{ padding: 0, backgroundColor: "#E9ECF0" }}
          className="font-lato font-medium text-[16px] text-[#25282B] text-center"
        >
          <div
            style={{ lineHeight: "40px", backgroundColor: "#E9ECF0" }}
            className="font-lato font-medium text-[16px] text-[#25282B] text-center"
          ></div>
        </HeaderCell>
        <Cell>
          {(rowData, rowIndex) => {
            return (
              <RadioCell
                onChange={handleCheck}
                rowData={rowData}
                checkedValue={
                  checkedKeys?.length === 1 &&
                  checkedKeys[0] === selectedOption[0]?.id
                    ? checkedKeys[0]
                    : checkedKeys[0] !== selectedOption[0]?.id
                    ? selectedOption[0]?.id
                    : checkedKeys[0]
                }
                componentID={`customerGrid_select${rowIndex}`}
              />
            );
          }}
        </Cell>
      </Column>
      <Column flexGrow={15}>
        <HeaderCell
          style={{
            backgroundColor: "#E9ECF0",
            display: "flex",
            alignItems: "center",
          }}
          className="font-lato font-medium text-[16px] text-[#25282B] text-center"
        >
          <span
            className="gap-2 flex justify-center items-center"
            onClick={() => handleSort("name")}
          >
            PMC Name <SortIcon />
          </span>
        </HeaderCell>
        <Cell>
          {(rowData, rowIndex) => {
            return (
              <span className="font-lato font-medium text-[16px] text-[#25282B]">
                {rowData.name ? rowData.name : "-"}
              </span>
            );
          }}
        </Cell>
      </Column>
      <Column flexGrow={20}>
        <HeaderCell
          style={{
            backgroundColor: "#E9ECF0",
            display: "flex",
            alignItems: "center",
          }}
          className="font-lato font-medium text-[16px] text-[#25282B]"
        >
          <span
            className="gap-2 flex justify-center items-center"
            onClick={() => handleSort("address1")}
          >
            Address
            <SortIcon />
          </span>
        </HeaderCell>
        <Cell>
          {(rowData, rowIndex) => {
            return (
              <span className="font-lato font-medium text-[16px] text-[#25282B]">
                <ToolTip
                  text={
                    rowData.address?.address ? rowData.address?.address : "-"
                  }
                  mode="customer"
                  maxLength={30}
                ></ToolTip>
              </span>
            );
          }}
        </Cell>
      </Column>
      <Column flexGrow={15}>
        <HeaderCell
          style={{
            backgroundColor: "#E9ECF0",
            display: "flex",
            alignItems: "center",
          }}
          className="font-lato font-medium text-[16px] text-[#25282B]"
        >
          <span
            className="gap-2 flex justify-center items-center"
            onClick={() => handleSort("main_contact")}
          >
            Contact Name
            <SortIcon />
          </span>
        </HeaderCell>
        <Cell>
          {(rowData, rowIndex) => {
            return (
              <span className="font-lato font-medium text-[16px] text-[#25282B]">
                {rowData.contact_name ? rowData.contact_name : "-"}
              </span>
            );
          }}
        </Cell>
      </Column>
      <Column flexGrow={10}>
        <HeaderCell
          style={{
            backgroundColor: "#E9ECF0",
            display: "flex",
            alignItems: "center",
          }}
          className="font-lato font-medium text-[16px] text-[#25282B]"
        >
          <span
            className="gap-2 flex justify-center items-center"
            onClick={() => handleSort("status_name")}
          >
            Status
            <SortIcon />
          </span>
        </HeaderCell>
        <Cell>
          {(rowData, rowIndex) => {
            return (
              <span className="font-lato font-medium text-[16px] text-[#25282B]">
                {rowData.status ? statusMapping[rowData.status] : "-"}
              </span>
            );
          }}
        </Cell>
      </Column>
      <Column flexGrow={14}>
        <HeaderCell
          style={{
            backgroundColor: "#E9ECF0",
            display: "flex",
            alignItems: "center",
          }}
          className="font-lato font-medium text-[16px] text-[#25282B]"
        >
          Phone Number
        </HeaderCell>
        <Cell>
          {(rowData, rowIndex) => {
            return (
              <span className="font-lato font-medium text-[16px] text-[#25282B]">
                {rowData.phone_number
                  ? formatPhoneNumber(rowData.phone_number)
                  : "-"}
              </span>
            );
          }}
        </Cell>
      </Column>
      <Column flexGrow={15}>
        <HeaderCell
          style={{
            backgroundColor: "#E9ECF0",
            display: "flex",
            alignItems: "center",
          }}
          className="font-lato font-medium text-[16px] text-[#25282B]"
        >
          Email Id
        </HeaderCell>
        <Cell
          dataKey="email"
          className="font-lato font-medium text-[16px] text-[#25282B]"
        >
          {(rowData, rowIndex) => {
            return (
              <span className="font-lato font-medium text-[16px] text-[#25282B]">
                {rowData.email ? rowData.email : "-"}
              </span>
            );
          }}
        </Cell>
      </Column>
      <Column width={40} align="center">
        <HeaderCell
          style={{
            backgroundColor: "#E9ECF0",
            display: "flex",
            alignItems: "center",
          }}
          className="font-lato font-medium text-[16px] text-[#25282B]"
        ></HeaderCell>
        <Cell>
          {(rowData, rowIndex) => {
            return rowData.is_registered ? (
              <></>
            ) : (
              <PencilIcon
                id={`customerGrid_edit${rowIndex}`}
                onClick={() => editCustomer(rowData.id)}
              />
            );
          }}
        </Cell>
      </Column>
      <Column width={80} align="left">
        <HeaderCell
          style={{
            backgroundColor: "#E9ECF0",
            display: "flex",
            alignItems: "center",
          }}
          className="font-lato font-medium text-[16px] text-[#25282B]"
        ></HeaderCell>
        <ExpandCell
          dataKey={"id"}
          expandedRowKeys={expandedRowKeys}
          onChange={handleExpanded}
        />
      </Column>
    </Table>
  );
}

export default CustomerGrid;