import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.0003 2.5C14.4937 2.5 18.232 5.73313 19.0158 10C18.232 14.2668 14.4937 17.5 10.0003 17.5C5.50681 17.5 1.7685 14.2668 0.984741 10C1.7685 5.73313 5.50681 2.5 10.0003 2.5ZM10.0003 15.8333C13.53 15.8333 16.5503 13.3767 17.3148 10C16.5503 6.62336 13.53 4.16667 10.0003 4.16667C6.47054 4.16667 3.45022 6.62336 2.68569 10C3.45022 13.3767 6.47054 15.8333 10.0003 15.8333ZM10.0003 13.75C7.92919 13.75 6.25026 12.0711 6.25026 10C6.25026 7.92893 7.92919 6.25 10.0003 6.25C12.0713 6.25 13.7503 7.92893 13.7503 10C13.7503 12.0711 12.0713 13.75 10.0003 13.75ZM10.0003 12.0833C11.1509 12.0833 12.0836 11.1506 12.0836 10C12.0836 8.84942 11.1509 7.91667 10.0003 7.91667C8.84971 7.91667 7.91692 8.84942 7.91692 10C7.91692 11.1506 8.84971 12.0833 10.0003 12.0833Z"
      fill="#006CDB"
    />
  </svg>
);
export default SVGComponent;
