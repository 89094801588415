import { axiosObject } from "./setup";

function userInfo() {
    return new Promise((resolve, reject) => {
      let uri = process.env.REACT_APP_RAILS_API_URL+`/v2/user_info`;
  
      axiosObject
        .get(uri, {params: {extra_attrs: ["subscription_module_list"]}})
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => {
          console.log(errors);
          if (errors) {
            reject(errors);
          }
          reject(errors);
        });
    });
  }
  
  export { userInfo };