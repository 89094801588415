const InspectionViewStyles = {
  addressProgressBarContainer: "flex items-center justify-between mt-[10px]",
  addressContainer: "flex flex-col",
  propertyUnitLabel:
    "font-lato font-normal text-[#383838] mt-[21px] not-italic text-[20px]",
  listInspectionDateLabel:
    "font-lato font-normal text-[#383838] not-italic text-[20px]",
  locationIconAddressContainer: "flex items-center space-x-[0.42rem]",
  locationIconStyles: "text-xl text-[#5B5B5B]",
  addressDetailsLabel: "font-lato font-medium not-italic text-[17px]",
  inspectionTypeDefaultStatusContainer:
    "flex justify-between items-center p-[25px] bg-[#F5F6F6] rounded-[4px]",
  inspectionTypeLargerStyles:
    "font-lato text-[20px] font-medium text-[#006CDB]",
  inspectionLineItemCardStyles: "mt-[13px] pb-8",
  resultsForLabelStyles:
    "text-[#383838] font-open-sans text-base font-normal text-[21px]",
  searchedProductLabelStyles:
    "text-[#383838] font-open-sans text-base font-bold text-[21px] ml-2",
  searchInputFilterLabelContainer:
    "flex justify-between px-10 items-center bg-[#F5F6F6]",
  filterIconLabelStyles: "flex space-x-[4px] items-center cursor-pointer",
  filterIconStyles: "text-[#006CDB] w-[28px] h-[20px]",
  filterLabelStyles: "text-[#006CDB] font-lato text-base font-normal",
  productSearchLoadingIconStyles: "flex justify-center items-center mt-10",
  productSearchResultContainer:
    "px-10 pb-10 space-y-3 bg-[#F5F6F6] mt-5 overflow-y-auto",
  manualProductResultNotFoundLabel:
    "flex flex-col font-lato text-[1rem] italic font-normal text-[#006CDB]",
  completeStatusFooterConatiner:
    "fixed bottom-0 left-0 right-0 w-full bg-[#F5F6F6] p-[25px] flex justify-between",
  defaultFooterContainer:
    "fixed bottom-0 left-0 right-0 w-full bg-[#F5F6F6] p-[25px]",
};

export default InspectionViewStyles;
