import React from "react";
import LineItemProductFormStyles from "../../../styles/components/inspectionLineItemProductForm";
import { InputField } from "../../index";

function InspectionLineItemProductForm({
  productTitle,
  setProductTitle,
  measurement,
  setMeasurement,
  comment,
  setComment,
  onSubmit,
}) {
  return (
    <form
      onSubmit={onSubmit}
      className={LineItemProductFormStyles.formContainer}
    >
      <section>
        <label>Product Title</label>
        <InputField
          placeholder={"Product Title"}
          value={productTitle}
          onChange={setProductTitle}
          theme={"secondary"}
        />
      </section>
      <section>
        <label>Measurement</label>
        <InputField
          placeholder={"Measurement"}
          value={measurement}
          onChange={setMeasurement}
          theme={"secondary"}
        />
      </section>
      <section>
        <label>Comments</label>
        <InputField
          placeholder={"Comments"}
          value={comment}
          onChange={setComment}
          theme={"secondary"}
        />
      </section>
    </form>
  );
}

export default InspectionLineItemProductForm;
