const CUSTOMERS_API_URL = "/companies";
const PROPERTIES_API_URL = "/properties";
const FLOORPLANS_API_URL = "/floorplans";
const BUILDINGS_API_URL = "/buildings";
const UNITS_API_URL = "/units";
const USERS_API_URL = "/users";
const INSPECTIONS_API_URL = "/inspections";
const QUOTE_MATRIX_URL = "/quote-matrices";
const LISTS_API_URL = "/lists";
const PRODUCTS_API_URL = "/products";
const LOGIN_API_URL = process.env.REACT_APP_LOGIN_API_URL;
const LOGOUT_API_URL = process.env.REACT_APP_LOGOUT_API_URL;

export {
  CUSTOMERS_API_URL,
  PROPERTIES_API_URL,
  FLOORPLANS_API_URL,
  BUILDINGS_API_URL,
  UNITS_API_URL,
  INSPECTIONS_API_URL,
  QUOTE_MATRIX_URL,
  PRODUCTS_API_URL,
  LISTS_API_URL,
  LOGIN_API_URL,
  LOGOUT_API_URL,
  USERS_API_URL,
};
