import * as React from "react"

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
    className="cursor-pointer"
  >
    <path
      fill="#BE2A15"
      d="M15 5.25v10.5a.75.75 0 0 1-.75.75H3.75a.75.75 0 0 1-.75-.75V5.25H1.5v-1.5h15v1.5H15Zm-10.5 0V15h9V5.25h-9Zm.75-3.75h7.5V3h-7.5V1.5Zm3 6h1.5v5.25h-1.5V7.5Z"
    />
  </svg>
)
export default SvgComponent