import React from "react";
import OutputFieldStyles from "../../../styles/components/outputField";

function OutputField({ label, value }) {
  return (
    <div className={OutputFieldStyles.container}>
      <label className={OutputFieldStyles.label}>{label}</label>
      <input  className={OutputFieldStyles.input} type="text" value={value} readOnly />
    </div>
  );
}

export default OutputField;