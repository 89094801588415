import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={11}
    height={2}
    viewBox="0 0 11 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.763 1.22877V0.175538H0.894531V1.22877H10.763Z"
      fill="#006CDB"
    />
  </svg>
);
export default SVGComponent;
