import React, { useState } from "react";
import InputFieldStyles from "../../../styles/components/inputField";
import { DollarIcon } from "../../../assets";
function PriceInputField({
    label,
    type,
    ref,
    placeholder,
    value,
    onChange,
    error,
    errorText,
    mandatory,
    mode,
    theme,
    defaultValue,
    isDisabled,
    maxlength,
    onClick,
    componentID,
    min,
    pattern,
    onKeyDown,
    onInput,
    sendAutoSave = (val) => val,
}) {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = (e) => {
        setIsFocused(false);
        sendAutoSave(e.target.value);
    };

    const handleKeyDown = (evt) => {
        if (type === "number" && (evt.key === "e" || evt.key === "-")) {
            evt.preventDefault();
        }
    };
    return (
        <div className={`${InputFieldStyles.inputContainer} ${InputFieldStyles.inputContainerWithIcon}`}>
            <label
                className={
                    isDisabled ? InputFieldStyles.disabled : InputFieldStyles.active
                }
            >
                {label}{" "}
                {mandatory && <span className={InputFieldStyles.mandatory}> *</span>}
            </label>
            <div>
                <input
                    type={type}
                    ref={ref}
                    placeholder={placeholder}
                    value={value}
                    min={min}
                    pattern={pattern}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    readOnly={isDisabled}
                    onKeyDown={handleKeyDown}
                    onClick={onClick}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    id={componentID}
                    onInput={onInput}
                    className={
                        theme === "secondary" && isDisabled
                            ? InputFieldStyles.quaternaryStyles
                            : theme === "primary" ? InputFieldStyles.primaryInputWrapper
                                : theme === "secondary"
                                    ? InputFieldStyles.inputWrapper
                                    : InputFieldStyles.primaryStyles
                    }
                />
                <span className={InputFieldStyles.dollarSymbol}>
                    <DollarIcon />
                </span>
            </div>
            {error && (
                <label
                    className={
                        isFocused
                            ? InputFieldStyles.pageErrorLabelBottom
                            : InputFieldStyles.pageErrorLabelBlur
                    }
                >
                    {errorText}
                </label>
            )}
        </div>

    );
}

export default PriceInputField;
