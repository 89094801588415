/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { DropdownOptionRemoveIcon } from "../../../assets";
import { FiSearch } from "react-icons/fi";
import "./dropdown.css";

const Icon = (id) => {
  return <MdOutlineArrowDropDown id={id} className="text-2xl" />;
};

const CloseIcon = () => {
  return <DropdownOptionRemoveIcon className="text-4xl text-[#8E8E8E]" />;
};

function Dropdown({
  placeHolder,
  options,
  isMulti,
  isSearchable,
  onChange,
  onClick,
  filterById,
  searchText,
  isClear,
  value,
  onSelect,
  componentID,
  error,
  isRequired,
  isDisable,
}) {
  const [showMenu, setShowMenu] = useState(false);
  const [selectedValue, setSelectedValue] = useState(isMulti ? [] : null);
  const [searchValue, setSearchValue] = useState("");
  const searchRef = useRef();
  const inputRef = useRef();

  useEffect(() => {
    setSearchValue("");
    if (showMenu && searchRef.current) {
      searchRef.current.focus();
    }
  }, [showMenu, filterById]);

  useEffect(() => {
    setSearchValue(filterById);
  }, [filterById]);

  useEffect(() => {
    if (typeof value === "object") {
      setSelectedValue(value);
      getDisplay();
    }
  }, [value]);

  useEffect(() => {
    setSearchValue(onSelect);
    // if (showMenu && searchRef.current) {
    //   searchRef.current.focus();
    // }
  }, [onSelect]);

  useEffect(() => {
    const handler = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    window.addEventListener("click", handler);
    return () => {
      window.removeEventListener("click", handler);
    };
  }, []);

  const handleInputClick = (e) => {
    setShowMenu(true);
  };

  const getDisplay = () => {
    if (!selectedValue || selectedValue.length === 0) {
      return placeHolder;
    }
    if (isMulti) {
      return (
        <div className="dropdown-tags">
          {selectedValue.map((option) => (
            <div
              key={option.id}
              className="flex bg-white border border-[#ADADAD] h-[25px] rounded-[4px] gap-x-2 items-center px-2 font-normal text-[12px] text-[#5B5B5B"
            >
              {option.name}
              <span onClick={(e) => onTagRemove(e, option)}>
                <CloseIcon />
              </span>
            </div>
          ))}
        </div>
      );
    }
    return selectedValue.name;
  };

  const removeOption = (option) => {
    return selectedValue.filter((o) => o.id !== option.id);
  };

  const onTagRemove = (e, option) => {
    e.stopPropagation();
    const newValue = removeOption(option);
    setSelectedValue(newValue);
    onChange(newValue);
  };

  const onItemClick = (option) => {
    let newValue;

    if (isMulti) {
      if (selectedValue.findIndex((o) => o.id === option.id) >= 0) {
        newValue = removeOption(option);
      } else {
        newValue = [...selectedValue, option];
      }
    } else {
      newValue = option;
    }
    setSelectedValue(newValue);
    onChange(newValue);
  };

  const isSelected = (option) => {
    if (isMulti) {
      return selectedValue.filter((o) => o.id === option.id).length > 0;
    }

    if (!selectedValue) {
      return false;
    }

    return selectedValue.id === option.id;
  };

  // Rest of the code...

  const onSearch = (e) => {
    const value = e.target.value;
    if (filterById) {
      setSearchValue(filterById);
    }
    searchText((value.length >= 3 || value.length === 0) && value);
    setSearchValue(value);
  };

  const getOptions = () => {
    if (!Array.isArray(options)) {
      return [];
    }

    if (!searchValue) {
      return options;
    }

    const finalResult = options.filter((option) => {
      if (option.name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0) {
        option.checked = false;
      } else {
        option.checked = true;
      }
      return option.name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0;
    });
    return finalResult;
  };

  return (
    <div
      className="border-[#C8C8C8] border rounded-[4px] h-[48px] w-full bg-white"
      style={{
        border: isDisable && "1px solid #C8C8C8",
        opacity: isDisable && "0.4",
      }}
      onClick={onClick}
    >
      <div
        ref={inputRef}
        onClick={isDisable ? null : handleInputClick}
        className="p-[5px] bg-white flex items-center rounded-[4px] justify-between h-[46px]"
        id={componentID}
      >
        <div
          className="pl-[12px] font-lato font-normal text-[14px] text-black placeholder:text-[#5B5B5B]"
          id="truncate"
        >
          {isClear ? placeHolder : getDisplay()}
        </div>
        <div>
          <div className="dropdown-tool">
            <Icon />
          </div>
        </div>
      </div>
      {showMenu && (
        <div
          className="w-full border border-[#ccc] rounded-[5px] overflow-auto max-h-[150px] bg-white relative z-50 shadow-md"
          style={{ zIndex: 100 }}
        >
          {isSearchable && (
            <div className="relative pt-[10px]">
              <span className="flex px-2 pb-2">
                <input
                  type="text"
                  className="focus:outline-none w-full h-[34px] rounded-[4px] font-lato border border-[#C8C8C8] px-[10px] placeholder:font-lato placeholder:font-normal placeholder:text-[13px] placeholder:text-[#595959] text-black"
                  placeholder="Search"
                  ref={searchRef}
                  value={searchValue}
                  onChange={onSearch}
                  onClick={(e) => e.stopPropagation()}
                />
              </span>
              <span className="absolute right-4 top-[20px] my-auto">
                <FiSearch className="" />
              </span>
            </div>
          )}

          {getOptions().length > 0 ? (
            getOptions().map((option, index) => {
              return (
                <div
                  className="hover:bg-gray-100 p-3 space-x-3 flex items-center font-lato font-normal text-[14px] text-black"
                  key={index}
                  onClick={(event) => onItemClick(option)}
                >
                  <span className="flex items-center">{option.name}</span>
                </div>
              );
            })
          ) : (
            <div className="hover:bg-gray-100 p-3 space-x-3 flex items-center font-lato font-normal text-[14px] text-gray-500">
              No Options
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Dropdown;
