import React from "react";
import { Drawer } from "rsuite";
import "../../../index.css";
import { CloseIcon } from "../../../assets";
import "../../../styles/components/drawer.css";
import SlideDrawerStyles from "../../../styles/components/drawer";

function SlideDrawer({
  children,
  openDrawer,
  setOpenDrawer,
  label,
  handleCancel,
  componentID,
  customWidth,
}) {
  return (
    <>
      <Drawer
        open={openDrawer}
        onClose={setOpenDrawer || handleCancel}
        size={customWidth ? customWidth : "sm"}
        className={SlideDrawerStyles.container}
        style={{
          overflow: "auto",
          boxShadow: "-9px 9px 12px 0px #aeaeae",
          zIndex: 1000,
        }}
        backdropClassName="bg-[#1a242a66]"
      >
        <div className={SlideDrawerStyles.drawerContentsOuterContainer}>
          <section className={SlideDrawerStyles.drawerContentsInnerContainer}>
            <header className={SlideDrawerStyles.header} id={componentID}>
              {label}
            </header>
            <header onClick={setOpenDrawer} className="cursor-pointer">
              <CloseIcon />
            </header>
          </section>
          {children}
        </div>
      </Drawer>
    </>
  );
}

export default SlideDrawer;
