import React from "react";
import { Modal } from "rsuite";
import { conditionalStyle } from "../../../utils";
import Button from "../../simple/button/Button";
import "./modal.css";

function ModalWrapper({ openModal, closeModal, children, title, openDrawer }) {
  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      dialogClassName="bg-white"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Modal.Header
        className={
          title === "Unit"
            ? "bg-[#ECF5FF] px-[20px] flex justify-between items-center h-[75px]"
            : title === "Quote Matrix" ? "bg-white px-[20px] flex justify-between items-center h-[65px]" : "bg-white px-[20px] flex justify-between items-center h-[40px]"
        }
      >
        <Modal.Title className={conditionalStyle("font-semibold", title === "Quote Matrix" ? "text-[20px]" :"text-[29px]")}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={title ? "m-0 p-0" : ""}>{children}</Modal.Body>
      {title && (
        <Modal.Footer>
          <section className={conditionalStyle("flex items-center h-[80px] space-x-[10px] px-[20px] bg-[#FFFFFF]",title === "Unit" ? "justify-end" : "justify-between" )}>
            <Button
              componentID={"modal_btnCancel"}
              buttonText={"Cancel"}
              theme={"tertiary-without-border"}
              onClick={closeModal}
            />
            <Button
              buttonText={title === "Unit" ? "Edit" : "Submit"}
              componentID={title === "Unit" ? "modal_btnEdit" : "modal_btnSend"}
              theme={"primary-fixed-width"}
              onClick={openDrawer}
            />
          </section>
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default ModalWrapper;