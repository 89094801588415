/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
} from "react";
import {
  InputField,
  LinkButton,
  UnitNumber,
  Button,
  DropdownField,
} from "../../components";
import generateDropdownOptions from "../../utils/dropdownOptionsGenerator";
import { httpAPICall } from "../../utils";
import qs from "qs";
import {
  CUSTOMERS_API_URL,
  PROPERTIES_API_URL,
  FLOORPLANS_API_URL,
  BUILDINGS_API_URL,
} from "../../constants";
import isEmpty from "../../utils/isEmpty";

const UnitForm = forwardRef(
  (
    {
      mode,
      passDataToNextSection,
      handleCancel,
      selectedCustomer,
      selectedProperty,
      selectedFloorplan,
      selectedBuilding,
      properties,
      floorplans,
      buildings,
      propertyId,
      id,
      propertyType,
      propertyTypeData,
      errors,
      unitData,
      isLoading,
      selectedFloorplanUnitCount,
    },
    ref
  ) => {
    const [customer, setCustomer] = useState("");
    const [property, setProperty] = useState("");
    const [floorplanName, setFloorplanName] = useState("");
    const [unitNumbers, setUnitNumbers] = useState([]);
    const [buildingName, setBuildingName] = useState("");
    const [buildingNumbers, setBuildingNumbers] = useState([]);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [propertyOptions, setPropertyOptions] = useState([]);
    const [floorplanOptions, setFloorplanOptions] = useState([]);
    const [buildingOptions, setBuildingOptions] = useState([]);
    const [customerSearchValue, setCustomerSearchValue] = useState("");
    const [propertySearchValue, setPropertySearchValue] = useState("");
    const [floorplanSearchValue, setFloorplanSearchValue] = useState("");
    const [buildingSearchValue, setBuildingSearchValue] = useState("");
    const [floorplanUnitCount, setFloorplanUnitCount] = useState("");
    const [floorplanData, setFloorplanData] = useState([]);
    const [buildingData, setBuildingData] = useState([]);
    const [customerDebouncedSearch, setCustomerDebouncedSearch] =
      useState(null);
    const [propertyDebouncedSearch, setPropertyDebouncedSearch] =
      useState(null);
    const [floorplanDebouncedSearch, setFloorplanDebouncedSearch] =
      useState(null);
    const [buildingDebouncedSearch, setBuildingDebouncedSearch] =
      useState(null);
    const [isCustomerError, setIsCustomerError] = useState(false);
    const [isPropertyError, setIsPropertyError] = useState(false);
    const [isFloorplanError, setIsFloorplanError] = useState(false);
    const [isBuildingError, setIsBuildingError] = useState(false);
    const [unit, setUnit] = useState([["", "", "", "", ""]]);
    const [isPropertyDrwnClear, setIsPropertyDrwnClear] = useState(false);
    const [isFloorplanDrwnClear, setIsFloorplanDrwnClear] = useState(false);
    const [isbuildingDrwnClear, setIsBuildingDrwnClear] = useState(false);
    const [isUnitNumberError, setIsUnitNumberError] = useState(false);
    const [buildingErrorText, setBuildingErrorText] = useState(
      "Please enter the building name"
    );
    const [floorplanErrorText, setFloorplanErrorText] = useState(
      "Please enter the floorplan name"
    );
    const floorplanBuildingOptionsRef = useRef([
      { name: "Floorplan", id: "Floorplan" },
      { name: "Building", id: "Building" },
    ]);
    const [type, setType] = useState(
      propertyType === "Building"
        ? { name: "Building", id: "Building" }
        : { name: "Floorplan", id: "Floorplan" }
    );
    const appendUnitNumbers = () => {
      const newArray = [...unit];
      const oneDArray = ["", "", "", "", ""];
      newArray.push(oneDArray);
      setUnit(newArray);
    };

    const fetchCustomers = async (filterObj) => {
      let generatedUrl = CUSTOMERS_API_URL + `?${qs.stringify(filterObj)}`;
      let response = await httpAPICall(generatedUrl, "GET", {});
      setCustomerOptions(generateDropdownOptions(response, "name", "id"));
    };

    const fetchProperties = async (filterObj) => {
      let generatedUrl = PROPERTIES_API_URL + `?${qs.stringify(filterObj)}`;
      let response = await httpAPICall(generatedUrl, "GET", {});
      setPropertyOptions(generateDropdownOptions(response, "name", "id"));
    };

    const fetchFloorplans = async (filterObj) => {
      let generatedUrl = FLOORPLANS_API_URL + `?${qs.stringify(filterObj)}`;
      let response = await httpAPICall(generatedUrl, "GET", {});
      setFloorplanData(response);
      setFloorplanOptions(generateDropdownOptions(response, "name", "id"));
      const selectedFloorplanName = selectedFloorplan?.name;
      const matchingFloorplan = response?.find(
        (floorplan) => floorplan?.name === selectedFloorplanName
      );
      setFloorplanUnitCount(
        matchingFloorplan?.additional_info?.planned_renovation_unit_count ||
          null
      );
    };

    const fetchBuildings = async (filterObj) => {
      let generatedUrl = BUILDINGS_API_URL + `?${qs.stringify(filterObj)}`;
      let response = await httpAPICall(generatedUrl, "GET", {});
      setBuildingData(response);
      setBuildingOptions(generateDropdownOptions(response, "name", "id"));
    };

    useEffect(() => {
      if (mode === "create") {
        let searchObj = {};
        fetchCustomers();
        if (selectedCustomer) {
          searchObj["customer_ids"] = selectedCustomer?.id;
          fetchProperties(searchObj);
          if (selectedProperty) {
            searchObj["property_ids"] = selectedProperty?.id;
            type?.id === "Floorplan"
              ? fetchFloorplans(searchObj)
              : fetchBuildings(searchObj);
          }
        }
      } else {
        setPropertyOptions(generateDropdownOptions(properties, "name", "name"));
      }
      if (mode === "page") {
        setIsPropertyError(errors?.property || false);
        setIsUnitNumberError(errors?.unitNumber || false);
        setCustomer(selectedCustomer);
        setProperty({ name: propertyId, id: propertyId });
        propertyTypeData &&
          setType({ name: propertyTypeData, id: propertyTypeData });
        if (propertyTypeData === "Floorplan") {
          setIsBuildingDrwnClear(true);
          setIsFloorplanError(errors?.floorplan || false);
          setFloorplanName({
            name: unitData[0]?.floorplan?.id,
            id: unitData[0]?.floorplan?.id,
          });
          const matchingFloorplan = floorplans.find(
            (floorplan) => floorplan?.name === unitData[0]?.floorplan?.id
          );
          const floorplanUnitCount =
            matchingFloorplan?.additional_info?.planned_renovation_unit_count ||
            null;
          setFloorplanUnitCount(floorplanUnitCount);
        } else if (propertyTypeData === "Building") {
          setIsBuildingError(errors?.floorplan || false);
          setBuildingName({
            name: unitData[0]?.building?.id,
            id: unitData[0]?.building?.id,
          });
        }
        const unitNumbers = unitData?.map((unit) => unit?.name);
        if (
          unitNumbers &&
          (unitNumbers?.length > 0 ||
            (unitNumbers?.length === 0 && unitNumbers[0] !== ""))
        ) {
          const numberOfEmptyValues = 5 - (unitNumbers.length % 5);
          const emptyValuesArray = Array(numberOfEmptyValues).fill("");

          const unitNumbersArray = [];
          for (let i = 0; i < unitNumbers.length; i += 5) {
            const chunk = unitNumbers.slice(i, i + 5);
            const chunkWithEmptyValues = chunk.concat(emptyValuesArray);
            unitNumbersArray.push(chunkWithEmptyValues.slice(0, 5));
          }
          unitNumbersArray.forEach((unitNumberArray) =>
            handleUnitNumberChange(
              unitNumberArray,
              id,
              errors?.unitNumber || false
            )
          );
          setUnit(unitNumbersArray);
        }
      }
      if (mode === "edit") {
        const unitNumbers = unitData?.map((unit) => ({
          id: unit.id,
          name: unit.name,
        }));
        const numberOfEmptyValues = 5 - (unitNumbers?.length % 5);
        const emptyValuesArray = Array(numberOfEmptyValues).fill("");

        const unitNumbersArray = [];
        for (let i = 0; i < unitNumbers?.length; i += 5) {
          const chunk = unitNumbers.slice(i, i + 5);
          const chunkWithEmptyValues = chunk.concat(emptyValuesArray);
          unitNumbersArray.push(chunkWithEmptyValues.slice(0, 5));
        }
        unitNumbersArray.forEach((unitNumberArray) =>
          handleUnitNumberChange(unitNumberArray, id)
        );
        setFloorplanUnitCount(selectedFloorplanUnitCount);
        setUnit(unitNumbersArray);
        setUnitNumbers(unitNumbersArray);
      }
      if (mode === "create" || mode === "edit") {
        setCustomer(selectedCustomer);
        setProperty(selectedProperty);
        setFloorplanName(selectedFloorplan);
        setBuildingName(selectedBuilding);
        propertyType === "Floorplan"
          ? setFloorplanName(selectedFloorplan)
          : setBuildingName(selectedBuilding);
      }
    }, []);

    const handleSubmit = async (event) => {
      const isFloorplanSelected = type?.id === "Floorplan" ? true : false;
      if (isFloorplanSelected) {
        setIsFloorplanDrwnClear(false);
        setIsBuildingDrwnClear(true);
        setIsBuildingError(false);
        setBuildingName("");
      } else {
        setIsBuildingDrwnClear(false);
        setIsFloorplanDrwnClear(true);
      }
      const uniqueUnitNumbers = unitNumbers
        ?.flat()
        ?.filter(
          (number, index, self) =>
            !isEmpty(number) && self.indexOf(number) === index
        );

      let payload = uniqueUnitNumbers.map((unit) => ({
        customer: {
          id:
            typeof customer === "object"
              ? customer?.id
              : mode === "page"
              ? selectedCustomer
              : selectedCustomer?.id,
        },
        [isFloorplanSelected ? "floorplan" : "building"]: {
          id: isFloorplanSelected ? floorplanName?.id : buildingName?.id,
        },
        name: unit,
      }));

      if (mode === "page" && payload?.length === 0) {
        payload = [
          {
            customer: {
              id:
                typeof customer === "object"
                  ? customer?.id
                  : mode === "page"
                  ? selectedCustomer
                  : selectedCustomer?.id,
            },
            [isFloorplanSelected ? "floorplan" : "building"]: {
              id: isFloorplanSelected ? floorplanName?.id : buildingName?.id,
            },
            name: "",
          },
        ];
      }

      const hasValidCustomer = !isEmpty(customer);
      const hasValidProperty = !isEmpty(property?.id);
      const hasValidFloorplan = !isEmpty(floorplanName?.id);
      const hasValidBuilding = !isEmpty(buildingName?.id);
      if (
        uniqueUnitNumbers.length !== 0 &&
        hasValidProperty &&
        hasValidCustomer &&
        (isFloorplanSelected ? hasValidFloorplan : hasValidBuilding)
      )
        mode === "page"
          ? passDataToNextSection(payload, property?.id, id, "", type?.id)
          : passDataToNextSection(payload, property?.id, "create");
      else {
        if (uniqueUnitNumbers.length === 0) setIsUnitNumberError(true);
        if (!hasValidCustomer) setIsCustomerError(true);
        if (!hasValidProperty) setIsPropertyError(true);

        isFloorplanSelected
          ? !hasValidFloorplan
            ? setIsFloorplanError(true)
            : setIsFloorplanError(false)
          : !hasValidBuilding
          ? setIsBuildingError(true)
          : setIsBuildingError(false);

        mode === "page" &&
          passDataToNextSection(
            payload,
            property?.id,
            id,
            {
              property: !hasValidProperty,
              floorplan: !hasValidFloorplan,
              unitNumber: uniqueUnitNumbers?.length === 0,
            },
            type?.id
          );
      }
    };

    const handleUpdate = async (event) => {
      const flattenUnit = [].concat(...unitNumbers);
      const payload = flattenUnit
        .filter((unit) => unit !== "" && unit !== undefined)
        .map((unit) => {
          const payloadObject = {
            customer: { id: customer?.id },
            [propertyType === "Floorplan" ? "floorplan" : "building"]: {
              id:
                propertyType === "Floorplan"
                  ? floorplanName?.id
                  : buildingName?.id,
            },
            name: unit?.name,
            status: "Active",
          };
          if (unit?.id !== null) {
            payloadObject.id = unit?.id;
          }
          return payloadObject;
        });

      if (!isEmpty(unit)) {
        passDataToNextSection(payload, property?.id, "update");
      } else {
        setIsUnitNumberError(true);
      }
    };

    useImperativeHandle(ref, () => ({
      handleClick(event) {
        handleSubmit(event);
      },
    }));

    const handleUnitNumberChange = (updatedNumbers, index, error) => {
      const updatedUnit = [...unitNumbers];
      updatedUnit[index] = updatedNumbers;
      setUnitNumbers(updatedUnit);
      setIsUnitNumberError(error || false);
    };

    const handleChange = (event, setterFunction, setErrorFunction, field) => {
      const value = event.target.value;
      setterFunction(value);
      setErrorFunction(isEmpty(value));
      let searchObj = {};
      if (field === "customer") {
        setIsPropertyDrwnClear(true);
        setProperty("");
        setFloorplanUnitCount("");
        if (type?.id === "Floorplan") {
          setIsFloorplanDrwnClear(true);
          setFloorplanName("");
        } else if (type?.id === "Building") {
          setIsBuildingDrwnClear(true);
          setBuildingName("");
        }
        searchObj["customer_ids"] = value?.id;
        fetchProperties(searchObj);
      } else if (field === "property") {
        setIsPropertyDrwnClear(false);
        if (type?.id === "Floorplan") {
          setFloorplanUnitCount("");
          setIsFloorplanDrwnClear(true);
          setFloorplanName("");
        } else if (type?.id === "Building") {
          setIsBuildingDrwnClear(true);
          setBuildingName("");
        }
        if (mode === "page") {
          setFloorplanOptions(
            generateDropdownOptions(
              floorplans.filter(
                (floorplan) => floorplan?.property?.id === value?.id
              ),
              "name",
              "name"
            )
          );
          setBuildingOptions(
            generateDropdownOptions(
              buildings.filter(
                (building) => building?.property?.id === value?.id
              ),
              "name",
              "name"
            )
          );
        } else if (mode === "create" || mode === "edit") {
          searchObj["customer_ids"] = customer?.id;
          searchObj["property_ids"] = value?.id;
          fetchFloorplans(searchObj)
          fetchBuildings(searchObj);
        }
      } else if (field === "floorplan") {
        setIsFloorplanDrwnClear(false);
        if (mode === "page") {
          const filteredFloorplan = floorplans?.find(
            (floorplan) => floorplan?.name === value.id
          );
          if (filteredFloorplan) {
            const plannedRenovationUnitCount =
              filteredFloorplan.additional_info?.planned_renovation_unit_count;
            setFloorplanUnitCount(plannedRenovationUnitCount);
          } else setFloorplanUnitCount(0);
        }
        if (mode === "create") {
          const filteredFloorplanData = floorplanData?.find(
            (floorplan) => floorplan?.name === value.name
          );
          if (filteredFloorplanData) {
            const plannedRenovationUnitCount =
              filteredFloorplanData.additional_info
                ?.planned_renovation_unit_count;
            setFloorplanUnitCount(plannedRenovationUnitCount);
          } else setFloorplanUnitCount("");
        }
      } else if (field === "building") {
        setIsBuildingDrwnClear(false);
      }
    };

    const handleSearch = (
      event,
      searchType,
      setFunction,
      setErrorFunction,
      debouncedSearch,
      setDebouncedSearch
    ) => {
      handleChange(event, setFunction, setErrorFunction);
      const searchText = event.target.value;
      if (mode === "create" || mode === "edit") {
        if (debouncedSearch) {
          clearTimeout(debouncedSearch);
        }
        setDebouncedSearch(
          setTimeout(() => {
            let searchObj = {};
            if (searchText) {
              if (searchType === "property" || searchType === "floorplan") {
                searchObj["customer_ids"] = selectedCustomer.id
                  ? selectedCustomer?.id
                  : customer?.id;
                if (searchType === "floorplan")
                  searchObj["property_ids"] = selectedProperty.id
                    ? selectedProperty?.id
                    : property?.id;
              }
              searchObj["q"] = searchText;
            }
            if (searchText) {
              if (searchType === "property") {
                fetchProperties(searchObj);
              } else if (searchType === "floorplan") {
                fetchFloorplans(searchObj);
              } else if (searchType === "building") {
                fetchBuildings(searchObj);
              } else {
                fetchCustomers(searchObj);
              }
            }
          }, 1200)
        );
      }
    };

    return mode === "page" ? (
      <>
        <form className="flex flex-col gap-4">
          <div className="grid grid-cols-2 gap-[60px]">
            <DropdownField
              label={"Floorplan/Building"}
              options={floorplanBuildingOptionsRef.current}
              theme={"secondary"}
              mandatory={true}
              componentID={"addUnitBuildingUnit_dwnType"}
              value={type}
              onChange={setType}
            />
          </div>
          <div className="grid grid-cols-2 gap-[60px]">
            <InputField
              label={"PMC"}
              theme={"secondary"}
              mode={"page"}
              componentID={"addUnit_txtCustomer"}
              mandatory={true}
              type={"text"}
              isDisabled={true}
              defaultValue={selectedCustomer}
            />
            <DropdownField
              label={"Property"}
              options={propertyOptions}
              theme={"secondary"}
              componentID={"addUnit_txtProperty"}
              mode={"page"}
              isClear={isPropertyDrwnClear}
              error={isPropertyError}
              errorText={"Please select a property"}
              value={property}
              mandatory={true}
              onChange={(property) =>
                handleChange(
                  { target: { value: property } },
                  setProperty,
                  setIsPropertyError,
                  "property"
                )
              }
            />
          </div>
          {type?.name === "Floorplan" ? (
            <>
              <div className="grid grid-cols-2 gap-[60px]">
                <DropdownField
                  label={"Floorplan Name"}
                  options={floorplanOptions}
                  componentID={"addUnit_txtFloorplan"}
                  theme={"secondary"}
                  mode={"page"}
                  isClear={isFloorplanDrwnClear}
                  error={isFloorplanError}
                  errorText={"Please select a floorplan"}
                  value={floorplanName}
                  mandatory={true}
                  onChange={(floorplan) =>
                    handleChange(
                      { target: { value: floorplan } },
                      setFloorplanName,
                      setIsFloorplanError,
                      "floorplan"
                    )
                  }
                />
                <InputField
                  label={"Floorplan Unit Count"}
                  theme={"secondary"}
                  mode={"page"}
                  value={floorplanUnitCount}
                  componentID={"addUnit_txtFloorplanUnitCount"}
                  type={"text"}
                  isDisabled={true}
                />
              </div>
              <div className="flex flex-col space-y-3">
                <label className="font-inter font-medium text-[14px] leading-[17px] text-[_rgba(27,43,65,0.72)]">
                  Unit Number<span className="text-[#EC0D0D]"> *</span>{" "}
                  <span className="italic text-[#ABAEB0] font-normal">
                    {" "}
                    (One Mandatory Unit number Required)
                  </span>
                </label>
                {unit &&
                  unit.map((el, index) => (
                    <UnitNumber
                      key={index}
                      data={el}
                      id={index}
                      mode="page"
                      type={"text"}
                      onChange={handleUnitNumberChange}
                      label={"Unit"}
                      mandatory={true}
                    />
                  ))}
                {isUnitNumberError && (
                  <label className="flex justify-start text-[14px] font-medium leading-[17px] text-[#EC0D0D] text-opacity-70">
                    Please enter atleast one unit number
                  </label>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="grid grid-cols-2 gap-[60px]">
                <DropdownField
                  label={"Building Name"}
                  options={buildingOptions}
                  componentID={"addBuilding_dwnBuildingName"}
                  theme={"secondary"}
                  mode={"page"}
                  isClear={isbuildingDrwnClear}
                  error={isBuildingError}
                  errorText={"Please select a building"}
                  value={buildingName}
                  isSearchable={true}
                  mandatory={true}
                  onChange={(building) =>
                    handleChange(
                      { target: { value: building } },
                      setBuildingName,
                      setIsBuildingError,
                      "building"
                    )
                  }
                />
              </div>
              <div className="flex flex-col space-y-3">
                <label className="font-inter font-medium text-[14px] leading-[17px] text-[_rgba(27,43,65,0.72)]">
                  Building Number<span className="text-[#EC0D0D]"> *</span>{" "}
                  <span className="italic text-[#ABAEB0] font-normal">
                    {" "}
                    (One Mandatory Building number Required)
                  </span>
                </label>
                {unit &&
                  unit.map((el, index) => (
                    <UnitNumber
                      key={index}
                      data={el}
                      id={index}
                      mode="page"
                      type={"text"}
                      onChange={handleUnitNumberChange}
                      label={"Unit"}
                      mandatory={true}
                    />
                  ))}
                {isUnitNumberError && (
                  <label className="flex justify-start text-[14px] font-medium leading-[17px] text-[#EC0D0D] text-opacity-70">
                    Please enter atleast one building number
                  </label>
                )}
              </div>
            </>
          )}
          <LinkButton
            label={
              type?.id === "Floorplan"
                ? "Add Unit Number"
                : "Add Building Number"
            }
            onClick={appendUnitNumbers}
          />
        </form>
      </>
    ) : mode === "edit" ? (
      <>
        <main className="overflow-y-auto px-10 h-full">
          <form className="flex flex-col gap-6">
            <InputField
              label={"PMC"}
              theme={"secondary"}
              isDisabled={true}
              componentID={"createUnit_txtCustomer"}
              mandatory={true}
              value={customer?.name}
            />
            <InputField
              label={"Property Name"}
              isDisabled={true}
              componentID={"createUnit_txtProperty"}
              theme={"secondary"}
              mandatory={true}
              value={property?.name}
            />
            {propertyType === "Floorplan" ? (
              <>
                <InputField
                  label={"Floorplan Name"}
                  theme={"secondary"}
                  isDisabled={true}
                  componentID={"createUnit_txtFloorplan"}
                  mandatory={true}
                  value={floorplanName?.name}
                />
                <InputField
                  label={"Floorplan Unit Count"}
                  theme={"secondary"}
                  value={floorplanUnitCount}
                  componentID={"createUnit_txtFloorplanUnitCount"}
                  type={"text"}
                  isDisabled={true}
                />
              </>
            ) : (
              <>
                <InputField
                  label={"Building Name"}
                  theme={"secondary"}
                  isDisabled={true}
                  error={isBuildingError}
                  errorText={buildingErrorText}
                  componentID={"createUnit_txtBuilding"}
                  mandatory={true}
                  value={buildingName?.name}
                />
              </>
            )}
            <div className="flex flex-col space-y-3">
              <label className="font-inter font-medium text-[14px] leading-[17px] text-[_rgba(27,43,65,0.72)]">
                Unit Number<span className="text-[#EC0D0D]"> *</span>
              </label>
              {unit &&
                unit.map((el, index) => (
                  <UnitNumber
                    key={index}
                    data={el}
                    mode={"edit"}
                    id={index}
                    type={"text"}
                    onChange={handleUnitNumberChange}
                    mandatory={true}
                  />
                ))}
              {isUnitNumberError && (
                <label className="flex justify-start text-[14px] font-medium leading-[17px] text-[#EC0D0D] text-opacity-70">
                  Please enter atleast one unit number
                </label>
              )}
            </div>
            <span className="flex items-end justify-end">
              <LinkButton
                label={"Add Unit Numbers"}
                onClick={appendUnitNumbers}
              />
            </span>
          </form>
        </main>
        <footer className="bg-white mt-[10px] flex justify-end items-center shadow-[_0px_-13px_28px_rgba(0,0,0,0.025)] pr-4 gap-4 h-[86px]">
          <Button
            theme={"tertiary"}
            onClick={handleCancel}
            componentID={"editUnit_cancel"}
            buttonText={"Cancel"}
          />
          <Button
            theme={"primary"}
            buttonText={"Save"}
            componentID={"editUnit_add"}
            type={"submit"}
            onClick={handleUpdate}
          />
        </footer>
      </>
    ) : (
      <>
        <main className="overflow-y-auto px-10 h-full">
          <form className="flex flex-col gap-6">
            <DropdownField
              label={"PMC"}
              theme={"secondary"}
              options={customerOptions}
              componentID={"createUnit_txtCustomer"}
              mandatory={true}
              isSearchable={true}
              error={isCustomerError}
              errorText={"Please select a PMC"}
              value={customer}
              searchText={(searchText) =>
                handleSearch(
                  { target: { value: searchText } },
                  "customer",
                  setCustomerSearchValue,
                  setIsCustomerError,
                  customerDebouncedSearch,
                  setCustomerDebouncedSearch
                )
              }
              onChange={(customer) =>
                handleChange(
                  { target: { value: customer } },
                  setCustomer,
                  setIsCustomerError,
                  "customer"
                )
              }
            />
            <DropdownField
              label={"Property Name"}
              options={propertyOptions}
              componentID={"createUnit_txtProperty"}
              theme={"secondary"}
              mandatory={true}
              isSearchable={true}
              isClear={isPropertyDrwnClear}
              error={isPropertyError}
              errorText={"Please select a property"}
              value={property}
              searchText={(searchText) =>
                handleSearch(
                  { target: { value: searchText } },
                  "property",
                  setPropertySearchValue,
                  setIsPropertyError,
                  propertyDebouncedSearch,
                  setPropertyDebouncedSearch
                )
              }
              onChange={(property) =>
                handleChange(
                  { target: { value: property } },
                  setProperty,
                  setIsPropertyError,
                  "property"
                )
              }
            />
            <DropdownField
              label={"Floorplan/Building"}
              options={floorplanBuildingOptionsRef.current}
              theme={"secondary"}
              mandatory={true}
              componentID={"createUnit_drwnPropertyFloorplan"}
              isSearchable={true}
              value={type}
              onChange={setType}
            />
            {type?.name === "Floorplan" ? (
              <>
                <DropdownField
                  label={"Floorplan Name"}
                  theme={"secondary"}
                  options={floorplanOptions}
                  componentID={"createUnit_txtFloorplan"}
                  mandatory={true}
                  isClear={isFloorplanDrwnClear}
                  isSearchable={true}
                  error={isFloorplanError}
                  errorText={"Please select a floorplan"}
                  value={floorplanName}
                  searchText={(searchText) =>
                    handleSearch(
                      { target: { value: searchText } },
                      "floorplan",
                      setFloorplanSearchValue,
                      setIsFloorplanError,
                      floorplanDebouncedSearch,
                      setFloorplanDebouncedSearch
                    )
                  }
                  onChange={(floorplan) =>
                    handleChange(
                      { target: { value: floorplan } },
                      setFloorplanName,
                      setIsFloorplanError,
                      "floorplan"
                    )
                  }
                />
                <InputField
                  label={"Floorplan Unit Count"}
                  theme={"secondary"}
                  isDisabled={true}
                  componentID={"createUnit_txtFloorplanUnitCount"}
                  value={floorplanUnitCount}
                />
              </>
            ) : (
              <>
                <DropdownField
                  label={"Building Name"}
                  theme={"secondary"}
                  options={buildingOptions}
                  componentID={"createUnit_dwnBuilding"}
                  mandatory={true}
                  isClear={isbuildingDrwnClear}
                  isSearchable={true}
                  error={isBuildingError}
                  errorText={"Please select a building"}
                  value={buildingName}
                  searchText={(searchText) =>
                    handleSearch(
                      { target: { value: searchText } },
                      "building",
                      setBuildingSearchValue,
                      setIsBuildingError,
                      buildingDebouncedSearch,
                      setBuildingDebouncedSearch
                    )
                  }
                  onChange={(building) =>
                    handleChange(
                      { target: { value: building } },
                      setBuildingName,
                      setIsBuildingError,
                      "building"
                    )
                  }
                />
              </>
            )}
            <div className="flex flex-col space-y-3">
              <label className="font-inter font-medium text-[14px] leading-[17px] text-[_rgba(27,43,65,0.72)]">
                Unit Number<span className="text-[#EC0D0D]"> *</span>{" "}
                <span className="italic text-[#ABAEB0] font-normal">
                  (One Mandatory Unit number Required)
                </span>
              </label>
              {unit &&
                unit.map((el, index) => (
                  <UnitNumber
                    key={index}
                    data={el}
                    id={index}
                    type={"text"}
                    onChange={handleUnitNumberChange}
                    mandatory={true}
                  />
                ))}
              {isUnitNumberError && (
                <label className="flex justify-start text-[14px] font-medium leading-[17px] text-[#EC0D0D] text-opacity-70">
                  Please enter atleast one unit number
                </label>
              )}
            </div>
            <span className="flex items-end justify-end">
              <LinkButton
                label={"Add Unit Numbers"}
                onClick={appendUnitNumbers}
              />
            </span>
          </form>
        </main>
        <footer className="bg-white mt-[10px] flex justify-end items-center shadow-[_0px_-13px_28px_rgba(0,0,0,0.025)] pr-4 gap-4 h-[86px]">
          <Button
            theme={"tertiary"}
            onClick={handleCancel}
            componentID={"createUnit_cancel"}
            buttonText={"Cancel"}
          />
          <Button
            theme={"primary"}
            processing={isLoading}
            buttonText={"Add"}
            componentID={"createUnit_add"}
            type={"submit"}
            onClick={handleSubmit}
          />
        </footer>
      </>
    );
  }
);

export default UnitForm;