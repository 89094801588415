import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={25}
    height={25}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_744_3755)">
      <path
        d="M11.6661 15.1665L12.4554 14.5525L12.4553 14.5523L12.4548 14.5516L12.4526 14.5488L12.4439 14.5377L12.4099 14.4939L12.2782 14.3247L11.7879 13.6943L10.1163 11.5454C8.77801 9.82521 7.10163 7.6707 5.74847 5.93258C5.66409 5.82215 5.73938 5.6665 5.87948 5.6665H22.1078C22.2694 5.6665 22.3294 5.83042 22.2499 5.93339L23.0411 6.54484L22.2521 5.93053C20.8986 7.669 19.2215 9.82453 17.8827 11.5454L16.211 13.6943L15.7207 14.3247L15.5891 14.4939L15.555 14.5377L15.5464 14.5488L15.5442 14.5516L15.5436 14.5523L15.5435 14.5525L16.3328 15.1665L15.5435 14.5526L15.3328 14.8234V15.1665V20.9998C15.3328 21.7309 14.7305 22.3332 13.9995 22.3332C13.2684 22.3332 12.6661 21.7309 12.6661 20.9998V15.1665V14.8234L12.4555 14.5526L11.6661 15.1665Z"
        stroke="#5B5B5B"
        strokeWidth={2}
      />
    </g>
    <defs>
      <clipPath id="clip0_744_3755">
        <rect width={28} height={28} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SVGComponent;
