import React from "react";
import { SearchIcon } from "../../../assets";
import SearchInputStyles from "../../../styles/components/searchInput";

function SearchInput({
  theme,
  searchValue,
  onChange,
  componentID,
  placeholder,
}) {
  return (
    <div className="relative">
      <input
        type="search"
        id={componentID}
        className={
          theme === "secondary"
            ? SearchInputStyles.secondary
            : SearchInputStyles.primary
        }
        placeholder={placeholder}
        value={searchValue}
        onChange={onChange}
      />
      <div className={SearchInputStyles.searchIconContainer}>
        <SearchIcon />
      </div>
    </div>
  );
}

export default SearchInput;
