import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="#000000"
      d="M12 22.35c-5.523 0-10-4.478-10-10 0-5.523 4.477-10 10-10a9.985 9.985 0 0 1 8 3.999h-2.71a8 8 0 1 0 .001 12h2.71a9.985 9.985 0 0 1-8.001 4Zm7-6v-3h-8v-2h8v-3l5 4-5 4Z"
    />
  </svg>
)
export default SvgComponent