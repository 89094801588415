import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.0206 10.0779L13.8758 12.933L12.933 13.8758L10.0778 11.0207C9.05125 11.842 7.74931 12.3333 6.33331 12.3333C3.02131 12.3333 0.333313 9.64534 0.333313 6.33334C0.333313 3.02134 3.02131 0.333344 6.33331 0.333344C9.64531 0.333344 12.3333 3.02134 12.3333 6.33334C12.3333 7.74934 11.842 9.05128 11.0206 10.0779ZM9.68311 9.58321C10.4983 8.74308 11 7.59708 11 6.33334C11 3.75501 8.91165 1.66668 6.33331 1.66668C3.75498 1.66668 1.66665 3.75501 1.66665 6.33334C1.66665 8.91168 3.75498 11 6.33331 11C7.59705 11 8.74305 10.4983 9.58318 9.68314L9.68311 9.58321Z"
      fill="#555555"
    />
  </svg>
);
export default SVGComponent;
