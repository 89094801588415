import {
  Routes,
  Route,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";
import InspectionCreation from "../pages/inspection/Add";
import InspectionSummary from "../pages/inspection/Index";
import QuoteMatrixSummary from "../pages/quote_matrix/Index";
import ViewInspection from "../pages/inspection/View";
import ViewQuoteMatrix from "../pages/quote_matrix/View";
import { useSelector, shallowEqual } from "react-redux";
import AddQuoteMatrix from "../pages/quote_matrix/Add";
import SummaryQuote from "../pages/quote/View";
import {
  RenovationSetup,
  RenovationCreateForm,
} from "../pages/renovation_setup";
import Login from "../pages/login/Login";
import ProtectedRoute from "./protectedRoute";

function App() {
  const { accessToken } = useSelector(
    ({ auth }) => ({
      accessToken: auth.accessToken,
    }),
    shallowEqual
  );

  return (
    <Router>
      <Routes>
        <Route path={"/"} element={<Login />} />
        <Route
          path={"/renovation-setup"}
          element={
            <ProtectedRoute activeTab={"Renovation Setup"}>
              <RenovationSetup />
            </ProtectedRoute>
          }
        />
        <Route
          path={"/renovation-setup/create"}
          element={
            <ProtectedRoute activeTab={"Renovation Setup"}>
              <RenovationCreateForm />
            </ProtectedRoute>
          }
        />
        <Route
          path={"/inspections"}
          element={
            <ProtectedRoute activeTab={"Inspections"}>
              <InspectionSummary />
            </ProtectedRoute>
          }
        />
        <Route
          path={"/create-inspection"}
          element={
            <ProtectedRoute activeTab={"Inspections"}>
              <InspectionCreation />
            </ProtectedRoute>
          }
        />
        <Route
          path={"/inspection/:id"}
          element={
            <ProtectedRoute activeTab={"Inspections"}>
              <ViewInspection />
            </ProtectedRoute>
          }
        />
        <Route
          path={"/quote-matrix"}
          element={
            <ProtectedRoute activeTab={"Quote Matrix"}>
              <QuoteMatrixSummary />
            </ProtectedRoute>
          }
        />
        <Route
          path={"/get-quote-matrix"}
          element={
            <ProtectedRoute activeTab={"Inspections"}>
              <AddQuoteMatrix />
            </ProtectedRoute>
          }
        />
        <Route path={"/quotes/customer/view?"} element={<SummaryQuote />} />
        <Route
          path={"/quote-matrix/:id"}
          element={
            <ProtectedRoute activeTab={"Quote Matrix"}>
              <ViewQuoteMatrix />
            </ProtectedRoute>
          }
        />
        <Route
          path={"/quote-matrix/:id"}
          element={
            <ProtectedRoute activeTab={"Quote Matrix"}>
              <ViewQuoteMatrix />
            </ProtectedRoute>
          }
        />
        <Route
          path="*"
          element={
            accessToken ? (
              <Navigate to={"/inspections"} />
            ) : (
              <Navigate to={"/"} />
            )
          }
        />
      </Routes>
    </Router>
  );
}

export default App;