const moment = require("moment");

const getFollowingDate = (numberOfMonths) => {
  const currentDate = moment();
  // Add one month to the current date
  const oneMonthAhead = currentDate.add(numberOfMonths, "month");
  // Format the date in your desired format
  const formattedDate = oneMonthAhead.format("MM/DD/YYYY");
  return formattedDate;
};

export default getFollowingDate;
