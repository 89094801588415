/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {
  useEffect,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import {
  InputField,
  Button,
  DropdownField,
} from "../../components";
import {
  CUSTOMERS_API_URL,
  PROPERTIES_API_URL,
  USERS_API_URL,
} from "../../constants";
import generateDropdownOptions from "../../utils/dropdownOptionsGenerator";
import qs from "qs";
import { httpAPICall } from "../../utils";
import {
  statusMapping,
  propertyStatusOptions,
} from "../../utils/statusMappings";
import { timeZones } from "../../utils";
import { getUSStates } from "../../utils/stateCities";
import { isEmpty, checkFieldValue } from "../../utils/isEmpty";

const PropertyForm = forwardRef(
  (
    {
      mode,
      passDataToNextSection,
      handleCancel,
      selectedCustomer,
      propertyID,
      id,
      propertyData,
      onsiteManagerData,
      errors,
      isLoading,
    },
    ref
  ) => {
    const [customer, setCustomer] = useState("");
    const [property, setProperty] = useState("");
    const [propertyDebouncedSearch, setPropertyDebouncedSearch] =
      useState(null);
    const [customerDebouncedSearch, setCustomerDebouncedSearch] =
      useState(null);
    const [propertyType, setPropertyType] = useState("");
    const [lowesAcct, setLowesAcct] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [zip, setZip] = useState("");
    const [timeZone, setTimeZone] = useState("");
    const [unitsRenovated, setUnitsRenovated] = useState("");
    const [onsiteManagerFirstName, setOnsiteManagerFirstName] = useState("");
    const [onsiteManagerLastName, setOnsiteManagerLastName] = useState("");
    const [onsiteManagerPhone, setOnsiteManagerPhone] = useState("");
    const [onsiteManagerEmail, setOnsiteManagerEmail] = useState("");
    const [supplierWarehouseID, setSupplierWarehouseID] = useState("");
    const [supplierPurchaseDeptEmail, setSupplierPurchaseDeptEmail] =
      useState("");
    const [isOnsiteManagerFirstNameError, setIsOnsiteManagerFirstNameError] =
      useState(false);
    const [isOnsiteManagerLastNameError, setIsOnsiteManagerLastNameError] =
      useState(false);
    const [isOnsiteManagerPhoneError, setIsOnsiteManagerPhoneError] =
      useState(false);
    const [isOnsiteManagerEmailError, setIsOnsiteManagerEmailError] =
      useState(false);
    const [supplierPurchaseDeptEmailError, setSupplierPurchaseDeptEmailError] =
      useState(false);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [isCustomerError, setIsCustomerError] = useState(false);
    const [isPropertyError, setIsPropertyError] = useState(false);
    const [isPropertyTypeError, setIsPropertyTypeError] = useState(false);
    const [isLowesAcctError, setIsLowesAcctError] = useState(false);
    const [isAddress1Error, setIsAddress1Error] = useState(false);
    const [isCityError, setIsCityError] = useState(false);
    const [isStateError, setIsStateError] = useState(false);
    const [isZipError, setIsZipError] = useState(false);
    const [propertyErrorText, setPropertyErrorText] = useState(
      "Please enter the property name"
    );
    const [lowesAcctErrorText, setLowesAcctErrorText] = useState(
      "Please enter the Lowes account number"
    );
    const [isTimeZoneError, setIsTimeZoneError] = useState(false);
    const [customerSearchValue, setCustomerSearchValue] = useState("");
    const [onsiteManager, setOnsiteManager] = useState("");
    const [status, setStatus] = useState([]);
    const [isSave, setIsSave] = useState(false);
    let states = getUSStates();

    useEffect(() => {
      setPropertyType({ name: "Multi Family", id: "Multifamily Property" });
      if (mode === "edit") {
        fetchPropertyData(propertyID);
      }
      if (mode === "create") {
        fetchCustomers();
        return () => {
          if (propertyDebouncedSearch) clearTimeout(propertyDebouncedSearch);
          if (customerDebouncedSearch) clearTimeout(customerDebouncedSearch);
        };
      }
      if (mode === "page") {
        setPropertyErrorText(
          errors?.propertyErrorText
            ? errors?.propertyErrorText
            : propertyErrorText
        );
        setLowesAcctErrorText(
          errors?.lowesAcctErrorText
            ? errors?.lowesAcctErrorText
            : lowesAcctErrorText
        );
        setIsPropertyError(errors?.property || false);
        setIsLowesAcctError(errors?.lowesAcct || false);
        setIsAddress1Error(errors?.address1 || false);
        setIsCityError(errors?.city || false);
        setIsZipError(errors?.zip || false);
        setIsStateError(errors?.state || false);
        setIsTimeZoneError(errors?.timeZone || false);
        setSupplierWarehouseID(propertyData?.additional_info?.warehouse_number);
        setIsOnsiteManagerEmailError(errors?.onsiteManagerEmail);
        setIsOnsiteManagerLastNameError(errors?.onsiteManagerLastName);
        setIsOnsiteManagerFirstNameError(errors?.onsiteManagerFirstName);
        setIsOnsiteManagerPhoneError(errors?.onsiteManagerPhone);
        setOnsiteManagerEmail(onsiteManagerData?.email || null);
        setOnsiteManagerFirstName(onsiteManagerData?.first_name || null);
        setOnsiteManagerLastName(onsiteManagerData?.last_name || null);
        setOnsiteManagerPhone(onsiteManagerData?.phone_number || null);
        if (propertyData?.warehouse) {
          setSupplierPurchaseDeptEmail(propertyData?.warehouse?.contact_email);
          setSupplierWarehouseID(
            propertyData?.warehouse?.external_reference_id
          );
        }
        setCustomer(selectedCustomer);
        setProperty(propertyData?.name);
        setPropertyType({ name: "Multi Family", id: "Multifamily Property" })
        setLowesAcct(propertyData?.accounts[1]?.account_number || null);
        setAddress1(propertyData?.address?.address1);
        setAddress2(propertyData?.address?.address2);
        setTimeZone(() => timeZones.find(timeZone => timeZone.id === propertyData?.time_zone?.name))
        setUnitsRenovated(
          propertyData?.additional_info?.planned_renovation_unit_count_per_month
        );
        setCity(propertyData?.address?.city);
        setState(states.find((state) => state?.id === propertyData?.address?.state) || "");
        setZip(propertyData?.address?.zip);
      }
    }, []);

    const fetchPropertyData = async (property_id) => {
      try {
        const response = await httpAPICall(
          PROPERTIES_API_URL + `/${property_id}`,
          "GET",
          {}
        );
        const onsiteManager = await httpAPICall(
          USERS_API_URL +
            `?access_level_id=${property_id}&access_level_type=Property&customer_ids=${response?.company?.id}`,
          "GET",
          {}
        );
        setOnsiteManager(onsiteManager[0]);
        if (response) {
          setProperty(response.name);
          setPropertyType({ name: "Multi Family", id: "Multifamily Property" });
          setTimeZone(() => timeZones.find(timeZone => timeZone.id === response.time_zone));
          setLowesAcct(response.renovation_account_number);
          setAddress1(response.address?.address);
          setAddress2(response.address?.address2);
          setCity(response?.address?.city);
          setState(states.find((state) => state?.id === response?.address?.state) || "");
          setZip(response.address?.zip);
          setUnitsRenovated(
            response.additional_info?.planned_renovation_unit_count_per_month
          );
          setSupplierWarehouseID(response.warehouse?.id);
          setSupplierPurchaseDeptEmail(response.warehouse?.email);
          setStatus(
            propertyStatusOptions.find(
              (propertyStatus) =>
                statusMapping[response?.status] === propertyStatus?.name
            )
          );
          setOnsiteManagerEmail(onsiteManager[0]?.email);
          setOnsiteManagerPhone(onsiteManager[0]?.phone_number);
          setOnsiteManagerLastName(onsiteManager[0]?.last_name);
          setOnsiteManagerFirstName(onsiteManager[0]?.first_name);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const propertyTypeOptions = [
      { name: "Multi Family", id: "Multifamily Property" },
    ];

    const fetchCustomers = async (filterObj) => {
      try {
        let generatedUrl = CUSTOMERS_API_URL + `?${qs.stringify(filterObj)}`;
        let result = await httpAPICall(generatedUrl, "GET", {});
        setCustomerOptions(generateDropdownOptions(result, "name", "id"));
      } catch (error) {
        console.log(error);
      }
      setIsSave(false);
    };

    const fetchProperty = async (filterObj, searchType) => {
      try {
        let generatedUrl = PROPERTIES_API_URL + `?${qs.stringify(filterObj)}`;
        let result = await httpAPICall(generatedUrl, "GET", {});
        if (result?.length > 0) {
          if (searchType === "property") {
            setPropertyErrorText("Property already exists");
            setIsPropertyError(true);
          } else {
            setLowesAcctErrorText("Lowes account number already exists");
            setIsLowesAcctError(true);
          }
        } else {
          if (searchType === "property") {
            setIsPropertyError(false);
            setPropertyErrorText("Please enter the property name");
          } else {
            setIsLowesAcctError(false);
            setLowesAcctErrorText("Please enter the Lowes Account number");
          }
        }
      } catch (error) {
        console.log(error);
      }
      setIsSave(false);
    };

    const handleUpdate = async (event) => {
      const payload = {
        id: propertyID,
        code: property,
        name: property,
        customer: {
          id: selectedCustomer?.id,
          name: selectedCustomer?.name,
        },
        status: status?.id,
        type: propertyType?.id,
        cbsa: {
          id: 1,
        },
        time_zone: {
          name: timeZone.id,
        },
        accounts: [
          {
            type: "Maintenance",
            account_number: lowesAcct,
          },
          {
            type: "Renovation",
            account_number: lowesAcct,
          },
        ],
        address: {
          address1: checkFieldValue(address1),
          address2: checkFieldValue(address2),
          city: checkFieldValue(city),
          state: checkFieldValue(state?.id),
          zip: checkFieldValue(zip),
        },
        warehouse:
          checkFieldValue(supplierWarehouseID) &&
          checkFieldValue(supplierPurchaseDeptEmail)
            ? {
                external_reference_id: checkFieldValue(supplierWarehouseID),
                contact_email: checkFieldValue(supplierPurchaseDeptEmail),
              }
            : null,
        additional_info: {
          planned_renovation_unit_count_per_month: unitsRenovated,
        },
      };

      const onsiteManagerPayload = {
        id: onsiteManager?.id,
        first_name: onsiteManagerFirstName,
        last_name: onsiteManagerLastName,
        email: onsiteManagerEmail,
        status: onsiteManager?.status_name,
        enabled: onsiteManager?.is_enable,
        phone_number: checkFieldValue(onsiteManagerPhone),
        customer: {
          id:
            typeof customer === "object"
              ? customer?.id
              : mode === "page"
              ? selectedCustomer
              : selectedCustomer?.id,
        },
        access_level: {
          id: propertyID,
          type: "Property",
        },
      };

      try {
        if (
          !isEmpty(
            property,
            lowesAcct,
            address1,
            state,
            city,
            zip,
            timeZone,
            onsiteManagerEmail,
            onsiteManagerFirstName,
            onsiteManagerLastName,
          ) &&
          !isLowesAcctError &&
          !isZipError &&
          !isPropertyError &&
          !isAddress1Error &&
          !isStateError &&
          !isTimeZoneError &&
          !isCityError &&
          !isOnsiteManagerEmailError &&
          !isOnsiteManagerFirstNameError &&
          !isOnsiteManagerLastNameError &&
          !isOnsiteManagerPhoneError
        ) {
          passDataToNextSection(
            payload,
            "update",
            propertyID,
            onsiteManagerPayload,
            supplierPurchaseDeptEmail,
            onsiteManager?.id
          );
        } else {
          isEmpty(property) && setIsPropertyError(true);
          isEmpty(lowesAcct) && setIsLowesAcctError(true);
          isEmpty(address1) && setIsAddress1Error(true);
          isEmpty(state) && setIsStateError(true);
          isEmpty(city) && setIsCityError(true);
          isEmpty(zip) && setIsZipError(true);
          isEmpty(timeZone) && setIsTimeZoneError(true);
          isEmpty(onsiteManagerEmail) && setIsOnsiteManagerEmailError(true);
          isEmpty(onsiteManagerFirstName) &&
            setIsOnsiteManagerFirstNameError(true);
          isEmpty(onsiteManagerLastName) &&
            setIsOnsiteManagerLastNameError(true);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const handleSubmit = async (event) => {
      const payload = {
        name: property,
        code: property,
        customer: {
          id:
            typeof customer === "object"
              ? customer?.id
              : mode === "page"
              ? selectedCustomer
              : selectedCustomer?.id,
          name:
            typeof customer === "object"
              ? customer?.name
              : mode === "page"
              ? selectedCustomer
              : selectedCustomer?.name,
        },
        time_zone: {
          name: timeZone?.id,
        },
        type: propertyType?.id,
        cbsa: {
          id: 1,
        },
        accounts: [
          {
            type: "Maintenance",
            account_number: lowesAcct,
          },
          {
            type: "Renovation",
            account_number: lowesAcct,
          },
        ],
        address: {
          address1: checkFieldValue(address1),
          address2: checkFieldValue(address2),
          city: checkFieldValue(city),
          state: checkFieldValue(state?.id),
          zip: checkFieldValue(zip),
        },
        warehouse:
          checkFieldValue(supplierWarehouseID) &&
          checkFieldValue(supplierPurchaseDeptEmail)
            ? {
                external_reference_id: checkFieldValue(supplierWarehouseID),
                contact_email: checkFieldValue(supplierPurchaseDeptEmail),
              }
            : null,
        additional_info: {
          planned_renovation_unit_count_per_month: unitsRenovated,
        },
      };

      const onsiteManagerPayload = {
        first_name: onsiteManagerFirstName,
        last_name: onsiteManagerLastName,
        email: onsiteManagerEmail,
        phone_number: checkFieldValue(onsiteManagerPhone),
        customer: {
          id:
            typeof customer === "object"
              ? customer?.id
              : mode === "page"
              ? selectedCustomer
              : selectedCustomer?.id,
        },
        access_level: {
          id: null,
          type: "Property",
        },
      };

      try {
        const hasValidCustomer =
          typeof customer === "object"
            ? !isEmpty(customer)
            : !isEmpty(selectedCustomer);
        const setErrors = () => {
          (isEmpty(property) ||
            handleSearch(
              { target: { value: property } },
              "property",
              setProperty,
              setIsPropertyError,
              propertyDebouncedSearch,
              setPropertyDebouncedSearch
            )) &&
            setIsPropertyError(true);
          isEmpty(lowesAcct) && setIsLowesAcctError(true);
          isEmpty(address1) && setIsAddress1Error(true);
          isEmpty(state) && setIsStateError(true);
          isEmpty(city) && setIsCityError(true);
          isEmpty(zip) && setIsZipError(true);
          isEmpty(timeZone) && setIsTimeZoneError(true);
          isEmpty(onsiteManagerEmail) && setIsOnsiteManagerEmailError(true);
          isEmpty(onsiteManagerFirstName) &&
            setIsOnsiteManagerFirstNameError(true);
          isEmpty(onsiteManagerLastName) &&
            setIsOnsiteManagerLastNameError(true);
          if (!hasValidCustomer) setIsCustomerError(true);
        };
        if (
          hasValidCustomer &&
          !isEmpty(
            property,
            propertyType,
            lowesAcct,
            address1,
            state,
            city,
            zip,
            timeZone,
            onsiteManagerFirstName,
            onsiteManagerLastName,
            onsiteManagerEmail,
          ) &&
          !isLowesAcctError &&
          !isZipError &&
          !isPropertyError &&
          !isAddress1Error &&
          !isStateError &&
          !isCityError &&
          !isTimeZoneError &&
          !isOnsiteManagerFirstNameError &&
          !isOnsiteManagerLastNameError &&
          !isOnsiteManagerEmailError &&
          !isOnsiteManagerPhoneError
        )
          mode === "page"
            ? passDataToNextSection(
                payload,
                id,
                "",
                onsiteManagerPayload,
                supplierPurchaseDeptEmail
              )
            : passDataToNextSection(
                payload,
                "create",
                null,
                onsiteManagerPayload,
                supplierPurchaseDeptEmail
              );
        else {
          if (mode === "page") {
            setErrors();
            passDataToNextSection(
              payload,
              id,
              {
                property: isEmpty(property) || isPropertyError,
                lowesAcct: isEmpty(lowesAcct) || isLowesAcctError,
                address1: isEmpty(address1),
                state: isEmpty(state),
                city: isEmpty(city),
                zip: isEmpty(zip),
                timeZone: isEmpty(timeZone),
                propertyErrorText: propertyErrorText,
                lowesAcctErrorText: lowesAcctErrorText,
                onsiteManagerEmail: isEmpty(onsiteManagerEmail),
                onsiteManagerFirstName: isEmpty(onsiteManagerFirstName),
                onsiteManagerLastName: isEmpty(onsiteManagerLastName),
              },
              onsiteManagerPayload,
              supplierPurchaseDeptEmail
            );
          } else {
            setErrors();
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    useImperativeHandle(ref, () => ({
      handleClick(event) {
        handleSubmit(event);
      },
    }));

    const handleChange = (event, setterFunction, setErrorFunction) => {
      setErrorFunction === "setPropertyErrorText" &&
        setPropertyErrorText("Please enter the property name");
      setErrorFunction === "setPropertyErrorText" &&
        setLowesAcctErrorText("Please enter the Lowes account number");
      const value = event.target.value;
      setterFunction(value);
      setErrorFunction(isEmpty(value));
    };

    const validateEmail = (email) => {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      return emailRegex.test(email);
    };

    const validatePhoneNumber = (phoneNumber) => {
      const phoneNumberRegex = /^\d{10}$/;
      return isEmpty(phoneNumber) ? true : phoneNumberRegex.test(phoneNumber);
    };

    const handlePhoneNumberChange = (event) => {
      const value = event.target.value;
      if (value?.length <= 10 && value >= 0) {
        setOnsiteManagerPhone(value);
        setIsOnsiteManagerPhoneError(value === "" ? false : !validatePhoneNumber(value));
      }
    };

    const handleEmailChange = (event, field) => {
      const value = event.target.value;
      if (field === "Onsite manager") {
        setOnsiteManagerEmail(value);
        setIsOnsiteManagerEmailError(!validateEmail(value));
      } else {
        setSupplierPurchaseDeptEmail(value);
        !isEmpty(value)
          ? setSupplierPurchaseDeptEmailError(!validateEmail(value))
          : setSupplierPurchaseDeptEmailError(false);
      }
    };

    function searchPropertyExceptAtIndex(arr, excludedIndex, propertyValue) {
      for (let i = 0; i < arr.length; i++) {
        if (i !== excludedIndex && arr[i].name === propertyValue) {
          return true;
        }
      }
      return false;
    }

    const handleSearch = (
      event,
      searchType,
      setFunction,
      setErrorFunction,
      debouncedSearch,
      setDebouncedSearch
    ) => {
      setIsSave(true);
      const searchText = event.target.value;
      if (searchType === "property" || searchType === "lowesAcct")
        handleChange(event, setFunction, setErrorFunction);
      if (debouncedSearch) {
        clearTimeout(debouncedSearch);
      }
      setDebouncedSearch(
        setTimeout(() => {
          let searchObj = {};
          if (searchText) {
            if (searchType === "lowesAcct")
              searchObj["renovation_account_number"] = searchText;
            else if (searchType === "property") searchObj["name"] = searchText;
          }
          if (!isEmpty(searchText)) {
            if (searchType === "property" || searchType === "lowesAcct") {
              fetchProperty(searchObj, searchType);
            } else {
              if (searchText) {
                searchObj["q"] = searchText;
                fetchCustomers(searchObj);
              }
            }
          } else {
            if (searchType === "property") {
              setIsPropertyError(true);
              setPropertyErrorText("Please enter the property name");
            } else if (searchType === "lowesAcct") {
              setIsLowesAcctError(true);
              setLowesAcctErrorText("Please enter the Lowes Account number");
            }
          }
        }, 1000)
      );
    };

    const handleZipChange = (event) => {
      const value = event.target.value;
      const zipRegex = /^\d{5}$/; // Regular expression for 5-digit zip code
      const isValidZip = zipRegex.test(value);
      if (value?.length <= 6 && value >= 0) {
        setIsZipError(!isValidZip);
        setZip(value);
      }
      if (isEmpty(value)) {
        setIsZipError(false);
        setZip(value);
      }
    };

    return mode === "page" ? (
      <form className="flex flex-col gap-4">
        <InputField
          label={"PMC"}
          theme={"secondary"}
          error={isCustomerError}
          componentID={"addProperty_txtCustomer"}
          errorText={"Please enter the PMC name"}
          mode={"page"}
          mandatory={true}
          isDisabled={true}
          defaultValue={selectedCustomer}
        />
        <InputField
          label={"Property"}
          theme={"secondary"}
          error={isPropertyError}
          errorText={propertyErrorText}
          mode={"page"}
          componentID={"addProperty_txtProperty"}
          mandatory={true}
          value={property}
          onChange={(event) =>
            handleSearch(
              event,
              "property",
              setProperty,
              setIsPropertyError,
              propertyDebouncedSearch,
              setPropertyDebouncedSearch
            )
          }
        />
        <div className="grid grid-cols-2 gap-[60px] z-[1100]">
          <DropdownField
            label={"Property Type"}
            options={propertyTypeOptions}
            theme={"secondary"}
            mode={"page"}
            componentID={"addPropety_txtPropertyType"}
            error={isPropertyTypeError}
            errorText={"Please select a property type"}
            value={propertyType}
            mandatory={true}
            onChange={(propertyType) =>
              handleChange(
                { target: { value: propertyType } },
                setPropertyType,
                setIsPropertyTypeError
              )
            }
          />
          <InputField
            label={"Lowe's Acct#"}
            theme={"secondary"}
            mode={"page"}
            error={isLowesAcctError}
            componentID={"addProperty_txtLowesAcct"}
            errorText={lowesAcctErrorText}
            mandatory={true}
            value={lowesAcct}
            onChange={(event) =>
              handleSearch(
                event,
                "lowesAcct",
                setLowesAcct,
                setIsLowesAcctError,
                propertyDebouncedSearch,
                setPropertyDebouncedSearch
              )
            }
          />
        </div>
        <div className="grid grid-cols-2 gap-[60px]">
          <InputField
            label={"Address 1"}
            theme={"secondary"}
            componentID={"addProperty_txtAddress1"}
            mode={"page"}
            mandatory={true}
            error={isAddress1Error}
            errorText={"Please enter the address"}
            value={address1}
            onClick={() =>
              handleChange(
                {
                  target: { value: address1 },
                },
                setAddress1,
                setIsAddress1Error
              )
            }
            onChange={(event) => {
              handleChange(event, setAddress1, setIsAddress1Error);
            }}
          />
          <InputField
            label={"Address 2"}
            theme={"secondary"}
            componentID={"addProperty_txtAddress2"}
            mode={"page"}
            value={address2}
            onChange={(event) => setAddress2(event.target.value)}
          />
        </div>
        <div className="grid grid-cols-3 gap-[50px]">
          <DropdownField
            label={"State"}
            theme={"secondary"}
            options={states}
            mode={"page"}
            error={isStateError}
            isSearchable={true}
            componentID={"addProperty_txtState"}
            errorText={"Please select the state"}
            mandatory={true}
            value={state}
            onChange={(state) =>
              handleChange({ target: { value: state }}, setState, setIsStateError)
            }
            // onClick={() =>
            //   handleChange(
            //     { target: { value: state } },
            //     setState,
            //     setIsStateError
            //   )
            // }
          />
          <InputField
            label={"City"}
            theme={"secondary"}
            mode={"page"}
            error={isCityError}
            componentID={"addProperty_txtCity"}
            errorText={"Please enter the city"}
            mandatory={true}
            value={city}
            onChange={(event) => handleChange(event, setCity, setIsCityError)}
            onClick={() =>
              handleChange({ target: { value: city } }, setCity, setIsCityError)
            }
          />
          <InputField
            label={"Zip"}
            theme={"secondary"}
            mode={"page"}
            componentID={"addProperty_numZip"}
            error={isZipError}
            errorText={"Zip code should be 5 digits"}
            mandatory={true}
            type={"number"}
            min={0}
            value={zip}
            onChange={handleZipChange}
            onClick={() => handleZipChange({ target: { value: zip } })}
          />
        </div>
        <div className="grid grid-cols-2 gap-[60px]">
          <DropdownField
            label={"Time Zone"}
            options={timeZones}
            theme={"secondary"}
            mode={"page"}
            isSearchable={true}
            componentID={"addProperty_timeTimeZone"}
            error={isTimeZoneError}
            searchText={() => {}}
            errorText={"Please select a time zone"}
            mandatory={true}
            value={timeZone}
            onChange={(timeZone) =>
              handleChange(
                { target: { value: timeZone } },
                setTimeZone,
                setIsTimeZoneError
              )
            }
          />
          <InputField
            label={"Units Renovated/Month"}
            theme={"secondary"}
            mode={"page"}
            type={"number"}
            componentID={"addProperty_numUnitsRenovatedMonth"}
            min={0}
            value={unitsRenovated}
            onChange={(event) => setUnitsRenovated(event.target.value)}
          />
        </div>
        <div className="grid grid-cols-3 gap-[50px]">
          <InputField
            label={"Onsite Manager First Name"}
            theme={"secondary"}
            mode={"page"}
            type={"text"}
            componentID={"addOnsiteManager_txtFirstName"}
            mandatory={true}
            errorText={"Please enter the onsite manager first name"}
            error={isOnsiteManagerFirstNameError}
            value={onsiteManagerFirstName}
            onChange={(event) =>
              handleChange(
                event,
                setOnsiteManagerFirstName,
                setIsOnsiteManagerFirstNameError
              )
            }
          />
          <InputField
            label={"Onsite Manager Last Name"}
            errorText={"Please enter the onsite manager last name"}
            error={isOnsiteManagerLastNameError}
            mode={"page"}
            theme={"secondary"}
            componentID={"addOnsiteManager_txtLastName"}
            mandatory={true}
            value={onsiteManagerLastName}
            onChange={(event) =>
              handleChange(
                event,
                setOnsiteManagerLastName,
                setIsOnsiteManagerLastNameError
              )
            }
          />
          <InputField
            label={"Onsite Manager Phone"}
            errorText={"Phone number should have 10 digits"}
            theme={"secondary"}
            error={isOnsiteManagerPhoneError}
            componentID={"addOnsiteManager_numContact"}
            // mandatory={true}
            type={"number"}
            max={10}
            value={onsiteManagerPhone}
            onChange={handlePhoneNumberChange}
            onClick={() =>
              handlePhoneNumberChange({
                target: { value: onsiteManagerPhone },
              })
            }
          />
        </div>
        <div className="grid grid-cols-3 gap-[50px]">
          <InputField
            label={"Onsite Manager Email"}
            errorText={"Please enter the valid onsite manager email"}
            theme={"secondary"}
            error={isOnsiteManagerEmailError}
            componentID={"addOnsiteManager_txtEmail"}
            mandatory={true}
            value={onsiteManagerEmail}
            onChange={(event) => handleEmailChange(event, "Onsite manager")}
            onClick={() =>
              handleEmailChange({ target: { value: onsiteManagerEmail } })
            }
          />
          <InputField
            label={"Supplier Warehouse"}
            theme={"secondary"}
            componentID={"addSupplier_txtWarehouseID"}
            value={supplierWarehouseID}
            onChange={(event) => setSupplierWarehouseID(event.target.value)}
          />
          <InputField
            label={"Supplier Purchase Dept Email"}
            theme={"secondary"}
            errorText={"Please enter the valid supplier purchase dept email"}
            error={supplierPurchaseDeptEmailError}
            componentID={"addSupplier_txtPurchaseDeptEmail"}
            value={supplierPurchaseDeptEmail}
            onChange={(event) => handleEmailChange(event, "Supplier")}
          />
        </div>
      </form>
    ) : (
      <>
        <main className="overflow-y-auto px-10 h-full">
          <form className="flex flex-col gap-4">
            {mode === "create" ? (
              <span className="z-[1101]">
                <DropdownField
                  label={"PMC"}
                  options={customerOptions}
                  theme={"secondary"}
                  error={isCustomerError}
                  isSearchable={true}
                  componentID={"createPropety_txtCustomer"}
                  errorText={"Please select a PMC"}
                  value={selectedCustomer}
                  searchText={(searchText) =>
                    handleSearch(
                      { target: { value: searchText } },
                      "customer",
                      setCustomerSearchValue,
                      setIsCustomerError,
                      customerDebouncedSearch,
                      setCustomerDebouncedSearch
                    )
                  }
                  mandatory={true}
                  onChange={(customer) =>
                    handleChange(
                      { target: { value: customer } },
                      setCustomer,
                      setIsCustomerError
                    )
                  }
                />
              </span>
            ) : (
              <InputField
                label={"PMC"}
                options={customerOptions}
                theme={"secondary"}
                componentID={"editProperty_txtCustomer"}
                isDisabled={true}
                value={selectedCustomer?.name}
                mandatory={true}
              />
            )}
            <InputField
              label={"Property Name"}
              theme={"secondary"}
              mandatory={true}
              value={property}
              error={isPropertyError}
              componentID={
                mode === "create"
                  ? "createProperty_txtProperty"
                  : "editProperty_txtProperty"
              }
              errorText={propertyErrorText}
              onChange={(event) =>
                handleSearch(
                  event,
                  "property",
                  setProperty,
                  setIsPropertyError,
                  propertyDebouncedSearch,
                  setPropertyDebouncedSearch
                )
              }
              onClick={
                mode === "create"
                  ? () =>
                      handleSearch(
                        { target: { value: property } },
                        "property",
                        setProperty,
                        setIsPropertyError,
                        propertyDebouncedSearch,
                        setPropertyDebouncedSearch
                      )
                  : undefined
              }
            />
            <div className="grid grid-cols-2 gap-[30px] z-[1100]">
              <DropdownField
                label={"Property Type"}
                options={propertyTypeOptions}
                theme={"secondary"}
                componentID={
                  mode === "create"
                    ? "createProperty_txtPropertyType"
                    : "editProperty_txtPropertyType"
                }
                mandatory={true}
                value={propertyType}
                error={isPropertyTypeError}
                errorText={"Please select a property type"}
                onChange={(propertyType) =>
                  handleChange(
                    { target: { value: propertyType } },
                    setPropertyType,
                    setIsPropertyTypeError
                  )
                }
              />
              <InputField
                label={"Lowe's Acct#"}
                theme={"secondary"}
                mandatory={true}
                componentID={
                  mode === "create"
                    ? "createProperty_txtLowesAcct"
                    : "editProperty_numLowesAcct"
                }
                value={lowesAcct}
                error={isLowesAcctError}
                errorText={lowesAcctErrorText}
                onChange={(event) =>
                  handleSearch(
                    event,
                    "lowesAcct",
                    setLowesAcct,
                    setIsLowesAcctError,
                    propertyDebouncedSearch,
                    setPropertyDebouncedSearch
                  )
                }
              />
            </div>
            <InputField
              label={"Address 1"}
              theme={"secondary"}
              mandatory={true}
              componentID={
                mode === "create"
                  ? "createProperty_txtAddress1"
                  : "editProperty_txtAddress1"
              }
              value={address1}
              error={isAddress1Error}
              errorText={"Please enter the address"}
              onClick={() =>
                handleChange(
                  { target: { value: address1 } },
                  setAddress1,
                  setIsAddress1Error
                )
              }
              onChange={(event) => {
                handleChange(event, setAddress1, setIsAddress1Error);
              }}
            />
            <div className="grid grid-cols-2 gap-[30px]">
              <DropdownField
                label={"State"}
                theme={"secondary"}
                options={states}
                error={isStateError}
                componentID={
                  mode === "create"
                    ? "createProperty_txtState"
                    : "editPropety_txtState"
                }
                errorText={"Please select the state"}
                isSearchable={true}
                mandatory={true}
                value={state}
                onChange={(state) =>
                  handleChange({ target: { value: state }}, setState, setIsStateError)
                }
                // onClick={() =>
                //   handleChange(
                //     { target: { value: state } },
                //     setState,
                //     setIsStateError
                //   )
                // }
              />
              <InputField
                label={"City"}
                theme={"secondary"}
                error={isCityError}
                componentID={
                  mode === "create"
                    ? "createProperty_txtCity"
                    : "editProperty_txtCity"
                }
                errorText={"Please enter the city"}
                mandatory={true}
                value={city}
                onChange={(event) =>
                  handleChange(event, setCity, setIsCityError)
                }
                onClick={() =>
                  handleChange(
                    { target: { value: city } },
                    setCity,
                    setIsCityError
                  )
                }
              />
            </div>
            <InputField
              label={"Address 2"}
              theme={"secondary"}
              componentID={
                mode === "create"
                  ? "createProperty_txtAddress2"
                  : "editProperty_txtAddress2"
              }
              type={"text"}
              value={address2}
              onChange={(event) => setAddress2(event.target.value)}
            />
            <div className="grid grid-cols-2 gap-[30px]">
              <InputField
                label={"Zip"}
                theme={"secondary"}
                componentID={
                  mode === "create"
                    ? "createProperty_numZip"
                    : "editPropety_numZip"
                }
                mandatory={true}
                type={"number"}
                value={zip}
                error={isZipError}
                errorText={"Zip code should be 5 digits"}
                onChange={(event) => handleZipChange(event)}
                onClick={() => handleZipChange({ target: { value: zip } })}
              />
              <DropdownField
                label={"Time Zone"}
                options={timeZones}
                theme={"secondary"}
                isSearchable={true}
                componentID={
                  mode === "create"
                    ? "createProperty_timeTimeZone"
                    : "editProperty_timeTimeZone"
                }
                mandatory={true}
                value={timeZone}
                searchText={() => {}}
                error={isTimeZoneError}
                errorText={"Please select a time zone"}
                onChange={(timeZone) =>
                  handleChange(
                    { target: { value: timeZone } },
                    setTimeZone,
                    setIsTimeZoneError
                  )
                }
              />
            </div>
            <div className="grid grid-cols-2 gap-[30px]">
              <InputField
                label={"Units Renovated/Month"}
                theme={"secondary"}
                type={"number"}
                componentID={
                  mode === "create"
                    ? "createProperty_numUnitsRenovatedMonth"
                    : "editPropery_numUnitsRenovatedMonth"
                }
                value={unitsRenovated}
                min={0}
                onChange={(event) => setUnitsRenovated(event.target.value)}
              />
              {mode === "edit" && (
                <DropdownField
                  label={"Status"}
                  options={propertyStatusOptions}
                  theme={"secondary"}
                  componentID={"editCustomer_txtStatus"}
                  value={status}
                  onChange={(status) => setStatus(status)}
                />
              )}
            </div>
            <div className="grid grid-cols-2 gap-[30px]">
              <InputField
                label={"Onsite Manager First Name"}
                errorText={"Please enter the onsite manager first name"}
                error={isOnsiteManagerFirstNameError}
                theme={"secondary"}
                componentID={"addOnsiteManager_txtFirstName"}
                mandatory={true}
                value={onsiteManagerFirstName}
                onChange={(event) =>
                  handleChange(
                    event,
                    setOnsiteManagerFirstName,
                    setIsOnsiteManagerFirstNameError
                  )
                }
              />
              <InputField
                label={"Onsite Manager Last Name"}
                errorText={"Please enter the onsite manager last name"}
                error={isOnsiteManagerLastNameError}
                theme={"secondary"}
                componentID={"addOnsiteManager_txtLastName"}
                mandatory={true}
                value={onsiteManagerLastName}
                onChange={(event) =>
                  handleChange(
                    event,
                    setOnsiteManagerLastName,
                    setIsOnsiteManagerLastNameError
                  )
                }
              />
            </div>
            <div className="grid grid-cols-2 gap-[30px]">
              <InputField
                label={"Onsite Manager Phone"}
                errorText={"Phone number should have 10 digits"}
                theme={"secondary"}
                error={isOnsiteManagerPhoneError}
                componentID={"addOnsiteManager_numContact"}
                // mandatory={true}
                type={"number"}
                max={10}
                value={onsiteManagerPhone}
                onChange={handlePhoneNumberChange}
              />
              <InputField
                label={"Onsite Manager Email"}
                errorText={"Please enter the valid onsite manager email"}
                theme={"secondary"}
                error={isOnsiteManagerEmailError}
                componentID={"addOnsiteManager_txtEmail"}
                mandatory={true}
                value={onsiteManagerEmail}
                onChange={(event) => handleEmailChange(event, "Onsite manager")}
              />
            </div>
            <div className="grid grid-cols-2 gap-[30px]">
              <InputField
                label={"Supplier Warehouse"}
                theme={"secondary"}
                componentID={"addSupplier_txtWarehouseID"}
                value={supplierWarehouseID}
                onChange={(event) => setSupplierWarehouseID(event.target.value)}
              />
              <InputField
                label={"Supplier Purchase Dept Email"}
                theme={"secondary"}
                componentID={"addSupplier_txtPurchaseDeptEmail"}
                errorText={
                  "Please enter the valid supplier purchase dept email"
                }
                error={supplierPurchaseDeptEmailError}
                value={supplierPurchaseDeptEmail}
                onChange={(event) => handleEmailChange(event, "Supplier")}
              />
            </div>
          </form>
        </main>
        <footer className="bg-white mt-[10px] flex justify-end items-center shadow-[_0px_-13px_28px_rgba(0,0,0,0.025)] pr-4 gap-4 h-[86px]">
          <Button
            theme={"tertiary"}
            onClick={handleCancel}
            componentID={
              mode === "create"
                ? "createProperty_btnCancel"
                : "editPropety_btnCancel"
            }
            buttonText={"Cancel"}
          />
          <Button
            theme={"primary"}
            buttonText={mode === "create" ? "Add" : "Save"}
            type={"submit"}
            disabled={isSave}
            processing={isLoading}
            componentID={
              mode === "create"
                ? "createProperty_btnAdd"
                : "editProperty_btnAdd"
            }
            onClick={mode === "create" ? handleSubmit : handleUpdate}
          />
        </footer>
      </>
    );
  }
);

export default PropertyForm;
