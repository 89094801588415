/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { InputField, Button, DropdownField } from "../../components";
import generateDropdownOptions from "../../utils/dropdownOptionsGenerator";
import { httpAPICall } from "../../utils";
import qs from "qs";
import {
  CUSTOMERS_API_URL,
  PROPERTIES_API_URL,
  FLOORPLANS_API_URL,
  BUILDINGS_API_URL,
} from "../../constants";
import { isEmpty, checkFieldValue } from "../../utils/isEmpty";
import {
  roofTypeOptions,
  sidingOptions,
} from "../../utils/floorplanDropdownOptions";

const FloorplanForm = forwardRef(
  (
    {
      mode,
      passDataToNextSection,
      handleCancel,
      selectedCustomer,
      selectedProperty,
      properties,
      id,
      errors,
      floorplanData,
      buildingData,
      propertyType,
      propertyTypeData,
      floorplanID,
      buildingID,
      isLoading,
      isPropertyType
    },
    ref
  ) => {
    const [customer, setCustomer] = useState("");
    const [property, setProperty] = useState("");
    const [floorplanName, setFloorplanName] = useState("");
    const [buildingName, setBuildingName] = useState("");
    const [customerDebouncedSearch, setCustomerDebouncedSearch] =
      useState(null);
    const [propertyDebouncedSearch, setPropertyDebouncedSearch] =
      useState(null);
    const [floorplanDebouncedSearch, setFloorplanDebouncedSearch] =
      useState(null);
    const [buildingDebouncedSearch, setBuildingDebouncedSearch] =
      useState(null);
    const [floorplanUnitCount, setFloorplanUnitCount] = useState(null);
    const [bedrooms, setBedrooms] = useState(null);
    const [halfBathrooms, setHalfBathrooms] = useState(null);
    const [fullBathrooms, setFullBathrooms] = useState(null);
    const [sqft, setSqft] = useState(null);
    const [roofType, setRoofType] = useState("");
    const [stories, setStories] = useState(null);
    const [siding, setSiding] = useState("");
    const [customerOptions, setCustomerOptions] = useState([]);
    const [customerSearchValue, setCustomerSearchValue] = useState("");
    const [propertySearchValue, setPropertySearchValue] = useState("");
    const [isCustomerError, setIsCustomerError] = useState(false);
    const [isPropertyError, setIsPropertyError] = useState(false);
    const [isFloorplanError, setIsFloorplanError] = useState(false);
    const [isBuildingError, setIsBuildingError] = useState(false);
    const [floorplanErrorText, setFloorplanErrorText] = useState(
      "Please enter the floorplan name"
    );
    const [buildingErrorText, setBuildingErrorText] = useState(
      "Please enter the building name"
    );
    const [isFloorplanUnitCountError, setIsFloorplanUnitCountError] =
      useState(false);
    const [propertyOptions, setPropertyOptions] = useState([]);
    const floorplanBuildingOptionsRef = useRef([
      { name: "Floorplan", id: "Floorplan" },
      { name: "Building", id: "Building" },
    ]);
    const [type, setType] = useState(
      propertyType === "Building"
        ? { name: "Building", id: "Building" }
        : { name: "Floorplan", id: "Floorplan" }
    );
    const [status, setStatus] = useState("");
    const [statusOptions, setStatusOptions] = useState([]);
    const [isSave, setIsSave] = useState(false);
    const [propertyDrwnClear, setPropertyDrwnClear] = useState(false);
    const [existingFloorplan, setExistingFloorplan] = useState("");
    const [existingBuilding, setExistingBuilding] = useState("");

    const fetchCustomers = async (filterObj) => {
      try {
        let generatedUrl = CUSTOMERS_API_URL + `?${qs.stringify(filterObj)}`;
        const response = await httpAPICall(generatedUrl, "GET", {});
        setCustomerOptions(generateDropdownOptions(response, "name", "id"));
      } catch (error) {
        console.log(error);
      }
    };

    const fetchProperties = async (filterObj) => {
      try {
        let generatedUrl = PROPERTIES_API_URL + `?${qs.stringify(filterObj)}`;
        const response = await httpAPICall(generatedUrl, "GET", {});
        setPropertyOptions(generateDropdownOptions(response, "name", "id"));
      } catch (error) {
        console.log(error);
      }
    };

    const fetchFloorplanData = async (floorplanID) => {
      try {
        const response = await httpAPICall(
          FLOORPLANS_API_URL + `/${floorplanID}`,
          "GET",
          {}
        );
        if (response) {
          setFloorplanName(response.name);
          setExistingFloorplan(response.name);
          setFloorplanUnitCount(
            response.additional_info?.planned_renovation_unit_count
          );
          setStatus(
            response.is_enabled
              ? { name: "Enabled", id: true }
              : { name: "Disabled", id: false }
          );
          setSqft(response?.square_feet);
          setFullBathrooms(response?.number_of_full_bathrooms);
          setHalfBathrooms(response.number_of_half_bathrooms);
          setBedrooms(response.number_of_bedrooms);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const fetchBuildingData = async (buildingID) => {
      try {
        const response = await httpAPICall(
          BUILDINGS_API_URL + `/${buildingID}`,
          "GET",
          {}
        );
        if (response) {
          setBuildingName(response.name);
          setExistingBuilding(response.name);
          setFloorplanUnitCount(
            response.additional_info?.planned_renovation_unit_count
          );
          setStatus(
            !response.is_disabled
              ? { name: "Enabled", id: true }
              : { name: "Disabled", id: false }
          );
          setRoofType(
            roofTypeOptions.find(
              (roofType) => roofType.id === response?.roof_type
            )
          );
          setSiding(
            sidingOptions.find((siding) => siding.id === response?.siding_type)
          );
          setStories(response?.stories_count);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const fetchFloorplans = async (filterObj) => {
      try {
        let generatedUrl = FLOORPLANS_API_URL + `?${qs.stringify(filterObj)}`;
        let result = await httpAPICall(generatedUrl, "GET", {});
        if (result?.length > 0) {
          if (existingFloorplan !== filterObj?.name) {
            setFloorplanErrorText("Floorplan already exists");
            setIsFloorplanError(true); 
          } else {
            setIsFloorplanError(false);
          }
        } else {
          setIsFloorplanError(false);
          setFloorplanErrorText("Please enter the floorplan name");
        }
      } catch (error) {
        console.log(error);
      }
      setIsSave(false);
    };

    const fetchBuildings = async (filterObj) => {
      try {
        let generatedUrl = BUILDINGS_API_URL + `?${qs.stringify(filterObj)}`;
        let result = await httpAPICall(generatedUrl, "GET", {});
        if (result.length > 0) {
          if (existingBuilding !== filterObj?.name) {
            setBuildingErrorText("Building already exists");
            setIsBuildingError(true);
          } else{
            setIsBuildingError(false);
          }
        } else {
          setIsBuildingError(false);
          setBuildingErrorText("Please enter the building name");
        }
      } catch (error) {
        console.log(error);
      }
      setIsSave(false);
    };

    useEffect(() => {
      if (mode === "page") {
        setFloorplanErrorText(
          errors?.floorplanErrorText
            ? errors?.floorplanErrorText
            : floorplanErrorText
        );
        setBuildingErrorText(
          errors?.buildingErrorText
            ? errors?.buildingErrorText
            : buildingErrorText
        );
        propertyTypeData &&
          setType({ name: propertyTypeData, id: propertyTypeData });
        setIsFloorplanError(errors?.floorplan || false);
        setIsPropertyError(errors?.property || false);
        setIsFloorplanUnitCountError(errors?.floorplanUnitCount || false);
        setPropertyOptions(generateDropdownOptions(properties, "name", "name"));
        setIsBuildingError(errors?.building);
        if (floorplanData && propertyTypeData === "Floorplan") {
          setProperty({
            name: floorplanData?.property.id,
            id: floorplanData?.property.id,
          });
          setFloorplanName(floorplanData?.name);
          setFloorplanUnitCount(
            floorplanData?.additional_info?.planned_renovation_unit_count
          );
          setFullBathrooms(floorplanData?.number_of_full_bathrooms);
          setHalfBathrooms(floorplanData?.number_of_full_bathrooms);
          setBedrooms(floorplanData?.number_of_bedrooms);
          setSqft(floorplanData?.square_feet);
        }
        setCustomer(selectedCustomer);
        if (propertyTypeData === "Building" && buildingData) {
          setProperty({
            name: buildingData?.property.id,
            id: buildingData?.property.id,
          });
          setBuildingName(buildingData?.name);
          setRoofType({
            id: buildingData?.roof_type,
            name: buildingData?.roof_type,
          });
          setSiding({
            id: buildingData?.siding_type,
            name: buildingData?.siding_type,
          });
          setStories(buildingData?.stories_count);
        }
      }
      if (mode === "edit") {
        type?.id === "Floorplan"
          ? fetchFloorplanData(floorplanID)
          : fetchBuildingData(buildingID);
        setStatusOptions([
          { id: true, name: "Enabled" },
          { id: false, name: "Disabled" },
        ]);
      }
      if (mode === "create") {
        fetchCustomers();
        if (selectedCustomer) {
          let searchObj = {};
          searchObj["customer_ids"] = selectedCustomer.id;
          fetchProperties(searchObj);
        }
      }
      if (mode === "edit" || mode === "create") {
        setCustomer(selectedCustomer);
        setProperty(selectedProperty);
      }
    }, []);

    const handleSubmit = async (event) => {
      const payload = {
        name: floorplanName,
        code: floorplanName,
        customer: {
          id:
            typeof customer === "object"
              ? customer?.id
              : mode === "page"
              ? selectedCustomer
              : selectedCustomer?.id,
          name:
            typeof customer === "object"
              ? customer?.name
              : mode === "page"
              ? selectedCustomer
              : selectedCustomer?.name,
        },
        property: {
          id:
            typeof property === "object"
              ? property?.id
              : mode === "page"
              ? property?.id
              : selectedProperty?.id,
          name:
            typeof property === "object"
              ? property?.id
              : mode === "page"
              ? property?.id
              : selectedProperty?.id,
        },
        square_feet: checkFieldValue(sqft),
        is_enabled: true,
        number_of_bedrooms: !isEmpty(bedrooms) ? bedrooms : 0,
        number_of_full_bathrooms: !isEmpty(halfBathrooms) ? halfBathrooms : 0,
        number_of_half_bathrooms: !isEmpty(fullBathrooms) ? fullBathrooms : 0,
        additional_info: {
          planned_renovation_unit_count: checkFieldValue(floorplanUnitCount),
        },
      };

      const buildingsPayload = {
        name: buildingName,
        customer: {
          id:
            typeof customer === "object"
              ? customer?.id
              : mode === "page"
              ? selectedCustomer
              : selectedCustomer?.id,
          name:
            typeof customer === "object"
              ? customer?.name
              : mode === "page"
              ? selectedCustomer
              : selectedCustomer?.name,
        },
        property: {
          id:
            typeof property === "object" ? property?.id : selectedProperty?.id,
          name:
            typeof property === "object"
              ? property?.name
              : selectedProperty?.name,
        },
        roof_type: roofType?.id,
        siding_type: siding?.id,
        stories_count: checkFieldValue(stories),
      };

      try {
        const hasValidFloorplan = !isEmpty(floorplanName, floorplanUnitCount);
        const hasValidBuilding = !isEmpty(buildingName);
        const hasValidCustomer =
          typeof customer === "object"
            ? !isEmpty(customer)
            : !isEmpty(selectedCustomer);
        const hasValidProperty = !isEmpty(property);
        let data = {};
        data = type?.id === "Floorplan" ? payload : buildingsPayload;
        if (
          (type?.id === "Floorplan"
            ? hasValidFloorplan && !isFloorplanError
            : hasValidBuilding && !isBuildingError) &&
          hasValidCustomer &&
          hasValidProperty
        ) {
          mode === "page"
            ? passDataToNextSection(data, id, "", type?.id)
            : passDataToNextSection(data, "create", "", type?.id);
        } else {
          !hasValidCustomer && setIsCustomerError(true);
          !hasValidProperty && setIsPropertyError(true);
          if (type?.id === "Floorplan") {
            isEmpty(floorplanName) && setIsFloorplanError(true);
            isEmpty(floorplanUnitCount) && setIsFloorplanUnitCountError(true);
          } else if (type?.id === "Building") {
            isEmpty(buildingName) && setIsBuildingError(true);
          }
          const errors = {
            property: isEmpty(property),
            floorplanUnitCount: isEmpty(floorplanUnitCount),
            floorplanErrorText: floorplanErrorText,
            buildingErrorText: "Please enter the building name",
            floorplan: isEmpty(floorplanName) || isFloorplanError,
            building: isEmpty(buildingName),
          };
          mode === "page" && passDataToNextSection(data, id, errors, type?.id);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const handleUpdate = async (event) => {
      const payload = {
        id: floorplanID,
        customer: {
          id: selectedCustomer?.id,
          name: selectedCustomer?.name,
        },
        property: {
          id: typeof property === "object" ? property?.id : selectedProperty?.id,
        },
        name: floorplanName,
        code: floorplanName,
        square_feet: checkFieldValue(sqft),
        is_enabled: status?.id ? 1 : 0,
        number_of_bedrooms: !isEmpty(bedrooms) ? bedrooms : 0,
        number_of_full_bathrooms: !isEmpty(fullBathrooms) ? fullBathrooms : 0,
        number_of_half_bathrooms: !isEmpty(halfBathrooms) ? halfBathrooms : 0,
        additional_info: {
          planned_renovation_unit_count: checkFieldValue(floorplanUnitCount),
        },
      };

      const buildingsPayload = {
        id: buildingID,
        name: buildingName,
        customer: {
          id:
            typeof customer === "object"
              ? customer?.id
              : mode === "page"
              ? selectedCustomer
              : selectedCustomer?.id,
          name:
            typeof customer === "object"
              ? customer?.name
              : mode === "page"
              ? selectedCustomer
              : selectedCustomer?.name,
        },
        property: {
          id:
            typeof property === "object" ? property?.id : selectedProperty?.id,
          name:
            typeof property === "object"
              ? property?.name
              : selectedProperty?.name,
        },
        roof_type: roofType ? roofType?.id : null,
        siding_type: siding ? siding?.id : null,
        stories_count: checkFieldValue(stories),
        disabled: status?.id ? 0 : 1,
      };
      const data = type?.id === "Floorplan" ? payload : buildingsPayload;
      try {
        if (
          type?.id === "Floorplan"
            ? !isEmpty(floorplanName, floorplanUnitCount) &&
              !isFloorplanUnitCountError &&
              !isFloorplanError
            : !isEmpty(buildingName) && !isBuildingError
        ) {
          passDataToNextSection(
            data,
            "update",
            type?.id === "Floorplan" ? floorplanID : buildingID,
            type?.id
          );
        } else {
          type?.id === "Floorplan"
            ? (isEmpty(floorplanName) && setIsFloorplanError(true))(
                isEmpty(floorplanUnitCount) &&
                  setIsFloorplanUnitCountError(true)
              )
            : isEmpty(buildingName) && setIsBuildingError(true);
        }
      } catch (error) {
        console.error(error);
      }
    };

    useImperativeHandle(ref, () => ({
      handleClick() {
        handleSubmit(id);
      },
    }));

    const handleChange = (event, setterFunction, setErrorFunction, field) => {
      const value = event.target.value;
      setterFunction(value);
      setErrorFunction(isEmpty(value));
      if (field === "customer") {
        setPropertyDrwnClear(true);
        setProperty("");
        setFloorplanName("");
        setIsFloorplanError(false);
        setFloorplanErrorText("Please enter the floorplan name");
        setIsPropertyError(false);
        let searchObj = {};
        searchObj["customer_ids"] = value?.id;
        fetchProperties(searchObj);
      } else if (field === "property") {
        setPropertyDrwnClear(false);
        setIsFloorplanError(false);
        setFloorplanErrorText("Please enter the floorplan name");
        setFloorplanName("");
      }
    };

    const handleSearch = (
      event,
      searchType,
      setFunction,
      setErrorFunction,
      debouncedSearch,
      setDebouncedSearch
    ) => {
      const searchText = event.target.value;
      searchType === "floorplan" && setIsSave(true);
      handleChange(event, setFunction, setErrorFunction);
      if (mode === "create" || mode === "edit") {
        if (debouncedSearch) {
          clearTimeout(debouncedSearch);
        }
        setDebouncedSearch(
          setTimeout(() => {
            let searchObj = {};
            if (searchText) {
              if (
                searchType === "property" ||
                searchType === "floorplan" ||
                searchType === "building"
              ) {
                searchObj["customer_ids"] = selectedCustomer?.id
                  ? selectedCustomer?.id
                  : customer?.id;
                if (searchType === "floorplan" || searchType === "building")
                  searchObj["property_ids"] = selectedProperty?.id
                    ? selectedProperty?.id
                    : property?.id;
              }
              if (searchType === "floorplan" || searchType === "building")
                searchObj["name"] = searchText;
              if (searchType === "property" || searchType === "customer")
                searchObj["q"] = searchText;
            }
            if (searchText) {
              if (searchType === "property") {
                fetchProperties(searchObj);
              } else if (searchType === "floorplan") {
                fetchFloorplans(searchObj);
              } else if (searchType === "customer") {
                fetchCustomers(searchObj);
              } else if (searchType === "building") {
                fetchBuildings(searchObj);
              }
            } else {
              if (searchType === "floorplan") {
                setIsFloorplanError(true);
                setFloorplanErrorText("Please enter the floorplan name");
              } else if (searchType === "building") {
                setIsBuildingError(true);
                setBuildingErrorText("Please enter the building name");
              }
            }
          }, 1200)
        );
      }
    };

    return mode === "page" ? (
      <>
        <form className="flex flex-col gap-4">
          <div className="grid grid-cols-2 gap-[60px]">
            <DropdownField
              label={"Floorplan/Building"}
              options={floorplanBuildingOptionsRef.current}
              theme={"secondary"}
              mandatory={true}
              componentID={"addFloorplanBuilding_dwnType"}
              value={type}
              onChange={setType}
            />
          </div>
          <div className="grid grid-cols-2 gap-[60px]">
            <InputField
              label={"PMC"}
              theme={"secondary"}
              mandatory={true}
              componentID={"addFloorplan_txtCustomer"}
              type={"text"}
              isDisabled={true}
              defaultValue={selectedCustomer}
            />
            <DropdownField
              label={"Property"}
              options={propertyOptions}
              theme={"secondary"}
              error={isPropertyError}
              componentID={"addFloorplan_txtProperty"}
              value={property}
              isSearchable={true}
              errorText={"Please select a property"}
              mandatory={true}
              onChange={(property) =>
                handleChange(
                  { target: { value: property } },
                  setProperty,
                  setIsPropertyError
                )
              }
            />
          </div>
          {type?.name === "Floorplan" ? (
            <>
              <div className="grid grid-cols-2 gap-[60px]">
                <InputField
                  label={"Floorplan Name"}
                  theme={"secondary"}
                  mandatory={true}
                  componentID={"addFloorplan_txtFloorplan"}
                  type={"text"}
                  error={isFloorplanError}
                  errorText={floorplanErrorText}
                  value={floorplanName}
                  onChange={(event) =>
                    handleSearch(
                      event,
                      "floorplan",
                      setFloorplanName,
                      setIsFloorplanError,
                      floorplanDebouncedSearch,
                      setFloorplanDebouncedSearch
                    )
                  }
                  onClick={() =>
                    handleSearch(
                      { target: { value: floorplanName } },
                      "floorplan",
                      setFloorplanName,
                      setIsFloorplanError,
                      floorplanDebouncedSearch,
                      setFloorplanDebouncedSearch
                    )
                  }
                />
                <InputField
                  label={"Floorplan Unit Count"}
                  theme={"secondary"}
                  mandatory={true}
                  componentID={"addFloorplan_numFloorplanUnitCount"}
                  type={"number"}
                  min={0}
                  error={isFloorplanUnitCountError}
                  errorText={"Please enter the floorplan unit count"}
                  value={floorplanUnitCount}
                  onClick={() =>
                    handleChange(
                      { target: { value: floorplanUnitCount } },
                      setFloorplanUnitCount,
                      setIsFloorplanUnitCountError
                    )
                  }
                  onChange={(event) =>
                    handleChange(
                      event,
                      setFloorplanUnitCount,
                      setIsFloorplanUnitCountError
                    )
                  }
                />
              </div>
              <div className="grid grid-cols-3 gap-[50px]">
                <InputField
                  label={"No. of Bedrooms"}
                  theme={"secondary"}
                  componentID={"addFloorplan_numBedrooms"}
                  type={"number"}
                  min={0}
                  value={bedrooms}
                  onChange={(e) => setBedrooms(e.target.value)}
                />
                <InputField
                  label={"No. of Full Bathrooms"}
                  theme={"secondary"}
                  componentID={"addFloorplan_numFullBathrooms"}
                  type={"number"}
                  min={0}
                  value={fullBathrooms}
                  onChange={(e) => setFullBathrooms(e.target.value)}
                />
                <InputField
                  label={"No. of Half Bathrooms"}
                  theme={"secondary"}
                  componentID={"addFloorplan_numHalfBathrooms"}
                  type={"number"}
                  min={0}
                  value={halfBathrooms}
                  onChange={(e) => setHalfBathrooms(e.target.value)}
                />
              </div>
              <div className="grid grid-cols-3 gap-[50px]">
                <InputField
                  label={"Sqft"}
                  theme={"secondary"}
                  componentID={"addFloorplan_numSqft"}
                  type={"number"}
                  min={0}
                  value={sqft}
                  onChange={(e) => setSqft(e.target.value)}
                />
              </div>
            </>
          ) : (
            <>
              <div className="grid grid-cols-3 gap-[60px]">
                <InputField
                  label={"Building Name"}
                  theme={"secondary"}
                  mandatory={true}
                  type={"text"}
                  error={isBuildingError}
                  componentID={
                    mode === "create"
                      ? "createBuilding_txtName"
                      : "editBuilding_txtName"
                  }
                  errorText={buildingErrorText}
                  value={buildingName}
                  onChange={(event) =>
                    handleSearch(
                      event,
                      "building",
                      setBuildingName,
                      setBuildingErrorText,
                      buildingDebouncedSearch,
                      setBuildingDebouncedSearch
                    )
                  }
                  onClick={() =>
                    handleSearch(
                      { target: { value: buildingName } },
                      "building",
                      setBuildingName,
                      setBuildingErrorText,
                      buildingDebouncedSearch,
                      setBuildingDebouncedSearch
                    )
                  }
                />
                <DropdownField
                  label={"Roof Type"}
                  theme={"secondary"}
                  options={roofTypeOptions}
                  componentID={
                    mode === "create"
                      ? "createBuilding_dwnRoofType"
                      : "editBuilding_dwnRoofType"
                  }
                  value={roofType}
                  onChange={setRoofType}
                />
                <DropdownField
                  label={"Siding"}
                  theme={"secondary"}
                  options={sidingOptions}
                  componentID={
                    mode === "create"
                      ? "createBuilding_dwnSiding"
                      : "editBuilding_dwnSiding"
                  }
                  value={siding}
                  onChange={setSiding}
                />
              </div>
              <div className="grid grid-cols-3 gap-[60px]">
                <InputField
                  label={"Stories"}
                  theme={"secondary"}
                  componentID={
                    mode === "create"
                      ? "createBuilding_numStories"
                      : "editBuilding_numStories"
                  }
                  type={"number"}
                  min={0}
                  value={stories}
                  onChange={(e) => setStories(e.target.value)}
                />
              </div>
            </>
          )}
        </form>
      </>
    ) : (
      <>
        <main className="block overflow-y-auto px-10 h-full">
          <form className="flex flex-col gap-6">
            {mode === "create" ? (
              <DropdownField
                label={"PMC"}
                options={customerOptions}
                theme={"secondary"}
                mandatory={true}
                componentID={"createFloorplan_txtCustomer"}
                isSearchable={true}
                error={isCustomerError}
                errorText={"Please select a PMC"}
                value={customer}
                searchText={(searchText) =>
                  handleSearch(
                    { target: { value: searchText } },
                    "customer",
                    setCustomerSearchValue,
                    setIsCustomerError,
                    customerDebouncedSearch,
                    setCustomerDebouncedSearch
                  )
                }
                onChange={(customer) =>
                  handleChange(
                    { target: { value: customer } },
                    setCustomer,
                    setIsCustomerError,
                    "customer"
                  )
                }
              />
            ) : (
              <InputField
                label={"PMC"}
                theme={"secondary"}
                isDisabled={true}
                componentID={"editFloorplan_txtCustomer"}
                mandatory={true}
                value={customer?.name}
              />
            )}
            {mode === "create" ? (
              <DropdownField
                label={"Property Name"}
                options={propertyOptions}
                theme={"secondary"}
                mandatory={true}
                isClear={propertyDrwnClear}
                componentID={"createFloorplan_txtProperty"}
                isSearchable={true}
                error={isPropertyError}
                errorText={"Please select a property"}
                value={property}
                searchText={(searchText) =>
                  handleSearch(
                    { target: { value: searchText } },
                    "property",
                    setPropertySearchValue,
                    setIsPropertyError,
                    propertyDebouncedSearch,
                    setPropertyDebouncedSearch
                  )
                }
                onChange={(property) =>
                  handleChange(
                    { target: { value: property } },
                    setProperty,
                    setIsPropertyError,
                    "property"
                  )
                }
              />
            ) : (
              <InputField
                label={"Property Name"}
                theme={"secondary"}
                mandatory={true}
                isDisabled={true}
                componentID={"editFloorplan_txtProperty"}
                value={property?.name}
              />
            )}
            {mode === "create" && !isPropertyType && (
              <DropdownField
                label={"Floorplan/Building"}
                options={floorplanBuildingOptionsRef.current}
                theme={"secondary"}
                mandatory={true}
                componentID={"createFloorplan_drwnPropertyFloorplan"}
                isSearchable={true}
                value={type}
                onChange={setType}
              />
            )}
            {type?.name === "Floorplan" ? (
              <>
                <InputField
                  label={"Floorplan Name"}
                  theme={"secondary"}
                  mandatory={true}
                  type={"text"}
                  error={isFloorplanError}
                  componentID={
                    mode === "create"
                      ? "createFloorplan_txtFloorplan"
                      : "editFloorplan_txtFloorplan"
                  }
                  errorText={floorplanErrorText}
                  value={floorplanName}
                  onChange={(event) =>
                    handleSearch(
                      event,
                      "floorplan",
                      setFloorplanName,
                      setIsFloorplanError,
                      floorplanDebouncedSearch,
                      setFloorplanDebouncedSearch
                    )
                  }
                  onClick={() =>
                    handleSearch(
                      { target: { value: floorplanName } },
                      "floorplan",
                      setFloorplanName,
                      setIsFloorplanError,
                      floorplanDebouncedSearch,
                      setFloorplanDebouncedSearch
                    )
                  }
                />
                <div className="grid grid-cols-2 gap-[30px]">
                  <InputField
                    label={"No. of Bedrooms"}
                    theme={"secondary"}
                    type={"number"}
                    min={0}
                    componentID={
                      mode === "create"
                        ? "createFloorplan_numBedrooms"
                        : "editFloorplan_numBedrooms"
                    }
                    value={bedrooms}
                    onChange={(e) => setBedrooms(e.target.value)}
                  />
                  <InputField
                    label={"No. of Full Bathrooms"}
                    theme={"secondary"}
                    componentID={
                      mode === "create"
                        ? "createFloorplan_numFullBathrooms"
                        : "editFloorplan_numFullBathrooms"
                    }
                    type={"number"}
                    min={0}
                    value={fullBathrooms}
                    onChange={(e) => setFullBathrooms(e.target.value)}
                  />
                </div>
                <div className="grid grid-cols-2 gap-[30px]">
                  <InputField
                    label={"No. of Half Bathrooms"}
                    theme={"secondary"}
                    componentID={
                      mode === "create"
                        ? "createFloorplan_numHalfBathrooms"
                        : "editFloorplan_numHalfBathrooms"
                    }
                    type={"number"}
                    min={0}
                    value={halfBathrooms}
                    onChange={(e) => setHalfBathrooms(e.target.value)}
                  />
                  <InputField
                    label={"Floorplan Unit Count"}
                    theme={"secondary"}
                    mandatory={true}
                    type={"number"}
                    min={0}
                    componentID={
                      mode === "create"
                        ? "createFloorplan_numFloorplanUnitCount"
                        : "editFloorplan_numFloorplanUnitCount"
                    }
                    value={floorplanUnitCount}
                    error={isFloorplanUnitCountError}
                    errorText={"Please enter the floorplan unit count"}
                    onClick={() =>
                      handleChange(
                        { target: { value: floorplanUnitCount } },
                        setFloorplanUnitCount,
                        setIsFloorplanUnitCountError
                      )
                    }
                    onChange={(event) =>
                      handleChange(
                        event,
                        setFloorplanUnitCount,
                        setIsFloorplanUnitCountError
                      )
                    }
                  />
                </div>
                <div className="grid grid-cols-2 gap-[30px]">
                  <InputField
                    label={"Sqft"}
                    componentID={
                      mode === "create"
                        ? "createFloorplan_numSqft"
                        : "editFloorplan_numSqft"
                    }
                    type={"number"}
                    min={0}
                    theme={"secondary"}
                    value={sqft}
                    onChange={(e) => setSqft(e.target.value)}
                  />
                  {mode === "edit" && (
                    <DropdownField
                      label={"Status"}
                      options={statusOptions}
                      componentID={"editFloorplan_txtStatus"}
                      theme={"secondary"}
                      value={status}
                      onChange={(status) => setStatus(status)}
                    />
                  )}
                </div>
              </>
            ) : (
              <>
                <InputField
                  label={"Building Name"}
                  theme={"secondary"}
                  mandatory={true}
                  type={"text"}
                  error={isBuildingError}
                  componentID={
                    mode === "create"
                      ? "createBuilding_txtName"
                      : "editBuilding_txtName"
                  }
                  errorText={buildingErrorText}
                  value={buildingName}
                  onChange={(event) =>
                    handleSearch(
                      event,
                      "building",
                      setBuildingName,
                      setBuildingErrorText,
                      buildingDebouncedSearch,
                      setBuildingDebouncedSearch
                    )
                  }
                  onClick={() =>
                    handleSearch(
                      { target: { value: buildingName } },
                      "building",
                      setBuildingName,
                      setBuildingErrorText,
                      buildingDebouncedSearch,
                      setBuildingDebouncedSearch
                    )
                  }
                />
                <DropdownField
                  label={"Roof Type"}
                  theme={"secondary"}
                  options={roofTypeOptions}
                  componentID={
                    mode === "create"
                      ? "createBuilding_dwnRoofType"
                      : "editBuilding_dwnRoofType"
                  }
                  value={roofType}
                  onChange={setRoofType}
                />
                <InputField
                  label={"Stories"}
                  theme={"secondary"}
                  componentID={
                    mode === "create"
                      ? "createBuilding_numStories"
                      : "editBuilding_numStories"
                  }
                  type={"number"}
                  min={0}
                  value={stories}
                  onChange={(e) => setStories(e.target.value)}
                />
                <DropdownField
                  label={"Siding"}
                  theme={"secondary"}
                  options={sidingOptions}
                  componentID={
                    mode === "create"
                      ? "createBuilding_dwnSiding"
                      : "editBuilding_dwnSiding"
                  }
                  value={siding}
                  onChange={setSiding}
                />
                {mode === "edit" && (
                  <DropdownField
                    label={"Status"}
                    options={statusOptions}
                    componentID={"editBuilding_dwnStatus"}
                    theme={"secondary"}
                    value={status}
                    onChange={(status) => setStatus(status)}
                  />
                )}
              </>
            )}
          </form>
        </main>
        <footer className="bg-white mt-[10px] flex justify-end items-center shadow-[_0px_-13px_28px_rgba(0,0,0,0.025)] pr-4 gap-4 h-[86px]">
          <Button
            theme={"tertiary"}
            onClick={handleCancel}
            componentID={
              mode === "create"
                ? "createFloorplan_btnCancel"
                : "editFloorplan_btnCancel"
            }
            buttonText={"Cancel"}
          />
          <Button
            theme={"primary"}
            buttonText={mode === "create" ? "Add" : "Save"}
            disabled={isSave}
            processing={isLoading}
            componentID={
              mode === "create"
                ? "createFloorplan_btnAdd"
                : "editFloorplan_btnSave"
            }
            type={"submit"}
            onClick={mode === "create" ? handleSubmit : handleUpdate}
          />
        </footer>
      </>
    );
  }
);
export default FloorplanForm;
