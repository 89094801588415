import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={12}
    height={11}
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.75179 10.6587V6.04555H11.5707V4.61315H6.75179V0H5.24863V4.61315H0.429688V6.04555H5.24863V10.6587H6.75179Z"
      fill="#006CDB"
    />
  </svg>
);
export default SVGComponent;
