const LineItemProductCardStyles = {
    "productContainer"        : "flex items-center rounded-[8px] p-[25px] space-x-[31px] bg-white",
    "img"                     : "w-[93px] h-[82px] shrink-0",
    "detailsContainer"        : "flex flex-col space-y-1 font-lato w-full",
    "name"                    : "text-[#383838] text-[15px] font-bold",
    "sku"                     : "text-[#383838] text-[16px] font-medium italic",
    "priceOuterContainer"     : "font-lato flex justify-between items-center",
    "priceInnerContainer"     : "text-[#006CDB] text-[20px] font-medium",
}

export default LineItemProductCardStyles;