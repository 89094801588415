/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import InspectionGrid from "./Grid";
import { useNavigate } from "react-router-dom";
import httpAPICall from "../../utils/httpApiCall";
import PropTypes from "prop-types";
import {
  SearchInput,
  Dropdown,
  Footer,
  Button,
  ModalWrapper,
} from "../../components";
import { generateDropdownOptions, inspectionStatusOptions } from "../../utils";
import {
  INSPECTIONS_API_URL,
  CUSTOMERS_API_URL,
  PROPERTIES_API_URL,
} from "../../constants";
import qs from "qs";

const ConfirmModal = ({ openModal, closeModal, inspectionsIds }) => {
  const navigate = useNavigate();
  return (
    <ModalWrapper openModal={openModal} closeModal={closeModal}>
      <main className="flex justify-center items-center flex-col space-y-[50px] py-[44px] w-[506px]">
        <section className="font-inter text-[24px] font-medium text-[#1B2B41B8] text-center">
          Are you sure you want to create
          <br />a Quote Matrix?
        </section>
        <section className="flex justify-center w-full space-x-[70px]">
          <Button
            componentID={"confirmModal_btnClose"}
            buttonText={"Close"}
            theme={"tertiary-without-border"}
            onClick={closeModal}
          />
          <Button
            buttonText={"Yes"}
            componentID={"confirmModal_btnYes"}
            theme={"primary-fixed-width"}
            onClick={() =>
              navigate("/get-quote-matrix", {
                state: {
                  inspectionsIds: inspectionsIds,
                },
              })
            }
          />
        </section>
      </main>
    </ModalWrapper>
  );
};

ConfirmModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  inspectionsIds: PropTypes.array.isRequired,
};

function InspectionList() {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [loadTable, setLoadTable] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [properties, setProperties] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [titleDebouncedSearch, setTitleDebouncedSearch] = useState(false);
  const [customerDebouncedSearch, setCustomerDebouncedSearch] = useState(null);
  const [propertyDebouncedSearch, setPropertyDebouncedSearch] = useState(null);
  const [customerSearchValue, setCustomerSearchValue] = useState("");
  const [propertySearchValue, setPropertySearchValue] = useState("");
  const [statusSearchValue, setStatusSearchValue] = useState("");
  const [selectedRow, setSelectedRow] = useState({});
  const [checkProperties, setCheckProperties] = useState([]);
  const [enableFooter, setEnableFooter] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [inspectionIds, setInspectionIds] = useState([]);
  const [inspectionSort, setInspectionSort] = useState("");
  const [checkProperty, setCheckProperty] = useState([]);
  const [selectedInspectionStatuses, setSelectedInspectionStatuses] =
    useState(false);
  const [selectedInspectionProperties, setSelectedInspectionProperties] =
    useState(false);
  const fetchInspections = async (filterObj) => {
    setLoadTable(true);
    let generatedUrl = INSPECTIONS_API_URL + `?${qs.stringify(filterObj)}`;
    let result = await httpAPICall(generatedUrl, "GET", {});
    setLoadTable(false);
    setTableData(result);
  };

  const fetchCustomers = async (filterObj) => {
    let generatedUrl = CUSTOMERS_API_URL + `?${qs.stringify(filterObj)}`;
    let result = await httpAPICall(generatedUrl, "GET", {});
    let options = generateDropdownOptions(result, "name", "id");
    setCustomers(options);
  };

  const fetchProperties = async (filterObj) => {
    let generatedUrl = PROPERTIES_API_URL + `?${qs.stringify(filterObj)}`;
    let result = await httpAPICall(generatedUrl, "GET", {});
    let options = generateDropdownOptions(result, "name", "id");
    setProperties(options);
  };

  const handleSearch = (
    value,
    searchType,
    setSearchText,
    debouncedSearch,
    setDebouncedSearch
  ) => {
    setSearchText(value);
    if (debouncedSearch) {
      clearTimeout(debouncedSearch);
    }
    setDebouncedSearch(
      setTimeout(() => {
        let searchObj = {};
        if (value) {
          if (searchType === "property") {
            searchObj["customer_ids"] = selectedCustomer?.id;
          }
          searchObj["q"] = value;
          if (searchType === "property") fetchProperties(searchObj);
          else if (searchType === "customer") fetchCustomers(searchObj);
          else {
            if (selectedCustomer)
              searchObj["customer_ids"] = selectedCustomer?.id;
            if (selectedProperty)
              searchObj["property_ids"] = selectedProperty?.id;
            if (selectedStatus) searchObj["status_ids"] = selectedStatus?.id;
            fetchInspections(searchObj);
          }
        }
      }, 2000)
    );
  };

  const copyInspection = () => {
    navigate("/create-inspection", {
      state: { inspectionDetails: selectedRow },
    });
  };

  const handleChange = (value, setterFunction, field) => {
    let queryStringObj = {};
    setterFunction(value);
    if (searchValue) {
      queryStringObj["q"] = searchValue;
    }
    if (field === "customer") {
      queryStringObj["customer_ids"] = value?.id;
      if (selectedStatus) queryStringObj["status_ids"] = selectedStatus.id;
      fetchProperties({ customer_ids: value?.id });
    } else if (field === "property") {
      if (selectedCustomer)
        queryStringObj["customer_ids"] = selectedCustomer?.id;
      if (selectedStatus) queryStringObj["status_ids"] = selectedStatus.id;
      queryStringObj["property_ids"] = value?.id;
    } else {
      if (selectedCustomer)
        queryStringObj["customer_ids"] = selectedCustomer?.id;
      if (selectedProperty)
        queryStringObj["property_ids"] = selectedProperty?.id;
      queryStringObj["status_ids"] = value?.id;
    }
    fetchInspections({ ...inspectionSort, ...queryStringObj });
  };

  const voidInspection = async () => {
    let params = INSPECTIONS_API_URL + `/${selectedRow?.id}/void`;
    setLoadTable(true);
    await httpAPICall(
      params,
      "DELETE",
      {},
      "",
      "",
      "Inspection Voided Successfully"
    );
    setLoadTable(false);
    fetchInspections();
  };

  useEffect(() => {
    fetchInspections();
  }, []);

  useEffect(() => {
    if (inspectionSort && Object.keys(inspectionSort).length > 0) {
      if (selectedCustomer)
        inspectionSort["customer_ids"] = selectedCustomer?.id;
      if (selectedProperty)
        inspectionSort["property_ids"] = selectedProperty?.id;
      if (selectedStatus) inspectionSort["status_ids"] = selectedStatus?.id;
      if (searchValue) inspectionSort["q"] = searchValue;
      fetchInspections(inspectionSort);
      setInspectionSort({});
    }
  }, [
    inspectionSort,
    selectedCustomer,
    selectedProperty,
    selectedStatus,
    searchValue,
  ]);

  return (
    <div>
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center space-x-4 w-full">
          <SearchInput
            theme={"secondary"}
            componentID={"inspection_titleSearch"}
            searchValue={searchValue}
            onChange={(event) =>
              handleSearch(
                event.target.value,
                "titleSearch",
                setSearchValue,
                titleDebouncedSearch,
                setTitleDebouncedSearch
              )
            }
            placeholder={"Inspection#/title"}
          />
          <span className="w-[200px]">
            <Dropdown
              options={customers}
              isSearchable={true}
              componentID={"inspection_customerSearch"}
              value={selectedCustomer}
              placeHolder={"PMC"}
              searchText={(searchText) =>
                handleSearch(
                  searchText,
                  "customer",
                  setCustomerSearchValue,
                  customerDebouncedSearch,
                  setCustomerDebouncedSearch
                )
              }
              onClick={() => fetchCustomers()}
              onChange={(selectedValue) =>
                handleChange(selectedValue, setSelectedCustomer, "customer")
              }
            />
          </span>
          <span className="w-[200px]">
            <Dropdown
              options={properties}
              value={selectedProperty}
              isSearchable={true}
              componentID={"inspection_propertySearch"}
              placeHolder={"Property"}
              searchText={(searchText) =>
                handleSearch(
                  searchText,
                  "property",
                  setPropertySearchValue,
                  propertyDebouncedSearch,
                  setPropertyDebouncedSearch
                )
              }
              onChange={(selectedValue) =>
                handleChange(selectedValue, setSelectedProperty, "property")
              }
            />
          </span>
          <span className="w-[200px]">
            <Dropdown
              options={inspectionStatusOptions}
              isSearchable={true}
              value={selectedStatus}
              placeHolder={"Status"}
              searchText={(searchText) => setStatusSearchValue(searchText)}
              onChange={(selectedValue) =>
                handleChange(selectedValue, setSelectedStatus, "status")
              }
            />
          </span>
          <div className="flex">
            <div
              id="clear-filter"
              className="font-lato font-semibold text-[16px] ml-[45px] leading-[16.8px] text-[#006CDB] hover:underline cursor-pointer"
              onClick={() => window.location.reload()}
            >
              Clear Filter
            </div>
          </div>
        </div>
      </div>

      <main className="mt-[32px]">
        <InspectionGrid
          tableData={tableData}
          checkedRows={(val) => {
            setEnableFooter(val);
            setInspectionIds(val);
          }}
          checkProperties={(val) => {
            setCheckProperty(val);
          }}
          selectedStatuses={setSelectedInspectionStatuses}
          selectedProperties={setSelectedInspectionProperties}
          searchText={customerSearchValue}
          isLoading={loadTable}
          sendSelectedRow={(val) => {
            setSelectedRow(val);
            setCheckProperties((prevState) => [...prevState, val]);
          }}
          sort={(sortObject) => setInspectionSort(sortObject)}
        />
      </main>

      <Footer
        theme={"secondary"}
        disabled={enableFooter.length === 1 ? false : true}
      >
        <div className="flex w-full justify-between">
          <section>
            <Button
              buttonText={"Copy Inspection"}
              componentID={"inspection_btnCopyInspection"}
              theme={
                enableFooter.length === 1
                  ? "secondary"
                  : "secondary-with-disabled"
              }
              disabled={!(enableFooter.length === 1)}
              onClick={() => copyInspection()}
            />
          </section>
          <section className="space-x-[32px]">
            <Button
              buttonText={"Void Inspection"}
              componentID={"inspection_btnVoidInspection"}
              theme={
                selectedInspectionStatuses
                  ? "tertiary"
                  : "tertiary-with-disabled"
              }
              disabled={selectedInspectionStatuses ? false : true}
              onClick={() => voidInspection()}
            />
            <Button
              buttonText={"Create Quote Matrix"}
              componentID={"inspection_btnCreateQuoteMatrix"}
              theme={
                selectedInspectionProperties
                  ? "primary"
                  : "primary-with-disabled"
              }
              disabled={selectedInspectionProperties ? false : true}
              onClick={() => {
                setOpenModal(true);
              }}
            />
            <section className="">
              <ConfirmModal
                openModal={openModal}
                closeModal={() => setOpenModal(false)}
                inspectionsIds={inspectionIds}
              />
            </section>
          </section>
        </div>
      </Footer>
    </div>
  );
}

export default InspectionList;
