import React from "react";
import { Tooltip } from "rsuite";
import ToolTipStyles from "../../../styles/components/toolTip";

function ToolTip({ text, maxLength, mode }) {
  const truncatedText = text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
  return (
   mode ? (
    <span title={text}>
      {truncatedText}
    </span>
	)
   : ( <Tooltip
      visible
      className= {ToolTipStyles.primaryStyles}
    >
      {text}
    </Tooltip>
    )
  );
}

export default ToolTip;
