import { createSlice } from "@reduxjs/toolkit";

export const companyDetailsSlice = createSlice({
  name: "companyDetails",
  initialState: {
    details: {},
  },
  reducers: {
    setCompanyDetails: (state, action) => {
      state.details = action.payload.details;
    },
  },
});

export const { setCompanyDetails } = companyDetailsSlice.actions;

export default companyDetailsSlice.reducer;
