import { Fragment } from "react";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { FacilgoIcon, LogoutIcon } from "../assets";
import { useDispatch } from "react-redux";
import { setUserLogin } from "../redux/slice/authSlice";
import { useNavigate } from "react-router-dom";
import { webStore } from "../redux";
import { logout } from "../services/api/logout";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Layout({ children, activeTab }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSignOut = async (e) => {
    e.preventDefault();
    const payload = {
      token: webStore.getState().auth.accessToken
    }
      const response = await logout(payload);
      if (response) {
        localStorage.clear();
        dispatch(setUserLogin({ accessToken: "" }));
        navigate("/");
      }
  };

  const userDetails = webStore.getState().companyDetails.details;

  const user = {
    name: userDetails?.first_name,
    email: userDetails?.email,
  };
  const navigation = [
    {
      name: "Renovation Setup",
      href: "/renovation-setup",
      current: activeTab === "Renovation Setup" ? true : false,
    },
    {
      name: "Inspections",
      href: "/inspections",
      current: activeTab === "Inspections" ? true : false,
    },
    {
      name: "Quote Matrix",
      href: "/quote-matrix",
      current: activeTab === "Quote Matrix" ? true : false,
    },
  ];
  const userNavigation = [
    { name: "Sign out", href: "#", onClick: handleSignOut },
  ];
  return (
    <>
      <div className="min-h-full">
        <Disclosure as="nav" className="border-b shadow-md">
          {({ open }) => (
            <>
              <div className="mx-auto px-4 sm:px-6 lg:px-8 h-[80.83px] flex items-center justify-between">
                <div className="flex h-16 items-center justify-between w-full">
                  <div
                    className="flex-shrink-0 font-inter font-bold text-[24px] leading-[35.17px] text-black cursor-pointer"
                    onClick={() => {
                      return (window.location.href = "/inspections");
                    }}
                  >
                    <FacilgoIcon />
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-14">
                      {navigation.map((item) => (
                        <a
                          key={item?.name}
                          href={item?.href}
                          onClick={
                            item?.onClick ? (e) => item.onClick(e) : undefined
                          }
                          className={classNames(
                            item?.current
                              ? "text-[#006CDB] underline"
                              : "text-[#848484]",
                            "font-inter px-3 py-2 text-sm md:text-[18px] md:leading-[26.37px] font-medium"
                          )}
                          aria-current={item?.current ? "page" : undefined}
                        >
                          {item?.name}
                        </a>
                      ))}
                    </div>
                  </div>

                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center gap-6 md:ml-6">
                      <div className="flex items-center font-inter font-normal text-[16px] leading-[23.44px] text-[#727272]">
                        Welcome back, {user.name}!
                      </div>
                      <LogoutIcon
                        onClick={handleSignOut}
                        className="cursor-pointer"
                      />
                    </div>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item?.name}
                      as="a"
                      href={item?.href}
                      onClick={
                        item?.onClick ? (e) => item.onClick(e) : undefined
                      }
                      className={classNames(
                        item?.current
                          ? "bg-gray-900 text-white"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white",
                        "block rounded-md px-3 py-2 text-base font-medium"
                      )}
                      aria-current={item?.current ? "page" : undefined}
                    >
                      {item?.name}
                    </Disclosure.Button>
                  ))}
                </div>
                <div className="border-t border-gray-700 pb-3 pt-4">
                  <div className="mt-3 space-y-1 px-2">
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item?.name}
                        as="a"
                        href={item?.href}
                        onClick={item?.onClick}
                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                      >
                        {item?.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <main>
          <div className="sm:px-8 lg:px-10 sm:py-8 lg:py-9">
            {children}
          </div>
        </main>
      </div>
    </>
  );
}