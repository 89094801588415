import React, { useState, useEffect, useRef } from "react";
import { Table } from "rsuite";
import { RadioCell } from "../../components/index";
import { PencilIcon, SortIcon } from "../../assets";
import isEmpty from "../../utils/isEmpty";
const { Column, HeaderCell, Cell } = Table;

function BuildingGrid({ tableData, onSelect, selectedOption, editBuilding, searchText, isLoading, sort }) {
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [prevSortByColumn, setPrevSortByColumn] = useState("");
  const [sortOrder, setSortOrder] = useState(1);
  const [baseClassName, setBaseClassName] = useState("border-b-[1px] border-solid border-[#C8C8C8]");
  const dataRef= useRef(tableData);
  let sortObject = {};

  const handleCheck = (value) => {
    setCheckedKeys([value]);
    const selectedBuilding = dataRef.current?.find((building) => building.id === value);
    if (selectedBuilding) {
      const { id, name } = selectedBuilding;
      onSelect({ id: id, name: name });
    }
  };

  if(selectedOption?.length === 1) {
    dataRef.current = selectedOption;
  } else if (checkedKeys.length === 0 && isEmpty(searchText)) {
    dataRef.current = tableData;
  }

  let filteredData = checkedKeys?.length === 1 && selectedOption[0]?.id === checkedKeys[0] ? dataRef.current?.filter((building) => building.id === checkedKeys[0]) : dataRef.current;
  if (tableData?.length === 1 && checkedKeys.length === 0){
    filteredData = tableData;
    handleCheck(filteredData[0]?.id)
  }
  
  const handleSort = (column) => {
    sortObject = { sort_by: column, sort_order: sortOrder };
    if (prevSortByColumn === column) {
      sortObject.sort_order = (-1*sortObject?.sort_order);
      setSortOrder(sortObject?.sort_order);
    }
    setPrevSortByColumn(column);
    if(checkedKeys.length === 0 && filteredData?.length > 1)
      sort(sortObject);
  };

  useEffect(() => {
    const rowClassName = () => {
      if (filteredData && filteredData?.length > 1) {
        setBaseClassName("border-b-[1px] flex align-center border-solid border-[#C8C8C8]");
      } else {
        setBaseClassName("border-0");
      }
    };
    rowClassName();
  }, [filteredData]);

  useEffect(() => {
    setCheckedKeys([]);
  }, [tableData]);

  return (
    <Table
      className="w-full border"
      loading={isLoading}
      data={filteredData}
      rowClassName={baseClassName}
      height={filteredData && filteredData?.length > 1 ? 410 : 88}
      locale={{emptyMessage: <div className="flex pl-6 italic font-lato text-[16px]">No Record(s) Found</div>, loading: "Loading"}}
    >
      <Column width={50} align="center">
        <HeaderCell style={{ padding: 0, backgroundColor: "#E9ECF0" }} className="font-lato font-medium text-[16px] text-[#25282B] text-center">
          <div style={{ lineHeight: '40px', backgroundColor: "#E9ECF0" }} className="font-lato font-medium text-[16px] text-[#25282B] text-center"></div>
        </HeaderCell>
        <Cell>
          {(rowData, rowIndex) =>{
            return <RadioCell dataKey="id" onChange={handleCheck} rowData={rowData} checkedValue={checkedKeys?.length === 1 && checkedKeys[0] === selectedOption[0]?.id ? checkedKeys[0] : checkedKeys[0] !== selectedOption[0]?.id ? selectedOption[0]?.id : checkedKeys[0]} componentID={`buildingGrid_select${rowIndex}`}/>
          }}
        </Cell>
      </Column>
      <Column flexGrow={20}>
        <HeaderCell
          style={{
            backgroundColor: "#E9ECF0",
            display: "flex",
            alignItems: "center",
          }}
          className="font-lato font-medium text-[16px] text-[#25282B]"
        >
          <span className="gap-2 flex justify-center items-center" onClick={() => handleSort("name")}>Building Name<SortIcon/></span>
        </HeaderCell>
        <Cell>
          {(rowData, rowIndex) =>{
            return <span className="font-lato font-medium text-[16px] text-[#25282B]">{rowData.name ? rowData.name : "-"}</span>
          }}
        </Cell>
      </Column>
      <Column flexGrow={20}>
        <HeaderCell
          style={{
            backgroundColor: "#E9ECF0",
            display: "flex",
            alignItems: "center",
          }}
          className="font-lato font-medium text-[16px] text-[#25282B]"
        >
          Roof Type
        </HeaderCell>
        <Cell>
          {(rowData, rowIndex) =>{
            return <span className="font-lato font-medium text-[16px] text-[#25282B]">{rowData.roof_type ? rowData.roof_type : "-"}</span>
          }}
        </Cell>
      </Column>
      <Column flexGrow={10}>
        <HeaderCell
          style={{
            backgroundColor: "#E9ECF0",
            display: "flex",
            alignItems: "center",
          }}
          className="font-lato font-medium text-[16px] text-[#25282B]"
        >
          Stories
        </HeaderCell>
        <Cell
          dataKey="email"
          className="font-lato font-medium text-[16px] text-[#25282B]"
        >
         {(rowData, rowIndex) =>{
           return <span className="font-lato font-medium text-[16px] text-[#25282B]">{rowData.stories_count ? rowData.stories_count  : "-"}</span>
         }}
        </Cell>
      </Column>
      <Column flexGrow={20}>
        <HeaderCell
          style={{
            backgroundColor: "#E9ECF0",
            display: "flex",
            alignItems: "center",
          }}
          className="font-lato font-medium text-[16px] text-[#25282B]"
        >
          Status
        </HeaderCell>
        <Cell>
          {(rowData, rowIndex) =>{
            return <span className="font-lato font-medium text-[16px] text-[#25282B]">{rowData.is_disabled ? "Disabled" : "Enabled"}</span>
          }}
        </Cell>
      </Column>
      <Column width={75} align="center">
        <HeaderCell
          style={{
            backgroundColor: "#E9ECF0",
            display: "flex",
            alignItems: "center",
          }}
          className="font-lato font-medium text-[16px] text-[#25282B]"
        >
        </HeaderCell>
        <Cell>
          {(rowData, rowIndex) =>{
            return <PencilIcon id={`floorplanGrid_edit${rowIndex}`} onClick={() => editBuilding(rowData.id)}/>
          }}
        </Cell>
      </Column>
    </Table>
  );
}

export default BuildingGrid;